import React, {useState, Fragment, useEffect} from "react";
import { Link } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import voskoolLogo from 'images/voskool_logo.png';
import LoginPage from 'components/loginPage';
import authHandler from "actions/auth_handler";
import { load_user } from "actions/auth";
import UserProfile from "components/userProfile";
import { Wrapper, VerticalNav, HorizontalNav, LogoContent, TextContent, BigTextContent, ImgContent, SearchContent, AuthContent } from 'components/navbar/style';


const NavBar = () => {
    const navArray = [
        { "id":1, "name":"Home", "ref":"?home", "icon_dd":"fas fa-home", "icon":null, "title":"Home", "drop_down":[] },
        { "id":2, "name":"Schools", "ref":"/", "icon_dd":"fas fa-school", "icon":null, "title":"Schools", "drop_down":[] },
        { "id":3, "name":"Plans & Pricing", "ref":"/", "icon_dd":"fas fa-coins", "icon":null, "title":"Plans and Pricing", "drop_down":[] },
        // { "id":4, "name":"Blog", "ref":"/", "icon_dd":"fas fa-blog", "icon":null, "title":"Blog", "drop_down":[] },
        // { "id":5, "name":"Services", "ref":"/", "icon_dd":"fas fa-arrow-right fontIcon", "icon":"fas fa-arrow-down fontIcon",  "title":"Services", "drop_down":[{"id":1, "ref":"/", 'name':"Job Offers", "icon":"fas fa-building dropDownFontIcon", "icon_dd":"fas fa-building dropDownFontIcon", "title":"Become A Teacher"}, 
        //     {"id":2, 'name':"Need Help, Chat Us Up", "ref":"/", "icon":"fas fa-comment dropDownFontIcon", "icon_dd":"fas fa-comments", "title":"Having Trouble On Something, Chat Us Up "}] },
        { "id":6, "ref":"/", "name":"About", "icon_dd":"fas fa-info-circle", "icon":null, "title":"About Us", "drop_down":[] },    
    ]
    const [horNav, setHorNav] = useState(false);
    const [state] = useState(navArray);
    const [dropDownDisp, setDropDownDisp] = useState(false);
    const [viewLoginPage, setViewLoginPage] = useState(false);
    const [user, setUser] = useState({
        "type":"",
        "data": null,
        "isAuthenticated":false,
        "code":0,
        "detail":""
    });

    const fetchUser =  async () => {
        
        await authHandler.load_user(load_user()).then(result => {
            setUser(result);
        })
    }
    useEffect(() => {
        fetchUser()
    }, []);
    
    const onClickLink = () => {
        if(dropDownDisp){
            setDropDownDisp(false);
        }
        else{
            setDropDownDisp(true);
        }
    }
    
    const onClickBurger = () => {
        if(horNav === false){
            setHorNav(true);
        }
        else{
            setHorNav(false);
        }

    }

    const authData = () => {
        if(user.isAuthenticated){
            return(
                <AuthContent>
                    <UserProfile user={user} />
                </AuthContent>
            )
        }
        else{
            return(
                <AuthContent>
                    <div className="log">
                        <Link to={`/signup`} target="_blank">Sign up <i className="fas fa-user-plus"></i></Link>
                    </div>
                    <div className="log">
                        <span onClick={() => setViewLoginPage(true)}>Login <i className="fas fa-sign-in-alt"></i></span>
                    </div>
                </AuthContent>
            )
        }
    }
    
    
    const HorizontalNavArray = state.map((item, index) => {
        const dropDown = item.drop_down.map((dd) => {
            return(
                <Fragment key={dd.id.toString()}>
                    <div className={dropDownDisp ? "disp_show" : "disp_none"}>
                        <div>
                            <Link key={dd.id.toString()} to={dd.ref} title={dd.title}>{dd.name} <i className={dd.icon_dd}></i></Link>
                        </div>
                    </div>
                </Fragment>
            );
        })
        if(item.drop_down.length > 0){
            return(
                <Fragment key={item.id.toString()}>
                    <div className="navLinks">
                        <span title={item.title} onClick={onClickLink}>{item.name}</span>    
                        {dropDown}
                    </div>
                    <div key={item.id.toString()} className="navIcons" onClick={onClickLink}>
                        <i  className={ dropDownDisp ? "fas fa-arrow-down" : "fas fa-arrow-right"}></i>
                    </div>
                </Fragment>
            );
        }
        else{
            return(
                <Fragment key={item.id.toString()}>
                    <div className="navLinks">
                        <Link key={item.id.toString()} to={item.ref} className="" title={item.title}>
                            {item.name}
                        </Link>
                    </div>
                    <div className="navIcons">
                        <i className={item.icon_dd}></i>
                    </div>
                </Fragment>
            )
        }
    })
    
    const VerticalNavArray = state.map((item, index) => {
        if(item.name.length < 8){
            return(
                <TextContent key={item.id.toString()}>
                    <Link to={item.ref} className={item.icon} title={item.title}>
                        {item.name}
                    </Link>
                </TextContent>
            );
        }
        else{
            if(item.drop_down.length > 0){
                const dropDown = item.drop_down.map((dd) => {
                    return(
                        <Link key={dd.id.toString()} to={dd.ref} title={dd.title}>{dd.name} <i key={dd.id.toString()} className={dd.icon}></i></Link>
                    );
                })
                return(
                <BigTextContent key={item.id.toString()}>
                    <span>
                        {item.name} <i key={item.id.toString()} className={item.icon}></i>
                        <div>
                            <div>
                              {dropDown}
                            </div>
                        </div>
                    </span>
                </BigTextContent>
                );
                
            }
            else{
                return(
                    <BigTextContent key={item.id.toString()}>
                        <Link to={item.ref} key={item.id.toString()}  className={item.icon} title={item.title}>
                            {item.name}
                        </Link>
                    </BigTextContent>
                );
            }
        }
        
    })

    return(
        <>
            <Wrapper>
                <VerticalNav>
                    <LogoContent onClick={ onClickBurger }>
                        <i className={horNav ? "fas fa-bars anim_icon" : "fas fa-bars"}></i>
                    </LogoContent>
                    <ImgContent>
                        <Link to="/">
                            <img src = { voskoolLogo } alt="Logo" title="A platform entrenched with the aim of connecting education and schools to parents and students" />
                        </Link>    
                    </ImgContent>

                    {VerticalNavArray}
                    
                    <SearchContent>
                        <div>
                            <input type="text" title="Search For Your School" placeholder="Search For Your School" name="school_search" />
                            <i className="fas fa-search"></i>
                        </div>
                    </SearchContent>
                    {authData()}
                </VerticalNav>
                <HorizontalNav className={horNav ? "disp_show" : "disp_none"}>
                    
                    <div className="header">
                        <h5>Navigation Bar</h5>
                    </div>
                    { HorizontalNavArray }
                </HorizontalNav>
            </Wrapper>
           <AnimatePresence
            initial={false}
            exitBeforeEnter={true}
            onExitComplete={() => null}
           >
               {viewLoginPage && <LoginPage handleClose={() => setViewLoginPage(false)} />}
           </AnimatePresence>
           

        </>
    );
}
export default NavBar;