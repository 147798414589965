import React from "react";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';

import { HeadComponent } from "components/head";

const TermsOfService = () => {
    let text = `<h1>Terms of Service</h1>
<p>These terms of service ("Terms") govern your use of our services at Voskool, a technology company offering various products and solutions. By accessing and using our services, you agree to abide by these Terms. If you do not agree to these Terms, please refrain from using our services.</p>
<h2>1. Acceptable Use</h2>
<p>You agree to use our services only for lawful purposes and in a manner that does not violate any applicable laws or regulations. You shall not engage in any unauthorized activities or misuse our services, including but not limited to:</p>
<ul>
    <li>Attempting to gain unauthorized access to our systems or user accounts</li>
    <li>Distributing malware, viruses, or any harmful software</li>
    <li>Interfering with the proper functioning of our services</li>
    <li>Collecting or harvesting user data without proper consent</li>
</ul>
<h2>2. Intellectual Property</h2>
<p>All content and materials provided through our services, including but not limited to software, logos, trademarks, and any other intellectual property, are owned or licensed by Voskool. You are granted a limited, non-exclusive, and non-transferable license to use our services for personal or internal business purposes. You shall not modify, reproduce, distribute, or create derivative works based on our intellectual property without our explicit permission.</p>
<h2>3. Privacy</h2>
<p>Your privacy is important to us. We collect and process personal data in accordance with our Privacy Policy, which is incorporated by reference into these Terms. By using our services, you consent to the data practices outlined in our Privacy Policy.</p>
<h2>4. Third-Party Content</h2>
<p>Our services may include links to third-party websites or content that is not owned or controlled by Voskool. We do not endorse or assume any responsibility for such third-party content. You access third-party content at your own risk and should review the terms and policies of those websites or services.</p>
<h2>5. Limitation of Liability</h2>
<p>Voskool and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use our services. This includes but is not limited to damages for loss of profits, data, or other intangible losses, even if Voskool has been advised of the possibility of such damages.</p>
<h2>6. Indemnification</h2>
<p>You agree to indemnify and hold harmless Voskool, its officers, directors, employees, and agents from any claims, damages, liabilities, or expenses (including legal fees) arising out of your use of our services or any breach of these Terms.</p>
<h2>7. Changes to the Terms</h2>
<p>Voskool reserves the right to modify or update these Terms at any time without prior notice. Changes will be effective upon posting to our website. It is your responsibility to review these Terms regularly for any updates. Continued use of our services after any changes indicate your acceptance of the updated Terms.</p>
<h2>8. Governing Law</h2>
<p>These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any legal action or proceeding arising out of or related to these Terms shall be brought exclusively in the courts of [Jurisdiction].</p>
<h2>9. Contact Us</h2>
<p>If you have any questions or concerns regarding these Terms or our services, please contact us at:</p>
<address>
    Email: voskool@voskool.com<br>
    Telephone: 0812 740 4244<br>
</address>
<p>Effective Date: 21/07/2023</p>
`;
    return(
        <>
        <HeadComponent title="Terms Of Service" />
        <div style={{padding:'20px'}}>
            {parse(text)}
            <h6>Click <Link to="/privacy_policy">here</Link> to view our privacy policy</h6>
        </div>
        </>
    )
}

export default TermsOfService;