import { styled } from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background: var(--appBgColor);
    opacity: .75;
    z-index: 10;
    .eee{
        color:#ccc;
    }
    .flex-70-my{
        flex: 0 0 70%;
        max-width: 70%;
    }
    .flex-30-my{
        flex: 0 0 30%;
        max-width: 30%;
    }
    .fixed{
        position: fixed;
    }
    .absolute{
        position: absolute;
    }
    .relative{
        position: relative;
        z-index: 10;
    }
    .mr-top-12px{
        margin-top: 12px;
    }
    .anim-ul{
        animation: anim_ul .5s ease ;
        -webkit-animation: anim_ul .6s ease;
        -moz-animation: anim_ul .6s ease;
        
        @keyframes anim_ul{
            from{
                transform: translateY(-100%);
            }
            to{
                transform: translateY(0%);
            }
        }
    }
    .anim_icon{
        animation: animIcon .6s ease;
        -webkit-animation: animIcon .6s ease;
        -moz-animation: animIcon .6s ease;
        transform: rotate(90deg);
        @keyframes animIcon{
            from{
                transform: rotate(0deg);
            }
            to{
                transform: rotate(90deg);
            }
        }
    }
    a.en-bg{
        border: 1px solid #fc1181;
        background: #fc1181;
        transition: .3s ease;
        border-radius: 20px;
    }
    a.en-bg:hover{
        background: #fc1181 !important;
        color: #ccc;
        border: none;
        transform: scale(.95);
    }
`;
export const NavContent = styled.div`
    width: 100%;
    
    padding: 0;
    box-sizing: border-box;
    position: relative;
    ul{
        list-style: none;
        overflow: hidden;
        background: #000000e1;
        /* box-shadow: 5px 4px 7px 4px rgba(0,0,0,0.5); */
        border-top-left-radius: 10px;
        border-bottom-left-radius: 15px;
        margin: 0;
        padding: 0;
        li{
            float: left;
        }
        li.float-right{
            float: right !important;
        }
        li.user-icon{
            margin: 5px 0;
        }
        li.log-link{
        
            /* display: inline-block !important;
            border:none;
            a{
                display: inline-block !important;
                border:none;
            } */
        }
        li.log-link:hover{
            background: transparent;
        }
        
        
        li a, li span{
            display: block;
            padding: 5px 16px;
            text-decoration: none;
            color: #fff;
            opacity: .8;
            border-right: 1px solid #222;
            transition: .4s ease;
            background: #000000e2;
            cursor: default;
            i{
                padding-left: 3px;
                color: #ccc;
            }
        }
        li a:hover:not(.active), li span:hover:not(.active){
            background: var(--appBgColor);
        }
        li ul{
            display: none;
            position : absolute;
            min-width: 150px;
            max-height: 70vh;
            border-top-left-radius: 0px;
            border-top-right-radius: 15px;
            border-bottom-left-radius: 5px;
            box-shadow: 0 0 3px 2px rgba(0,0,0,0.3);
            li{
                float: none;
                border-bottom: 1px solid #333;
                
                .active{
                    background: var(--appBgColor);
                }
            }
        }
        li:hover > a, li:hover > span{
            background: var(--appBgColor);
        }
        li:hover ul {
            display: block;
        }
        .active{
            background: var(--appBgColor);
        }
    }
`;
export const NavAbsContent = styled.div`
    width: 100%;
    margin: 0;
    padding: 0;
    ul{
        list-style: none;
        overflow: hidden;
        /* box-shadow: 5px 4px 7px 4px rgba(0,0,0,0.5); */
        border-top-left-radius: 10px;
        border-bottom-left-radius: 15px;
        margin: 0;
        z-index: 10;
        padding: 0;
        .d-none{
            display: none;
            @media(max-width: 780px){
                display: inline-block;
            }
        }
        .d-none2{
            display: none;

            @media(max-width: 780px){
                display: block;

                ul{
                    position: relative !important;
                }
            }
        }
        .d-block{
            display: inline-block;

            @media(max-width: 780px){
                display: none;
            }
        }
        li.li-nav{
            float: left;
            @media(max-width:780px){
                float: none !important;
                display: none;
            }
        }
        /* li{
            float: left;
        } */
        li.float-right{
            float: right !important;
            padding: 0 5px;
        }
        li.float-left{
            float: left;
        }
        li.user-icon{
            margin: 5px 0;
        }
        li a, li span{
            display: block;
            padding: 5px 16px;
            text-decoration: none;
            color: #fff;
            opacity: .8;
            border-right: 1px solid #222;
            transition: .4s ease;
            background: #000000e2;
            cursor: default;
            i{
                padding-left: 3px;
                color: #ccc;
            }
        }
        li a:hover:not(.active), li span:hover:not(.active){
            background: var(--appBgColor);
        }
        li ul{
            display: none;
            min-width: 150px;
            max-height: 70vh;
            border-top-left-radius: 0px;
            border-top-right-radius: 15px;
            border-bottom-left-radius: 5px;
            box-shadow: 0 0 3px 2px rgba(0,0,0,0.3);
            li{
                float: none;
                border-bottom: 1px solid #333;
                
                .active{
                    background: var(--appBgColor);
                }
            }
        }
        li:hover > a, li:hover > span{
            background: var(--appBgColor);
        }
        li:hover ul {
            display: block;
        }
        li.user{
            
            .user-data{
                display: none;
                z-index: 1;
                background: #fff;
                border-radius: 15px;
                box-shadow: 0 0 3px 5px rgba(0,0,0,0.1);
                transform: translateX(-90%);
                padding: 10px;
                transition: .4s ease;
                width: 200px;
                @media(max-width: 450px){
                    width: 180px;
                }
                /* @media(max-width: 375px){
                    transform: translateX(-70%);
                } */
                .main-header{
                    
                    display: flex;          
                    .header{
                        justify-content: space-between;
                        padding: 0 5px;
                        width: 100%;
                        margin-bottom: 4px;
                        .heading{
                            font-weight: 600;
                            color: #555;
                            text-transform: lowercase;
                            background: transparent;
                            border-right: none;
                        }
                        .text{
                            font-weight: 500;
                            text-transform: lowercase;   
                            background: transparent;
                            border-right: none;
                        }
                    }
                }
                .data-content{
                margin: 10px 0;
                
                .header{
                    margin-top: 10px;
                    margin-bottom: 8px;
                    span{
                        font-weight: 700;
                        color: #555;
                        background: transparent;
                        border-right: none;
                    }
                }
                .flex{
                    display: flex;
                    justify-content: space-between;
                }
                div.data-flex-cont{
                    text-decoration: none;
                    background: transparent;
                    border-right: none;
                    .data-flex{
                        display: flex;
                        flex-wrap: wrap;
                        padding: 6px;
                        margin-bottom: 6px;
                        transition: .4s ease;
                        border-bottom: 1px solid #ddd;
                        border-radius: 5px;
                        cursor: pointer;
                        :hover{
                            border-bottom: 1px solid var(--voskoolBagColor);
                        }
                        .data-img{
                            flex: 0 0 17%;
                            max-width: 17%; 
                            background: transparent;
                            img{
                                width: 20px;
                                height: 20px;
                                background: transparent;
                                border-right: none;
                            }
                        }
                        .datas-img{
                            flex: 0 0 20%;
                            max-width: 20%; 
                            background: transparent;
                            img{
                                width: 24px;
                                height: 24px;
                                background: transparent;
                                border-right: none;
                            }
                            i{
                                font-size: 1.2rem;
                                color: var(--voskoolBagColor);
                                background: transparent;
                                border-right: none;
                            }
                        }
                        .datas{
                            flex: 0 0 80%;
                            max-width: 80%; 
                            background: transparent;
                            span{
                                font-size:.7rem;
                                font-weight: 700;
                                display: block;
                                color: #666;
                                text-transform: capitalize;
                                padding-top: 5px;
                                padding-bottom: 1px;
                                padding-left: 3px;
                                background: transparent;
                                border-right: none;
                            }
                        }
                        .data{
                            flex: 0 0 78%;
                            max-width: 78%; 
                            
                            .heading{
                                font-size:.7rem;
                                font-weight: 700;
                                display: block;
                                color: #111;
                                text-transform: uppercase;
                                padding-bottom: 1px;
                                background: transparent;
                                border-right: none;
                            }
                            .text{
                                font-size:.65rem;
                                font-weight: 600;
                                display: block;
                                color: #888;
                                text-transform: capitalize;
                                background: transparent;
                                border-right: none;
                            }
                        }
                        .data-icon{
                            flex: 0 0 5%;
                            max-width: 5%;

                            i{
                                font-size:.6rem; 
                                padding: 3px;
                                background: var(--voskoolBagColor);
                                border-radius: 50%;
                                color: #eee;
                                border-right: none;
                            }
                        }
                    }
                }
            }
            }
        }
        li.user:hover .user-data{
            display: block;
        }
        .active{
            background: var(--appBgColor);
        }
    }
`;
export const ImgContent = styled.div`
    flex: 0 0 15%;
    max-width: 15%;
    padding: 3px;
    @media (max-width: 1280px) {
        flex: 0 0 12%;
        max-width: 12%;
    }
    @media (max-width: 780px){
        flex: 0 0 20%;
        max-width: 20%;
    }
    img{
        width: 70%;
        height: 90px;

        @media (max-width: 1280px) {
            width: 90%;
            height: 80px;
        }
        @media (max-width: 780px) {
            height: 70px;
        }
    }
`;

export const TextContent = styled.div`
    flex: 0 0 85%;
    max-width: 85%;
    padding: 3px;
    @media (max-width: 1280px) {
        flex: 0 0 88%;
        max-width: 88%;
    }
    @media (max-width: 780px){
        flex: 0 0 80%;
        max-width: 80%;
    }
`;