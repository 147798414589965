import React, { useState, useEffect, useMemo, Suspense, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { SchoolWebHomeWrapper,  HomeContent } from 'containers/home/style';
import Notification from "components/app/notification";
import  NavBar  from 'components/school/navBar';
import Header, {HeaderDesc} from "components/school/header";
import Grid from "components/grid";
import Footer from "components/app/footer";
import ClassPlugin from "./component/classPlugin";
import Loader from 'components/app/loader';
import PreloaderLine from "components/preloaderLine";
import { previewer } from "./action/previewer";
import { colorThemePallete } from "./action/colorManager";

import { NotificationWrapper } from "components/app/notification/style";

// import home2 from 'images/home2.jpg';

import { appServices, appServices5 } from "api/schoolApp/services";
import { addItemToArray } from 'components/app/actions/array-utils/Add';
import { HeadComponent } from "components/head";

import { BACKEND_URL } from "actions/url";


const SchoolWebsite = ({user}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);

    const [error, setError] = useState(initialErrorState);

    const [state, setState] = useState({id:0});
    const [isOk, setIsOk] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const [preloading2, setPreloading2] = useState(true);
    const [reload, setReload] = useState(true);
    const [reloadAll, setReloadAll] = useState(true);

    const [allClassSection, setAllClassSection] = useState([]);
    const [allClass, setAllClass] = useState([]);
    const [allWeb, setAllWeb] = useState([]);
    const [webConfig, setWebConfig] = useState([]);

    const {sname} = useParams();

    const fetchSchool = (sname="") => {
        setError({data:[], title:''});
        setPreloading(true);
        setError({data:[], title:''});
        appServices.getSchool(sname).then(res => {
            setIsOk(true);
            setPreloading(false);
            setState(res.data);
        }).catch(e => {
            setIsOk(false);
            setState({id:0});
            setPreloading(false);
            setPreloading2(false);
            //setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Data Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
        })
    }
    const fetchAllClassSection = (sid) => {
        setError({data:[], title:''});
        appServices.getAllClassSectionSchool(sid).then(res => {
            setAllClassSection(addItemToArray(res.data, {from_age_value:'', to_age_value:'', desc_value:'', img_value:null, img_value_value:'', is_add_image:false, is_age_change:false}));
        }).catch(e => {
            setAllClassSection([]);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Class Section Error'});
        })
    }
    const fetchAllClass = (sid) => {
        setError({data:[], title:''});
        appServices.getAllClassSchoolItem(sid).then(res => {
            setAllClass(addItemToArray(res.data));
            setPreloading2(false);
        }).catch(e => {
            setPreloading2(false);
            setAllClass([]);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Class Section Error'});
        })
    }
    const fetchAllWeb = (sid) => {
        setError({data:[], title:''});
        appServices5.getAllWeb(sid).then(res => {
            setAllWeb(addItemToArray(res.data));
        }).catch(e => {
            setAllWeb([]);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Web Link Data Fetch Error'});
        })
    }
    const fetchAllWebLink = (sid, initConfig={}) => {
        setError({data:[], title:''});
        appServices5.getAllWebLink(sid).then(res => {
            setWebConfig(addItemToArray(res.data, {layout:'', ...initConfig}));
            setPreloading(false);
        }).catch(e => {
            setWebConfig([]);
            setPreloading(false);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Web Link Data Fetch Error'});
        })
    }

    useEffect(() => {
        // let arr = ['result_checker']
        // if(arr.includes(sname)){
        //     setIsOk(true);
        //     setPreloading(false);
        //     return;
        // }
        if(sname && reloadAll){
            setReload(true);
            setReloadAll(false);
            fetchSchool(sname);
        }
    }, [sname, reloadAll])

    useEffect(() => {
        if(state.id && reload){
            const school_id = state.id;
            setReload(false);
            fetchAllWebLink(school_id);
            fetchAllClassSection(school_id);
            fetchAllWeb(school_id);
            fetchAllClass(school_id);
        }
    }, [state, reload])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const dd = webConfig.filter((it) => it.type !== "home").map((it) => {return {name:it.title, link:`#${it.type === "class" ? 'enroll' : it.type.split(' ').join('_')}`}})

    return(
        <Suspense fallback={<div style={{width:'100%', background:'#000', height:'100vh'}}><Loader /></div>}>
        <NotificationWrapper>
            {error.title && (errorHandler)}
        </NotificationWrapper>
        {preloading2 && <PreloaderLine />}
        {!preloading && (
            <>
            {isOk ? (
                <>
                <HeadComponent title={`${state.title.toUpperCase()} School Website`} />
                <NavBar is_join={state.is_start_student_enroll} school={state} icon={state.icon} user={user.data}
                    full_name={state.name} short_name={state.title}
                    links={[{name:'Home', link:'#home', dd:[], is_active:true, icon:'fas fa-key'}, 
                        {name:'About Us', link:'', icon:'', is_active:false, dd:dd},
                        {name:'Result Checker', link:'/result_checker', dd:[], is_active:false, icon:'fas fa-key'}, ]} />
                {webConfig.length > 0 && (
                    <>
                    {webConfig.map((item, i) => {
                        const all_web = allWeb.filter((it) => it.school === item.id);
                        const color = colorThemePallete(item.theme);
                        return(
                            <Fragment key={i}>
                                <section id={`${item.type === "class" ? 'enroll' : item.type.split(' ').join('_')}`}>
                                {item.is_plugin ? (
                                    <>
                                    {item.plugin_type === "class" && (
                                        <>
                                        {all_web.map((item_web, j) => {
                                            return (
                                                <Fragment key={j}>
                                                    <div className={item.theme}>
                                                        <SchoolWebHomeWrapper>
                                                            {item_web.header_title && <Header classname={color.app_text_color} title={item_web.header_title} is_placeholder={false}/> }
                                                            <HomeContent style={{margin:0, padding:0, marginBottom:'10px'}}>
                                                                <HeaderDesc text={item_web.header_title_desc} />
                                                                <Grid grid_width={"big-w"}>
                                                                    {allClassSection.map((item_class, i) => {
                                                                        const cls = allClass.filter((it) => it.class_section_school === item_class.id).map((it) => { return {...it, to:user.isAuthenticated ? (state.is_start_student_enroll ? `/vo/enroll/${state.title}` : '') : `/login?red_url=${state.title}`}})
                                                                        return(
                                                                            <Fragment key={i}>
                                                                                <ClassPlugin title={`${item_class.name} section`} class_arr={cls} from_age={item_class.from_age} to_age={item_class.to_age} text={item_class.desc ? item_class.desc : ''} img_src={item_class.pic ? `${BACKEND_URL}${item_class.pic.pic}` : ''} />                                                                                                      
                                                                            </Fragment>
                                                                        )
                                                                    })}
                                                                    
                                                                </Grid>
                                                            </HomeContent>
            
                                                        </SchoolWebHomeWrapper>
                                                    </div>
                                                </Fragment>
                                            )
                                        } )}
                                        </>
                                    )}
                                    </>
                                ) : (
                                    <>
                                    <div className={'app-body-color'} style={{marginBottom:'5px'}}>
                                        {all_web.map((item_web, j) => (
                                            <Fragment key={j}>
                                                {previewer(item_web.is_split ? 2 : 1, item.type, item_web.header_title, {content_type:item.type === "home" ? 'img' : (item_web.pic.length > 0 ? 'img' : 'text'), preface:item_web.preface, title:item_web.title, desc:item_web.desc, text:item_web.text, img:item.type === "home" ? state.pic : ''}, {content_type:'text', preface:item_web.preface2, title:item_web.title2, desc:item_web.desc2, text:item_web.text2}, item.theme)}
                                            </Fragment>
                                        ))}
                                        {/* <div className='disp-flex wrap mr-t-5px'>
                                            <div className='flex-50-line align-center'>
                                                <button type='button' className='btn br app-bg-text-color bg-black font-slightly-small hover-opacity' onClick={() => setIsPreview(false)}><i className='fas fa-arrow-left'></i> Back</button>
                                            </div>
                                            <div className='flex-50-line align-center'>
                                                <button type='button' className='btn br app-bg-text-color app-bg-color font-slightly-small hover-opacity' onClick={() => onClickNext(item.num, item.uid)}>{webConfig.length-1 === item.num ? 'Finish' : 'Next Page'}  <i className='fas fa-arrow-right'></i></button>
                                            </div>
                                        </div> */}
                                    </div>
                                    </>
                                )}
                                </section>
                            </Fragment>
                        )
                    })} 
                    {state.is_start_staff_enroll && (
                        <div className={'app-theme padd-4px'}>
                            <SchoolWebHomeWrapper>
                                <span className="disp-block font-big padd-4px app-text-color fw-600"> Are you looking for a job? <Link target="_blank" to={`/vo/preroll/${state.title}`}> Apply Now </Link></span>
                            </SchoolWebHomeWrapper>
                        </div>
                    )}
                    
                   <Footer>
                    <div className="flex-100">
                        <span className="disp-block font-big padd-4px app-opp-text-color fw-600">CONTACT US</span>
                        {/* <div className="disp-flex wrap jc-stretch padd-4px">
                            <div className="padd-4px">
                                <i className="app-bg-color-text fas fa-paper-plane"></i>
                            </div>
                            <div  className="padd-4px">
                                <span className="font-small fw-500 app-opp-text-color">1 Lamborgini Street, Apapa Lagos State</span>
                            </div>
                        </div> */}
                        <div className="disp-flex wrap jc-stretch padd-4px">
                            <div className="padd-4px">
                                <i className="app-bg-color-text fas fa-square"></i>
                            </div>
                            <div  className="padd-4px">
                                <HashLink to={`/${state.title}`} className="font-small fw-500 app-opp-text-color">www.voskool.com/{state.title}</HashLink>
                            </div>
                        </div>
                        <div className="disp-flex wrap jc-stretch padd-4px">
                            <div className="padd-4px">
                                <i className="app-bg-color-text fas fa-phone"></i>
                            </div>
                            <div  className="padd-4px">
                                <span className="font-small fw-500 app-opp-text-color">{state.phone}</span>
                            </div>
                        </div>
                        {/* <div className="disp-flex wrap jc-stretch padd-4px">
                            <div className="padd-4px">
                                <i className="app-bg-color-text fas fa-phone"></i>
                            </div>
                            <div  className="padd-4px">
                                <span className="font-small fw-500 app-opp-text-color">+234 81 33172441</span>
                            </div>
                        </div> */}
                        {state.email && (
                            <div className="disp-flex wrap jc-stretch padd-4px">
                                <div className="padd-4px">
                                    <i className="app-bg-color-text fas fa-phone"></i>
                                </div>
                                <div  className="padd-4px">
                                    <Link to={`mailto:${state.email}`} className="font-small fw-500 app-opp-text-color">{state.email}</Link>
                                </div>
                            </div>
                        )}
                        
                        </div>
                    </Footer>
                </>
                )}
                </>
            ) : (
                <></>
            )}
            </>
        )}
        </Suspense>
    )
}

export default SchoolWebsite;