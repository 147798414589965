import React from "react";
import { Link } from "react-router-dom";

import PlansAndPricing from 'containers/plansAndPricing';
import ComponentHeader from "components/compoHeader";
import moneyFast from "images/money_hand_holding.jpg";
import countryLoc from "images/countries_loc.jpg";
import teacherTeaching from "images/teacher_teaching.jpg";
import resultImg from "images/result.jpg";
import assistantImg from "images/assistant.jpg";
import Chunk from 'components/chunk';

const SchoolOffers = () => {
    return(
        <>
        <section id="offer">
            <ComponentHeader title={"What we offer..."} numIcons={5} />
            <Chunk img={moneyFast} alt="Money" title="Fast And Secure Online Payment...." desc={"Combining speed, convenience and top-notch security, we ensure that your parent(s) and student(s) enjoy a seamless online payment to your school without headache. We offer different varieties of payment options using our tested payment gateway for a hitch-free payment. Our payments include : Pay with bank, with transfer and more"} img_top_resp={true} is_put_img={true} is_put_img_right={true} />
            <Chunk img={countryLoc} alt="access" title="Access To Different Location(s) Of Your School..." desc={"With the voskool app school branching system, you can create and manage different school branches in their respective location with their respective users "} img_top_resp={true} is_put_img={true}  />
            <Chunk img={teacherTeaching} alt="live" title="Live Timetable Teaching Session...." desc={"With the voskool app live timetable teaching session system, admin, parents and students can  view synopsis of what your staff (teacher) is currently teaching at the stipulated time on the timetable  and students can give reviews about the topic taught all on realtime "} img_top_resp={true} is_put_img={true} is_put_img_right={true} />
            <Chunk img={resultImg} alt="result_checker" title="Online Result Checker..." desc={"Students can check their school result online with just their pin at the result checker portal"} img_top_resp={true} is_put_img={true}  />
            <Chunk img={assistantImg} title="24 Hrs Live Customer Support And More" is_put_img is_put_img_right img_top_resp>
                <p className="font-small">Get 24 hours uninterrupted customer support through our chat app or email address at <Link to="mailto:support@voskool.com">support@voskool.com</Link></p>
                <p className="font-small">Are you intrigued? click <Link to="/">here</Link> to see more of what we offer</p>
            </Chunk>
            <PlansAndPricing />
        </section>
        </>    
    );
}

export default SchoolOffers;