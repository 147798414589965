import parse from 'html-react-parser';

import { colorThemePallete } from '../../action/colorManager';

import {Container, HomeContent} from 'containers/home/style';

const OnePage= ({preface="", text="", bg_color="", img_src="", img_alt="",  arr=[], is_loading=false, preface_underbelly=''}) => {
    const color = colorThemePallete(bg_color);
    return(
        <>
        <Container>
            <HomeContent style={{background:bg_color, paddingTop:'3px'}}> 
                <div className="flex-100">
                    {img_src && (
                        <div className="align-center">
                            <img src={img_src} alt={img_alt} className="img-header" />
                        </div>
                    )}
                    <div className='disp-block'>
                        <span className={`disp-block font-super-big fw-700 ${color.app_text_color}`}>{preface}</span>
                    </div>
                    {preface_underbelly && <span className="disp-block font-bigger app-text-color fw-600" style={{paddingLeft:'10px'}}><b>({preface_underbelly})</b></span>}
                    <div className='disp-block'>
                        <span className={`disp-block fw-500 font-small ${color.app_text_color}`}  style={{letterSpacing:'.6px'}}>
                            {text && parse(text)}
                        </span>
                    </div>
                    {arr.length > 0 && (
                        <HomeContent style={{margin:0, padding:0, marginBottom:'10px'}}>
                            <ul>
                                {arr.map((text, i) => (
                                    <li key={i} className="font-slightly-small app-text-color fw-500">{text}</li>
                                ))}
                            </ul>
                        </HomeContent>
                    )}
                </div>
            </HomeContent>
        </Container>
        </>
    )       
}

export default OnePage;