import React from "react";
import { Wrapper, Content } from "./style";
import { default as icon } from "images/icon.svg";

const ComponentHeader = ({title, numIcons}) => {
    const insert_icons = (num, iconImg) => {
        const rows = [];
        for(let i = 0; i < num; i++){
            rows.push(<img src={iconImg} key={i} className={`${"icon-"}${i}`} alt="" />);
        }
        return rows;
    }
    return(
        <Wrapper>
            <Content>
                <div className="title_cont">
                    <h2>{title}</h2>
                </div>
                <div className="img_cont">    
                    {insert_icons(numIcons, icon)}
                </div>    
            </Content>
        </Wrapper>
    );
}

export default ComponentHeader;