import React from 'react';
// import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { Wrapper, Content } from "./style";
import {default as icon} from 'images/success_icon2.png';
import { default as voskool } from "images/voskool_logo.png";
import { default as icon404 } from "images/404.gif";

const Redirect = ({children, target1='', target2="", error=false, success=false, error404=false, title='Oops... Page Not Found!', link1='', btn1=false, btn1_title="", btn2=false, btn2_title="", onClickBtn1, onClickBtn2, link1_title='', link2='', link2_title=''}) => {
    return(
        <Wrapper>
            <Content>
                <div className="logo-container">
                    <img className="logo" src={voskool} alt="LOGO" />
                </div>
                
                {error404 && (
                    <div className="h-t err">
                        <span className="h err circle">404</span>
                    </div>
                )}
                {success && (
                    <div className="h-t suc">
                        <img className="success" src={icon} alt="Success" />
                    </div>
                )}
                {!error ? (
                    <div className="title">
                        <h2 className={success ? 'success' : ''}>{title}</h2>
                    </div>
                ) : (
                    <div className="h-t err">
                        <span className="h err circle">{title}</span>
                    </div>
                )}
                
                
                {error404 && (
                    <div className='i-404-container'>
                        <img className='i-404' src={icon404} alt="error" />
                    </div>
                )}
                {!error404 && (
                    <div className="text">
                        <h4>{children}</h4>
                    </div>
                )}
                
                <div className="link-container">
                    {link1 && (<NavHashLink to={link1} className='br' target={target1}>{link1_title}</NavHashLink>)}
                    {link2 && (<NavHashLink to={link2} className='bg' target={target2}>{link2_title}</NavHashLink>)}
                    {btn1 && (<button type="button" className='br' onClick={onClickBtn1}>{btn1_title}</button>)}
                    {btn2 && (<button type="button" className='bg' onClick={onClickBtn2}>{btn2_title}</button>)}
                    {/* <Link to="/" className='bg'>Go Back To Homepage</Link> */}
                </div>
            </Content>
        </Wrapper>
    )
}

export default Redirect;