import React, { useEffect, useState, useCallback } from "react";
// import { HashLink } from "react-router-hash-link";

import { Wrapper, ImgContent, TextContent } from "./style";

import { BACKEND_URL } from "actions/url";

import MainNav from "./mainNav";


const NavBar = ({icon="", full_name="", short_name="", desc="", links=[], user={}, is_join=true}) => {

    const [y, setY] = useState(window.scrollY);

    const handleScrollY = useCallback((e) => {
        const window = e.currentTarget;
        setY(window.scrollY);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScrollY);

        return () => {
            window.removeEventListener('scroll', handleScrollY);
        }
    }, [handleScrollY]);
    return(
        <>
            <Wrapper>
                <ImgContent>
                    {icon && (
                        <img src={`${BACKEND_URL}${icon}`} className="" alt="school" />
                    )}
                </ImgContent>
                <TextContent>
                    {full_name && (
                        <>
                            <span className="disp-block eee font-bigger text-captialize fw-600" style={{marginTop:'4px'}}>{full_name.length > 40 ? `${full_name.slice(0, 40)}...` : full_name} (<span className="text-upper">{short_name}</span>)</span>
                            {!desc && (
                                <div className="disp-inline">
                                    <span className="font-small fw-600 eee">Motto:</span> <span className="font-small fw-500 eee">{desc.length > 30 ? `${desc.slice(0, 30)}...` : desc}Good Thinking Better Society</span>
                                </div>
                            )}
                        </>
                    )}
                    {links.length && (
                        <>
                            {y < 100  && <MainNav user={user} school_title={short_name} is_join={is_join} links={links} is_fixed={false}/>}
                        </>
                    )}
                </TextContent>
                {y > 100 && (
                    <MainNav user={user} school_title={short_name} is_join={is_join} links={links} />
                )}
            </Wrapper>
        </>
    )
}

export default NavBar;