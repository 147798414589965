import OnePage from "../component/onePage";
import PageByPage from "../component/pageByPage";
import Header from "components/school/header";
import { SchoolWebHomeWrapper } from 'containers/home/style';

import { BACKEND_URL } from 'actions/url';
import { colorThemePallete } from "./colorManager";

export const previewer = (layout="", type="", header_title="", data={content_type:'', preface:'', title:'', img:'', text:'', is_array:false}, data2={content_type:'', title:'', img:'', text:'', preface:'', is_array:false}, bg_theme="") => {
    const color = colorThemePallete(bg_theme);
    if(parseInt(layout) === 2){
        return(
            <>
                <div className={bg_theme}>
                    <SchoolWebHomeWrapper>
                        {header_title && <Header classname={color.app_text_color} title={header_title} is_placeholder={false}/> }
                        <PageByPage content_type1={type === "home" ? 'img' : data.content_type} preface1={data.title} preface_underbelly={data.preface} text1={data.text} img_src1={`${BACKEND_URL}${data.img}`} img_alt1={header_title} 
                            content_type2={data2.content_type} preface2={data2.title} preface_underbelly2={data2.preface} text2={data2.text} img_src2={`${BACKEND_URL}${data2.img}`} img_alt2={header_title}  />
                    </SchoolWebHomeWrapper>
                </div>
            </>
        )
    }
    if(parseInt(layout) === 1){
        return(
            <>
            <div className={bg_theme}>
                <SchoolWebHomeWrapper>
                {header_title && <Header title={header_title} classname={color.app_text_color} is_placeholder={false}/> }
                <OnePage bg_color={bg_theme}  preface={data.title} preface_under={data.preface} text={data.text}  title={data.title} />
                </SchoolWebHomeWrapper>
            </div>
            </>
        )
    }
}