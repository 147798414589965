import React, {Fragment, useEffect, useState} from "react";
import { Link } from "react-router-dom";

import CountDownTimer from "components/app/countDownTimer";
import Grid from "components/grid";
import ChoosePlan from "components/choosePlan";
import PreloaderLine from "components/preloaderLine";
import { Wrapper, Content } from "components/schools/style";
import { InnerContent } from "./style";
import { convertNumber } from "components/app/actions/money";
import { updateArray } from "components/app/actions/array-utils/Update";
import { addItemToArray } from "components/app/actions/array-utils/Add";
import { appServices } from "api/schoolApp/services";

// var planArr = [
//     {name:'optimum', num:0, price_six:18000, price:29400, prev_price:0, renewal_price:25980, renewal_price_six:15000, type:'annual', discount:100, curr:'N', desc:'Ideal for schools with a low budget but still want an optimum package', is_active:true}, 
//     {name:'intermediate', num:1, price_six:72300, price:139200, prev_price:0, renewal_price:138000, renewal_price_six:72300, type:'annual', discount:25, curr:'N', desc:'Ideal for schools looking for the best of both sides', is_active:false}, 
//     {name:'premium', num:2, price_six:306000, price:582000, prev_price:0, renewal_price:599400, renewal_price_six:306000, type:'annual', discount:10, curr:'N', desc:'Ideal for schools looking for the best of the best. All new features will be added instantaneously', is_active:false}
// ]
const PlansAndPricingComponents = () => {
    const [plan_arr, setPlanArr] = useState([]);
    const [preloadingLine, setPreloadingLine] = useState(true);
    const feature_arr = [
        {name:'Online School Website', act:[{name:'', type:'Y'}, {name:'', type:'Y'},{name:'', type:'Y'}], desc:'Get an embedded website where you can showcase your school for prospective students or parent to find and enroll to your school'},
        {name:'VoSpace Added ', act:[{name:'+10', type:'M'}, {name:'+1800', type:'Y'}], desc:'The total number of student to integrate! (+ the student result checking fee, other in-app fees, app maintainance, data backup and all user slot allocation embedded in one)'},
        {name:'School Domain Name', act:[{name:'', type:'Y'}, {name:'', type:'Y'}, {name:'', type:'Y'}], desc:'The school domain name'},
        {name:'Life-Time Access (App)', act:[{name:'', type:'Y'}, {name:'', type:'Y'}, {name:'', type:'Y'}], desc:"Get a life time access to your school's management system"},
        {name:'Early Access To New Features', act:[{name:'', type:'N'}, {name:'', type:'Y'}]},
        {name:'Data Backup', act:[{name:'Every 30 Days', type:'M'}, {name:'Every 7 Days', type:'Y'}], desc:""},
        {name:'Offline Exam System', act:[{name:'', type:'Y'}, {name:'', type:'Y'}, {name:'', type:'Y'}], desc:'Exam questions can be made and printed with this feature'},
        {name:'Online Enrollment', act:[{name:'', type:'Y'}, {name:'', type:'Y'},{name:'', type:'Y'}]},
        {name:'Online Payment', act:[{name:'', type:'Y'}, {name:'', type:'Y'}, {name:'', type:'Y'}]},
        {name:'Online Result Checker', act:[{name:'', type:'Y'}, {name:'', type:'Y'}, {name:'', type:'Y'}]},
        {name:'App Fee (cap at N2,950)', desc:'resource usage allocation, maintainance, registration, integration, data backup, security, embedded features, cart systems and other embedded dynamic app features for all-in-app-usage users', act:[{name:plan_arr.length > 0 ? `${parseFloat(plan_arr[0].app_fee_tax)}% / >N${parseFloat(plan_arr[0].app_fee)}` : '', type:'M'}, {name:plan_arr.length > 0 ? `${parseFloat(plan_arr[1].app_fee_tax)}% / >N${parseFloat(plan_arr[1].app_fee)}` : '', type:'M'}]},
        {name:'Online Payment Tax', act:[{name:plan_arr.length > 0 ? `${plan_arr[0].app_tax}%` : '', type:''}, {name:plan_arr.length > 0 ? `${plan_arr[1].app_tax}%` : '', type:''}]},
        {name:'Online School Timetable', act:[{name:'', type:'Y'}, {name:'', type:'Y'}, {name:'', type:'Y'}]},
        {name:'Live Teaching Session', act:[{name:'Coming Soon', type:'Y'}, {name:'Coming Soon', type:'Y'}, {name:'Coming Soon', type:'Y'}]},
        {name:'Staff Scoring System', act:[{name:'', type:'Y'}, {name:'', type:'Y'}, {name:'', type:'Y'}]},
        {name:'Staff Exam Typing System', act:[{name:'', type:'Y'}, {name:'', type:'Y'}, {name:'', type:'Y'}]},
        {name:'Interactive Games', act:[{name:'Coming Soon', type:''}, {name:'Coming Soon', type:''}, {name:'Coming Soon', type:''}]}
    ];
    const actionType = (name="", type="") => {
        if(type === "Y" && name){
            return(
                <span className="green fw-600">{typeof name === "number" ? `N${convertNumber(name)}` : name}</span>
            );
        }
        if(type === "M" && name){
            return(
                <span className="yellow fw-600">{typeof name === "number" ? `N${convertNumber(name)}` : name}</span>
            );
        }
        if(type === "N" && name){
            return(
                <span className="red fw-600">{typeof name === "number" ? `N${convertNumber(name)}` : name}</span>
            );
        }
        if(type === "Y"){
            return(
                <span><i className="fas fa-check green"></i></span>
            );
        }
        if(type === "M"){
            return(
                <span><i className="fas fa-check yellow"></i></span>
            );
        }
        if(type === "N"){
            return(
                <span><i className="fas fa-times red"></i></span>
            );
        }
        return(
            <span>{typeof name === "number" ? `N${convertNumber(name)}` : name}</span>
        );
    }
    const fetchAllPlan = () => {
        appServices.getAllVoskoolPlan().then(res => {
            setPreloadingLine(false);
            const arr = addItemToArray(res.data, {is_active:false});
            setPlanArr(updateArray(arr, 0, {...arr[0], is_active:true}));
        }).catch(e => {
            setPlanArr([]);
        })
    }
    const handleClick = (i, is_active=false) => {
        setPlanArr(updateArray(addItemToArray(plan_arr, {is_active:false}), i, {...plan_arr[i], is_active:is_active}));
    }

    useEffect(() => {
        fetchAllPlan();
    }, [])

    const access_token = localStorage.getItem('access') ? JSON.parse(localStorage.getItem('access')) : {access:'', refresh:''};
    return(
        <>
        {preloadingLine && <PreloaderLine />}
        {plan_arr.length >0 && (
            <Wrapper>
            <Content>
                {/* <div className="padd-4px disp-block">
                    <h3 className="align-center">Would you like to see your school in action now?</h3>
                </div> */}
                <div className="padd-4px br" style={{background:'var(--voskoolBagColor)', borderRadius:'10px', padding:'10px'}}>
                    <div className='disp-block padd-4px'>
                        <div className='disp-flex wrap jc-sb'>
                            <div className='disp-block align-center' style={{maxWidth:'60%'}}>
                                <div className='disp-block align-center'>
                                    <span className='font-big app-bg-text-color fw-700'>Get a FREE Trial Demo NOW!</span>
                                </div> <br />
                                <div className="disp-block">
                                    <span style={{letterSpacing:'.4px'}} className='font-small app-bg-text-color fw-500'>You can start using our technologies for FREE now and pay later after experiencing the value in it</span><br /> <br /><span className="font-very-small fw-500 app-bg-text-color">Click on the link below</span>
                                </div> <br />
                            </div>
                            <div className='disp-block'>
                                <div className='disp-block align-center'>
                                    <span className='font-slightly-small fw-600' style={{color:'#555'}}>Deal ends in!</span>
                                </div>
                                <CountDownTimer end={'2024-02-23 12:00:00'} />
                            </div>
                        </div>
                        <div className='disp-flex wrap jc-sb' style={{marginTop:'10px'}}>
                            <div className='disp-block align-center padd-4px' style={{marginTop:'5px', width:'70%'}}>
                                <Link to={`https://register.voskool.com/register?is_trial=true`} target={'_blank'} className={'font-slightly-small app-bg-text-color bg-none btn br hover-opacity fw-600'} style={{border:'1px solid #ccc'}}>Let's Go</Link>
                            </div>
                            <div className='disp-block align-center'>
                                <span className={'font-small app-bg-text-color fw-600'}> 30 Days <br /> <span className="font-very-small fw-500">Validity</span></span>
                            </div>
                        </div>
                        <div className="disp-flex wrap" style={{marginTop:'10px'}}>
                            <div className="flex-33">
                                <div className="disp-flex wrap jc-stretch padd-4px">
                                    <div className="disp-block">
                                        <span className="font-very-small app-bg-text-color fw-500">VoSpace Added</span>
                                    </div>
                                    <div className="disp-block" style={{paddingLeft:'5px'}}>
                                        <span className="font-very-small fw-600" style={{color:'yellow'}}>+10</span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-33">
                                <div className="disp-flex wrap jc-stretch padd-4px">
                                    <div className="disp-block">
                                        <span className="font-very-small app-bg-text-color fw-500">School Domain Name</span>
                                    </div>
                                    <div className="disp-block" style={{paddingLeft:'5px'}}>
                                        <span className="font-very-small fw-600" style={{color:'red'}}><i className="fas fa-times"></i></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="flex-33">
                                <div className="disp-flex wrap jc-stretch padd-4px">
                                    <div className="disp-block">
                                        <span className="font-very-small app-bg-text-color fw-500">Life-Time Access</span>
                                    </div>
                                    <div className="disp-block" style={{paddingLeft:'5px'}}>
                                        <span className="font-very-small fw-600" style={{color:'red'}}><i className="fas fa-times"></i></span>
                                    </div>  
                                </div>
                            </div>
                            
                        </div>
                        <div className="disp-flex wrap">
                            <div className="flex-33">
                                <div className="disp-flex wrap jc-stretch padd-4px">
                                    <div className="disp-block">
                                        <span className="font-very-small app-bg-text-color fw-500">Data Backup</span>
                                    </div>
                                    <div className="disp-block" style={{paddingLeft:'5px'}}>
                                        <span className="font-very-small fw-600" style={{color:'red'}}><i className="fas fa-times"></i></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="flex-33">
                                <div className="disp-flex wrap jc-stretch padd-4px">
                                    <div className="disp-block">
                                        <span className="font-very-small app-bg-text-color fw-500">Access to all APP Technologies</span>
                                    </div>
                                    <div className="disp-block" style={{paddingLeft:'5px'}}>
                                        <span className="font-very-small fw-600" style={{color:'green'}}><i className="fas fa-check"></i></span>
                                    </div>  
                                </div>
                            </div>
                            {/* <div className="flex-33">
                                <div className="disp-flex wrap jc-stretch padd-4px">
                                    <div className="disp-block">
                                        <span className="font-very-small app-bg-text-color fw-500">School Domain Name</span>
                                    </div>
                                    <div className="disp-block" style={{paddingLeft:'5px'}}>
                                        <span className="font-very-small fw-600" style={{color:'red'}}><i className="fas fa-times"></i></span>
                                    </div>  
                                </div>
                            </div> */}
                        </div>
                    </div>  
                </div>
                <div className="padd-4px disp-block" style={{marginTop:'10px'}}>
                    <h3 className="align-center">Choose a Plan NOW, and get over a <b style={{color:'green'}}>20% DISCOUNT</b>, with a LIFE-TIME ACCESS Offer</h3>
                </div>
                <Grid header="Choose Your School Plan" position="align-center" grid_width="big-w">
                    <ChoosePlan arr={plan_arr} feature_arr={feature_arr} />
                </Grid> 
                 
                <InnerContent>
                    <div className="flex-100 align-center">
                         <h2 className="flex-header">Compare All School Plans</h2>
                    </div>
                    <div className="flex-left " id="plan_detail">
                        <div className="item-header">   
                            <h2>Plan Features</h2>
                        </div>

                        <div className="item-cont">
                            {feature_arr.map((item, i) => {
                                return(
                                    <div className="item">
                                        <span><span className="text-capitalize">{item.name}</span> {item.desc &&(<div className="menu-cont"><i className="fas fa-question"></i>
                                            <div className="menu  right-menu left-tooltip">
                                                <p>{item.desc}</p>
                                            </div>
                                        </div>)}</span>
                                    </div>
                                )
                            })}
                            {/* <div className="item">
                                <span>Total Users <div className="menu-cont"><i className="fas fa-question"></i>
                                    <div className="menu  right-menu left-tooltip">
                                        <p>The total number of staff, parent and other users that you can create!</p>
                                    </div>
                                </div></span>
                            </div>
                            <div className="item">
                                <span>Transportation System Tech. <i className="fas fa-question"></i></span>
                            </div>
                            <div className="item">
                                <span>Library System Tech. <i className="fas fa-question"></i></span>
                            </div>
                            <div className="item">
                                <span>Finance Managing System <i className="fas fa-question"></i></span>
                            </div>
                            <div className="item">
                                <span>Online Enrollment <i className="fas fa-question"></i></span>
                            </div>
                            <div className="item">
                                <span>Online Payment <i className="fas fa-question"></i></span>
                            </div>
                            <div className="item">
                                <span>Attendance System Tech. <i className="fas fa-question"></i></span>
                            </div>
                            <div className="item">
                                <span>Offline Exam Tech. <i className="fas fa-question"></i></span>
                            </div>
                            <div className="item">
                                <span>Online Exam Tech. <i className="fas fa-question"></i></span>
                            </div> */}
                        </div>
                    </div>
                    <div className="flex-right">
                        <div className="small-item-header-cont">
                            {plan_arr.map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <div className="small-item-header">
                                            <span className={item.is_active ? "active text-capitalize" : 'text-capitalize'} onClick={() => handleClick(item.num, !item.is_active)}>{item.name}</span>
                                        </div>
                                    </Fragment>
                                )
                            })}
                        </div>
                        <div className={`flex-33 border-left border-right ${plan_arr[0].is_active ? '' : 'small-800-disp-none'}`}>
                            <div className="item-header" style={{marginTop:'10px'}}>
                                <h4 className="text-capitalize">{plan_arr[0].name} School</h4>
                                <Link to={`https://register.voskool.com/?t=${plan_arr[0].name}&act=${access_token.access}&rft=${access_token.refresh}`} target={'_blank'}>Select</Link>
                            </div>
                            <div className={`item-cont`}>
                                <Link to={`https://register.voskool.com/?t=${plan_arr[0].name}&act=${access_token.access}&rft=${access_token.refresh}`} target={'_blank'}>
                                    {feature_arr.map((item, i) => {
                                        return(
                                            <div className="item" key={i}>
                                                {actionType(item.act[0].name, item.act[0].type)}
                                            </div>
                                        )
                                    })}
                                    {/* <div className="item">
                                        <span>500</span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-times red"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-times red"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-times red"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-check green"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-check green"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-check green"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-check green"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-times red"></i></span>
                                    </div> */}
                                </Link>
                            </div>
                        </div> 
                        <div className={`flex-33 border-left border-right ${plan_arr[1].is_active ? '' : 'small-800-disp-none'}`}>  
                            <div className="item-header">
                                <h4 className="text-capitalize">{plan_arr[1].name}  School </h4>
                                <Link  to={`https://register.voskool.com/?t=${plan_arr[1].name}&act=${access_token.access}&rft=${access_token.refresh}`} target={'_blank'}>Select</Link>
                            </div>    
                            <div className="item-cont">
                                <Link to={`https://register.voskool.com/?t=${plan_arr[1].name}&act=${access_token.access}&rft=${access_token.refresh}`} target={'_blank'}>
                                    {feature_arr.map((item, i) => {
                                        return(
                                            <div className="item" key={i}>
                                                {actionType(item.act[1].name, item.act[1].type)}
                                            </div>
                                        )
                                    })}
                                    {/* <div className="item">
                                        <span>1500</span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-check green"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-check green"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-check green"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-check green"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-check green"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-check green"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-check green"></i></span>
                                    </div>
                                    <div className="item">
                                        <span><i className="fas fa-times red"></i></span>
                                    </div> */}
                                </Link>    
                            </div>
                        </div>
                        {/* <div className={`flex-33 border-left border-right ${plan_arr[2].is_active ? '' : 'small-800-disp-none'}`}>
                            <div className="item-header">
                                <h4 className="text-capitalize">{plan_arr[2].name}  School</h4>
                                <Link to={`https://register.voskool.com/?t=${plan_arr[2].name}&act=${access_token.access}&rft=${access_token.refresh}`} target={'_blank'}>Select</Link>
                            </div>    
                             <div className="item-cont">
                                <Link to={`https://register.voskool.com/?t=${plan_arr[2].name}&act=${access_token.access}&rft=${access_token.refresh}`} target={'_blank'}>
                                    {feature_arr.map((item, i) => {
                                        return(
                                            <div className="item" key={i}>
                                                {actionType(item.act[2].name, item.act[2].type)}
                                            </div>
                                        )
                                    })}
                                    
                                </Link>    
                            </div>
                        </div> */}
                    </div>
                </InnerContent>
            </Content>
        </Wrapper>
        )}
        </>
        
    );

}

export default PlansAndPricingComponents;