import React, { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate, useSearchParams, Link } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import { motion } from "framer-motion";
import VoWrapper from "components/app/vo/SmallerComponents/wrapper";
import { NotificationWrapper } from "components/app/notification/style";
import { Wrapper, Content } from "components/app/vo/signUpPage/style.js";

import Loader from "components/app/loader";
import Notification from "components/app/notification";
import TABLE from "components/app/table_html/table";
import TR from "components/app/table_html/tr";
import TD from "components/app/table_html/td";
import TH from "components/app/table_html/th";
import Note from "components/app/NOTE";
import Redirect from 'components/redirectPage';

import { appServices, appServices5 } from "api/schoolApp/services";
import { BACKEND_URL, PAYSTACK_LIVE_KEY } from "actions/url";

import { addItemToArray } from "components/app/actions/array-utils/Add";
import { updateArray, updateArrayWithArray } from "components/app/actions/array-utils/Update";
import { convertNumber } from "components/app/actions/money";
import PreloaderLine from "components/preloaderLine";


// const initalLayoutState = {
//     "type":"",
//     "data": {},
//     "isAuthenticated":true,
// };
const initialUserState = {
    email: "",
    first_name: "",
    id: 0,
    last_name: "",
    phone_number: "",
    user_name: "",
    user_type: null,
}
const StudentEnrollmentPayment = ({inner_state}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialPaymentState = useMemo(() => ({cart:[], id:0}), []);

    const {sn} = useParams();
    const [searchParams] = useSearchParams();

    // const [state, setState] = useState(initalLayoutState);
    const [user, setUser] = useState(initialUserState);
    const [school, setSchool] = useState({id:0, title:''});
    const [schoolBank, setSchoolBank] = useState({});
    const [enrollState, setEnrollState] = useState({});
    const [unactivatedPaymentState, setUnactivatedPaymentState] = useState(initialPaymentState);
    const [allBooks, setAllBooks] = useState({results:[]});
    const [backupAllBooks, setBackupAllBooks] = useState({results:[]});
    const [currSession, setCurrSession] = useState({});
    // const [studentEnroll, setStudentEnroll] = useState({});
    const [config, setConfig] = useState({
        reference: '',
        email:user.email,
        amount: 0,
        split:{},
        is_split:false,
        publicKey:PAYSTACK_LIVE_KEY
    })

    const [allSchoolFee, setAllSchoolFee] = useState([]);
    const [allParentKid, setAllParentKid] = useState([]);
    const [backupAllSchoolFee, setBackupAllSchoolFee] = useState([]);

    const [navigate, setNavigate] = useState('');
    const [preErrorMsg, setPreErrorMsg] = useState('Data loaded with errors... try reloading');

    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const [preError, setPreError] = useState(true);
    const [reload, setReload] = useState(false);
    const [exist, setExist] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [initCheckoutForm, setInitCheckoutForm] = useState(false);
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [paymentSuccessful2, setPaymentSuccessful2] = useState(false);
    const [startPayment, setStartPayment] = useState(false);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);

    const nav = useNavigate();

    const initializePayment = usePaystackPayment(config);


    const fetchAcceptedEnroll = (user_id, name="", user_type="", key="") => {
        setLoading2(true);
        setExist(true);
        appServices.getSchool(name).then(result => {
            setSchool(result.data);
            // const sch = result.data;
            // if (sch.is_vos && sch.vo_space_rem < 1){
            //     setPreError(true);
            //     setPreErrorMsg('Data loaded with errors, insufficient vospace, please contact the school administrator');
            //     setReload(false);
            //     setPreloading(false);
            //     setLoading2(false);
            //     return;
            // }
            appServices.getSchoolBank(result.data.id).then(res => {
                setSchoolBank(res.data);
            }).catch(e => {
                setSchoolBank({});
                setExist(false);
                return;
            })
            if(user_type === "student"){ 
                appServices.getStudentEnrolledAcceptedData(user_id, result.data.id).then(res => {
                    setEnrollState(res.data);
                    setLoading2(false);
                    setExist(true);
                }).catch(e => {
                    setEnrollState({});
                    setLoading2(false);
                    setExist(false);
                })
                return;
            }
            if(user_type === "parent" && key){
                appServices.getStudentEnrolledAcceptedData(key, result.data.id).then(res => {
                    setEnrollState(res.data);
                    setLoading2(false);
                    setExist(true);
                }).catch(e => {
                    setEnrollState({});
                    setLoading2(false);
                    setExist(false);
                })
                return;
            }
        }).catch(e => {
            setSchool({id:0, title:''});
            setLoading2(false);
            setExist(false);
        })
        
    }

    const fetchAllParentKid = (id="") => {
        appServices.getAllStudentEnrolledByParentData(id).then(res => {
            setAllParentKid(res.data);
        }).catch(e => {
            setAllParentKid([]);
            setPreError(true);
        })
    }
    // const fetchStudentEnroll = (id) => {
    //     appServices.getStudentEnrolledData(id).then(res => {
    //         setStudentEnroll(res.data);
    //     }).catch(e => {
    //         setStudentEnroll({});
    //         setPreError(true);
    //     })
    // }
    
    // const fetchBank = (sid) => {
    //     if(sid){
    //         appServices.getSchoolBank(sid).then(res => {
    //             setSchoolBank(res.data);
    //         }).catch(e => {
    //             setSchoolBank({});
    //             setExist(false);
    //         })
    //     }
    // }

    const fetchCurrentSchoolSession = (school_id) => {
        setError({data:[], title:''});
        appServices5.getCurrentSession(school_id).then(res => {
            setCurrSession(res.data);
        }).catch(e => {
            setCurrSession({id:0});
            setPreError(true);
            setError({data:[e.response.data.detail], title:'Session Data Fetch Error'});
        })
    }

    const totAmt = (item={}) => {
        const tot_amt = convertNumber(Math.ceil(item.tot_amt_tp));
        return tot_amt;
    }
    const totAmtClean = (item={}) => {
        const tot_amt = Math.ceil(item.tot_amt_tp)
        return tot_amt;
    }
    const onCartPayment = useCallback((ref) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading3(true);
        if(unactivatedPaymentState.cart.length > 0 || unactivatedPaymentState.is_app_fee_only){
            const data = {
                tot_amt_school:unactivatedPaymentState.tot_amt_school,
                tot_amt_tax: unactivatedPaymentState.tot_amt_tax,
                tot_amt_app_tax:unactivatedPaymentState.tot_amt_app_tax,
                tot_amt_app_tax_sec: unactivatedPaymentState.tot_amt_app_tax_sec,
                paid_by: user.id,
                pay_date: new Date(),
                tot_amt_disct: unactivatedPaymentState.tot_amt_disct
            }
            appServices.processSchoolStudentPaymentCartPay(unactivatedPaymentState.uid, data).then(res => {
                setPaymentSuccessful(true);
                setLoading3(false);
                setPreloading(false);
            }).catch(e => {
                setError({title:'Payment Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
                setPreloading(false);
                setLoading3(false);
                setReload(true);
            })
            return;
        }
        setError({title:'Payment Error', data:["Cart Not Found"]});
    }, [unactivatedPaymentState, user, initialErrorState, initialSuccessState])
    const checkCartPayment = (item={}, user_id) => {
        if(item.cart.length > 0 || item.is_app_fee_only){
            const data = {
                tot_amt_school:item.tot_amt_school,
                tot_amt_tax: item.tot_amt_tax,
                tot_amt_app_tax:item.tot_amt_app_tax,
                tot_amt_app_tax_sec: item.tot_amt_app_tax_sec,
                paid_by: user_id,
                pay_date: new Date(),
                tot_amt_disct: item.tot_amt_disct
            }
            appServices.processSchoolStudentPaymentCartPay(item.uid, data).then(res => {
                setPaymentSuccessful(true);

            }).catch(e => {
                
            })
            return;
        }
    }
    const processSchoolFeeToCart = useCallback((arr=[]) => {
        setPreloading(true);
        setPreError(false);
        setError(initialErrorState);
        const filterGeneralItem = arr.filter((item) => item.is_general === true);
        let data = {
            student:enrollState.id, classes:'', sess_added:''
        }
        appServices.getUnactivatedPaymentAccess(enrollState.uid).then(result => {
            setUnactivatedPaymentState(result.data);
            const toAmt = totAmtClean(result.data)*100;
            setConfig((prev) => typeof prev === "object" ? {...prev, email:user.email, reference:result.data.ref_id, amount:toAmt} : prev);
            result.data.is_start_checkout ? setInitCheckoutForm(true) : setInitCheckoutForm(false);
            checkCartPayment(result.data, user.id);
            const filterGeneralCartItem = result.data.cart.filter((item) => item.fee_is_general === true);
            if(!result.data.is_app_fee_only){
                if(filterGeneralCartItem.length !== filterGeneralItem.length){
                    let cart = [...result.data.cart]
                    filterGeneralItem.forEach((item, i) => {
                        setTimeout(() => {
                            let item_data = {
                                payment:result.data.id,
                                type: item.name,
                                item_name: item.name,
                                fee: item.id,
                                added_by: user.id,
                                amt_school: item.fee,
                                school_branch: enrollState.school_branch,
                                book_bought:null
                            }
                            appServices.insertSchoolStudentPaymentCart(item_data).then(res => {
                                cart.push({...res.data})
                                setUnactivatedPaymentState((prev) => prev && {...prev, cart:cart})
                            }).catch(e => {
                                // setError({data:[e.response.data.detail], title:'Cart Data Insert Error'});
                            })
                        }, i * 10);
                    })
                    setTimeout(() => {
                        setPreloading(false);
                        setReload(true);
                    }, filterGeneralItem.length * 20);
                }
                else{
                    const filterOptionalCartItem = result.data.cart.filter((item) => item.fee_is_optional === true);
                    const filterBookCartItem = result.data.cart.filter((item) => item.type === "book");
                    if(filterOptionalCartItem.length){
                        setAllSchoolFee((prev) => prev.length && addItemToArray(updateArrayWithArray(prev, addItemToArray(filterOptionalCartItem, {is_checked:true, is_optional:true, is_list_price:false, is_general:false}), 'id', 'fee')));
                    }
                    if(filterBookCartItem.length ){
                        setAllBooks((prev) => prev.results.length && {results:addItemToArray(updateArrayWithArray(prev.results, addItemToArray(filterBookCartItem, {is_checked:true}, "qty", "qty_bought"), "id", "book_bought"))});
                    }
                    setPreloading(false);
                }
                return;
            }
            if(!result.data.is_start_checkout){
                appServices.processSchoolStudentPaymentCartCheckout(result.data.uid, user.id, "create").then(res => {
                    setReload(true);
                }).catch(e => {
                    setPreloading(false);
                })
                return;
            }
            setPreloading(false);
        }).catch(e => {
            if(school.is_vos && school.vo_space_rem < 1){
                setPreError(true);
                setPreloading(false);
                setPreErrorMsg('Data loaded with errors, insufficient vospace, please contact the school administrator');
                return;
            }
            if(school.is_vos && enrollState.is_accepted && enrollState.class_accepted && enrollState.is_enrolled && enrollState.uid && !school.is_start_student_payment){
                appServices.insertSchoolStudentFreshPayment(data).then(res => {
                    setPaymentSuccessful2(true);
                    setPreloading(false);
                }).catch(e => {
                    setPreError(true);
                    setPreloading(false);
                    setPreErrorMsg('Data loaded with errors, something went wrong');
                })
                return;
            }
            setError({data:[e.response.data.detail], title:'Payment Data Fetch Error'});
            setPreloading(false);
            setUnactivatedPaymentState(initialPaymentState);
            appServices.insertSchoolStudentFreshPayment(data);
            if(enrollState.is_accepted && enrollState.class_accepted && enrollState.is_enrolled && enrollState.uid){
                setReload(true);
                return;
            }
            setPreError(true);
        })
    }, [enrollState, school, initialErrorState, initialPaymentState, user]);

    const fetchAllSchoolFee = useCallback((sbid, type, csid, cid) => {
        setPreloading(true);
        setError({data:[], title:""});
        setPreError(false);
        appServices.getAllSchoolFees_USERS(sbid, type, csid).then(res => {
            setAllSchoolFee(addItemToArray(res.data, {is_checked:false}));
            setBackupAllSchoolFee(addItemToArray(res.data, {is_checked:false}));
            const filterBook = res.data.filter((item) => item.is_optional === true && item.is_list_price === true && item.name === "book");
            if(filterBook.length > 0){
                appServices.getAllBooks_FOR_PAYMENT(school.id, cid, sbid).then(book_res => {
                    setAllBooks({results:addItemToArray(book_res.data, {is_checked:false, qty:0})});
                    setBackupAllBooks({results:addItemToArray(book_res.data, {is_checked:false, qty:0})})
                    processSchoolFeeToCart(res.data);
                }).catch(e => {
                    setAllBooks({results:[]});
                    setBackupAllBooks({results:[]});
                    processSchoolFeeToCart(res.data);
                })
                return;
            }
            processSchoolFeeToCart(res.data);
        }).catch(e => {
            setError({data:[e.response.data.detail], title:'School Fee Data Fetch Error'});
            setPreError(true);
            setPreloading(false);
            setAllSchoolFee([]);
        })
    }, [processSchoolFeeToCart, school])

    const handleOptionalFee = (num=0, checked=false, item={}) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading3(true);
        if(checked){
            const data = {
                payment:unactivatedPaymentState.id,
                type: item.name,
                item_name: item.name,
                fee: item.id,
                added_by: user.id,
                amt_school: item.fee,
                school_branch: enrollState.school_branch,
                book_bought:null,
            }
            appServices.insertSchoolStudentPaymentCart(data).then(res => {
                setAllSchoolFee(updateArray(allSchoolFee, num, {...res.data, is_checked:true, is_optional:true, is_list_price:false, is_general:false}))
                setLoading3(false);
                setSuccess({title:'', text:'Item added to cart'});
            }).catch(e => {
                setError({title:'Cart Insert Error', data:[e.response.data.detail]});
                setLoading3(false);
            })
            return;
        }
        appServices.deleteSchoolStudentPaymentCart(item.uid, user.id).then(res => {
            setAllSchoolFee(updateArray(allSchoolFee, num, {...backupAllSchoolFee[num], is_checked:false}));
            setLoading3(false);
            setSuccess({title:'', text:'Item removed from cart'});
        }).catch(e => {
            setError({title:'Cart Item Delete Error', data:[e.response.data.detail]});
            setLoading3(false);
        })
    }

    const handleBookFee = (num=0, e, item={}) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading3(true);
        if(typeof e === "boolean"){
            if(e){
                const data = {
                    payment:unactivatedPaymentState.id,
                    type: 'book',
                    item_name: item.name,
                    fee: null,
                    qty_bought:1,
                    added_by: user.id,
                    amt_school: item.price,
                    school_branch: enrollState.school_branch,
                    book_bought:item.id,
                }
                appServices.insertSchoolStudentPaymentCart(data).then(res => {
                    setAllBooks({results:updateArray(allBooks.results, num, {...res.data, is_checked:true, qty:1})});
                    setLoading3(false);
                    setSuccess({title:'', text:'Item added to cart'});
                }).catch(e => {
                    setError({title:'Cart Insert Error', data:[e.response.data.detail]});
                    setLoading3(false);
                })
                return;
            }
            appServices.deleteSchoolStudentPaymentCart(item.uid, user.id).then(res => {
                setAllBooks({results: updateArray(allBooks.results, num, {...backupAllBooks.results[num], is_checked:false, qty:0})});
                setLoading3(false);
                setSuccess({title:'', text:'Item removed from cart'});
            }).catch(e => {
                setError({title:'Cart Item Delete Error', data:[e.response.data.detail]});
                setLoading3(false);
            })
            return;
        }
        if(parseInt(e.target.value)){
            const init_qty = item.qty;
            setAllBooks({results:updateArray(allBooks.results, num, {...item, qty:parseInt(e.target.value), qty_bought:parseInt(e.target.value)})});
            appServices.updateSchoolStudentPaymentCartQty(item.uid, user.id, {qty_bought:e.target.value}).then(res => {
                setLoading3(false);
            }).catch(e => {
                setError({title:'Cart Item Qty Update Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
                setLoading3(false);
                setAllBooks({results:updateArray(allBooks.results, num, {...item, qty:parseInt(init_qty), qty_bought:parseInt(init_qty)})});
            })
            return
        }
        setError({title:'Cart Item Qty Update Error', data:["Quantity must be greater than 0", "To remove item from cart uncheck the box above"]});
        setLoading3(false);
    }

    const onCartCheckout = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setPreloading(true);
        if(unactivatedPaymentState.cart.length > 0){
            appServices.processSchoolStudentPaymentCartCheckout(unactivatedPaymentState.uid, user.id, "create").then(res => {
                setSuccess({title:'', text:res.data.message});
                setReload(true);
            }).catch(e => {
                setError({title:'Cart Checkout Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
                setInitCheckoutForm(false);
                setPreloading(false);
            })
            return;
        }
        setError({title:'Cart Checkout Error', data:["Cart Not Found"]});
    }

    const onBackToCart = () => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setPreloading(true);
        appServices.processSchoolStudentPaymentCartCheckout(unactivatedPaymentState.uid, user.id, "remove").then(res => {
            setReload(true);
        }).catch(e => {
            setError({title:'Cart Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
            setPreloading(false);
        })
    }


    const onClose = () => {
        setReload(true);
    }
    
    const onInitializeCartPayment = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading3(true);
        const schAmt = Math.ceil(unactivatedPaymentState.tot_amt_school) * 100;
        unactivatedPaymentState.is_app_fee_only ? setConfig((prev) => { return prev}) :  setConfig((prev) => typeof prev === "object" ? {...prev, split:JSON.stringify({ type:"flat", bearer_type:'account', subaccounts:[{ subaccount:schoolBank.p_subaccount_code, share:schAmt}] }), is_split:true} : {...prev})
        setTimeout(() => { 
            setStartPayment(true);
        })
    }

    

    useEffect(() => {
        let k = Object.fromEntries([...searchParams]).k;
        setLoading(true);
        setLoading2(true);
        if(inner_state.type === "success"){
            setIsAuthenticated(inner_state.isAuthenticated);
            setLoading(false);
            fetchAcceptedEnroll(inner_state.data.id, sn, inner_state.data.user_type, k);
        }
        else if(inner_state.type === "error"){
            setIsAuthenticated(inner_state.isAuthenticated);
            setLoading(false);
            appServices.getSchool(sn).then(res => {
                setSchool(res.data.title);
                setLoading2(false);
            }).catch(e => {
                setSchool("");
                setLoading2(false);
            })
        }
    }, [inner_state, sn, searchParams])

    useEffect(() => {
        if(isAuthenticated && !loading){
            // setState({...inner_state});
            setUser({...inner_state.data});
        }
    }, [inner_state, loading, isAuthenticated])

    useEffect(() => {
        if(navigate){
            return nav(navigate)
        }
    }, [navigate, nav]);

    useEffect(() => {
        if(reload && user.user_type === "student"){
            fetchCurrentSchoolSession(enrollState.school);
            fetchAllSchoolFee(enrollState.school_branch, enrollState.enroll_type, enrollState.class_section_accepted_id,  enrollState.class_accepted);
            setReload(false);
            return;
        }
        if(reload && user.user_type === "parent"){
            fetchAllParentKid(user.uid);
            fetchCurrentSchoolSession(enrollState.school);
            fetchAllSchoolFee(enrollState.school_branch, enrollState.enroll_type, enrollState.class_section_accepted_id,  enrollState.class_accepted);
            setReload(false);
            return;
        }
    }, [reload, user, enrollState, fetchAllSchoolFee])

    useEffect(() => {
        if(!loading2){
            if(exist && enrollState.id && inner_state.data.user_type === "student"){
                fetchCurrentSchoolSession(enrollState.school);
                fetchAllSchoolFee(enrollState.school_branch, enrollState.enroll_type, enrollState.class_section_accepted_id, enrollState.class_accepted);
                return;
            }
            if(exist && enrollState.id && inner_state.data.user_type === "parent"){
                fetchAllParentKid(inner_state.data.uid);
                fetchCurrentSchoolSession(enrollState.school);
                fetchAllSchoolFee(enrollState.school_branch, enrollState.enroll_type, enrollState.class_section_accepted_id, enrollState.class_accepted);
                return;
            }
            setPreloading(false);
        }
    }, [exist, inner_state, loading2, enrollState, fetchAllSchoolFee])

    useEffect(() => {
        if(startPayment && unactivatedPaymentState.is_app_fee_only && config.is_split){
            initializePayment(onCartPayment, onClose);
            setLoading3(false);
            setStartPayment(false);
            return;
        }
        if(startPayment && unactivatedPaymentState.cart.length > 0 && config.is_split){
            initializePayment(onCartPayment, onClose);
            setLoading3(false);
            setStartPayment(false);
            return;
        }
        if(startPayment){
            setLoading3(false);
            setError({title:'Payment Error', data:["Cart Not Found"]});
            setStartPayment(false);
            return;
        }
    }, [unactivatedPaymentState, onCartPayment, startPayment, initializePayment, config])

    useEffect(() => {
        if(!loading2){     
            if(school.title !== sn){
                setNavigate(`/`);
                return;
            }    
            else if(isAuthenticated === false){
                setNavigate(`/${sn}`);
                return;
            }
        }
    }, [school, sn, isAuthenticated, loading2]);

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertTDData = () => {
        const filterGeneralItem = unactivatedPaymentState.cart.filter((item) => item.fee_is_general === true);
        const filterOptionalItem = allSchoolFee.filter((item) => item.is_optional === true);
        const filterOptionalItem2 = allSchoolFee.filter((item) => item.is_optional === true && item.is_list_price === false);
        const filterOptionalBookItem = allSchoolFee.filter((item) => item.is_optional === true && item.is_list_price === true && item.name === "book");

        return(
            <>
                {filterGeneralItem.length > 0 && (
                    <>
                        <TR>
                            <TD colspan={2} classname={"align-center text-upper fw-700"} font_size="small">General Items</TD>
                        </TR>
                        {filterGeneralItem.map((item, i) => {
                            return (
                            <Fragment key={i}>
                                <TR>
                                    <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>{item.item_name === "school" ? 'School Tuition' : item.item_name} fee</TD>
                                    <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(item.amt_school)}</TD>
                                    <TD style={{border:'1px solid #ccc'}}><input type={"checkbox"} defaultChecked disabled/></TD>
                                </TR>
                            </Fragment>
                            )
                        })}
                        
                    </>
                )}
                {parseInt(unactivatedPaymentState.tot_amt_app_tax) > 0 && (
                    <TR>
                        <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>App fee</TD>
                        <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(unactivatedPaymentState.tot_amt_app_tax)}</TD>
                        <TD style={{border:'1px solid #ccc'}}><input type={"checkbox"} defaultChecked disabled/></TD>
                    </TR>
                )}
                
                {filterOptionalItem.length > 0 && (
                    <>
                        <TR>
                            <TD colspan={2} classname={"align-center text-upper fw-700"} font_size="small">Optional Items</TD>
                        </TR>
                        {filterOptionalItem2.map((item, i) => {
                            return(
                                <Fragment key={i}>
                                    <TR>
                                        <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>{item.is_checked ? item.item_name : item.name} fee</TD>
                                        <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(item.is_checked ? item.amt_school : item.fee)}</TD>
                                        <TD style={{border:'1px solid #ccc'}}><input type={"checkbox"} name="optional" onChange={() => handleOptionalFee(item.num, !item.is_checked, {...item})} checked={item.is_checked} /></TD>
                                    </TR>
                                </Fragment>
                            )
                        })}
                        {filterOptionalBookItem.length > 0 && (
                            <>
                            {allBooks.results.map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <TR>
                                            <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize"}>BOOK - {item.is_checked ? item.item_name : item.name}</TD>
                                            <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(item.is_checked ? item.amt_school : item.price)}</TD>
                                            <TD style={{border:'1px solid #ccc'}}>
                                                <input type={"checkbox"} name="book" onChange={() => handleBookFee(item.num, !item.is_checked, {...item})} checked={item.is_checked} />   
                                                {item.is_checked && (
                                                    <>
                                                    {item.qty > 0 && (
                                                        <input type={"number"} style={{width:'40px'}} onChange={(e) => handleBookFee(item.num, e, {...item})} className="f-c font-very-small" name="qty" value={item.qty} placeholder="Qty" />
                                                    )}
                                                    </>
                                                )}
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                            </>
                        )}
                        
                    </>
                )}
                {/* <TR>
                    <TD colspan={3} style={{border:'1px solid #555', color:'red'}} classname={"fw-700 align-left"}><i>tax = {school.curr_symbol}{convertNumber(tot_tax)}</i></TD>
                </TR> */}
            </>
        )
    }

    const insertCartTTData = () => {
        // let tot_app_amt = parseFloat(unactivatedPaymentState.tot_amt_app_tax);
        let tot_tax = parseFloat(unactivatedPaymentState.tot_amt_tax)
        let tot = parseFloat(unactivatedPaymentState.tot_amt_tp) - tot_tax
        let tot_amt = tot + tot_tax;
        let tot_amt_disct = parseFloat(unactivatedPaymentState.tot_amt_disct)
        const filterFee = unactivatedPaymentState.cart.filter((item) => item.fee)
        const filterOthers = unactivatedPaymentState.cart.filter((item) => !item.fee)
        return(
            <>
                {filterFee.map((item, i) => {
                    const amt = item.is_quantifiable ? item.amt_school * item.qty_bought : item.amt_school;
                    return(
                        <Fragment key={i}>
                            <TR>
                                <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>{item.fee ? `${item.item_name === "school" ? 'School Tuition' : item.item_name} fee` : `${item.type.toUpperCase()} - ${item.item_name === "sport" ? 'Sport Tuition' : item.item_name}`}</TD>
                                <TD style={{border:'1px solid #ccc'}}>{item.is_quantifiable ? item.qty_bought : '-'}</TD>
                                <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(amt)}</TD>
                            </TR>
                        </Fragment>
                    )
                })}
                {!unactivatedPaymentState.is_vo_space_enabled && (
                    <TR>
                        <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>App fee</TD>
                        <TD style={{border:'1px solid #ccc'}}>{'-'}</TD>
                        <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(unactivatedPaymentState.tot_amt_app_tax)}</TD>
                    </TR>
                )}
                
                {filterOthers.map((item, i) => {
                    const amt = item.is_quantifiable ? item.amt_school * item.qty_bought : item.amt_school;
                    return(
                        <Fragment key={i}>
                            <TR>
                                <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>{item.fee ? `${item.item_name} fee` : `${item.type.toUpperCase()} - ${item.item_name}`}</TD>
                                <TD style={{border:'1px solid #ccc'}}>{item.is_quantifiable ? item.qty_bought : '-'}</TD>
                                <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(amt)}</TD>
                            </TR>
                        </Fragment>
                    )
                })}
                <TR>
                    <TD ></TD>
                    <TD style={{border:'1px solid #777'}} colspan={2} classname={"fw-700"}>T = {school.curr_symbol}{convertNumber(tot)} </TD>
                </TR>
                <TR>
                    <TD colspan={3} style={{border:'1px solid #555', color:'red'}} classname={"fw-700 align-left"}><i>TAX = {school.curr_symbol}{convertNumber(Math.ceil(tot_tax))}</i></TD>
                </TR>
                {tot_amt_disct > 0 && (
                    <TR>
                        <TD colspan={3} style={{border:'1px solid #555', color:'rgb(25, 245, 40)'}} classname={"fw-700 align-left"}><i>DISCT = -{school.curr_symbol}{convertNumber(Math.ceil(tot_tax))}</i></TD>
                    </TR>
                )}
                <TR>
                    <TD ></TD>
                    <TD style={{border:'1px solid #777'}} colspan={2} classname={"fw-700 align-center"}><i className="font-very-small">Total Amount</i> <br /> = <br />
                        {school.curr_symbol}{convertNumber(Math.ceil(tot_amt))} 
                    </TD>
                </TR>
            </>
        )
    }
    

    return(
        <>
        <NotificationWrapper>
            {error.title && (errorHandler)}
            {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
        </NotificationWrapper> 
        {preloading && <PreloaderLine />}
        {!preloading && (
            <>
            
           
        {!loading2 && (
            <>
            {exist ? (
                <>
                {!preError ? (
                    <>
                        {!initCheckoutForm ? (
                        <VoWrapper img={`${BACKEND_URL}${school.icon}`} page="Payment" page_under="Congratulations on your acceptance, complete the payment process to end enrollment!" action="Payment Cart"
                        data={[{name:'Enrollment', done:true},  {name:"Cart", done:false}, {name:'Make Payment', done:false}]}>
                            <Wrapper>
                                {loading3 && <Loader />}
                                <span className="font-small align-center text-upper" style={{display:'block', color:'black', fontWeight:'700', transform:'translateY(-20px)'}}>{school.title} {currSession.session} {currSession.term_name} Term School Fee Payment </span>
                                {user.user_type === "parent" && (<span className="font-small align-center text-upper" style={{display:'block', color:'black', fontWeight:'700'}}>FOR {enrollState.enroll_sur_name} {enrollState.enroll_other_name} - {enrollState.class_accepted_name} <br /> </span>)}
                                <form onSubmit={e => onCartCheckout(e)}>
                                <Content>
                                    
                                        <div style={{width:'100%'}}>
                                            <TABLE >
                                                <thead>
                                                    <TR style={{backgroundColor:'#000'}}>
                                                        <TH style={{width:'89%', color:'#ccc'}}>Item</TH>
                                                        <TH style={{color:'#ccc'}}>Amount</TH>
                                                        <TH style={{color:'#ccc'}}>Act</TH>
                                                    </TR>
                                                </thead>
                                                <tbody>
                                                    {insertTDData()}
                                                </tbody>
                                            </TABLE>
                                        </div>
                                        <Note>To add item to cart, check the checkbox</Note>
                                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                            <button type="submit" className="btn hover-a br-5 shadow rem1_ bg-black">Proceed to Checkout <i className="fas fa-arrow-right"></i></button>
                                        </motion.div>
                                        <div className="disp-block" style={{marginTop:'20px'}}>
                                            {allSchoolFee.filter((it) => it.is_general && it.name === "school").map((item, i) => (
                                                <div className="disp-flex wrap jc-stretch" key={i}>
                                                    <div className="padd-4px">
                                                        <span className="fw-600 font-very-small app-text-color text-capitalize">School Tuition Fee:</span>
                                                    </div>
                                                    <div className="padd-4px">
                                                        <span className="fw-500 font-very-small app-text-color">{item.description ? item.description : 'Payment for educational services to be recieved'}</span>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="disp-flex wrap jc-stretch">
                                                <div className="padd-4px">
                                                    <span className="fw-600 font-very-small app-text-color text-capitalize">App Fee:</span>
                                                </div>
                                                <div className="padd-4px">
                                                    <span className="fw-500 font-very-small app-text-color">This covers payment for the initial result checking key card and other app infrastructures </span>
                                                </div>
                                            </div>
                                            {allSchoolFee.filter((it) => it.is_general && it.description && it.name !== "school" ).map((item, i) => {
                                                return(
                                                    <Fragment key={i}>
                                                        <div className="disp-flex wrap jc-stretch">
                                                            <div className="padd-4px">
                                                                <span className="fw-600 font-very-small app-text-color text-capitalize">{item.name} Fee:</span>
                                                            </div>
                                                            <div className="padd-4px">
                                                                <span className="fw-500 font-very-small app-text-color">{item.description}</span>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            })}

                                        </div>
                                </Content>
                                </form>
                            </Wrapper>
                        </VoWrapper>
                    ) : (
                        <>
                        {paymentSuccessful2 ? (
                            <>
                            <Redirect success={true} title={`Enrollment Ended Successfully `} target1="_blank" target2="_blank" link1={`https://app.voskool.com/${school.title}/pay`} link1_title="Start School App"><span className="font-big">Your enrollment to {school.name} ({school.title.toUpperCase()}) {user.user_type === "parent" && `school for ${enrollState.enroll_sur_name.toUpperCase()} ${enrollState.enroll_other_name.toUpperCase()}`}  has been APPROVED and ended successfully, you can now access the school application</span><br />
                                {allParentKid.filter((item) => item.is_enrolled && !item.is_ended && !item.is_done && item.uid !== searchParams.get('k')).length > 0 && (
                                    <>
                                       <br /> <span className="disp-block fw-700" style={{fontSize:'1.5rem'}}>Other Kid(s)</span>
                                    </>
                                )}
                                <Content style={{width:'100%', display:'flex', background:'#555', padding:'6px'}}>
                                    
                                    {allParentKid.filter((item) => item.is_enrolled && !item.is_ended && !item.is_done && item.uid !== searchParams.get('k')).map((item, i) => {
                                        const returnLink = () => {
                                            if(item.is_enrolled && !item.is_accepted){
                                                return(
                                                    <Fragment key={i}>
                                                        <div className={'f-50-alt'} style={{borderTop:'1px solid #bbb'}}>
                                                            <span className="disp-block fw-600 text-capitalize" style={{color:'#ddd'}}>{item.enroll_sur_name} {item.enroll_other_name}</span>
                                                        </div>
                                                        <div className={'f-50-alt'} style={{borderTop:'1px solid #bbb'}}>
                                                            <span className="disp-block text-capitalize"><Link  style={{color:'yellow'}} to={`/vo/enroll/${item.school_title}`}>Continue Enrollment</Link></span>
                                                        </div>
                                                    </Fragment>
                                                )
                                            }
                                            if(item.is_enrolled && item.is_accepted){
                                                return(
                                                    <Fragment key={i}>
                                                        <div className={'f-50-alt'} style={{borderTop:'1px solid #bbb', marginBottom:'2px'}}>
                                                            <span className="disp-block text-capitalize" style={{color:'#eee'}}>{item.enroll_sur_name} {item.enroll_other_name}</span>
                                                        </div>
                                                        <div className={'f-50-alt'} style={{borderTop:'1px solid #bbb', marginBottom:'2px'}}>
                                                            <span className="disp-block text-capitalize"><Link style={{color:'limegreen'}} to={`/vo/payment/enroll/${item.school_title}?k=${item.uid}`}>Start Payment</Link></span>
                                                        </div>
                                                    </Fragment>
                                                )  
                                            }
                                            return(
                                                <Fragment key={i}>

                                                </Fragment>
                                            )

                                        }
                                        return returnLink();
                                    })}
                                </Content>
                            </Redirect>
                            </>
                        ) : (
                            <>
                            {!paymentSuccessful ? (
                        <VoWrapper img={`${BACKEND_URL}${school.icon}`} page="Payment" page_under="Congratulations on your acceptance, complete the payment process to end enrollment!" action="Payment"
                        data={[{name:'Enrollment', done:true}, {name:"Cart", done:true}, {name:'Make Payment', done:false}]}>
                            <Wrapper>
                                {loading3 && <Loader />}
                                <span className="font-small align-center text-upper" style={{display:'block', color:'black', fontWeight:'700', transform:'translateY(-20px)'}}>{school.title} {currSession.session} {currSession.term_name} Term School Fee Payment </span>
                                {user.user_type === "parent" && (<span className="font-small align-center text-upper" style={{display:'block', color:'black', fontWeight:'700'}}>FOR {enrollState.enroll_sur_name} {enrollState.enroll_other_name} - {enrollState.class_accepted_name} <br /> </span>)}
                                <form onSubmit={e => onInitializeCartPayment(e)}>
                                <Content>
                                    <div style={{width:'100%'}}>
                                        <TABLE >
                                            <thead>
                                                <TR style={{backgroundColor:'var(--appBgColor)'}}>
                                                    <TH style={{width:'89%', color:'#ccc'}}>Item</TH>
                                                    <TH style={{color:'#ccc'}}>Qty</TH>
                                                    <TH style={{color:'#ccc'}}>Amount</TH>
                                                </TR>
                                            </thead>
                                            <tbody>
                                                {insertCartTTData()}
                                            </tbody>
                                        </TABLE>
                                        <div style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className="form-group align-center f-50" style={{marginTop:'15px', flex:'0 0 50%', maxWidth:'50%'}}>
                                                <button type="button" onClick={() => onBackToCart()} className="btn fw-500 hover-a br-5 shadow rem1_ bg-black" style={{padding:'7px'}}> <i className="fas fa-arrow-left"></i> Back </button>
                                            </motion.div>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className="form-group align-center f-50" style={{marginTop:'15px', flex:'0 0 50%', maxWidth:'50%'}}>
                                                <button type="submit" className="mybtn br-5 font-slightly-small fw-600 app-bg-color" style={{ color:'#eee', padding:'11px'}}>Pay {`${school.curr_symbol}${totAmt(unactivatedPaymentState)}`}  <i className="fas fa-money-alt"></i> </button>
                                            </motion.div>
                                        </div>
                                    </div>
                                </Content>
                                </form>
                            </Wrapper>
                        </VoWrapper> 
                        ) : (
                            <Redirect success={true} title={`Payment Successful! `} target1="_blank" target2="_blank" link1={`https://app.voskool.com/${school.title}/pay`} link1_title="Generate Reciept" link2={`https://app.voskool.com/${school.title}/pay`} link2_title="Start School App"><span className="font-big">Your payment of  {school.curr_symbol}<b>{totAmt(unactivatedPaymentState)}</b> to {school.name} ({school.title.toUpperCase()}) {user.user_type === "parent" && `school for ${enrollState.enroll_sur_name.toUpperCase()} ${enrollState.enroll_other_name.toUpperCase()}`}  was successful</span><br />
                                {allParentKid.filter((item) => item.is_enrolled && !item.is_ended && !item.is_done && item.uid !== searchParams.get('k')).length > 0 && (
                                    <>
                                       <br /> <span className="disp-block fw-700" style={{fontSize:'1.5rem'}}>Other Kid(s)</span>
                                    </>
                                )}
                                <Content style={{width:'100%', display:'flex', background:'#555', padding:'6px'}}>
                                    
                                    {allParentKid.filter((item) => item.is_enrolled && !item.is_ended && !item.is_done && item.uid !== searchParams.get('k')).map((item, i) => {
                                        const returnLink = () => {
                                            if(item.is_enrolled && !item.is_accepted){
                                                return(
                                                    <Fragment key={i}>
                                                        <div className={'f-50-alt'} style={{borderTop:'1px solid #bbb'}}>
                                                            <span className="disp-block fw-600 text-capitalize" style={{color:'#ddd'}}>{item.enroll_sur_name} {item.enroll_other_name}</span>
                                                        </div>
                                                        <div className={'f-50-alt'} style={{borderTop:'1px solid #bbb'}}>
                                                            <span className="disp-block text-capitalize"><Link  style={{color:'yellow'}} to={`/vo/enroll/${item.school_title}`}>Continue Enrollment</Link></span>
                                                        </div>
                                                    </Fragment>
                                                )
                                            }
                                            if(item.is_enrolled && item.is_accepted){
                                                return(
                                                    <Fragment key={i}>
                                                        <div className={'f-50-alt'} style={{borderTop:'1px solid #bbb', marginBottom:'2px'}}>
                                                            <span className="disp-block text-capitalize" style={{color:'#eee'}}>{item.enroll_sur_name} {item.enroll_other_name}</span>
                                                        </div>
                                                        <div className={'f-50-alt'} style={{borderTop:'1px solid #bbb', marginBottom:'2px'}}>
                                                            <span className="disp-block text-capitalize"><Link style={{color:'limegreen'}} to={`/vo/payment/enroll/${item.school_title}?k=${item.uid}`}>Start Payment</Link></span>
                                                        </div>
                                                    </Fragment>
                                                )  
                                            }
                                            return(
                                                <Fragment key={i}>

                                                </Fragment>
                                            )

                                        }
                                        return returnLink();
                                    })}
                                </Content>
                            </Redirect>
                        )}
                            </>
                        )}
                        
                        </>
                    )}
                    </>
                ) : (
                    <Redirect error title="400"  btn1 btn1_title="Reload" onClickBtn1={() => setReload(true)}>{preErrorMsg}</Redirect>
                )}
                
                    
                </>
            ) : (
                <Redirect error404 link1={`/vo/enroll/${school.title}`} link1_title="Back To Homepage" />
            )}
            </>
        )}
        </>
        )}
        </>
        
    )
}

export default StudentEnrollmentPayment;
