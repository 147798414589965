export const phoneDivider = (ph="") => {
    let phone = ph.toString();
    if(phone.length > 0){
        const phone_div = phone.slice(4, phone.length-4);
        let val = phone.slice(0, 4);
        for(let i = 0; i < phone_div.length; i = i + 3){
            val = ` ${val} ${phone_div.slice(i, i+3)}`;
        }
        val = `${val} ${phone.slice(phone.length-4, phone.length)}`;
        return val;
    }
    return phone;
}