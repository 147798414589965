import React, {useState, useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import SignUpPage from "components/app/vo/signUpPage";
import PrerollmentPage from "components/app/vo/prerollPage";
import EnrollmentPage from "components/app/vo/enrollPage";
import JobApplicationPage from "components/app/vo/jobApplicationPage";
import JobApplicationFormPage from "components/app/vo/jobApplicationFormPage";
import CheckApplication from "components/app/vo/checkApplication";
import StudentEnrollmentPayment from "components/app/vo/payment/student";
import Redirect from "components/redirectPage";

export const VoRoutes = ({state}) => {
    const [user, setUser] = useState(state);
    useEffect(() => {
        setUser(state);
    }, [state])
    return(
        <Routes>
            <Route path="signup" element={<SignUpPage state={user} />} />
            <Route path="preroll/:sn" element={<PrerollmentPage />} />
            <Route path="enroll/:sn" element={<EnrollmentPage inner_state={user} />} />
            <Route path="payment/enroll/:sn" element={<StudentEnrollmentPayment inner_state={user} />} />
            <Route path="job/application" element={<JobApplicationFormPage />} />
            <Route path="job/check/application" element={<CheckApplication />} />
            <Route path="job/application/:sn" element={<JobApplicationPage />} />
            <Route path="/*" element={<Redirect error404 title='Page Not Found' link1='/' link1_title='Back To Voskool' />} />
        </Routes>
    )
}