import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.div)`
    margin-top: 5px;

    .header{
        width: 100%;
        padding: 3px;
        border-radius: 2px;
        margin-bottom : 8px;
        @media(max-width:540px){
            padding: 1px;
        }
        span{
            font-weight: 600;
            display: inline-block;
            i{
                padding: 0 3px;
            }
        }
    }
    .content{
        padding: 0 10px;
        @media(max-width:540px){
            padding:0 7px;
        }
        
    }
`;