import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";

import VoWrapper from "components/app/vo/SmallerComponents/wrapper";
import { Wrapper, Content } from "components/app/vo/signUpPage/style.js";
import { FileContent, FileBtnContent } from "./style";
import { NotificationWrapper } from "components/app/notification/style";

import { BACKEND_URL } from "actions/url";

import { appServices } from "api/schoolApp/services";
import { COUNTRIES, STATES } from "api/voskool/services/countries";
import { load_user } from "actions/auth";
import authHandler from "actions/auth_handler";
import { validateSchoolStaff } from "components/app/actions/validation";

import Loader from "components/app/loader";
import Notification from "components/app/notification";
import Spinner from "components/spinner";
import Redirect from 'components/redirectPage';

const initialUserState = {
    email: "",
    first_name: "",
    id: 0,
    uid:'',
    last_name: "",
    phone_number: "",
    user_name: "",
    user_type: null,
}
const JobApplicationFormPage = () => {
    const initialFormUserState = useMemo(() => ({profile_pic:File, national_id:File, school_cert_value:'', profile_pic_value:'', national_id_value:'', school_cert:File, gender:'', dob:'', rel:'', nationality:'', state_origin:'', marital_status:'', work_phone:'',
        lang_spoken:'', addr:'', job:'',  cert_name:'', cert_type:'', exp:'', loc_heard:'', kin:'', kin_name:'', kin_email:'',
        kin_phone:'', kin_work_phone:'', kin_addr:''}), []);
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialImgParamState = useMemo(() => ({next:0, prev:0, page:0, id:0}), []);
    const [formUser, setFormUser] = useState(initialFormUserState)
    const [formUserError, setFormUserError] = useState({});
    const [staffSchoolType, setStaffSchoolType] = useState({id:0, staff_t:'', name:''});
    const [user, setUser] = useState(initialUserState);
    const [staff, setStaff] = useState({id:0});
    const [openImgFile, setOpenImgFile] = useState(null);
    const [openImg, setOpenImg] = useState(null);
    const [openImgParam, setOpenImgParam] = useState(initialImgParamState)

    const [allCountries, setAllCountries] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [allLocations, setAllLocations] = useState([]);
    const [allGuardians, setAllGuardians] = useState([]);
    const [allStaffSchoolType, setAllStaffSchoolType] = useState([]);
    const [allCv, setAllCv] = useState([]);

    const navigate = useNavigate();

    // const [formName, setFormName] = useState('personal');
    const [urlName, setUrlName] = useState('');
    const [loading, setLoading] = useState(false);
    const [spin, setSpin] = useState(false);
    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [isOpened, setIsOpened] = useState(false);
    const [submit, setSubmit] = useState('');

    const fetchUser =  async () => {
        setIsAuthenticated(true);
        setLoading(true);
        setSpin(true)
        await authHandler.load_user(load_user()).then(result => {
            setIsAuthenticated(result.isAuthenticated); 
            setUser({...result.data})
            setLoading(false)
            appServices.getStaffSchool(result.data.id).then(res => {
                setStaff({...res.data});
                appServices.getAllStaffSchoolCVData(result.data.id).then(r => {
                    setAllCv([...r.data])
                    setSpin(false);
                }).catch(e => {
                    setAllCv([])
                    setSpin(false);
                })
                console.log(res);
            }).catch(e => {
                setStaff({id:0});
            })
        }).catch(error => {
            setIsAuthenticated(false);
            console.log(error)
            setLoading(false)
        })
    }
    const fetchAllCountries = () => {
        COUNTRIES(1, 300).then(res => {
            setAllCountries([...res.data.results])
        }).catch(e => {
            setAllCountries([]);
        })
    }
    const fetchAllStates = (cid) => {
        STATES(cid).then(res => {
            console.log(res);
            setAllStates([...res.data]);
        }).catch(e => {
            setAllStates([]);
        })
    }
    const fetchAllLocations = () => {
        appServices.getAllLocations().then(res => {
            setAllLocations([...res.data])
        }).catch(e => {
            setAllLocations([]);
        })
    }
    const fetchAllStaffSchoolType = (id=0) => {
        appServices.getAllStaffSchoolTypeData(id).then(res => {
            setAllStaffSchoolType([...res.data])
        }).catch(e => {
            setAllStaffSchoolType([]);
        })
    }
    const getStaffSchoolType = (id=0) => {
        const check = allStaffSchoolType.find((item) => {
            return item.id === id
        })
        if(check){
            const filter = allStaffSchoolType.filter((item) => {
                return item.id === id
            })
            return filter[0];
        }
        return {staff_t:'', id:0, name:''};
        
        
    }
    const fetchAllGuardians = () => {
        appServices.getAllGuardians().then(res => {
            setAllGuardians([...res.data]);
        }).catch(e => {
            setAllGuardians([]);
        })
    }
    const fetchAllCV = (id) => {
        setSpin(true);
        appServices.getAllStaffSchoolCVData(id).then(res => {
            setAllCv([...res.data]);
            setSpin(false);
        }).catch(e => {
            setAllCv([]);
            setSpin(false);
        })
    }

    const handleUserChange = (e) => {
        if(e.target.name === "job"){
            setFormUser({...formUser, [e.target.name]:e.target.value});
            setStaffSchoolType(getStaffSchoolType(parseInt(e.target.value)))
        }
        if(e.target.name === "qualification"){
            setFormUser({...formUser, school_cert:null, school_cert_value:'',  cert_name:'', cert_type:'', [e.target.name]:e.target.value});
            return;
        }
        setFormUser({...formUser, [e.target.name]:e.target.value});
    }
    const handleUserChangeFile = (e) => {
        setFormUser({...formUser, [e.target.name]:e.target.files[0], [e.target.name + '_value']:e.target.value});
    }
    const handleUserCountryChange = (e) => {
        setFormUser({...formUser, [e.target.name]:e.target.value});
        fetchAllStates(e.target.value);
    }
    const onCvNext = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        if(allCv.length > 0){
            setUrlName('/vo/job/application/school');
        } else {
            setError({title:'Error', data:['No CV Found, To Procceed Click Skip']})
        }
    }
    const handleOpenImg = (e) => {
        const file = e.target.files[0];
        const ext = ['jpeg', '.jpg', '.png', '.gif'];
        const img = URL.createObjectURL(e.target.files[0]);
        const file_ext = file.name.slice(-4);
        setError(initialErrorState);
        if(!ext.includes(file_ext)){
            setError({title:'Error', data:['File Must Be An Image']});
            closeOpenedImg();
            e.target.value = null;
        }
        else{
            setOpenImgFile(e.target.files[0]);
            setOpenImg(img);
            setOpenImgParam({...initialImgParamState, page: allCv.length + 1});
            setIsOpened(true);
            e.target.value = null;
        }
    }
    const closeOpenedImg = () => {
        setOpenImgFile(null);
        setOpenImg('');
        setIsOpened(false);
        setOpenImgParam(initialImgParamState)
    }
    const onUpload = (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(initialSuccessState);
        setError(initialErrorState);
        const formData = new FormData();
        formData.append('cv', openImgFile, openImgFile.name);
        formData.append('staff', staff.id);
        appServices.insertStaffSchoolCV(formData).then(res => {
            setLoading(false);
            closeOpenedImg();
            fetchAllCV(user.id);
            setSuccess({title:'File Uploaded', text:`File Uploaded Successfully`});
        }).catch(e => {
            setLoading(false);
            setError({title:'Upload Failed', data:['Something Went Wrong']});
        })
    }
    const onClickCv = (item, i=0) => {
        const next = i + 1;
        const prev = i - 1;
        setOpenImgFile(null);
        setOpenImg(`${BACKEND_URL}${item.cv}`);
        setIsOpened(false);
        setOpenImgParam({page:i, next:allCv.length === i ? 0: next, prev:prev, id:item.id});
    }
    const onClickArrowBtn = (i) => {
        const filterItem = allCv[i-1];
        onClickCv(filterItem, i);
    }
    const onDeleteUploadedFile = (e, id) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(initialSuccessState);
        setError(initialErrorState);
        appServices.deleteStaffSchoolCV(id).then(res => {
            setLoading(false);
            closeOpenedImg();
            fetchAllCV(user.id);
            setSuccess({title:'File Deleted', text:`Image Deleted Successfully`});
        }).catch(e => {
            setLoading(false);
            setError({title:'Error', data:['Something Went Wrong']});
        })
    }
    const onSubmitSchoolStaff = (e) => {
        e.preventDefault();
        setFormUserError(validateSchoolStaff(formUser, staffSchoolType));
        setSubmit("insert_school_staff")
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(submit === "inserted_school_staff"){
            setLoading(true);
            const formData = new FormData();
            formData.append("gender", formUser.gender);
            formData.append("dob", formUser.dob);
            formData.append("rel", formUser.rel);
            formData.append("lang_spoken", formUser.lang_spoken);
            formData.append("work_phone", formUser.work_phone);
            formData.append("marital_status", formUser.marital_status);
            formData.append("national_id", formUser.national_id, formUser.national_id.name);
            formData.append("qualification", formUser.qualification !== "none" ? formUser.qualification : formUser.qualification_other);
            formUser.qualification !== "none" && formData.append("cert", formUser.school_cert, formUser.school_cert.name);
            formUser.qualification !== "none" && formData.append("cert_name", formUser.cert_name);
            formUser.qualification !== "none" && formData.append("cert_type", formUser.cert_type);
            staffSchoolType.name === "teacher" && formData.append("subject_handle", formUser.sub_teach);
            formData.append("address", formUser.addr);
            formData.append("experience", formUser.exp);
            formData.append("nok_name", formUser.kin_name);
            formData.append("nok_email", formUser.kin_email);
            formData.append("nok_phone", formUser.kin_phone);
            formData.append("nok_work_phone", formUser.kin_work_phone);
            formData.append("nok_address", formUser.kin_addr);
            formData.append("country_origin", formUser.nationality);
            formData.append("state_origin", formUser.state_origin);
            formData.append("loc_heard", formUser.loc_heard);
            formData.append("nok", formUser.kin);
            formData.append("staff_type", formUser.job);
            formData.append("user", user.id);
            formData.append("is_done", true);
            const profileForm = new FormData();
            profileForm.append("profile_pic", formUser.profile_pic, formUser.profile_pic.name);

            appServices.updateUserProfile(user.uid, profileForm).then(() => {
                appServices.insertStaffSchool(formData).then(res => {
                    setLoading(false);
                    setSubmit('');
                    setFormUser(initialFormUserState);
                    setStaff({...res.data});
                    setSuccess({title:'Success', text:`Form Submitted Successfully`});
                }).catch(e => {
                    setLoading(false);
                    setSubmit('');
                })
            })
        }
    }
    console.log(openImgParam)
    useEffect(() => {
        if(isAuthenticated === false){
            return navigate('/vo/signup');
        }
        fetchUser();
        fetchAllCountries();
        fetchAllLocations();
        fetchAllGuardians();
        fetchAllStaffSchoolType();
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if(Object.keys(formUserError).length === 0 && submit === "insert_school_staff"){
            setSubmit("inserted_school_staff");
        }
        if(urlName){
            return navigate(urlName);
        }
    }, [formUserError, submit, urlName, navigate])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })

    const countryOptions = allCountries.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const stateOptions = allStates.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const locationOptions = allLocations.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const guardianOptions = allGuardians.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const staffSchoolTypeOptions = allStaffSchoolType.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const mappedCv = allCv.map((item, i) => {
        i++;
        return(
            <>
            <div className={i === openImgParam.page ? "img-cont selected" : "img-cont"} onClick={() => onClickCv(item, i)}>
                <img src={`${BACKEND_URL}${item.cv}`} alt="CV" />
                <span className="page align-center">{i}</span>
            </div>
            </>
        )
    })

    const formInsert = () => {
        if(!staff.id){
            return(
                <VoWrapper page="JOBBER" page_under="Application Form Page" action="PERSONAL FORM"
                data={[{name:'What Service Would You Like From Us Today?', done:true}, {name:'Personal Form', done:false}, {name:'CV', done:false}, {name:'Select School....', done:false}, {name:'School Job Application', done:false}, {name:'Role', done:false},]}>
                    <Wrapper>
                        {loading && <Loader />}
                        <form onSubmit={e => onSubmitSchoolStaff(e)}>
                            <Content>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Passport Picture<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="file" className={formUserError.profile_pic ? `f-c b-red`: `f-c`} name="profile_pic" value={formUser.profile_pic_value} onChange={e => handleUserChangeFile(e)} />
                                    {formUserError.profile_pic && (<span className="error">{formUserError.profile_pic}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                    <label>Select Gender<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserError.gender ? `f-c b-red`: `f-c`} name="gender" value={formUser.gender} onChange={e => handleUserChange(e)}>
                                        <option value="">Select...</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                    {formUserError.gender && (<span className="error">{formUserError.gender}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Date Of Birth<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="date" className={formUserError.dob ? `f-c b-red`: `f-c`} name="dob" value={formUser.dob} onChange={e => handleUserChange(e)} />
                                    {formUserError.dob && (<span className="error">{formUserError.dob}</span>)}
                                </motion.div>        
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                    <label>Select Religion<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserError.rel ? `f-c b-red`: `f-c`} name="rel" value={formUser.rel} onChange={e => handleUserChange(e)}>
                                        <option value="">Select...</option>
                                        <option value="islam">Islam</option>
                                        <option value="christian">Christianity</option>
                                    </select>
                                    {formUserError.rel && (<span className="error">{formUserError.rel}</span>)}
                                </motion.div>   
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                    <label>Select Nationality<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserError.nationality ? `f-c b-red`: `f-c`} name="nationality" value={formUser.nationality} onChange={e => handleUserCountryChange(e)}>
                                        <option value="">Select...</option>
                                        {countryOptions}
                                    </select>
                                    {formUserError.nationality && (<span className="error">{formUserError.nationality}</span>)}
                                </motion.div>   
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                    <label>Select State Of Origin<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserError.state_origin ? `f-c b-red`: `f-c`} name="state_origin" value={formUser.state_origin} onChange={e => handleUserChange(e)}>
                                        <option value="">Select...</option>
                                        {stateOptions}
                                    </select>
                                    {formUserError.state_origin && (<span className="error">{formUserError.state_origin}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                    <label>Select Marital Status<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserError.marital_status ? `f-c b-red`: `f-c`} name="marital_status" value={formUser.marital_status} onChange={e => handleUserChange(e)}>
                                        <option value="">Select...</option>
                                        <option value="single">Single</option>
                                        <option value="married">Married</option>
                                        <option value="divorced">Divorced</option>
                                        <option value="seperated">Seperated</option>
                                        <option value="widowed">Widowed</option>
                                        <option value="widower">Widower</option>
                                    </select>
                                    {formUserError.marital_status && (<span className="error">{formUserError.marital_status}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Work Phone Number <span>(optional)</span></label>
                                    <input type="text" className={`f-c`} name="work_phone" placeholder="Work Phone Number" value={formUser.work_phone} onChange={e => handleUserChange(e)} />
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Language Spoken<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="text" className={formUserError.lang_spoken ? `f-c b-red`: `f-c`} name="lang_spoken" placeholder="Languages" value={formUser.lang_spoken} onChange={e => handleUserChange(e)} />
                                    {formUserError.lang_spoken && (<span className="error">{formUserError.lang_spoken}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Current Residential Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <textarea className={formUserError.addr ? `f-c b-red`: `f-c`} name="addr" placeholder="Address" value={formUser.addr} onChange={e => handleUserChange(e)} />
                                    {formUserError.addr && (<span className="error">{formUserError.addr}</span>)}
                                </motion.div>  
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Identification (Birth / National ID)<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="file" className={formUserError.national_id ? `f-c b-red`: `f-c`} name="national_id" value={formUser.national_id_value} onChange={e => handleUserChangeFile(e)} />
                                    {formUserError.national_id && (<span className="error">{formUserError.national_id}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                    <label>What Job Would You Like To Apply?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserError.job ? `f-c b-red text-capitalize`: `f-c text-capitalize`} name="job" value={formUser.job} onChange={e => handleUserChange(e)}>
                                        <option value="">Select...</option>
                                        {staffSchoolTypeOptions}
                                    </select>
                                    {formUserError.job && (<span className="error">{formUserError.job}</span>)}
                                </motion.div>
                                {formUser.job && (
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Qualification<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.qualification ? `f-c b-red text-capitalize`: `f-c text-capitalize`} name="qualification" value={formUser.qualification} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            <option value="phd degree">Doctorate (ph.D)</option>
                                            <option value="master degree">Master's Degree (MA, M.Sc)</option>
                                            <option value="undergraduate degree">Undergraduate Degree (Bachelors)</option>
                                            <option value="polytechnic degree">Polythechnic Degree (HND, HNC)</option>
                                            <option value="nce">NCE</option>
                                            <option value="a level">A-Level</option>
                                            <option value="ssce">SSCE</option>
                                            <option value="other">Other</option>
                                            <option value="none">None</option>
                                        </select>
                                        {formUserError.qualification && (<span className="error">{formUserError.qualification}</span>)}
                                    </motion.div>
                                )}
                                
                                {formUser.qualification === "other" && (
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Qualification Name<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="text" className={formUserError.qualification_other ? `f-c b-red`: `f-c`} name="qualification_other" placeholder="Qualification Name" value={formUser.qualification_other} onChange={e => handleUserChange(e)} />
                                        {formUserError.qualification_other && (<span className="error">{formUserError.qualification_other}</span>)}
                                    </motion.div>
                                )}
                                
                                {formUser.qualification  && (
                                    <>
                                        {formUser.qualification !== "none" && (
                                            <>
                                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                                <label>School/University Certificate<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                                <input type="file" className={formUserError.school_cert ? `f-c b-red`: `f-c`} name="school_cert" value={formUser.school_cert_value} onChange={e => handleUserChangeFile(e)} />
                                                {formUserError.school_cert && (<span className="error">{formUserError.school_cert}</span>)}
                                            </motion.div>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                                <label>Name Of School/University Graduated From? <span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                                <input type="text" className={formUserError.cert_name ? `f-c b-red`: `f-c`} name="cert_name" placeholder="Name" value={formUser.cert_name} onChange={e => handleUserChange(e)} />
                                                {formUserError.cert_name && (<span className="error">{formUserError.cert_name}</span>)}
                                            </motion.div>
                                            {formUser.qualification.search('degree') > 0 && (
                                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                                    <label>Type Of School/University Degree? <span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                                    <input type="text" className={formUserError.cert_type ? `f-c b-red`: `f-c`} name="cert_type" placeholder="e.g B.Sc Computer Sci" value={formUser.cert_type} onChange={e => handleUserChange(e)} />
                                                    {formUserError.cert_type && (<span className="error">{formUserError.cert_type}</span>)}
                                                </motion.div> 
                                            )} 
                                            
                                            </>
                                        )}
                                    
                                    </>
                                )}
                                {staffSchoolType.name === "teacher" && 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>What Subject(s) can you teach?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <textarea className={formUserError.sub_teach ? `f-c b-red`: `f-c`} name="sub_teach" placeholder="Subjects" value={formUser.sub_teach} onChange={e => handleUserChange(e)} />
                                        {formUserError.sub_teach && (<span className="error">{formUserError.sub_teach}</span>)}
                                    </motion.div>
                                }
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>What Prior Experience Do You Have On This Stipulated Field Of Work?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <textarea className={formUserError.exp ? `f-c b-red`: `f-c`} name="exp" placeholder="Experience...." value={formUser.exp} onChange={e => handleUserChange(e)} />
                                    {formUserError.exp && (<span className="error">{formUserError.exp}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                    <label>How did you hear about us?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserError.loc_heard ? `f-c b-red`: `f-c`} name="loc_heard" value={formUser.loc_heard} onChange={e => handleUserChange(e)}>
                                        <option value="">Select...</option>
                                        {locationOptions}
                                    </select>
                                    {formUserError.loc_heard && (<span className="error">{formUserError.loc_heard}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100 align-center">
                                    <h4>Next Of Kin Details</h4>
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Next Of Kin<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserError.kin ? `f-c b-red`: `f-c`} name="kin" value={formUser.kin} onChange={e => handleUserChange(e)}>
                                        <option value="">Select...</option>
                                        {guardianOptions}
                                    </select>
                                    {formUserError.kin && (<span className="error">{formUserError.kin}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Kin's FullName<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="text" className={formUserError.kin_name ? `f-c b-red`: `f-c`} name="kin_name" placeholder="Full Name" value={formUser.kin_name} onChange={e => handleUserChange(e)} />
                                    {formUserError.kin_name && (<span className="error">{formUserError.kin_name}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Kin's Email<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="email" className={formUserError.kin_email ? `f-c b-red`: `f-c`} name="kin_email" placeholder="Email" value={formUser.kin_email} onChange={e => handleUserChange(e)} />
                                    {formUserError.kin_email && (<span className="error">{formUserError.kin_email}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Kin's Phone Number<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="text" className={formUserError.kin_phone ? `f-c b-red`: `f-c`} name="kin_phone" placeholder="Phone Number" value={formUser.kin_phone} onChange={e => handleUserChange(e)} />
                                    {formUserError.kin_phone && (<span className="error">{formUserError.kin_phone}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Kin's Work Phone Number <span>(optional)</span></label>
                                    <input type="text" className={`f-c`} name="kin_work_phone" placeholder="Work Phone Number" value={formUser.kin_work_phone} onChange={e => handleUserChange(e)} />
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Kin's Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <textarea className={formUserError.kin_addr ? `f-c b-red`: `f-c`} name="kin_addr" placeholder="Address" value={formUser.kin_addr} onChange={e => handleUserChange(e)} />
                                    {formUserError.kin_addr && (<span className="error">{formUserError.kin_addr}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className={`form-group align-center f-100`} style={{marginTop:'15px'}}>
                                    <button type="submit" className="btn hover-a br-5 shadow rem1_ bg-black">Next <i className="fas fa-arrow-right"></i></button>
                                </motion.div>
                            </Content>
                        </form>
                    </Wrapper>
                </VoWrapper>
            )
        } else {
            return(
                <VoWrapper page="JOBBER" page_under="Application Form Page" action="UPLOAD CV"
                data={[{name:'What Service Would You Like From Us Today?', done:true}, {name:'Personal Form', done:true}, {name:'CV', done:false}, {name:'Select School....', done:false}, {name:'School Job Application', done:false}, {name:'Role', done:false},]}>
                    <Wrapper>
                        {loading && <Loader />}
                        <form onSubmit={e => onCvNext(e)}>
                            <Content>
                                <FileContent>
                                    <div className="cont">
                                        {openImg ? <img src={openImg} alt="CV" /> :
                                            <div className="text-cont">
                                                <span className="text"><i className="fa fa-image"></i> Open file to select a page image of your CV </span>
                                            </div>
                                        }
                                    </div>
                                    <div className="wrapper-btn">
                                        <div className="jc-sb">
                                            {openImgParam.prev !== 0 &&             
                                                <button type="button" onClick={() => onClickArrowBtn(openImgParam.prev)} className="btn-br"><i className="fas fa-arrow-left"></i></button>
                                            }
                                            {openImg && 
                                                <span className="page align-center">Page {openImgParam.page}</span> 
                                            } 
                                            {openImgParam.next !== 0 &&             
                                                <button type="button" onClick={() => onClickArrowBtn(openImgParam.next)} className="btn-br"><i className="fas fa-arrow-right"></i></button>
                                            }
                                        </div>  
                                    </div>
                                    {spin ? <Spinner /> :
                                        allCv.length > 0 && (
                                            <div className="wrapper-img">
                                                <h4 className="align-center">All Uploaded CV<sub>s</sub></h4>
                                    
                                                <div className="inner-cont">
                                                    {mappedCv}
                                                </div>
                                            </div>
                                        )
                                    }
                                </FileContent>
                                <FileBtnContent>
                                    <div>
                                        <input type="file" onChange={e => handleOpenImg(e)} id="open" />
                                        {!openImg ? 
                                            <label className="btn-br" htmlFor="open">Open File... <i className="fas fa-folder-open"></i></label> :
                                            <button className="btn-br" onClick={() => closeOpenedImg()}>Cancel <i className="fas fa-times"></i></button>
                                        }
                                    </div>
                                    {openImgParam.id !== 0 && (
                                        <div>
                                            <button className="btn-da" onClick={(e) => onDeleteUploadedFile(e, openImgParam.id)}>Delete <i className="fas fa-trash"></i></button>
                                        </div>
                                    )}
                                    <div>
                                        <button type="button" onClick={(e) => onUpload(e)} disabled={!isOpened ? true : false} className={!isOpened ? "disabled btn-bg" : 'btn-bg'}>Upload <i className="fas fa-upload"></i></button>
                                    </div>
                                </FileBtnContent>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className={`form-group align-center f-50-alt`} style={{marginTop:'15px'}}>
                                    <Link to="/vo/job/application/school"><button type="button"  className="btn hover-b br-5 shadow rem1_">Skip <i className="fas fa-stop"></i></button></Link>
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className={`form-group align-center f-50-alt`} style={{marginTop:'15px'}}>
                                    <button type="submit" className="btn hover-a br-5 shadow rem1_ bg-black">Next <i className="fas fa-arrow-right"></i></button>
                                </motion.div>
                            </Content>
                        </form>
                    </Wrapper>
                </VoWrapper>
            )
        }
    }

    return(
        <>
            <NotificationWrapper>
                {error.title && (errorHandler)}
                {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper> 
            {user.user_type === "staff" ? formInsert() : (<Redirect error404 link1="/" link1_title="Back To Homepage" />)}
        </>
    )
}

export default JobApplicationFormPage;