import axios from 'axios';
import { BACKEND_URL, API } from 'actions/url';
const token = localStorage.getItem("access") ? JSON.parse(localStorage.getItem("access")) : '';

const href = window.location.href.split('/');
export default axios.create({
    baseURL: `${BACKEND_URL}${API}`,
    headers: {
        "Content-Type":"application/json",
        Authorization: `JWT ${token.access}`,
        School: href[3],
    }
})
//For Staff
export const http4 = (sub_ass='') => {
    return axios.create({
        baseURL:`${BACKEND_URL}${API}`,
        headers: {
            "Content-Type":"application/json",
            Authorization: `JWT ${token.access}`,
            Ass: sub_ass,
            School: href[3],
        }
    })
}
export const http_file4= (sub_ass="") => {return axios.create({
    baseURL:`${BACKEND_URL}${API}`,
    headers: {
        'Content-Type':'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
        Authorization: `JWT ${token.access}`,
        Ass: sub_ass,
        School: href[3],
    }
})}

//For parent and children
export const http3 = (child_auth="") => {
    return axios.create({
    baseURL: `${BACKEND_URL}${API}`,
    headers: {
        "Content-Type":"application/json",
        Authorization: `JWT ${token.access}`,
        Child_Authorization: child_auth,
        School: href[3],
    }
    
    })
}

export const normal_http = axios.create({
    baseURL: `${BACKEND_URL}${API}`,
    headers: {
        "Content-Type":"application/json",
    }
})

export const http_file = axios.create({
    baseURL:`${BACKEND_URL}${API}`,
    headers: {
        'Content-Type':'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
        Authorization: `JWT ${token.access}`,
        School: href[3],
    }
})