import React from "react";
import {OuterWrapper, Wrapper, InnerWrapper} from './style';

const CenteredPage = ({children, type="big", title="", bg_style={}}) => {
    return(
        <OuterWrapper>
            <Wrapper>
                <InnerWrapper className={`${type} app-theme` } style={bg_style}>
                    {title && (
                        <div className=" disp-block">
                            <span className="font-small fw-700 ">{title}</span>
                        </div>
                    )}
                    {children}
                </InnerWrapper>
            </Wrapper>
        </OuterWrapper>
    )
}

export default CenteredPage;