import { styled } from "styled-components";

export const OuterWrapper = styled.div`
    width: 100%;
    margin: 30px 0px;
    padding: 10px;
    padding-left: 40px;
    padding-right: 40px;
    overflow-x: auto;
`;
export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
`;

export const Content = styled.div`
    display: block;
    img{
        width: 200px;
        height: 200px;
    }
`;