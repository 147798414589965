import React from "react";
import { Link } from 'react-router-dom';
import { Content } from "./style";
// import user_icon from "images/user-icon.png";
const UserProfile = ({user={data:{}}}) => {
    return(
    <Content>
        <div className="user">
            {user.data.profile_pic ? <img src={`${user.data.profile_pic}`} className="user-icon" alt="Me" /> :  <i className="fas fa-user user-icon white"></i> }
            
            <div className="user-data">
                <div className="main-header">
                    
                    <div className="header">
                        <span className="heading">{user.data.user_name}</span>
                    </div>   
                    <div className="header align-right">
                        <span className="text green">Online</span>
                    </div> 
                    
                    
                </div>
                {user.data.profile_pic && (
                    <div className="header align-center">
                        <img src={user.data.profile_pic} className="user-icon" style={{width:'60px', height:'60px'}} alt="Me" />
                    </div>
                )}
                <div className="data-content">
                    <div className="">   
                        <Link>
                            <div className="data-flex">
                                <div className="data">
                                    <span className="heading">Logout</span>
                                </div>
                                <div className="data-icon">
                                    <i className="fas fa-power-off red font-small" style={{background:'none'}}></i>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* <div className="data-content">
                    <div className="header">
                        <span>Enrolled School(s)</span>
                    </div>
                    <Link to="/">
                        <div className="data-flex">
                            <div className="data-img">
                                <img src={user_icon} alt="emife" />
                            </div>
                            <div className="data">
                                <span className="heading">EMIFE</span>
                                <span className="text">El-maasum Institute For Excellence</span>
                            </div>
                            <div className="data-icon">
                                <i className="fas fa-arrow-right"></i>
                            </div>
                        </div>
                    </Link>    
                    <Link to="/">
                        <div className="data-flex">
                            <div className="data-img">
                                <img src={user_icon} alt="emife" />
                            </div>
                            <div className="data">
                                <span className="heading">EMIFE</span>
                                <span className="text">El-maasum Institute For Excellence</span>
                            </div>
                            <div className="data-icon">
                                <i className="fas fa-arrow-right"></i>
                            </div>
                        </div>
                    </Link>    
                </div>
                <div className="data-content">
                    <div className="header flex">
                        <span>Activities</span>
                        <img className="country-icon" src={user_icon} alt="Country" />
                    </div>
                    <Link to="/">
                        <div className="data-flex">
                            <div className="datas-img">
                            <i className="fas fa-user"></i>
                            </div>
                            <div className="datas">
                                <span>my profile</span>
                            </div>
                        </div>
                        <div className="data-flex">
                            <div className="datas-img">
                                <i className="fas fa-money-bill"></i>
                            </div>
                            <div className="datas">
                                <span>Payment Record</span>
                            </div>
                        </div>    
                    </Link>
                </div> */}
            </div>
        </div>    
    </Content>
    )
}

export default UserProfile;