import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 5px;
    
    @media(max-width: 420px){
        margin: 0;
    }
    
`;
export const Content = styled.div`
    background:var(--voskoolBagColor);
    padding: 20px 4px;
    box-shadow: 0px 0px 7px 5px rgba(0,0,0,0.2);
    border: 2px solid grey;
    position: relative;
    /* @media(max-width: 1024px){
        width: 85%;
    }
    @media(max-width: 420px){
        width: 80%;
    } */
    
    .card-header{
        padding-bottom: 20px;
        
        .heading{
            color: #ddd;
            font-size: 1.8rem;
            font-weight: 700;
            letter-spacing: .8px;

            i{
                padding-top: 5px;
                display: inline-block;
                font-weight: 600;
                font-size: .7rem;
            }
        }
        
    }
    .card-content{
        padding: 0;
        display: inline-block;
        .price-item{
            color: #ddd;
            margin-bottom: 15px;
            @media(max-width: 540px){
                margin-bottom: 10px;
            }
            .span{
                font-size: .9rem;
                font-weight: 500;
                @media(max-width: 540px){
                    font-size: .8rem;
                }
            }
            .old-price{
                text-decoration: line-through;
                font-size: .8rem;
                font-weight: 600;
                @media(max-width: 540px){
                    font-size: .7rem;
                }
            }
            .disct{
                margin-left: 10px;
                padding: 5px;
                border-radius: 25px;
                font-size: .76rem;
                border: 1px solid #ddd;
                text-transform: uppercase;
                @media(max-width: 540px){
                    font-size: .6rem;
                }
            }
            .curr{
                color:#ddd;
                font-size: 1.4rem;
                font-weight: 500;
                padding-right: 3px;
                @media(max-width: 540px){
                    font-size: 1.2rem;
                }
            }
            .price{
                color:#fff;
                font-size: 2.5rem;
                font-weight: 700;
                letter-spacing: .8px;
                @media(max-width: 540px){
                    font-size: 2rem;
                }
            }
            .price-time{
                color:#ddd;
                font-size: 1.4rem;
                font-weight: 500;
                padding-left: 3px;
                @media(max-width: 540px){
                    font-size: 1.2rem;
                }
            }
            .item-cont{
                padding: 15px 0px;
                a{
                    
                    padding: 10px 75px;
                    text-decoration: none;
                    font-size: 1.3rem;
                    color: #eee;
                    font-weight: 600;
                    background: #fc1181;
                    border-radius: 25px;
                    border: 1px solid #fc1181;
                    transition:.5s ease;
                    &:hover{
                        background: transparent;
                        border: 1px solid #fc1181;
                    }
                    @media(max-width: 540px){
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
    .card-footer{
        border-top: 1px solid #ccc;
        padding: 10px 15px;
        margin-top: 20px;
        text-align: left;

        .item-header{
            margin: 20px 0;

            span{
                font-size: 1.2rem;
                color: #ddd;
                font-weight: 600;
            }
        }
        .item-content{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            
            .icon{
                font-size: .7rem;
                padding-right: 5px;
            }
            .text{
                font-size: .9rem;
                color: #ddd;
            }
            .quest-icon{
                font-size: .5rem;
                padding: 5px;
                border: 1px solid #eee;
                border-radius: 50%;
                background: var(--voskoolBagColor);
                color: #eee;
                opacity: .7;
                @media(max-width: 800px){
                    font-size: .4rem;
                }
                @media(max-width: 320px){
                    font-size: .35rem;
                    padding: 3px;
                }
            }
            
        }
    }
`;