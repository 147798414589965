import React, {useState, useEffect} from "react";
import { VoRoutes } from "appContainer/voRoutes";
import { GlobalStyle } from 'GlobalStyle';

const Layout = ({inner_state}) => {
    const [state, setState] = useState(inner_state);

    useEffect(() => {
        setState(inner_state);
    }, [inner_state])
    return(
        <>
         <VoRoutes state={state} />
         <GlobalStyle />
        </>
    )
}

export default Layout