import {normal_http} from "api/http_setup";

export const COUNTRIES = (p=1, size=20, q="") => {
    return normal_http.get(`/voskool/countries?p=${p}&size=${size}&q=${q}`);
}

export const COUNTRIES_VO = () => {
    return normal_http.get(`/voskool/countries_vo`);
}

export const STATES = (country_id) => {
    return normal_http.get(`/voskool/country/states/${country_id}`);
}