import React from "react";
import { Wrapper, Content } from "./style";
// import schools from "sort/voskool/schools";
// import homeImg from 'images/home_logo.png';
// import emifeLogo from 'images/emife.png';
import { NavHashLink } from "react-router-hash-link";

const PopulateSchools = ({title="", name="", icon_src="", icon_alt="", bg_img_src="", bg_img_alt="", link_title="", link="", children}) => {
    return (
        <>
        <Wrapper>
            <Content>
                <div className="img-cont">
                    <div className="link-wrapper">
                        <NavHashLink to={link}><span className="link-text disp-none text-capitalize">{link_title}</span> <i className="fas fa-arrow-right"></i></NavHashLink>
                    </div>
                    <div className="overlay">
                        <div className="logo-cont">
                         <img className="logo" src={icon_src} alt={icon_alt} />
                        </div>
                        <div className="text">
                            <h5> {title}</h5>
                            <span>{name.length > 35 ? `${name.slice(0, 35)}...` : name}</span>
                        </div>
                    </div>
                    <img className="img" src={bg_img_src} alt={bg_img_alt} />
                </div>   
                <div className="text-wrapper">
                    {children}
                    {/* <div className="text-cont">
                        <span className="heading">Class Section:</span><span className="text">Nursery, Primary, Junior Secondary, Senior Secondary</span>
                    </div>
                    <div className="text-cont">
                        <span className="heading">Fee:</span><span className="heading">N5,000 - N15,000</span>
                    </div>
                    <div className="text-cont">
                        <div className="pos abs-right abs-top">
                            <span className="pos-text">Rated By, 1400 People</span>
                        </div>
                        <span className="heading">Rating:</span><span className="text"><i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i> <i className="fas fa-star"></i></span>
                    </div>
                    <div className="text-cont">
                        <span className="heading">Address:</span><span className="text">Sarkin-pawa Minna, P.O Box 3695 Niger State</span>
                    </div> */}
                </div> 
                
            </Content>
        </Wrapper>
        
        </>
    );    
};

export const Div = ({title="", desc="", is_top=false, top_desc='', is_capitalize=false}) => {
    return(
        <div className="text-cont">
            {is_top && (
                <div className="pos abs-right abs-top">
                    <span className="pos-text">{top_desc}</span>
                </div>
            )}
            <span className="heading">{title}</span><span className="text">{!is_capitalize ? desc : <span className="text-capitalize">{desc}</span>}</span>
        </div>
    )
}

export default PopulateSchools;