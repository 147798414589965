import styled from 'styled-components';



export const CountryWrapper = styled.div`
    position: absolute;
    z-index: 5;

    .selected-country{
        padding: 3px;
        border-radius: 8px;
        border: 2px solid var(--unAssignedColor);
        transition: .3s ease;
        -webkit-transition: .3s ease;
        -moz-transition: .3s ease;
        -ms-transition: .3s ease;
        cursor: default;
        @media (max-width:280px){
            padding: 1px;
        }
        .country-cont{
        position: absolute;
        margin-top: 1px;
        z-index: 2;
        }
        img{
            width: 17px;
            height: 15px;
            @media (max-width:280px){
                width: 15px;
            height: 11px;
            }
        }
        i{
            font-size:.8rem;
        }
        
    }
    .selected-country:hover{
        background: #333;
        i{
            color: var(--assignedColor);
        }
    }
    
`;
export const CountryContent = styled.div`
    width: 200px;
    padding: 10px;
    min-height: 10vh;
    overflow-y: auto;
    border-radius: 20px;
    background-color: #eee;

    div{
        padding: 7px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom: 1px solid #ccc;

        button{
            font-size: .8rem;
            color: var(--unAssignedColor);
            margin-left: 15px;
            font-weight: 500;
            border: none;
            background: transparent;
        }
    }
    div:hover{
        border-bottom-color: var(--unAssignedColor);
        box-shadow: 0 0 8px 0px rgba(0,0,0,0.1);
        button{
            color: var(--unAssignedColor);
            
        }
    }
`;
export const CountryInp = styled.input`
    width: 100%;
    border-radius: 6px;
    padding: 5px;
    font-size: .8rem;
    margin-bottom: 10px;
    background: transparent;
    color: var(--unAssignedColor);
    border: 1px solid #333;
`;

export const CountryImage = styled.img`
    width: 17px;
    height: 15px;
`;