import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
`;
export const Content = styled.div`
    padding: 2px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 3px;
    grid-row-gap: 3px;
    border-bottom: 1px solid #ddd;
    border-radius: 10px;
    transition: .5s ease;
    box-sizing: border-box;
    -webkit-transition: .5s ease;
    -moz-transition: .5s ease;
    animation: animCont 1s ease;
    -webkit-animation: animCont  1s ease;
    -moz-animation: animCont 1s ease;

    @keyframes animCont{
        from{
            width: 85%;
        }
        to{
            width: 100%;
        }
    }

    &:hover{
        box-shadow: 5px 3px 3px rgba(0,0,0,0.1);
        .list-rate{
            .link-list{
                display: block;
            }
        }
    }

    .list-numbering{
        h5{
            color: var(--medGrey);
            font-size: 1rem;
            font-weight: 600;
            padding: 3px;

            @media (max-width: 1280px) {
                font-size: .7rem;
                font-weight: 700;
            }
        }
    }
    .list-img{
        position: relative;
        margin: 0 5px;
        img{
            position: absolute;
            top:50%;
            bottom: 50%;
            width: 55px;
            max-height: 50px;
            transform: translate(-50%, -50%);
            border-radius: 25px;

            @media (max-width: 1280px) {
                width: 40px;
                height: 34px;
            }
        }
    }
    .list-text{
        position: relative;
        width: 100%;
        h5{
            color: var(--medGrey);
            font-size: .7rem;
            font-weight: 800;
            text-transform: uppercase;
            @media (max-width: 1280px) {
                font-size: .6rem;
            }
        }
        p{
            transform: translateY(-12px);
            color: var(--medGrey);
            font-size: .6rem;
            font-weight: 600;
            text-transform: capitalize;

            @media (max-width: 1280px) {
                font-size: .54rem;
            }
        }
        
        .footer{
            position: absolute;
            bottom: 0;
            /* @media (max-width: 1000px) {
                display: none;
            } */
            span{
                
                font-size: .45rem;
                font-weight: 700;
                text-align: center;
                text-transform: capitalize;
                @media (max-width: 1280px) {
                font-size: .42rem;
                }

                
            }
        }
    }
    .list-rate{
        position: relative;
        .link-list{
            display: none;
            position: absolute;
            /* top: 50%; */
            bottom: 10%;
            transition: .5s ease;
            -webkit-transition: .5s ease;
            -moz-transition: .5s ease;
            a{
                background: var(--unAssignedColor);
                text-decoration: none;
                padding: 5px;
                border-radius: 8px;
                font-size: 0.5rem ;
                outline: none;
                color: var(--assignedColor);
                cursor: default;
                transition: .5s ease;
                -webkit-transition: .5s ease;
                -moz-transition: .5s ease;
                &:hover{
                    border: 1px solid var(--unAssignedColor); 
                    background: transparent;
                    color: var(--unAssignedColor);
                }
                @media (max-width: 1280px) {
                font-size: .45rem;
                }
                
            }
        }
        h6{
            color: var(--medGrey);
            font-size: .5rem;
            font-weight: 800;
            @media (max-width: 1280px) {
                font-size: .4rem;
            }
            i{
                color: #eda800;
                font-size: .55rem;
                @media (max-width: 1280px) {
                font-size: .45rem;
                }
            }
        }
        
        .footer{
            position: absolute;
            bottom: 0;
            right: 0;

            span{
                font-size: .5rem;
                font-weight: 600;
                font-style: italic;
                text-transform: uppercase;
            }
        }
    }
`;