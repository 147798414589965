import styled from "styled-components";

export const Wrapper = styled.div`
    position: absolute;
    right: 50px;
    bottom: 100px;
    z-index: 1000000000000;
    border-radius: 0px;
    .animate{
        animation: animateChatIcon 3s linear infinite;
        -webkit-animation: animateChatIcon 3s linear infinite;

        @keyframes animateChatIcon{
            from{
                transform: scale(1);
                border-radius: 50px;
                background: rgba(65, 45, 230, 0.7);
                padding: 3px;
            }
            to{
                background: rgba(65, 45, 230, 0.7);
                border-radius: 50px;
                padding: 10px;
            }
        }
    }
`;

export const Content = styled.div`
    position: relative;
    
    .num-cont{
        position: absolute;
        bottom: -10px;
        .num{
            padding: 2px 6px;
            background: rgb(245, 23, 43);
            color: #eee;
            border-radius: 50%;
        }
    }
    i{
        padding: 3px 7px;
        cursor: pointer;
        border-radius: 50px;
    }
`;