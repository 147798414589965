import React from "react";
import { Wrapper, Button } from "./style";

const LoadMoreSchoolsBtn = ({text, result, onClick}) => {
    return(
        <Wrapper>
            {result && (<h5>{result} Result(s) Found</h5>)}
            <Button onClick={onClick}>
                {text} <i className="fas fa-arrow-down"></i>
            </Button>
        </Wrapper>
    );
}

export default LoadMoreSchoolsBtn;