import styled from "styled-components";

export const Wrapper = styled.div`
    background: #fff;
    border-radius: 10px;
    transition: .5s ease;

    &:hover{
        box-shadow: 0 0 3px 5px rgba(0,0,0,0.04);
    }
`;
export const Content = styled.div`
    position: relative;
    .img-cont{
        position: relative;
        .link-wrapper{
        position: absolute;
        bottom: 0;
        right: 45%;
        z-index: 2;
        transform: translateY(20px);
        @media (max-width: 320px){
            transform: translateY(17px);
        }

        a{
            text-decoration: none;
            padding: 7px;
            color: #eee;
            background: var(--voskoolBagColor);
            border-radius: 50%;
            font-size: .6rem;
            transition: .5s ease;

            @media (max-width: 320px){
                font-size: .4rem;
            }
            span{
                transition: .5s linear;
            }
        }
        a:hover{
            border-radius: 15px;
            opacity: .8;
            .disp-none{
                display: inline-block;
            }
        }
    }
        .overlay{
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 180px;
            border-radius: 10px;
            background: rgba(0,0,0,0.3);
            transition: .3s ease;
            -webkit-transition: .3s ease;
            -moz-transition: .3s ease;
            padding: 20px;

            &:hover{
                background: rgba(0,0,0,0.45);
            }
            @media (max-width: 320px){
                height: 150px;
            }
            
            .logo-cont{
                position: absolute;
                bottom: 4px;
                right: 5px;
                .logo{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    opacity: .8;

                    @media(max-width: 320px){
                        width: 25px;
                         height: 25px;
                    }
                }
            }

            .text{
                margin: 0;
                padding: 0;
                margin-top: 25%;
                h5{   
                    color: #ccc;
                    font-weight: 800;
                    letter-spacing: 1px;
                    font-size: .9rem;
                    text-transform: uppercase;
                    @media (max-width: 320px){
                        font-size: .7rem;
                        font-weight: bolder;
                    }
                }
                span{
                    color: #ccc;
                    font-weight: 600;
                    font-size: .73rem;
                    letter-spacing: .6px;
                    text-transform: capitalize;
                    @media (max-width: 320px){
                        font-size: .5rem;
                    }
                }
            }
        }
        .img{
            width: 100%;
            height: 180px;
            object-fit: cover;
            background: #555;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            @media (max-width: 320px){
                height: 150px;
            }
        }
    }
    .text-wrapper{
        padding: 17px 5px;
        margin-bottom: 7px;
        .text-cont{
            display: flex;
            flex-wrap: wrap;
            position: relative;
            padding-bottom: 10px;
            padding-top: 10px;
            border-radius: 5px;
            border-bottom: 1px solid #ccc;
            transition: .2s ease;

            @media(max-width: 540px){
                padding-bottom: 5px;
                 padding-top: 5px;
            }
            .pos{
                position: absolute;

                span{
                    color: #999;
                    font-size: .5rem;
                    font-weight: 600;

                    @media (max-width: 320px){
                        font-size: .3rem;
                    }
                }
            }
            .heading{
                
                font-weight: 700;
                font-size: .8rem;
                padding-left: 7px;
                padding-bottom: 5px;
                color: #222;
                @media(max-width: 540px){
                    padding-left: 5px;
                    font-size: .65rem;
                }
                @media(max-width: 320px){
                    font-size: .5rem;
                }
            }
            .text{
                padding-left: 15px;
                padding-top: 1px;
                font-size: .7rem;
                color: #555;
                font-weight: 600;
                @media(max-width: 540px){
                    padding-left: 10px;
                    font-size: .6rem;
                }
                @media(max-width: 320px){
                    font-size: .4rem;
                }
            }
        }
    }
    
`;