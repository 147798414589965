import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";

import VoWrapper from "components/app/vo/SmallerComponents/wrapper";
import { Wrapper, Content } from "components/app/vo/signUpPage/style.js";
import { NotificationWrapper } from "components/app/notification/style";

import TABLE from "components/app/table_html/table";
import TR from "components/app/table_html/tr";
import TH from "components/app/table_html/th";
import TD from "components/app/table_html/td";
import Note from "components/app/NOTE";

import { BACKEND_URL } from "actions/url";

import { appServices } from "api/schoolApp/services";
import { COUNTRIES, STATES } from "api/voskool/services/countries";
// import { load_user } from "actions/auth";
// import authHandler from "actions/auth_handler";
import { validateParentChildDataForm, validateParentDataForm, validateStudentDataForm } from "components/app/actions/validation";
import blobToFile, {getBlob} from 'components/app/actions/blobToFile';

import Loader from "components/app/loader";
import Notification from "components/app/notification";
import Redirect from 'components/redirectPage';


const initialUserState = {
    email: "",
    first_name: "",
    id: 0,
    uid: '',
    last_name: "",
    phone_number: "",
    user_name: "",
    user_type: null,
}
const EnrollmentPage = ({inner_state={}}) => {
    const initialFormUserState = useMemo(() => ({is_moth_dead:false, is_fath_dead:false, terms:false, sign_check:false, profile_pic_value:'', profile_pic:File,
        is_parent:'', is_spouse_parent:'', sur_name:'', other_name:'', gender:'', dob:'', rel:'', lang_spoken:'', addr:'', disability:'', loc_heard:'', 
        nationality:'', state_origin:'', prev_class:'', prev_school:'', last_rep_card:File, birth_cert:File,  sign:File, second_sign:File, birth_cert_value:'', last_rep_card_value:'', sign_value:'', second_sign_value:'', school_branch:'', moth_name:'', moth_phone:'', moth_work_phone:'',
        moth_addr:'', moth_occupation:'', moth_nationality:'', moth_email:'', fath_name:'', fath_phone:'', fath_work_phone:'', fath_addr:'', fath_nationality:'', fath_email:'', fath_occupation:'', emergency_name:'', emergency_email:'', emergency_phone:'',
        emergency_work_phone:'', emergency_relate:'', emergency_occupation:'', emergency_addr:'', emergency_country_residence:'',  custody:'', curr_class:'', enroll_type:'' }), []);
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const [formUser, setFormUser] = useState(initialFormUserState)
    const [formUserError, setFormUserError] = useState({});
    const [school, setSchool] = useState({id:0, uid:'', title:'', icon:'',name:''});
    const [user, setUser] = useState(initialUserState);
    const [parent, setParent] = useState({id:0})
    const [enroll, setEnroll] = useState({id:0, is_enrolled:false, is_accepted:false})

    const [allGuardians, setAllGuardians] = useState([]);
    const [allOccupations, setAllOccupations] = useState([]);
    const [allLocations, setAllLocations] = useState([]);
    const [allCountries, setAllCountries] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [allDisabilities, setAllDisabilities] = useState([]);
    const [allSchoolBranch, setAllSchoolBranch] = useState([]);
    const [allClassItem, setAllClassItem] = useState([]);
    const [allEnrolledStudentByParent, setAllEnrolledStudentByParent] = useState([]);

    const {sn} = useParams();
    const nav= useNavigate();

    const [navigate, setNavigate] = useState('');

    const [studentNo, setStudentNo] = useState(0);
    const [currStudentNo, setCurrStudentNo] = useState(0);
    const [formName, setFormName] = useState('');
    const [submit, setSubmit] = useState('');
    const [formComplete, setFormComplete] = useState('');
    const [formCompleteStudent, setFormCompleteStudent] = useState(false);
    const [exist, setExist] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const [preloadingError, setPreloadingError] = useState(false);
    const [reload, setReload] = useState(true);
    const [loading, setLoading] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [warning, setWarning] = useState(initialSuccessState);

    // const fetchUser =  async () => {
    //     setIsAuthenticated(true);
    //     setLoading(true);
    //     await authHandler.load_user(load_user()).then(result => {
    //         setIsAuthenticated(result.isAuthenticated); 
    //         setUser({...result.data})
    //         setFormName(result.data.user_type);
    //         setLoading(false)
    //         {result.data.user_type === "parent" && 
    //             appServices.getParentData(result.data.id).then(res => {
    //                 setParent({...res.data})
    //                 setFormName('student_no');
                    // setFormUser({is_moth_dead:false, is_fath_dead:false, terms:false, sign_check:false, profile_pic_value:''});
    //                 console.log(res);
    //             }).catch(e => {
    //                 setParent({id:0});
    //             })
    //         }
    //         {result.data.user_type === "student" && 
    //             appServices.getStudentEnrolledData(result.data.id).then(res => {
    //                 setEnroll({...res.data});
    //                 setFormComplete('student') 
    //             }).catch(e => {
    //                 setEnroll({id:0});
    //             })
    //         }
            
    //         console.log(result)
    //     }).catch(error => {
    //         setIsAuthenticated(false);
    //         console.log(error)
    //         setLoading(false)
    //     })
    // }
    const fetchSchool = (title) => {
        if(title){
            setPreloading(true);
            setPreloadingError(false);
            appServices.getSchool(title).then(res => {
                setSchool({id:res.data.id, title:res.data.title, icon:res.data.icon, name:res.data.name, is_start_student_payment:res.data.is_start_student_payment, is_vos:res.data.is_vos});
                appServices.getAllSchoolBranch(res.data.id).then(school_branch_res => {
                    setAllSchoolBranch(school_branch_res.data);
                    appServices.getAllClassSchoolItem(res.data.id).then(result => {
                        setAllClassItem(result.data);
                        setPreloading(false);
                    }).catch(e => {
                        setAllClassItem([]);
                        setPreloading(false);
                        setPreloadingError(true);
                    })
                }).catch(e => {
                    setAllSchoolBranch([]);
                    setPreloadingError(true);
                    setPreloading(false);
                })
                

            }).catch(e => {
                setSchool({id:0, title:'', icon:'', name:''});
                setPreloadingError(true);
                setPreloading(false);
            })
        }
    }
    const fetchAllEnrolledStudentsByParent = (uid="") => {
        appServices.getAllStudentEnrolledByParentData(uid).then(res => {
            setAllEnrolledStudentByParent(res.data);
        }).catch(e => {
            setAllEnrolledStudentByParent([]);
        })
    }
    const fetchAllOccupations = () => {
        appServices.getAllOccupations().then(res => {
            setAllOccupations([...res.data])
        }).catch(e => {
            setAllOccupations([]);
        })
    }
    const fetchAllLocations = () => {
        appServices.getAllLocations().then(res => {
            setAllLocations([...res.data])
        }).catch(e => {
            setAllLocations([]);
        })
    }
    const fetchAllGuardians = () => {
        appServices.getAllGuardians().then(res => {
            setAllGuardians([...res.data])
        }).catch(e => {
            setAllGuardians([]);
        })
    }
    const fetchAllDisabilities = () => {
        appServices.getAllDisabilities().then(res => {
            setAllDisabilities([...res.data])
        }).catch(e => {
            setAllDisabilities([]);
        })
    }
    const fetchAllCountries = () => {
        COUNTRIES(1, 300).then(res => {
            setAllCountries([...res.data.results])
        }).catch(e => {
            setAllCountries([]);
        })
    }
    const fetchAllStates = (cid) => {
        STATES(cid).then(res => {
            setAllStates([...res.data]);
        }).catch(e => {
            setAllStates([]);
        })
    }

    const handleUserChange = (e) => {
        if(e.target.name === "prev_class" && e.target.value === "none"){
            setFormUser({...formUser,  prev_school:'', last_rep_card:File, last_rep_card_value:'', [e.target.name]:e.target.value});
            return
        }
        setFormUser({...formUser, [e.target.name]:e.target.value});
    }
    const handleUserSelect = (e, val) => {
        if(e.target.name === "sign_check" && val){
            getBlob(`${BACKEND_URL}${parent.sign}`).then(res => {
                setFormUser({...formUser, sign:blobToFile(res, `${BACKEND_URL}${parent.sign}`), [e.target.name]:val})
            }).catch(() => {
                setError({data:["File Error"], title:'Error'});
            })       
        }
        else if(e.target.name === "sign_check" && !val){
            getBlob(`${BACKEND_URL}${parent.sign}`).then(res => {
                setFormUser({...formUser, sign:File, [e.target.name]:val})
            }).catch(() => {
                setError({data:["File Error"], title:'Error'});
            })        
        }
        setFormUser({...formUser, [e.target.name]:val});
    }
    const handleUserChangeFile = (e) => {
        setFormUser({...formUser, [e.target.name]:e.target.files[0], [e.target.name + '_value']:e.target.value});
    }
    const handleUserCountryChange = (e) => {
        setFormUser({...formUser, [e.target.name]:e.target.value});
        fetchAllStates(e.target.value);
    }
    const handleSubmitParent = (e) => {
        e.preventDefault();
        setSubmit('insert_parent');
        setFormUserError(validateParentDataForm(formUser));
        if(submit === "inserted_parent"){
            setLoading(true);
            const data = {
                title:formUser.title,
                gender:formUser.gender,
                dob:formUser.dob,
                marital_status:formUser.marital_status,
                address:formUser.addr,
                rel:formUser.rel,
                country_origin: formUser.nationality,
                state_res: formUser.state_origin,
                occupation: formUser.occupation,
                spouse_type: formUser.marital_status === "married" ? formUser.spouse_type : '',
                spouse_fullname: formUser.spouse_type ? formUser.spouse_name : '',
                spouse_phone: formUser.spouse_type ? formUser.spouse_phone : '',
                spouse_work_phone: formUser.spouse_type ? formUser.spouse_work_phone : '',
                spouse_title:formUser.spouse_type ? formUser.spouse_title : '',
                spouse_email:formUser.spouse_type ? formUser.spouse_email : '',
                spouse_occupation:formUser.spouse_type ? formUser.spouse_occupation : '',
                has_spouse: formUser.marital_status === "married" ? true : false,
                emergency_fullname:formUser.emergency_name,
                emergency_phone:formUser.emergency_phone,
                emergency_work_phone:formUser.emergency_work_phone,
                emergency_addr:formUser.emergency_addr,
                emergency_email: formUser.emergency_email,
                emergency_occupation: formUser.emergency_occupation,
                emergency_country_origin: formUser.emergency_country_residence,
                emergency_relate: formUser.emergency_relate,
                loc_heard: formUser.loc_heard,
                user:user.id,
            }
            appServices.insertParentData(data).then(res => {
                setLoading(false);
                setFormUser({is_moth_dead:false, is_fath_dead:false, sign_check:false})
                setParent({...res.data});
                setFormName('student_no');
            }).catch(e => {
                setLoading(false);
                setFormName('parent');
                setError({data:[e.response.data.detail ? e.response.data.detail : "Something went wrong, try again"], title:'Error 400'})
            });
        }
    }
    const handleNoStudentSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if(studentNo){
            setFormName('parent_student');
            setLoading(false);
        }
        setLoading(false);

    }
    const fatherHandler = (data) => {
        if((formUser.is_parent === "no" && parent.gender === "M") || (!formUser.is_spouse_parent && parent.spouse_type === "husband") || (formUser.is_spouse_parent === "no" && parent.spouse_type === "husband") ){
            return data;
        }
        else{
            return '';
        }
    }
    const motherHandler = (data) => {
        if((formUser.is_parent === "no" && parent.gender === "F") || (!formUser.is_spouse_parent && parent.spouse_type === "wife") || (formUser.is_spouse_parent === "no" && parent.spouse_type === "wife") ){
            return data;
        }
        else{
            return '';
        }
    }
    const onClickYesBtn = (name) => {
        setWarning(initialSuccessState);
        setFormComplete(name);
    }
    const onClickParentFinishBtn = (e) => {
        setSubmit("insert_parent_student");
        setWarning({initialSuccessState});
        setFormUserError(validateParentChildDataForm(formUser, parent));
        if(submit === "inserted_parent_student"){
            setWarning({...initialSuccessState});
            handleParentChildSubmit(e, "finish");
        }
        else{
            setSubmit("")
            setTimeout(() => {
                setWarning({title:'Invalid Application Form', text:'Previous Forms Will Be Submitted Excluding This One, Do You Want To Continue?', click_text:'YES', handleClick: () => onClickYesBtn("parent_student")});
            }, 100);
        }
    }
    const handleParentChildSubmit = (e, type) => {
        e.preventDefault();
        setSubmit("insert_parent_student");
        setFormUserError(validateParentChildDataForm(formUser, parent));
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(submit === "inserted_parent_student"){
            setLoading(true);
            const formData = new FormData()
            formData.append('is_guardian_parent', formUser.is_parent === "no" ? false : true)
            formData.append('is_guardian_parent_spouse', formUser.is_spouse_parent === "no" ? false : true)
            formData.append('first_relationship', formUser.is_parent === "no" ? '' : formUser.is_parent)
            formData.append('second_relationship', formUser.is_spouse_parent === "no" ? '' : formUser.is_spouse_parent)
            formData.append('is_guardian_parent_spouse', formUser.is_spouse_parent === "no" ? false : true)
            formData.append('profile_pic', formUser.profile_pic, formUser.profile_pic.name)
            formData.append('sur_name', formUser.sur_name)
            formData.append('other_names', formUser.other_name)
            formData.append('rel', formUser.rel)
            formData.append('lang_spoken', formUser.lang_spoken)
            formData.append('address', formUser.addr)
            formData.append('med_challenge', formUser.disability)
            formData.append('is_med_challenged', formUser.disability ? true : false)
            formData.append('country_origin', formUser.nationality)
            formData.append('state_res', formUser.state_origin)
            formUser.relate_child && formData.append('guardian_type', formUser.relate_child)
            parent.loc_heard && formData.append('loc_heard', parent.loc_heard)
            formData.append('first_sign', formUser.sign)
            formUser.prev_class !== "none" && formData.append('current_class', formUser.prev_class)
            if(formUser.last_rep_card){
                formUser.prev_class !== "none" && formData.append('report_card', formUser.last_rep_card, formUser.last_rep_card.name);
            }
            formData.append('details', formUser.prev_class === "none" ? '' : formUser.prev_school)
            formData.append('gender', formUser.gender)
            formData.append('dob', formUser.dob)
            formData.append('guardian', parseInt(parent.id))
            formUser.birth_cert && formData.append('birth_cert', formUser.birth_cert, formUser.birth_cert.name)
            formData.append('is_mother_deceased', formUser.is_moth_dead)
            formData.append('is_father_deceased', formUser.is_fath_dead)
            formData.append('is_mother_custody', formUser.custody === "mother" ? true : false)
            formData.append('is_father_custody', formUser.custody === "father" ? true : false)
            formData.append('is_both_custody', formUser.custody === "both" ? true : false)
            formData.append('is_guardian_custody', formUser.custody === "guardian" ? true : false)
            formData.append('fath_fullname', fatherHandler(formUser.fath_name))
            formData.append('fath_email', !formUser.is_fath_dead ? fatherHandler(formUser.fath_email) : '')
            formData.append('fath_phone', !formUser.is_fath_dead ? fatherHandler(formUser.fath_phone) : '')
            formData.append('fath_work_phone', !formUser.is_fath_dead ? fatherHandler(formUser.fath_work_phone) : '')
            formData.append('fath_addr', !formUser.is_fath_dead ?  fatherHandler(formUser.fath_addr) : '')
            formData.append('fath_country_origin', fatherHandler(formUser.fath_nationality))
            formData.append('fath_occupation', !formUser.is_fath_dead ? fatherHandler(formUser.fath_occupation) : '')
            formData.append('moth_fullname', motherHandler(formUser.moth_name))
            formData.append('moth_email', !formUser.is_moth_dead ? motherHandler(formUser.moth_email) : '')
            formData.append('moth_phone', !formUser.is_moth_dead ? motherHandler(formUser.moth_phone) : '')
            formData.append('moth_work_phone', !formUser.is_moth_dead ? motherHandler(formUser.moth_work_phone) : '')
            formData.append('moth_addr', !formUser.is_moth_dead ? motherHandler(formUser.moth_addr) : '')
            formData.append('moth_country_origin', motherHandler(formUser.moth_nationality))
            formData.append('moth_occupation', !formUser.is_moth_dead ? motherHandler(formUser.moth_occupation) : '')
            
            
            appServices.insertStudentData(formData).then(res => {
                const enrolled_data = {
                    student:res.data.id,
                    enroll_type: formUser.enroll_type,
                    class_enrolled: formUser.curr_class,
                    enrolled_by: user.id,
                    school: school.id,
                    school_branch: formUser.school_branch,
                    is_accept_terms: formUser.terms
                }
                appServices.insertStudentEnrolledData(enrolled_data).then(res => {
                    if(type === "finish"){
                        setLoading(false);
                        setSubmit('');
                        setFormComplete('parent_student');
                        setFormUser(initialFormUserState);
                        setSuccess({title:'Success', text:`All Application Form Submitted Successfully`});
                        setCurrStudentNo((prev) => {
                            if(prev === parseInt(studentNo)){
                                return prev;
                            }
                            return prev+1;
                        });
                    }
                    setLoading(false);
                    setSubmit('');
                    setFormUser(initialFormUserState);
                    setSuccess({title:'Success', text:`Child ${currStudentNo+1} Application Form Added Successfully`});
                    setCurrStudentNo((prev) => {
                        if(prev === parseInt(studentNo)){
                            return prev;
                        }
                        return prev+1;
                    });
                    
                }).catch(e => {
                    setLoading(false);
                    setError({data:[e.response.data.detail ? e.response.data.detail : "Something went wrong, try again"], title:'Error 400'})
                    setSubmit('');
                })
            }).catch(e => {
                setLoading(false);
                setError({data:[e.response.data.detail ? e.response.data.detail : "Something went wrong, try again"], title:'Error 400'})
                setSubmit('');
            })
        }
    }
    const handleStudentSubmit = (e) => {
        e.preventDefault();
        setSubmit("insert_student");
        setFormUserError(validateStudentDataForm(formUser));
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(submit === "inserted_student"){
            setLoading(true);
            const formData = new FormData()
            formData.append('rel', formUser.rel)
            formData.append('lang_spoken', formUser.lang_spoken)
            formData.append('address', formUser.addr)
            formData.append('med_challenge', formUser.disability)
            formData.append('is_med_challenged', formUser.disability ? true : false)
            formData.append('country_origin', formUser.nationality)
            formData.append('state_res', formUser.state_origin)
            formData.append('details', formUser.prev_class === "none" ? '' : formUser.prev_school)
            formData.append('gender', formUser.gender)
            formData.append('dob', formUser.dob)
            formUser.birth_cert && formData.append('birth_cert', formUser.birth_cert, formUser.birth_cert.name)
            formUser.prev_class !== "none" && formData.append('current_class', formUser.prev_class)
            if(formUser.last_rep_card){
                formUser.prev_class !== "none" && formData.append('report_card', formUser.last_rep_card, formUser.last_rep_card.name);
            }
            formData.append('is_mother_deceased', formUser.is_moth_dead)
            formData.append('is_father_deceased', formUser.is_fath_dead)
            formData.append('is_mother_custody', formUser.custody === "mother" ? true : false)
            formData.append('is_father_custody', formUser.custody === "father" ? true : false)
            formData.append('is_both_custody', formUser.custody === "both" ? true : false)
            formData.append('is_guardian_custody', formUser.custody === "guardian" ? true : false)
            formData.append('fath_fullname', formUser.fath_name)
            formData.append('fath_email', !formUser.is_fath_dead ? formUser.fath_email : '')
            formData.append('fath_phone', !formUser.is_fath_dead ? formUser.fath_phone : '')
            formData.append('fath_work_phone', !formUser.is_fath_dead ? formUser.fath_work_phone : '')
            formData.append('fath_addr', !formUser.is_fath_dead ?  formUser.fath_addr : '')
            formData.append('fath_country_origin', formUser.fath_nationality)
            formData.append('fath_occupation', !formUser.is_fath_dead ? formUser.fath_occupation : '')
            !formUser.is_fath_dead && formData.append('first_sign', formUser.sign, formUser.sign.name)
            formData.append('moth_fullname', formUser.moth_name)
            formData.append('moth_email', !formUser.is_moth_dead ? formUser.moth_email : '')
            formData.append('moth_phone', !formUser.is_moth_dead ? formUser.moth_phone : '')
            formData.append('moth_work_phone', !formUser.is_moth_dead ? formUser.moth_work_phone : '')
            formData.append('moth_addr', !formUser.is_moth_dead ? formUser.moth_addr : '')
            formData.append('moth_country_origin', formUser.moth_nationality)
            formData.append('moth_occupation', !formUser.is_moth_dead ? formUser.moth_occupation : '')
            !formUser.is_moth_dead && formData.append('second_sign', formUser.second_sign, formUser.second_sign.name)
            formData.append('emergency_fullname', formUser.emergency_name)
            formData.append('emergency_email',  formUser.emergency_email)
            formData.append('emergency_phone', formUser.emergency_phone)
            formData.append('emergency_work_phone', formUser.emergency_work_phone)
            formData.append('emergency_addr', formUser.emergency_addr)
            formData.append('emergency_country_res', formUser.emergency_country_residence)
            formData.append('emergency_occupation', formUser.emergency_occupation )
            formData.append('emergency_type', formUser.emergency_relate )
            formData.append('loc_heard', formUser.loc_heard)
            formData.append('user', user.id)
            formData.append('is_self_sponsored', true);
            formData.append('is_done', true);
            formData.append('is_orphaned', formUser.is_fath_dead ? true : false);


            const profileForm = new FormData()
            profileForm.append("profile_pic", formUser.profile_pic, formUser.profile_pic.name)

            appServices.updateUserProfile(user.uid, profileForm).then(() => {
                appServices.insertStudentData(formData).then(res => {
                    const enrolled_data = {
                        student:res.data.id,
                        enroll_type: formUser.enroll_type,
                        enrolled_by: user.id,
                        class_enrolled: formUser.curr_class,
                        school: school.id,
                        school_branch: formUser.school_branch,
                        is_accept_terms: formUser.terms
                    }
                    appServices.insertStudentEnrolledData(enrolled_data).then(res => {
                        setLoading(false);
                        setSubmit('');
                        setFormUser(initialFormUserState);
                        setFormComplete('student');
                        setSuccess({title:'Success', text:`Application Form Submitted Successfully`});
                    }).catch(e => {
                        setLoading(false);
                        setSubmit('');
                        setError({data:[e.response.data.detail ? e.response.data.detail : "Something went wrong, try again"], title:'Error 400'})
                    })

                }).catch(e => {
                    setLoading(false);
                    setSubmit('');
                    setError({data:[e.response.data.detail ? e.response.data.detail : "Something went wrong, try again"], title:'Error 400'})
                })

            }).catch(e => {
                setLoading(false);
                setSubmit('');
                setError({data:[e.response.data.detail ? e.response.data.detail : "Something went wrong, try again"], title:'Error 400'})
            })
        }
    }
    console.log(formUser)
    useEffect(() => {
        if(reload && inner_state.type){
            setPreloading(true);
            setReload(false);
            setPreloadingError(false);
            if(inner_state.type === "success"){
                setIsAuthenticated(inner_state.isAuthenticated);
                setUser({...inner_state.data})
                setFormName(inner_state.data.user_type);
                setExist(true);
                if(inner_state.data.user_type === "parent"){
                    appServices.getParentData(inner_state.data.id).then(res => {
                        setParent({...res.data})
                        setFormName('student_no');
                        setFormUser({is_moth_dead:false, is_fath_dead:false, terms:false, sign_check:false, profile_pic_value:''});
                        fetchSchool(sn);
                        fetchAllEnrolledStudentsByParent(inner_state.data.uid);
                    }).catch(e => {
                        setParent({id:0});
                        fetchSchool(sn);
                        setFormName('parent');
                    })
                    return;
                }
                if(inner_state.data.user_type === "student"){
                    appServices.getStudentEnrolledData(inner_state.data.id).then(res => {
                        setEnroll({...res.data});
                        setFormComplete('student');
                        setFormCompleteStudent(true);
                        fetchSchool(sn);
                    }).catch(e => {
                        setEnroll({id:0});
                        fetchSchool(sn);
                        setFormName('student');
                    })
                    return;
                }
                return;
            }
            setTimeout(() => {
                setPreloading(false);
                setExist(false);
            }, 100);
        }
    }, [inner_state, sn, reload])
    useEffect(() => {
        if(isAuthenticated === false){
            return setNavigate('/vo/signup');
        }
        fetchAllOccupations();
        fetchAllGuardians();
        fetchAllCountries();
        fetchAllDisabilities();
        fetchAllLocations();
        // fetchParent(user.id & user.id);
    }, [isAuthenticated]);

    useEffect(() => {
        if(user.user_type === "staff" && user.id) {
            setNavigate(`/vo/preroll/school`);
        }
    }, [user]);
    useEffect(() => {
        if(Object.keys(formUserError).length === 0 && submit === "insert_parent"){
            setSubmit('inserted_parent');
        }
        if(Object.keys(formUserError).length === 0 && submit === "insert_parent_student"){
            setSubmit('inserted_parent_student');
        }
        if(Object.keys(formUserError).length === 0 && submit === "insert_student"){
            setSubmit('inserted_student');
        }
    }, [formUserError, submit]);

    useEffect(() => {
        if(navigate){
            nav(navigate);
        }
    }, [navigate, nav])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })

    const occupationOptions = allOccupations.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const locationOptions = allLocations.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const guardianOptions = allGuardians.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const countryOptions = allCountries.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const stateOptions = allStates.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const schoolBranchOptions = allSchoolBranch.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    const classItemOptions = allClassItem.map((item) => {
        return(
            <option key={item.id} value={item.o_class_id}>{item.name}</option>
        )
    })
    const disabilitiesOptions = allDisabilities.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })

    // const getClassName = (o_class_id=0) => {
    //     const filterItem = allClassItem.filter((item) => item.o_class_id===o_class_id);
    //     if(filterItem.length === 1){
    //         return filterItem[0].name;
    //     }
    //     return '';
    // }

    const processTDStat = (stat={is_accepted:false, is_enrolled:false, is_done:false}, id="") => {
        if(stat.is_enrolled && stat.is_accepted && stat.is_done){
            return(
                <TD classname={"green fw-700"} align="center">APPROVED</TD>
            )
        }
        if(stat.is_enrolled && stat.is_accepted){
            return(
                <TD align="center"><b className="green fw-700">ACCEPTED</b>,<br/> <Link to={`/vo/payment/enroll/${school.title}?k=${id}`} className="btn-bg font-very-small text-decoration-none">Start Payment <i className="fas fa-coins"></i></Link></TD>
            )
        }
        if(!stat.is_enrolled){
            return(
                <TD classname={"red fw-700"} align="center">DECLINED</TD>
            )
        }
        if(stat.is_enrolled && stat.is_start_interview && stat.is_finish_interview){
            return(
                <TD  align="center"><b className={"fw-700"} style={{color:'yellow'}}>PENDING</b>, <span className="font-very-small fw-600" style={{color:'#888'}}>Interview done</span></TD>
            )
        }
        if(stat.is_enrolled && stat.is_start_interview){
            return(
                <TD align="center"><b className={"fw-700"} style={{color:'yellow'}}>PENDING</b>, <span className="font-very-small fw-600" style={{color:'#888'}}>Interview ongoing</span></TD>
            )
        }
        if(stat.is_enrolled){
            return(
                <TD align="center"><b className={"fw-700"} style={{color:'yellow'}}>PENDING</b></TD>
            )
        }
        
    }
    const insertChildNextBtn = () => {
        const inc = currStudentNo + 2;
        const num = studentNo - inc;
        if(inc <= studentNo){return(
            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className={num  === 0 ? `form-group align-center f-50-alt` : `form-group align-center f-50-alt`} style={{marginTop:'15px'}}>
                <button type="submit" className="btn hover-a br-5 shadow rem1_ bg-black">Next Child <i className="fas fa-arrow-right"></i></button>
            </motion.div>
        )}
    }
    const insertChildFinishBtn = () => {
        const inc = currStudentNo + 1;
        if(inc === 1 && studentNo > 1){
            return (
                <></>
            )
        }
        else if(inc >= studentNo){
            return (
                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                    <button type="submit" className="btn hover-a br-5 shadow rem1_ bg-black">Finish <i className="fas fa-paper-plane"></i></button>
                </motion.div>
            )
        }
        else{
            return (
                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className="form-group align-center f-50-alt" style={{marginTop:'15px'}}>
                    <button type="button" onClick={e => onClickParentFinishBtn(e)} className="btn hover-a br-5 shadow rem1_ bg-black">Finish <i className="fas fa-paper-plane"></i></button>
                </motion.div>
            )
        }
            
        
    }
    const insertChildGuardianSign = () => {
        if(!parent.sign){
            return(
                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                    <label>A picture of your signature as validation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                    <input type="file" className={formUserError.sign ? `f-c b-red`: `f-c`} name="sign" value={formUser.sign_value} onChange={e => handleUserChangeFile(e)} />
                    {formUserError.sign && (<span className="error">{formUserError.sign}</span>)}
                </motion.div>
            )
        }
        return(
            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                <label>Use your existing signature as validation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                <input type="checkbox" className={formUserError.sign_check ? ` b-red`: ``} name="sign_check" checked={formUser.sign_check} value={formUser.sign_check} onChange={e => handleUserSelect(e, !formUser.sign_check)} />
                {formUserError.sign_check && (<span className="error">{formUserError.sign_check}</span>)}
            </motion.div>
        )

    }
    const insertChildGuardianRelate = () => {
        if(formUser.is_parent === "no"){
            return(           
                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                    <label>Select Relationship To Child<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                    <select className={formUserError.relate_child ? `f-c b-red`: `f-c`} name="relate_child" value={formUser.relate_child} onChange={e => handleUserChange(e)}>
                        <option value="">Select...</option>
                        {guardianOptions}
                    </select>
                    {formUserError.relate_child && (<span className="error">{formUserError.relate_child}</span>)}
                </motion.div>       
            )
        }
    }
    const insertChildFatherForm = () => {
        if((formUser.is_parent === "no" && parent.gender === "M") || (!formUser.is_spouse_parent && parent.spouse_type === "husband") || (formUser.is_spouse_parent === "no" && parent.spouse_type === "husband") ){
            return(
                <>

                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100 align-center">
                        <h4>Father's Details</h4>
                    </motion.div>
                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                        <label>Is Father Deceased?</label>
                        <input type="checkbox" name="is_fath_dead"  value={formUser.is_fath_dead} onChange={e => handleUserSelect(e, !formUser.is_fath_dead)} />
                    </motion.div> 
                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                        <label>Father's FullName<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                        <input type="text" className={formUserError.fath_name ? `f-c b-red`: `f-c`} name="fath_name" placeholder="Fullname" value={formUser.fath_name} onChange={e => handleUserChange(e)} />
                        {formUserError.fath_name && (<span className="error">{formUserError.fath_name}</span>)}
                    </motion.div> 
                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                        <label>Select Father's Nationality<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                        <select className={formUserError.fath_nationality ? `f-c b-red`: `f-c`} name="fath_nationality" value={formUser.fath_nationality} onChange={e => handleUserChange(e)}>
                            <option value="">Select...</option>
                            {countryOptions}
                        </select>
                        {formUserError.fath_nationality && (<span className="error">{formUserError.fath_nationality}</span>)}
                    </motion.div> 
                    {!formUser.is_fath_dead && (
                        <>
                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                            <label>Father's Email<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                            <input type="email" className={formUserError.fath_email ? `f-c b-red`: `f-c`} name="fath_email" placeholder="Email" value={formUser.fath_email} onChange={e => handleUserChange(e)} />
                            {formUserError.fath_email && (<span className="error">{formUserError.fath_email}</span>)}
                        </motion.div>
                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                            <label>Father's Phone Number<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                            <input type="text" className={formUserError.fath_phone ? `f-c b-red`: `f-c`} name="fath_phone" placeholder="Phone Number" value={formUser.fath_phone} onChange={e => handleUserChange(e)} />
                            {formUserError.fath_phone && (<span className="error">{formUserError.fath_phone}</span>)}
                        </motion.div>
                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                            <label>Father's Work Phone Number <span>(optional)</span></label>
                            <input type="text" className={`f-c`} name="fath_work_phone" placeholder="Work Phone Number" value={formUser.fath_work_phone} onChange={e => handleUserChange(e)} />
                        </motion.div>
                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                            <label>Select Father's Occupation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                            <select className={formUserError.fath_occupation ? `f-c b-red`: `f-c`} name="fath_occupation" value={formUser.fath_occupation} onChange={e => handleUserChange(e)}>
                                <option value="">Select...</option>
                                {occupationOptions}
                            </select>
                            {formUserError.fath_occupation && (<span className="error">{formUserError.fath_occupation}</span>)}
                        </motion.div> 
                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                            <label>Father's Residential Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                            <textarea className={formUserError.fath_addr ? `f-c b-red`: `f-c`} name="fath_addr" placeholder="Address" value={formUser.fath_addr} onChange={e => handleUserChange(e)} />
                            {formUserError.fath_addr && (<span className="error">{formUserError.fath_addr}</span>)}
                        </motion.div>
                        </>
                    )}
                    
                </>
            )
        }
    }
    const insertChildMotherForm = () => {
        if((formUser.is_parent === "no" && parent.gender === "F") || (!formUser.is_spouse_parent && parent.spouse_type === "wife") || (formUser.is_spouse_parent === "no" && parent.spouse_type === "wife") ){
            return(
                <>
                    
                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100 align-center">
                        <h4>Mother's Details</h4>
                    </motion.div>
                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                        <label>Is Mother Deceased?</label>
                        <input type="checkbox" name="is_moth_dead" value={formUser.is_moth_dead} checked={formUser.is_moth_dead} onChange={e => handleUserSelect(e, !formUser.is_moth_dead)} />
                    </motion.div> 
                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                        <label>Mother's FullName<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                        <input type="text" className={formUserError.moth_name ? `f-c b-red`: `f-c`} name="moth_name" placeholder="Fullname" value={formUser.moth_name} onChange={e => handleUserChange(e)} />
                        {formUserError.moth_name && (<span className="error">{formUserError.moth_name}</span>)}
                    </motion.div>    
                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                        <label>Select Mother's Nationality<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                        <select className={formUserError.moth_nationality ? `f-c b-red`: `f-c`} name="moth_nationality" value={formUser.moth_nationality} onChange={e => handleUserChange(e)}>
                            <option value="">Select...</option>
                            {countryOptions}
                        </select>
                        {formUserError.moth_nationality && (<span className="error">{formUserError.moth_nationality}</span>)}
                    </motion.div> 
                    {!formUser.is_moth_dead && (
                        <>
                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                            <label>Mother's Email<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                            <input type="email" className={formUserError.moth_email ? `f-c b-red`: `f-c`} name="moth_email" placeholder="Email" value={formUser.moth_email} onChange={e => handleUserChange(e)} />
                            {formUserError.moth_email && (<span className="error">{formUserError.moth_email}</span>)}
                        </motion.div>   
                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                            <label>Mother's Phone Number<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                            <input type="text" className={formUserError.moth_phone ? `f-c b-red`: `f-c`} name="moth_phone" placeholder="Phone Number" value={formUser.moth_phone} onChange={e => handleUserChange(e)} />
                            {formUserError.moth_phone && (<span className="error">{formUserError.moth_phone}</span>)}
                        </motion.div>
                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                            <label>Mother's Work Phone Number <span>(optional)</span></label>
                            <input type="text" className={`f-c`} name="moth_work_phone" placeholder="Work Phone Number" value={formUser.moth_work_phone} onChange={e => handleUserChange(e)} />
                        </motion.div>
                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                            <label>Select Mother's Occupation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                            <select className={formUserError.moth_occupation ? `f-c b-red`: `f-c`} name="moth_occupation" value={formUser.moth_occupation} onChange={e => handleUserChange(e)}>
                                <option value="">Select...</option>
                                {occupationOptions}
                            </select>
                            {formUserError.moth_occupation && (<span className="error">{formUserError.moth_occupation}</span>)}
                        </motion.div> 
                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                            <label>Mother's Residential Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                            <textarea className={formUserError.moth_addr ? `f-c b-red`: `f-c`} name="moth_addr" placeholder="Address" value={formUser.moth_addr} onChange={e => handleUserChange(e)} />
                            {formUserError.moth_addr && (<span className="error">{formUserError.moth_addr}</span>)}
                        </motion.div>
                        </>
                    )}
                    
                    
                </>
            )
        }
    }
    const formInsert = () => {
       
        if(formName === "parent"){
            return(
                <>
                
                    <VoWrapper page="Enrollment" page_under={`${school.name} Parent's Form`} action="Parent's Form" img={`${BACKEND_URL}${school.icon}`} back={`/${school.title}`}
                    data={[{name:'What Service Would You Like From Us Today?', done:true}, {name:'Parent Form', done:false}, {name:'How Many Children...', done:false}, {name:'Application Form', done:false}, {name:'Start Payment', done:false}]}>
                        <Wrapper>
                            {loading && <Loader />}
                            <form onSubmit={e => handleSubmitParent(e)}>
                                <span className="sm-font" style={{display:'block', marginBottom:'5px', color:'blue'}}>Please bear with us, we would like to take some information about you , to enable us give out a swift response in case of any unforseen circumstances</span>
                                <Content>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Enter Title<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="text" className={formUserError.title ? `f-c b-red`: `f-c`} name="title" placeholder="(Mr/Miss/Dr/Alhaji)" value={formUser.title} onChange={e => handleUserChange(e)} />
                                        {formUserError.title && (<span className="error">{formUserError.title}</span>)}
                                    </motion.div>           
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Gender<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.gender ? `f-c b-red`: `f-c`} name="gender" value={formUser.gender} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>
                                        </select>
                                        {formUserError.gender && (<span className="error">{formUserError.gender}</span>)}
                                    </motion.div>   
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Marital Status<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.marital_status ? `f-c b-red`: `f-c`} name="marital_status" value={formUser.marital_status} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            <option value="single">Single</option>
                                            <option value="married">Married</option>
                                            <option value="divorced">Divorced</option>
                                            <option value="seperated">Seperated</option>
                                            <option value="widowed">Widowed</option>
                                            <option value="widower">Widower</option>
                                        </select>
                                        {formUserError.marital_status && (<span className="error">{formUserError.marital_status}</span>)}
                                    </motion.div>   
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Date Of Birth<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="date" className={formUserError.dob ? `f-c b-red`: `f-c`} name="dob" value={formUser.dob} onChange={e => handleUserChange(e)} />
                                        {formUserError.dob && (<span className="error">{formUserError.dob}</span>)}
                                    </motion.div>        
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Religion<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.rel ? `f-c b-red`: `f-c`} name="rel" value={formUser.rel} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            <option value="islam">Islam</option>
                                            <option value="christian">Christianity</option>
                                        </select>
                                        {formUserError.rel && (<span className="error">{formUserError.rel}</span>)}
                                    </motion.div>   
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Nationality<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.nationality ? `f-c b-red`: `f-c`} name="nationality" value={formUser.nationality} onChange={e => handleUserCountryChange(e)}>
                                            <option value="">Select...</option>
                                            {countryOptions}
                                        </select>
                                        {formUserError.nationality && (<span className="error">{formUserError.nationality}</span>)}
                                    </motion.div>   
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select State Of Origin<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.state_origin ? `f-c b-red`: `f-c`} name="state_origin" value={formUser.state_origin} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            {stateOptions}
                                        </select>
                                        {formUserError.state_origin && (<span className="error">{formUserError.state_origin}</span>)}
                                    </motion.div>   
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Occupation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.occupation ? `f-c b-red`: `f-c`} name="occupation" value={formUser.occupation} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            {occupationOptions}
                                        </select>
                                        {formUserError.occupation && (<span className="error">{formUserError.occupation}</span>)}
                                    </motion.div> 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Current Residential Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <textarea className={formUserError.addr ? `f-c b-red`: `f-c`} name="addr" placeholder="Address" value={formUser.addr} onChange={e => handleUserChange(e)} />
                                        {formUserError.addr && (<span className="error">{formUserError.addr}</span>)}
                                    </motion.div>    
                                    {formUser.marital_status === "married" && (
                                        <>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100 align-center">
                                                <h4>Spouse's Details</h4>
                                            </motion.div>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                                <label>Enter Spouse's Title<span>(optional)</span></label>
                                                <input type="text" className={formUserError.spouse_title ? `f-c b-red`: `f-c`} name="spouse_title" placeholder="(Mr/Miss/Dr/Alhaji)" value={formUser.spouse_title} onChange={e => handleUserChange(e)} />
                                                {formUserError.spouse_title && (<span className="error">{formUserError.spouse_title}</span>)}
                                            </motion.div>    
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                                <label>Select Spouse Type<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                                <select className={formUserError.spouse_title ? `f-c b-red`: `f-c`} name="spouse_type" value={formUser.spouse_type} onChange={e => handleUserChange(e)}>
                                                    <option value="">Select...</option>
                                                    <option value="husband">Husband</option>
                                                    <option value="wife">Wife</option>
                                                </select>
                                                {formUserError.spouse_type && (<span className="error">{formUserError.spouse_type}</span>)}
                                            </motion.div> 
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                                <label>Enter Spouse's FullName<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                                <input type="text" className={formUserError.spouse_name ? `f-c b-red`: `f-c`} name="spouse_name" placeholder="Fullname" value={formUser.spouse_name} onChange={e => handleUserChange(e)} />
                                                {formUserError.spouse_name && (<span className="error">{formUserError.spouse_name}</span>)}
                                            </motion.div> 
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                                <label>Enter Spouse's Email<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                                <input type="email" className={formUserError.spouse_email ? `f-c b-red`: `f-c`} name="spouse_email" placeholder="Email" value={formUser.spouse_email} onChange={e => handleUserChange(e)} />
                                                {formUserError.spouse_email && (<span className="error">{formUserError.spouse_email}</span>)}
                                            </motion.div>   
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                                <label>Enter Spouse's Phone Number<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                                <input type="text" className={formUserError.spouse_phone ? `f-c b-red`: `f-c`} name="spouse_phone" placeholder="Phone Number" value={formUser.spouse_phone} onChange={e => handleUserChange(e)} />
                                                {formUserError.spouse_phone && (<span className="error">{formUserError.spouse_phone}</span>)}
                                            </motion.div>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                                <label>Enter Spouse's Work Phone Number <span>(optional)</span></label>
                                                <input type="text" className={`f-c`} name="spouse_work_phone" placeholder="Work Phone Number" value={formUser.spouse_work_phone} onChange={e => handleUserChange(e)} />
                                            </motion.div>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                                <label>Select Occupation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                                <select className={formUserError.spouse_occupation ? `f-c b-red`: `f-c`} name="spouse_occupation" value={formUser.spouse_occupation} onChange={e => handleUserChange(e)}>
                                                    <option value="">Select...</option>
                                                    {occupationOptions}
                                                </select>
                                                {formUserError.spouse_occupation && (<span className="error">{formUserError.spouse_occupation}</span>)}
                                            </motion.div> 
                                        </>
                                    )}
                                    
            
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100 align-center">
                                        <h4>Additional Emergency Contact Details</h4>
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Emergency Contact's Name<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="text" className={formUserError.emergency_name ? `f-c b-red`: `f-c`} name="emergency_name" placeholder="Fullname" value={formUser.emergency_name} onChange={e => handleUserChange(e)} />
                                        {formUserError.emergency_name && (<span className="error">{formUserError.emergency_name}</span>)}
                                    </motion.div> 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Emergency Contact's Relationship To You<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.emergency_relate ? `f-c b-red`: `f-c`} name="emergency_relate" value={formUser.emergency_relate} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            {guardianOptions}
                                        </select>
                                        {formUserError.emergency_relate && (<span className="error">{formUserError.emergency_relate}</span>)}
                                    </motion.div> 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Emergency Contact's Email<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="email" className={formUserError.emergency_email ? `f-c b-red`: `f-c`} name="emergency_email" placeholder="Email" value={formUser.emergency_email} onChange={e => handleUserChange(e)} />
                                        {formUserError.emergency_email && (<span className="error">{formUserError.emergency_email}</span>)}
                                    </motion.div> 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Emergency Contact's Phone Number<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="text" className={formUserError.emergency_phone ? `f-c b-red`: `f-c`} name="emergency_phone" placeholder="Phone Number" value={formUser.emergency_phone} onChange={e => handleUserChange(e)} />
                                        {formUserError.emergency_phone && (<span className="error">{formUserError.emergency_phone}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Emergency Contact's Work Phone Number<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="text" className={`f-c`} name="emergency_work_phone" placeholder="Work Phone Number" value={formUser.emergency_work_phone} onChange={e => handleUserChange(e)} />
                                        {formUserError.emergency_work_phone && (<span className="error">{formUserError.emergency_work_phone}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Emergency Contact's Country Of Residence<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.emergency_country_residence ? `f-c b-red`: `f-c`} name="emergency_country_residence" value={formUser.emergency_country_residence} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            {countryOptions}
                                        </select>
                                        {formUserError.emergency_country_residence && (<span className="error">{formUserError.emergency_country_residence}</span>)}
                                    </motion.div> 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Emergency Contact's Occupation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.emergency_occupation ? `f-c b-red`: `f-c`} name="emergency_occupation" value={formUser.emergency_occupation} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            {occupationOptions}
                                        </select>
                                        {formUserError.emergency_occupation && (<span className="error">{formUserError.emergency_occupation}</span>)}
                                    </motion.div> 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Emergency Contact's Current Residential Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <textarea className={formUserError.emergency_addr ? `f-c b-red`: `f-c`} name="emergency_addr" placeholder="Address" value={formUser.emergency_addr} onChange={e => handleUserChange(e)} />
                                        {formUserError.emergency_addr && (<span className="error">{formUserError.emergency_addr}</span>)}
                                    </motion.div> 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>How did you hear about us?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.loc_heard ? `f-c b-red`: `f-c`} name="loc_heard" value={formUser.loc_heard} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            {locationOptions}
                                        </select>
                                        {formUserError.loc_heard && (<span className="error">{formUserError.loc_heard}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                        <button type="submit" className="btn hover-a br-5 shadow rem1 bg-black">Next <i className="fas fa-arrow-right"></i></button>
                                    </motion.div>
                                </Content>
                            </form>
                        </Wrapper>
                    </VoWrapper>   
                </>
            )
        }
        else if(formName === "student_no"){
            return(
                <>
                    <VoWrapper page="Enrollment" page_under={`${school.name} Application Form`} action={`${school.title.toUpperCase()} APPLICATION FORM`} img={`${BACKEND_URL}${school.icon}`} back={`/${school.title}`}
                    data={[{name:'What Service Would You Like From Us Today?', done:true}, {name:'Parent Form', done:true}, {name:'How Many Children...', done:false}, {name:'Application Form', done:false}, {name:'Start Payment', done:false}]}>
                        <Wrapper>
                        {loading && <Loader />}
                        {allEnrolledStudentByParent.length > 0 && (
                            <>
                            <Content>
                                <div className="flex-100">
                                    <span className="font-med fw-700 disp-block padd-4px">Enrolled Kid(s) Status</span>
                                </div>
                                <div className="flex-100">
                                    <TABLE>
                                        <thead>
                                            <TR>
                                                <TH style={{width:'60%'}}>Name</TH>
                                                <TH>School - <span className="font-very-small">Class Accepted</span></TH>
                                                <TH>Enroll Type</TH>
                                                <TH>Status</TH>
                                            </TR>
                                        </thead>
                                        <tbody>
                                            {allEnrolledStudentByParent.map((item, i) => {
                                                return(
                                                    <Fragment key={i}>
                                                        <TR>
                                                            <TD style={{width:'60%'}} classname={"text-capitalize"} align="center">{item.enroll_sur_name} {item.enroll_other_name}</TD>
                                                            <TD classname={"text-upper"}>{item.school_title} - {item.class_accepted_name}</TD>
                                                            <TD classname={"text-capitalize"}><span className="font-very-small">{item.enroll_type}</span></TD>
                                                            {processTDStat({is_accepted:item.is_accepted, is_done:item.is_done, is_enrolled:item.is_enrolled, is_start_interview:item.is_start_interview, is_finish_interview:item.is_finish_interview}, item.uid)}
                                                        </TR>
                                                    </Fragment>
                                                )
                                            })}

                                            
                                        </tbody>
                                    </TABLE>
                                    {allEnrolledStudentByParent.filter((it) => it.is_accepted && it.is_enrolled && it.is_done).length > 0 && (
                                        <div className="disp-block align-center">
                                            <Link to={`https://app.voskool.com/${school.title}/pay`} className='btn padd-4px font-slightly-small br hover-opacity app-bg-color app-bg-text-color' target={'_blank'}>Start School App</Link>
                                        </div>
                                    )}
                                    <div className="flex-100">
                                        <Note>Enrollment status can change at anytime click <button type="button" onClick={() => setReload(true)}>here</button> to refresh this page</Note>
                                    </div>
                                </div>
                                <div className="flex-100">
                                    <span className="font-med fw-700 disp-block padd-4px">Enroll New Kid(s)</span>
                                </div>
                            </Content>
                            </>
                        )}
                            <form onSubmit={e => handleNoStudentSubmit(e)}>
                                <Content>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group mrn f-100">
                                        <label>How many of your children would you like to enroll to <b>{school.title}</b> school?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input required type="number" min={1} max={10} className={`f-c`} name="no_child" placeholder="Number" value={studentNo} onChange={e => setStudentNo(e.target.value)} />
                                    </motion.div> 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.9}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                        <button type="submit" className="btn hover-a br-5 shadow rem1 bg-black">Next <i className="fas fa-arrow-right"></i></button>
                                    </motion.div>
                                </Content>
                            </form>
                        </Wrapper>
                    </VoWrapper>
                </>
            )
        }
        else if(formName === "parent_student"){
            return(
                <>
                    <VoWrapper page="Enrollment" back={`/${school.title}`} page_under={`${school.name} Application Form Child ${currStudentNo + 1}`} action={`${school.title.toUpperCase()} APPLICATION FORM ${currStudentNo + 1}`} img={`${BACKEND_URL}${school.icon}`}
                    data={[{name:'What Service Would You Like From Us Today?', done:true}, {name:'Parent Form', done:true}, {name:'How Many Children...', done:true}, {name:'Application Form', done:false}, {name:'Start Payment', done:false}]}>
                        <Wrapper>
                        {loading && <Loader />}
                            <form onSubmit={e => { (parseInt(currStudentNo) + 1) === parseInt(studentNo) ? handleParentChildSubmit(e, "finish") : handleParentChildSubmit(e)}}>
                                <Content>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Are you the <b>{parent.gender==="M" ? 'father' : 'mother'}</b> to this child?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.is_parent ? `f-c b-red`: `f-c`} name="is_parent" value={formUser.is_parent} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            <option value={parent.gender==="M" ? 'father' : 'mother'}>Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        {formUserError.is_parent && (<span className="error">{formUserError.is_parent}</span>)}
                                    </motion.div>
                                    {parent.has_spouse && (
                                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                            <label>Is your spouse the <b>{parent.gender==="M" ? 'mother' : 'father'}</b> to this child?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                            <select className={formUserError.is_spouse_parent ? `f-c b-red`: `f-c`} name="is_spouse_parent" value={formUser.is_spouse_parent} onChange={e => handleUserChange(e)}>
                                                <option value="">Select...</option>
                                                <option value={parent.gender==="M" ? 'mother' : 'father'}>Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                            {formUserError.is_spouse_parent && (<span className="error">{formUserError.is_spouse_parent}</span>)}
                                        </motion.div>
                                    )}
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Child's Passport Picture<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="file" className={formUserError.profile_pic ? `f-c b-red`: `f-c`} name="profile_pic" value={formUser.profile_pic_value} onChange={e => handleUserChangeFile(e)} />
                                        {formUserError.profile_pic && (<span className="error">{formUserError.profile_pic}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Child's Surname<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="text" className={formUserError.sur_name ? `f-c b-red`: `f-c`} name="sur_name" placeholder="Surname" value={formUser.sur_name} onChange={e => handleUserChange(e)} />
                                        {formUserError.sur_name && (<span className="error">{formUserError.sur_name}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Child's Othernames<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="text" className={formUserError.other_name ? `f-c b-red`: `f-c`} name="other_name" placeholder="Othernames" value={formUser.other_name} onChange={e => handleUserChange(e)} />
                                        {formUserError.other_name && (<span className="error">{formUserError.other_name}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Child's Gender<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.gender ? `f-c b-red`: `f-c`} name="gender" value={formUser.gender} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>
                                        </select>
                                        {formUserError.gender && (<span className="error">{formUserError.gender}</span>)}
                                    </motion.div>  
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Child's Date Of Birth<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="date" className={formUserError.dob ? `f-c b-red`: `f-c`} name="dob" value={formUser.dob} onChange={e => handleUserChange(e)} />
                                        {formUserError.dob && (<span className="error">{formUserError.dob}</span>)}
                                    </motion.div>        
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Child's Religion<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.rel ? `f-c b-red`: `f-c`} name="rel" value={formUser.rel} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            <option value="islam">Islam</option>
                                            <option value="christian">Christianity</option>
                                        </select>
                                        {formUserError.rel && (<span className="error">{formUserError.rel}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Language Spoken<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="text" className={formUserError.lang_spoken ? `f-c b-red`: `f-c`} name="lang_spoken" placeholder="Languages" value={formUser.lang_spoken} onChange={e => handleUserChange(e)} />
                                        {formUserError.lang_spoken && (<span className="error">{formUserError.lang_spoken}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Child's Current Residential Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <textarea className={formUserError.addr ? `f-c b-red`: `f-c`} name="addr" placeholder="Address" value={formUser.addr} onChange={e => handleUserChange(e)} />
                                        {formUserError.addr && (<span className="error">{formUserError.addr}</span>)}
                                    </motion.div> 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Child's Disability <span>(optional)</span></label>
                                        <select className={ `f-c text-capitalize`} name="disability" value={formUser.disability} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            {disabilitiesOptions}
                                        </select>
                                        {formUserError.disability && (<span className="error">{formUserError.disability}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Child's Nationality<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.nationality ? `f-c b-red`: `f-c`} name="nationality" value={formUser.nationality} onChange={e => handleUserCountryChange(e)}>
                                            <option value="">Select...</option>
                                            {countryOptions}
                                        </select>
                                        {formUserError.nationality && (<span className="error">{formUserError.nationality}</span>)}
                                    </motion.div>   
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Child's State Of Origin<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.state_origin ? `f-c b-red`: `f-c`} name="state_origin" value={formUser.state_origin} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            {stateOptions}
                                        </select>
                                        {formUserError.state_origin && (<span className="error">{formUserError.state_origin}</span>)}
                                    </motion.div> 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Child's Class In Previous School<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.prev_class ? `f-c b-red`: `f-c`} name="prev_class" value={formUser.prev_class} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            <option value="none">None</option>
                                            {classItemOptions}
                                        </select>
                                        {formUserError.prev_class && (<span className="error">{formUserError.prev_class}</span>)}
                                    </motion.div> 
                                    {formUser.prev_class !== "none" && (
                                        <>
                                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                            <label>Child's Previous School Name And Location<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                            <textarea className={formUserError.prev_school ? `f-c b-red`: `f-c`} name="prev_school" placeholder="Name and Location" value={formUser.prev_school} onChange={e => handleUserChange(e)} />
                                            {formUserError.prev_school && (<span className="error">{formUserError.prev_school}</span>)}
                                        </motion.div>
                                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                            <label>Child's Last Report Card <span className="font-super-small" style={{color:'#555'}}>(optional)</span></label>
                                            <input type="file" className={formUserError.last_rep_card ? `f-c b-red`: `f-c`} name="last_rep_card" value={formUser.last_rep_card_value} onChange={e => handleUserChangeFile(e)} />
                                            {formUserError.last_rep_card && (<span className="error">{formUserError.last_rep_card}</span>)}
                                        </motion.div>
                                        </>
                                    )}
                                    
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Child's Birth Certificate <span className="font-super-small" style={{color:'#555'}}>(optional)</span></label>
                                        <input type="file" className={formUserError.birth_cert ? `f-c b-red`: `f-c`} name="birth_cert" value={formUser.birth_cert_value} onChange={e => handleUserChangeFile(e)} />
                                        {formUserError.birth_cert && (<span className="error">{formUserError.birth_cert}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Which branch of our school would you like to enroll your child to?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.school_branch ? `f-c b-red`: `f-c`} name="school_branch" value={formUser.school_branch} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            {schoolBranchOptions}
                                        </select>
                                        {formUserError.school_branch && (<span className="error">{formUserError.school_branch}</span>)}
                                    </motion.div> 
                                    {insertChildGuardianRelate()}
                                    {insertChildGuardianSign()}
                                    {insertChildFatherForm()}
                                    {insertChildMotherForm()}
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Is your child a ...<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.enroll_type ? `f-c b-red`: `f-c`} name="enroll_type" value={formUser.enroll_type} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            <option value="new">New Student</option>
                                            <option value="returning">Returning Student</option>
                                        </select>
                                        {formUserError.enroll_type && (<span className="error">{formUserError.enroll_type}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100">
                                        <label>Who is in custody of this child?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.custody ? `f-c b-red`: `f-c`} name="custody" value={formUser.custody} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            <option value="mother">Mother</option>
                                            <option value="father">Father</option>
                                            <option value="both">Both Mother and Father</option>
                                            <option value="guardian">Guardian</option>
                                        </select>
                                        {formUserError.custody && (<span className="error">{formUserError.custody}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>What class would like to enroll your child to?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.curr_class ? `f-c b-red`: `f-c`} name="curr_class" value={formUser.curr_class} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            {classItemOptions}
                                        </select>
                                        {formUserError.curr_class && (<span className="error">{formUserError.curr_class}</span>)}
                                    </motion.div>  
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100">
                                        <input type="checkbox" name="terms" value={formUser.terms} checked={formUser.terms} onChange={e => handleUserSelect(e, !formUser.terms)}   /><label><b>I Hereby Agree with the {school.title.toUpperCase()} School's <Link to="/terms_of_service" target="_blank">Terms And Agreement</Link>?</b><span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        {formUserError.terms && (<span className="error">{formUserError.terms}</span>)}
                                    </motion.div>
                                     
                                    {insertChildNextBtn()}
                                    {insertChildFinishBtn()}

                                    <Note>Data processing may take a while, please try and be patient</Note>
                                    
                                </Content>
                            </form>
                        </Wrapper>
                    </VoWrapper>
                </>
            )
        }
    }
    const studentFormInsert = () => {
        if(formName === "student"){
        return(
            <VoWrapper back={`/${school.title}`} page="Enrollment" page_under={`${school.name} Student Application Form`} action={`${school.title.toUpperCase()} STUDENT APPLICATION FORM`} img={`${BACKEND_URL}${school.icon}`}
                    data={[{name:'What Service Would You Like From Us Today?', done:true}, {name:'Application Form', done:false}, {name:'Start Payment!', done:false}]}>
                <Wrapper>
                    {loading && <Loader />}
                    <form onSubmit={e => handleStudentSubmit(e)}>
                        <Content>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Passport Picture<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <input type="file" className={formUserError.profile_pic ? `f-c b-red`: `f-c`} name="profile_pic" value={formUser.profile_pic_value} onChange={e => handleUserChangeFile(e)} />
                                {formUserError.profile_pic && (<span className="error">{formUserError.profile_pic}</span>)}
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Select Gender<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.gender ? `f-c b-red`: `f-c`} name="gender" value={formUser.gender} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                </select>
                                {formUserError.gender && (<span className="error">{formUserError.gender}</span>)}
                            </motion.div>  
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Date Of Birth<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <input type="date" className={formUserError.dob ? `f-c b-red`: `f-c`} name="dob" value={formUser.dob} onChange={e => handleUserChange(e)} />
                                {formUserError.dob && (<span className="error">{formUserError.dob}</span>)}
                            </motion.div>        
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Select Religion<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.rel ? `f-c b-red`: `f-c`} name="rel" value={formUser.rel} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    <option value="islam">Islam</option>
                                    <option value="christian">Christianity</option>
                                </select>
                                {formUserError.rel && (<span className="error">{formUserError.rel}</span>)}
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Language Spoken<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <input type="text" className={formUserError.lang_spoken ? `f-c b-red`: `f-c`} name="lang_spoken" placeholder="Languages" value={formUser.lang_spoken} onChange={e => handleUserChange(e)} />
                                {formUserError.lang_spoken && (<span className="error">{formUserError.lang_spoken}</span>)}
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Current Residential Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <textarea className={formUserError.addr ? `f-c b-red`: `f-c`} name="addr" placeholder="Address" value={formUser.addr} onChange={e => handleUserChange(e)} />
                                {formUserError.addr && (<span className="error">{formUserError.addr}</span>)}
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Select Disability <span>(optional)</span></label>
                                <select className={ `f-c text-capitalize`} name="disability" value={formUser.disability} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    {disabilitiesOptions}
                                </select>
                                {formUserError.disability && (<span className="error">{formUserError.disability}</span>)}
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Select Nationality<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.nationality ? `f-c b-red`: `f-c`} name="nationality" value={formUser.nationality} onChange={e => handleUserCountryChange(e)}>
                                    <option value="">Select...</option>
                                    {countryOptions}
                                </select>
                                {formUserError.nationality && (<span className="error">{formUserError.nationality}</span>)}
                            </motion.div>   
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Select State Of Origin<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.state_origin ? `f-c b-red`: `f-c`} name="state_origin" value={formUser.state_origin} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    {stateOptions}
                                </select>
                                {formUserError.state_origin && (<span className="error">{formUserError.state_origin}</span>)}
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Select Class In Previous School<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.prev_class ? `f-c b-red`: `f-c`} name="prev_class" value={formUser.prev_class} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    {classItemOptions}
                                </select>
                                {formUserError.prev_class && (<span className="error">{formUserError.prev_class}</span>)}
                            </motion.div> 
                            {formUser.prev_class !== "none" && (
                                <>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Previous School Name And Location<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <textarea className={formUserError.prev_school ? `f-c b-red`: `f-c`} name="prev_school" placeholder="School Name and Location" value={formUser.prev_school} onChange={e => handleUserChange(e)} />
                                    {formUserError.prev_school && (<span className="error">{formUserError.prev_school}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Last Report Card <span className="font-super-small" style={{color:'#555'}}>(optional)</span></label>
                                    <input type="file" className={formUserError.last_rep_card ? `f-c b-red`: `f-c`} name="last_rep_card" value={formUser.last_rep_card_value} onChange={e => handleUserChangeFile(e)} />
                                    {formUserError.last_rep_card && (<span className="error">{formUserError.last_rep_card}</span>)}
                                </motion.div>
                                </>
                            )}
                            
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Birth Certificate <span className="font-super-small" style={{color:'#555'}}>(optional)</span></label>
                                <input type="file" className={formUserError.birth_cert ? `f-c b-red`: `f-c`} name="birth_cert" value={formUser.birth_cert_value} onChange={e => handleUserChangeFile(e)} />
                                {formUserError.birth_cert && (<span className="error">{formUserError.birth_cert}</span>)}
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Which branch of our school would you like to enroll to?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.school_branch ? `f-c b-red`: `f-c`} name="school_branch" value={formUser.school_branch} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    {schoolBranchOptions}
                                </select>
                                {formUserError.school_branch && (<span className="error">{formUserError.school_branch}</span>)}
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100 align-center">
                                <h4>Father's Details</h4>
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Is Father Deceased?</label>
                                <input type="checkbox" name="is_fath_dead"  value={formUser.is_fath_dead} onChange={e => handleUserSelect(e, !formUser.is_fath_dead)} />
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Father's FullName<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <input type="text" className={formUserError.fath_name ? `f-c b-red`: `f-c`} name="fath_name" placeholder="Fullname" value={formUser.fath_name} onChange={e => handleUserChange(e)} />
                                {formUserError.fath_name && (<span className="error">{formUserError.fath_name}</span>)}
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Select Father's Nationality<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.fath_nationality ? `f-c b-red`: `f-c`} name="fath_nationality" value={formUser.fath_nationality} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    {countryOptions}
                                </select>
                                {formUserError.fath_nationality && (<span className="error">{formUserError.fath_nationality}</span>)}
                            </motion.div> 
                            {!formUser.is_fath_dead && (
                                <>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Father's Email<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="email" className={formUserError.fath_email ? `f-c b-red`: `f-c`} name="fath_email" placeholder="Email" value={formUser.fath_email} onChange={e => handleUserChange(e)} />
                                    {formUserError.fath_email && (<span className="error">{formUserError.fath_email}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Father's Phone Number<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="text" className={formUserError.fath_phone ? `f-c b-red`: `f-c`} name="fath_phone" placeholder="Phone Number" value={formUser.fath_phone} onChange={e => handleUserChange(e)} />
                                    {formUserError.fath_phone && (<span className="error">{formUserError.fath_phone}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Father's Work Phone Number <span>(optional)</span></label>
                                    <input type="text" className={`f-c`} name="fath_work_phone" placeholder="Work Phone Number" value={formUser.fath_work_phone} onChange={e => handleUserChange(e)} />
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                    <label>Select Father's Occupation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <select className={formUserError.fath_occupation ? `f-c b-red`: `f-c`} name="fath_occupation" value={formUser.fath_occupation} onChange={e => handleUserChange(e)}>
                                        <option value="">Select...</option>
                                        {occupationOptions}
                                    </select>
                                    {formUserError.fath_occupation && (<span className="error">{formUserError.fath_occupation}</span>)}
                                </motion.div> 
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>Father's Residential Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <textarea className={formUserError.fath_addr ? `f-c b-red`: `f-c`} name="fath_addr" placeholder="Address" value={formUser.fath_addr} onChange={e => handleUserChange(e)} />
                                    {formUserError.fath_addr && (<span className="error">{formUserError.fath_addr}</span>)}
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                    <label>A picture of your father's signature as validation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="file" className={formUserError.sign ? `f-c b-red`: `f-c`} name="sign" value={formUser.sign_value} onChange={e => handleUserChangeFile(e)} />
                                    {formUserError.sign && (<span className="error">{formUserError.sign}</span>)}
                                </motion.div>
                                </>
                            )}
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100 align-center">
                            <h4>Mother's Details</h4>
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Is Mother Deceased?</label>
                                <input type="checkbox" name="is_moth_dead" value={formUser.is_moth_dead} checked={formUser.is_moth_dead} onChange={e => handleUserSelect(e, !formUser.is_moth_dead)} />
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Mother's FullName<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <input type="text" className={formUserError.moth_name ? `f-c b-red`: `f-c`} name="moth_name" placeholder="Fullname" value={formUser.moth_name} onChange={e => handleUserChange(e)} />
                                {formUserError.moth_name && (<span className="error">{formUserError.moth_name}</span>)}
                            </motion.div>    
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Select Mother's Nationality<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.moth_nationality ? `f-c b-red`: `f-c`} name="moth_nationality" value={formUser.moth_nationality} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    {countryOptions}
                                </select>
                                {formUserError.moth_nationality && (<span className="error">{formUserError.moth_nationality}</span>)}
                            </motion.div> 
                            {!formUser.is_moth_dead && (
                                <>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Mother's Email<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="email" className={formUserError.moth_email ? `f-c b-red`: `f-c`} name="moth_email" placeholder="Email" value={formUser.moth_email} onChange={e => handleUserChange(e)} />
                                        {formUserError.moth_email && (<span className="error">{formUserError.moth_email}</span>)}
                                    </motion.div>   
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Mother's Phone Number<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="text" className={formUserError.moth_phone ? `f-c b-red`: `f-c`} name="moth_phone" placeholder="Phone Number" value={formUser.moth_phone} onChange={e => handleUserChange(e)} />
                                        {formUserError.moth_phone && (<span className="error">{formUserError.moth_phone}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Mother's Work Phone Number <span>(optional)</span></label>
                                        <input type="text" className={`f-c`} name="moth_work_phone" placeholder="Work Phone Number" value={formUser.moth_work_phone} onChange={e => handleUserChange(e)} />
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                        <label>Select Mother's Occupation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <select className={formUserError.moth_occupation ? `f-c b-red`: `f-c`} name="moth_occupation" value={formUser.moth_occupation} onChange={e => handleUserChange(e)}>
                                            <option value="">Select...</option>
                                            {occupationOptions}
                                        </select>
                                        {formUserError.moth_occupation && (<span className="error">{formUserError.moth_occupation}</span>)}
                                    </motion.div> 
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>Mother's Residential Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <textarea className={formUserError.moth_addr ? `f-c b-red`: `f-c`} name="moth_addr" placeholder="Address" value={formUser.moth_addr} onChange={e => handleUserChange(e)} />
                                        {formUserError.moth_addr && (<span className="error">{formUserError.moth_addr}</span>)}
                                    </motion.div>
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                        <label>A picture of your mother's signature as validation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                        <input type="file" className={formUserError.second_sign ? `f-c b-red`: `f-c`} name="second_sign" value={formUser.second_sign_value} onChange={e => handleUserChangeFile(e)} />
                                        {formUserError.second_sign && (<span className="error">{formUserError.second_sign}</span>)}
                                    </motion.div>
                                </>
                            )}
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100 align-center">
                                <h4>Additional Emergency Contact Details</h4>
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Emergency Contact's Name<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <input type="text" className={formUserError.emergency_name ? `f-c b-red`: `f-c`} name="emergency_name" placeholder="Fullname" value={formUser.emergency_name} onChange={e => handleUserChange(e)} />
                                {formUserError.emergency_name && (<span className="error">{formUserError.emergency_name}</span>)}
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Emergency Contact's Relationship To You<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.emergency_relate ? `f-c b-red`: `f-c`} name="emergency_relate" value={formUser.emergency_relate} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    {guardianOptions}
                                </select>
                                {formUserError.emergency_relate && (<span className="error">{formUserError.emergency_relate}</span>)}
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Emergency Contact's Email<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <input type="email" className={formUserError.emergency_email ? `f-c b-red`: `f-c`} name="emergency_email" placeholder="Email" value={formUser.emergency_email} onChange={e => handleUserChange(e)} />
                                {formUserError.emergency_email && (<span className="error">{formUserError.emergency_email}</span>)}
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Emergency Contact's Phone Number<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <input type="text" className={formUserError.emergency_phone ? `f-c b-red`: `f-c`} name="emergency_phone" placeholder="Phone Number" value={formUser.emergency_phone} onChange={e => handleUserChange(e)} />
                                {formUserError.emergency_phone && (<span className="error">{formUserError.emergency_phone}</span>)}
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Emergency Contact's Work Phone Number <span>(optional)</span></label>
                                <input type="text" className={`f-c`} name="emergency_work_phone" placeholder="Work Phone Number" value={formUser.emergency_work_phone} onChange={e => handleUserChange(e)} />
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Emergency Contact's Country Of Residence<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.emergency_country_residence ? `f-c b-red`: `f-c`} name="emergency_country_residence" value={formUser.emergency_country_residence} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    {countryOptions}
                                </select>
                                {formUserError.emergency_country_residence && (<span className="error">{formUserError.emergency_country_residence}</span>)}
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Emergency Contact's Occupation<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.emergency_occupation ? `f-c b-red`: `f-c`} name="emergency_occupation" value={formUser.emergency_occupation} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    {occupationOptions}
                                </select>
                                {formUserError.emergency_occupation && (<span className="error">{formUserError.emergency_occupation}</span>)}
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-33">
                                <label>Emergency Contact's Current Residential Address<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <textarea className={formUserError.emergency_addr ? `f-c b-red`: `f-c`} name="emergency_addr" placeholder="Address" value={formUser.emergency_addr} onChange={e => handleUserChange(e)} />
                                {formUserError.emergency_addr && (<span className="error">{formUserError.emergency_addr}</span>)}
                            </motion.div><br />

                            <br /><motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>Are you a ...<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.enroll_type ? `f-c b-red`: `f-c`} name="enroll_type" value={formUser.enroll_type} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    <option value="new">New Student</option>
                                    <option value="returning">Returning Student</option>
                                </select>
                                {formUserError.enroll_type && (<span className="error">{formUserError.enroll_type}</span>)}
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100">
                                <label>Who are you in custody of?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.custody ? `f-c b-red`: `f-c`} name="custody" value={formUser.custody} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    <option value="none">None</option>
                                    <option value="mother">Mother</option>
                                    <option value="father">Father</option>
                                    <option value="both">Both Mother and Father</option>
                                    <option value="guardian">Guardian</option>
                                </select>
                                {formUserError.custody && (<span className="error">{formUserError.custody}</span>)}
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>What class would like to enroll to?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.curr_class ? `f-c b-red`: `f-c`} name="curr_class" value={formUser.curr_class} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    {classItemOptions}
                                </select>
                                {formUserError.curr_class && (<span className="error">{formUserError.curr_class}</span>)}
                            </motion.div> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-33">
                                <label>How did you hear about us?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                <select className={formUserError.loc_heard ? `f-c b-red`: `f-c`} name="loc_heard" value={formUser.loc_heard} onChange={e => handleUserChange(e)}>
                                    <option value="">Select...</option>
                                    {locationOptions}
                                </select>
                                {formUserError.loc_heard && (<span className="error">{formUserError.loc_heard}</span>)}
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group f-100">
                                <input type="checkbox" name="terms" value={formUser.terms} checked={formUser.terms} onChange={e => handleUserSelect(e, !formUser.terms)}   /><label><b>I Hereby Agree with the {school.title.toUpperCase()} School's <Link to="/terms_of_service" target="_blank">Terms And Agreement</Link>?</b><span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                {formUserError.terms && (<span className="error">{formUserError.terms}</span>)}
                            </motion.div>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                <button type="submit" className="btn hover-a br-5 shadow rem1_ bg-black">Finish <i className="fas fa-paper-plane"></i></button>
                            </motion.div>
                        </Content>
                    </form>
                </Wrapper>
            </VoWrapper>
        )
        }
    }
    return(
        <>
        
        <NotificationWrapper>
            {error.title && (errorHandler)}
            {warning.text && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
            {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
        </NotificationWrapper> 
        {preloading && <Loader />}
        {!preloading && (
            <>
            {exist ? (
                <>
                {!preloadingError ? (
                    <>
                        {school.title ?
                        user.user_type === "parent" && 
                            formComplete === "parent_student" ? (<Redirect success={true} title="Form Submitted Successfully" btn1={true} btn1_title="Application Status" onClickBtn1={() => window.location.reload()} >Applications Are Currently Being Processed, An Email Will Be Sent To You After It's Been Processed, Or You Can Click The Link Below To Check Application Status</Redirect>) :  formInsert() :
                            (<Redirect error404 link1="/" link1_title="Back To Homepage" />)
                        }
                        {school.title ?
                        user.user_type === "student" && 
                            formComplete === "student" ? enroll.is_enrolled && enroll.is_accepted ? 
                            (<Redirect success={true} title={`Congratulations, Your Application To ${school.title.toUpperCase()} School Has Been Accepted Successfully`} link1="/" link1_title="Application Status" link2="/" link2_title="Start Payment">Click on the payment link below, to start the payment procedure ASAP, or you can click on the application status to see the procedures</Redirect>)
                            : (<Redirect success={!formCompleteStudent} title={!formCompleteStudent ? "Form Submitted Successfully" : "Application Request Still PENDING"}  btn1={true} btn1_title="Reload" onClickBtn1={() => window.location.reload()} link2={`/?t=${user.user_type}`} link2_title="Back To Homepage" >Application Is Currently Being Processed By The School, An Email Will Be Sent To You After It's Been Processed. Please Excercise Due Patience. {formCompleteStudent && 'Reload The Page Constantly To Check Status Concurrently'}</Redirect>) :  studentFormInsert()
                        : (<Redirect error404 link1="/" link1_title="Back To Homepage" />)
                        } 
                    </>
                ) : (
                    <Redirect error title="400"  btn1 btn1_title="Reload" link2={`/?t=${user.user_type}#schools`} link2_title="Search Your School" onClickBtn1={() => setReload(true)}>Data loaded with errors... try reloading, if it still persists, search for your school from the link below</Redirect>
                )}
                    
                </>
            ) : (
                <Redirect error404 link1="/" link1_title="Back To Homepage" />
            )}
            </>
        )}
        
        </>
    )
    
}

export default EnrollmentPage;