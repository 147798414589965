import styled from "styled-components";
import { motion } from "framer-motion";

export const Content = styled(motion.div)`
    position: absolute;
     width: clamp(60%, 200px, 90%); /*when the screen is too small it goes 50% of 300px when its too big it goes 90% */
    .container{
        background: #fff;
        padding: 20px;
        border-radius: 30px;
        position: relative;

        .header{
            margin-bottom: 30px;

            .heading{
                margin-bottom: 8px;
                span{
                    font-size: 1.7rem;
                    font-weight: 700;
                    letter-spacing: .8px;
                    @media(max-width: 780px){
                        font-size: 1.4rem;
                    }
                }
            }
            img{
                height: 50px;
                max-width: 150px;
                display: inline-block;
                @media(max-width: 780px){
                    height: 40px;
                    max-width: 100px;
                }
            }
            
        }
        .inner-content{
                .group{
                    display: block;
                    padding: 2px;
                    text-align: right;
                    span, a{
                        color: blue;  
                        font-size: .7rem;
                        cursor: pointer;
                        font-weight: 500;

                        @media(max-width: 540px){
                            font-size: .6rem;
                        }
                        @media(max-width: 320px){
                            font-size: .5rem;
                        }
                    }
                }
        }
    }
`;