import styled from "styled-components";

export const FileContent = styled.div`
    flex: 0 0 80%;
    max-width: 80%;
    @media(max-width: 1280px){
        flex: 0 0 75%;
        max-width: 75%;
    }
    @media(max-width: 540px){
        flex: 0 0 70%;
        max-width: 70%;
    }
    .jc-sb{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    div.cont{
        width: 100%;
        height: 440px;
        border: 2px groove #bbb;
        /* border-style: ; */
        @media(max-width: 540px){
            height: 350px;
        }
        img{
            width: 100%;
            height: 100%;
        }
        .text-cont{
            margin: auto 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        span.text{
            padding: 10px;
            font-size: 1rem;
            font-weight: 600;
            color: #777;

            @media(max-width: 1280px){
                font-size: .9rem;
            }
            @media(max-width: 540px){
                font-size: .8rem;
            }
            @media(max-width: 420px){
                font-size: .7rem;
            }
        }
    }
    span.page{
        display: block;
        padding: 5px;
        font-size: .9rem;
        font-weight: 600;
    }
    .wrapper-btn{
        width: 100%;
    }
    .wrapper-img{
        width: 100%;
        .inner-cont{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(10%, 1fr));
            grid-gap: 1rem;
            @media(max-width: 1280px){
                grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
            }
            @media(max-width: 580px){
                grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
            }
            .img-cont.selected{
                background: var(--voskoolBagColor);
                .page{
                    color: #eee;
                }
            }
            .img-cont{
               transition: .2s ease;
               cursor: pointer;
               border-radius: 5px;
                img{
                    width: 100%;
                    height: 50px;
                    transition: .2s linear;
                }
                .page{
                    font-size: .8rem;
                }
                :hover{
                    img{
                        transform: scale(1.3);
                    }
                }
            }
        }
    }
`;

export const FileBtnContent = styled.div`
    flex: 0 0 20%;
    max-width: 20%;
    @media(max-width: 1280px){
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media(max-width: 540px){
        flex: 0 0 30%;
        max-width: 30%;
    }
    div{
        padding: 0 4px;
        text-align: center;
        margin-top: 20px;
        
        input[type=file]{
            visibility: hidden;
        }
    }
`;