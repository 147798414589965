import React, {Fragment, useState} from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";

import { NavAbsContent } from "components/school/navBar/style";

const MainNav = ({links=[], is_fixed=true, user={}, school_title="", is_join=true}) => {
    const [dispBars, setDispBars] = useState(false);

    const onClickBurger = () => {
        setDispBars(!dispBars);
    }
    const onLogout = () => {
        localStorage.removeItem('access');
        window.location.reload();
    }
    const access = localStorage.getItem('access') ? JSON.parse(localStorage.getItem('access')) : {access:'', refresh:''}
    return(
        <NavAbsContent className={is_fixed ? 'fixed bg-black' : 'relative mr-top-12px'}>
            <ul className={is_fixed ? 'app-bg-color anim-ul' : 'bg-black'}>
            <li className='d-none'>
                <span className='font-small' onClick={() => onClickBurger()}><i className={dispBars ? 'fas fa-bars font-small anim_icon' : 'fas fa-bars font-small '}></i></span>
            </li>
            {links.map((item, i) => {
                return(
                    <li key={i}  className={dispBars ? 'd-none2' : 'li-nav'}>
                        {item.dd.length > 0 ? (
                            <>
                                <span className={`font-slightly-small fw-600 ${item.is_active && 'active'}`}>{item.name} <i className={`fas fa-angle-down font-very-small`}></i></span>
                                <ul className={is_fixed ? 'fixed' : 'absolute'}>
                                {item.dd.map((item2, j) => {
                                    return(
                                        <Fragment key={j}>
                                            <li>
                                            <HashLink className={`font-slightly-small fw-600 ${item2.is_active && 'active'}`} to={item2.link}>{item2.name} {item2.icon && <i className={`${item2.icon} font-very-small`}></i>}</HashLink>
                                            </li>
                                        </Fragment>
                                    )
                                })}
                                </ul>
                            </>
                        ) : (
                            <HashLink className={`font-slightly-small fw-600 ${item.is_active && 'active'}`} to={item.link}>{item.name} {item.icon && <i className={`${item.icon} font-very-small`}></i>}</HashLink>
                        )}
                    </li>
                )
            })} 
            {!user.id ? (
                <><li className='float-right log-link' style={{display:'inline'}}><HashLink className='font-super-small' style={{display:'inline', cursor:'pointer', textDecoration:'underline', padding:'0', paddingRight:'3px', background:'none'}} to={`/login?red_url=/${school_title}`}>Login</HashLink><b className='font-super-small'>/</b><HashLink to={`/vo/signup?red_url=/${school_title}`} className='font-super-small' style={{display:'inline', padding:'0', paddingLeft:'3px', cursor:'pointer', textDecoration:'underline', background:'none'}} >Register Here</HashLink></li></>
            ) : (
                <>
                <li className="float-right user" style={{paddingRight:'8px'}}>
                    {user.profile_pic ? <img  className="user-icon" style={{width:'30px', height:'25px'}} alt="My"  src={user.profile_pic} /> : <i className="fas fa-user user-icon font-big white"></i>}
                    <div className={is_fixed ? `user-data fixed` : `user-data absolute`}>
                        <div className="main-header">
                            <div className="header">
                                <span className="heading font-super-small">{user.name}</span>
                            </div>   
                            <div className="header align-right">
                                <span className="text  font-super-small green">Online</span>
                            </div> 
                        </div>
                        {user.profile_pic && (
                            <div className="header align-center">
                                <img src={user.profile_pic} className="user-icon" style={{width:'60px', height:'60px'}} alt="Me" />
                            </div>
                        )}
                        {/* <div className="data-content">
                            <div className="header">
                                <span className="font-small">Enrolled School(s)</span>
                            </div>
                            <HashLink to={`/${school_title}`}>
                                <div className="data-flex">
                                    <div className="data-img">
                                        <img src={user_icon} alt="emife" />
                                    </div>
                                    <div className="data">
                                        <span className="heading">{school_title}</span>
                                    </div>
                                    <div className="data-icon">
                                        <i className="fas fa-arrow-right"></i>
                                    </div>
                                </div>
                            </HashLink>       
                        </div> */}
                        <div className="data-content">
                            <div className="header flex">
                                <span className="font-small">Activities</span>
                                {/* <img className="country-icon" src={user_icon} alt="Country" /> */}
                            </div>
                            <div className='data-flex-cont'>
                                <div className="data-flex">
                                    <div className="datas-img">
                                        <i className="fas fa-user"></i>
                                    </div>
                                    <div className="datas">
                                        <Link to={`https://app.voskool.com/${school_title}/dashboard?act=${access.access}&rft=${access.refresh}`} target="_blank" className="font-super-small">Start App</Link>
                                    </div>
                                </div>
                                <div className="data-flex">
                                    <div className="data">
                                        <span className="heading" onClick={() => onLogout()}>Logout</span>
                                    </div>
                                    <div className="data-icon">
                                        <i className="fas fa-power-off red font-small" style={{background:'none'}}></i>
                                    </div>
                                </div>
                                {/* <div className="data-flex">
                                    <div className="datas-img">
                                        <i className="fas fa-money-bill"></i>
                                    </div>
                                    <div className="datas">
                                        <span>Payment Record</span>
                                    </div>
                                </div>     */}
                            </div>
                        </div>
                    </div>
                </li>
                </>
            )}
            {is_join && (
                <li className='float-right'>
                    <HashLink className='font-very-small en-bg' to="#enroll">Join Us!</HashLink>
                </li>
            )}
            </ul>
        </NavAbsContent>
    )
}

export default MainNav;