import React, {useState, useEffect, useMemo, Fragment, useCallback, useRef} from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import BackDrop from "../SmallerComponents/backDrop";
import Spinner from "components/app/school/SmallerComponents/spinner";
import Box from "components/app/school/SmallerComponents/box";
import parse from "html-react-parser";
import Background from "../SmallerComponents/background";
import Notification from "components/app/school/SmallerComponents/notification";
import ImgViewer2 from 'components/app/school/SmallerComponents/imgViewer';
import Img from 'components/app/school/SmallerComponents/imgViewer/img';


import Flex, { FlexWrapper } from "../SmallerComponents/flex";
import Header from "../SmallerComponents/header";
import ChatUi, { ChatUserUI } from "../SmallerComponents/chatUI";
import MessageUI, { MessageUIWrapper } from "../SmallerComponents/messageUI";
import BtnUI, { BtnUIWrapper } from "../SmallerComponents/btnUI";
// import BtnAbsUI, { BtnAbsUIBtn, BtnAbsUIWrapper } from "../SmallerComponents/btnAbsUI";
import ProfileUI, { ProfileUIImage, ProfileUIWrapper, ProfileUIWrapper2 } from "../SmallerComponents/profileUI";
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";
import {Wrapper} from 'components/notFound/style.js';

import voskool_logo from 'images/voskool_logo.png';

import PreloaderLine from "components/preloaderLine";

import { chatServices } from "api/chatApp/services";
import { BACKEND_URL } from "actions/url";

import {processNavigation, setParameter} from 'components/app/school/actions/url';
import { addItemToArray } from "components/app/school/actions/array-utils/Add";
import {updateArray} from 'components/app/school/actions/array-utils/Update';
import { groupArray } from "components/app/school/actions/array-utils/Group";
// import {  filterArrayByArray2 } from "components/app/school/actions/array-utils/Filter";
import { convertDate } from "components/app/school/SmallerComponents/action/dateConverter";
import { loopDateTime } from "components/app/school/actions/array-utils/dates";
import { spreadArrayByDatetimeDay } from "components/app/school/actions/array-utils/Spread";
import { timify } from "components/app/school/actions/timify";
import PageDownBtn from "../SmallerComponents/pageDownBtn";
import ImgViewer, { ImgViewerWrapper } from "../SmallerComponents/imageViewer";

const Chat = ({user_id, user_type, user={}, school_id, school}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);
    const initialMsgState = useMemo(() => ({va:null, sender:null, friend:null, is_loading:true, is_sent:false, is_error:false, img_files:[]}), []);

    const [error, setError] = useState(initialErrorState);
    const [success] = useState(initialSuccessState);
    const [warning] = useState(initialSuccessState);

    const [accountState, setAccountState] = useState({id:0})
    // const [currPageState, setCurrPageState] = useState({page:1, max_id:0});
    const [currPageFriendState, setCurrPageFriendState] = useState({page:0, max_id:0});
    const [searchNewFriend, setSearchNewFriend] = useState({search:'', p:1, size:100});
    const [imgViewer, setImgViewer] = useState({});

    const [allMessagePaged, setAllMessagePaged] = useState(initialPaginationState);
    const [allMessages, setAllMessages] = useState([]);
    const [allFriendMessages, setAllFriendMessages] = useState([]);
    const [allFriendMessagePaged, setAllFriendMessagePaged] = useState(initialPaginationState);
    const [allInitialFriendMessages, setAllInitialFriendMessages] = useState([]);
    const [allInitialMessages, setAllInitialMessages] = useState([]);
    const [allPreFriendMessage, setAllPreFriendMessage] = useState([]);
    const [allPreMessage, setAllPreMessage] = useState([]);
    const [allSearchFriend, setAllSearchFriend] = useState([]);
    const [allFriendRequest, setAllFriendRequest] = useState([]);
    const [allFriends, setAllFriends] = useState([]);
    const [btnImageFiles, setBtnImageFiles] = useState([]);

    const [nameVal, setNameVal] = useState('');
    const [navigate, setNavigate] = useState('');
    const [msg, setMsg] = useState('');
    const [chatType, setChatType] = useState('');
    const [totUnseenMsg, setTotUnseenMsg] = useState(0);
    

    const [preloading, setPreloading] = useState(true);
    const [preLoadingMsg, setPreLoadingMsg] = useState(false);
    const [preloadingFriendMsg, setPreloadingFriendMsg] = useState(true);
    const [loadingFriendMsg, setLoadingFriendMsg] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isTypingToFriend, setIsTypingToFriend] = useState(false);
    const [isClickChatBtn, setIsClickChatBtn] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState(false);
    const [loadingFriends, setLoadingFriends] = useState(false);
    const [loadingSearchFriend, setLoadingSearchFriend] = useState(false);
    const [isSearchNewFriend, setIsSearchNewFriend] = useState(false);
    const [openImgViewer, setOpenImgViewer] = useState(false);
    const [reload, setReload] = useState(true);

    const [isConnectOnlineAssistant, setIsConnectOnlineAssistant] = useState(false);
    const [isConnectedOnlineAssistant, setIsConnectedOnlineAssistant] = useState(false);
    const [isSetupAccount, setIsSetupAccount] = useState(false);
    const [isInitChat, setIsInitChat] = useState(false);
    const [isFetchMessageVa, setIsFetchMessageVa] = useState(true);
    const [isFetchMessageFriend, setIsFetchMessageFriend] = useState(true);
    const [isScrollDownToLastMessage, setIsScrollDownToLastMessage] = useState(true);
    const [isScrollDownBtn, setIsScrollDownBtn] = useState(false);

    const [searchParam] = useSearchParams();
    const nav = useNavigate();

    const loadMoreButtonRef = useRef(null);
    const messageEndRef = useRef(null);
    const messageEndRefMobile = useRef(null);

    const fetchAccount = (id, user={}) => {
        setError({title:'', data:[]})
        setPreloading(true);
        chatServices.getAccount(id).then(res => {
            setAccountState(res.data);
            setPreloading(false);
            setIsInitChat(true);
            if(res.data.is_link && res.data.is_assistant_online > new Date().getTime()){
                setIsConnectedOnlineAssistant(true);
            }
        }).catch(e => {
            setPreloading(false);
            if (e.response.status === 400){
                setIsSetupAccount(true);
                const name = `${user.last_name.toLowerCase()}${user.first_name.toLowerCase()}`
                setNameVal(name.length > 20 ? user.first_name : name);
            }
            
        })
    }
    const fetchAllSearchFriends = (name="") => {
        if(name){
            setLoadingSearchFriend(true);
            chatServices.searchFriends(name).then((res) => {
                setAllSearchFriend(addItemToArray(res.data, {is_loading:false}));
                setLoadingSearchFriend(false);
            }).catch(e => {
                setAllSearchFriend([]);
                setLoadingSearchFriend(false);
            })
            return;
        }
        setLoadingSearchFriend(false);
        // setAllSearchFriend([]);
    }
    const fetchAllFriendReqs = () => {
        chatServices.getAllFriendReqs().then(res => {
            setAllFriendRequest(addItemToArray(res.data, {is_loading:false}));
        }).catch(e => {
            
        })
    }
    const fetchAllFriend = useCallback((pre=false) => {
        setLoadingFriends(pre);
        chatServices.getAllFriends().then(res => {
            setLoadingFriends(false);
            setAllFriends(addItemToArray(res.data));
            if(searchParam.get('msger') && searchParam.get('msger') !== "virtual_assistant"){
                const filter = res.data.filter((it) => it.uid === searchParam.get('msger'));
                if(filter.length === 1){
                    const item = filter[0];
                    const tot_unseen_msg = item.by_friend === accountState.id ? item.tot_unseen_msg2 : item.tot_unseen_msg1;
                    if(tot_unseen_msg > 0){
                        setTotUnseenMsg(tot_unseen_msg);
                        setIsScrollDownBtn(true);
                    }   
                }
            }
        }).catch(e => {
            setLoadingFriends(false);
        })
    }, [searchParam, accountState.id])
    const fetchAccountIntval = (id) => {
        chatServices.getAccount(id).then(res => {
            setAccountState(res.data);
        }).catch(() => {
        })
    }
    const fetchAllMessagesVa = (id, pre=true, start_id='', p=1, size=100) => {
        setPreLoadingMsg(pre);
        setLoadingMsg(!pre);
        chatServices.getAllMessagesVa(id, p, size, start_id).then(res => {
            setPreLoadingMsg(false);
            setLoadingMsg(false);
            setAllMessagePaged(res.data);
            if(p > 1){
                setAllMessages((prev) => {return groupArray([...prev, ...res.data.results], 'id')});
            } else{
                const results = res.data.results;
                setAllInitialMessages(results);
                // if(results.length > 0){
                    
                //     setCurrPageState((prev) => { return {...prev, max_id:results[results.length-1].id}})
                // }
            }
        }).catch(e => {
            setPreLoadingMsg(false);
            setLoadingMsg(false);
        })
    }
    const fetchAllMessagesFriend = (id, pre=true, start_id='', p=1, pf=0, size=100) => {
        setPreloadingFriendMsg(pre);
        setLoadingFriendMsg(!pre);
        chatServices.getAllFriendMessages(id, pf > 0 ? pf : p, size, start_id).then(res => {
            setPreloadingFriendMsg(false);
            setLoadingFriendMsg(false);
            setAllFriendMessagePaged(res.data);
            // setIsScrollDownToLastMessage(false);
            if(pf > 0){
                setAllFriendMessages((prev) => {return groupArray([...prev, ...res.data.results], 'id')});
                setCurrPageFriendState((prev) => { return {...prev, page:pf}});
            } else{
                const results = res.data.results;
                setAllInitialFriendMessages(results);
                if(results.length > 0){
                    
                    setCurrPageFriendState((prev) => { return {...prev, page:pf, max_id:results[results.length-1].id}})
                }
            }
        }).catch(e => {
            setPreLoadingMsg(false);
            setLoadingMsg(false);
        })
    }
    const fetchMsg100 = (id) => {
        chatServices.getAllMessagesVa(id, 1, 100, '', true).then(res => {
            if(res.data.results.length > 0){
                setAllInitialMessages((prev) => {return  groupArray([...res.data.results, ...prev], 'id')});
            }
        }).catch(() => {

        })
    }
    const fetchFriendMsg100 = (id) => {
        chatServices.getAllFriendMessages(id, 1, 100, '', true).then(res => {
            if(res.data.results.length > 0){
                setAllInitialFriendMessages((prev) => {return  groupArray([...res.data.results, ...prev], 'id')});
            }
        }).catch(() => {

        })
    }

    const onChange = (e, type="") => {
        setMsg(e.target.value);
        setIsTyping(false);
        setIsTypingToFriend(false);
        if(type === "friend"){
            setIsTypingToFriend(true);
            return;
        }
        if(isConnectedOnlineAssistant){
            setIsTyping(true);
        }
    }
    // const onChangeSearchNewFriend = (e) => {
    //     setSearchNewFriend((prev) => { return {...prev, search:[e.target.value]}});
    //     if(!e.target.value){
    //         setAllSearchFriend([]);
    //     }
    //     setIsSearchNewFriend(true);
    //     setLoadingSearchFriend(true);
    // }
    const onChangeBtnImageFile = e => {
        setError(initialErrorState);
        setTimeout(() => {
            if(!e.target.value){
                setBtnImageFiles([]);
                return;
            }
            const file = e.target.files[0];
            const ext = ['.png', 'jpeg', '.jpg'];
            const file_ext = file.name.slice(-4);
            if(!ext.includes(file_ext)){
                setBtnImageFiles([]);
                setError({title:'File Error', data:['File must be an image']});
                return;
            }
            setBtnImageFiles([{file:file, img:URL.createObjectURL(file), value:e.target.value}]);
        })
        
    }
    ///[^a-zA-Z0-9\s]/

    const onRegisterAcct = e => {
        e.preventDefault();
        setError(initialErrorState);
        const check = /[^a-zA-Z0-9]/
        if(nameVal.length > 4 && !check.test(nameVal)){
            setPreloading(true);
            const data = {school:school_id, user:user_id, username:nameVal.toLowerCase()}
            chatServices.createAccount(data).then(res => {
                setReload(true);
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong'], title:'Account Creation Error'});
                setPreloading(false);
            })
            return;
        }
        setError({data:['error: Invalid Name'], title:'Form Error'});
    }
    const updateNeedVa = (id) => {
        chatServices.updateAccount(id, {is_need_va:new Date().getTime() + (1000 * 60 * 4)}).then(res => {
            setAccountState((prev) => {return {...prev, ...res.data}});
            if(res.data.is_link && res.data.is_assistant_online > new Date().getTime()){
                setIsConnectedOnlineAssistant(true);
            }
        }).catch(e => {

        })
    }
    const onSendMsg = () => {
        if(msg || allPreMessage.length > 0){
            let data = {...initialMsgState, message:msg, num:allPreMessage.length}
            let pre_msg = [...allPreMessage, data];
            setAllPreMessage(pre_msg);
            if(pre_msg.filter((it => it.is_error)).length > 0){
                setMsg('');
                pre_msg.forEach((it) => {
                    setTimeout(() => {
                        const dt = isConnectedOnlineAssistant ? {va:accountState.va, sender:accountState.id, message:it.message} : {sender:accountState.id, message:it.message, is_send_by_bot:true}
                        chatServices.createMessageVa(dt).then(res => {
                            setAllPreMessage(allPreMessage.filter((item) => item.num !== it.num));
                            setAllInitialMessages((prev) => { return groupArray([res.data, ...prev], 'id').sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())})
                        }).catch(() => {

                        })
                    }, 100)
                })
            }
            if(msg){
                setMsg('');
                const dt = isConnectedOnlineAssistant ? {va:accountState.va, sender:accountState.id, message:data.message} : {sender:accountState.id, message:data.message, is_send_by_bot:true}
                chatServices.createMessageVa(dt).then(res => {
                    setAllPreMessage(allPreMessage.filter((item) => item.num !== data.num));
                    chatServices.updateAccountVa(accountState.va_uid, {is_typing1:new Date().getTime() - (1000 * 7) }).then(res => {
                        setAccountState((prev) => { return {...prev, is_typing1:new Date().getTime() - (1000 * 7)}});
                    })
                    setAllInitialMessages((prev) => { return groupArray([res.data, ...prev], 'id').sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())})
                }).catch(() => {
                    setAllPreMessage(updateArray(allPreMessage, data.num, {...data, is_error:true}));
                })
            }
        }
    }
    // const onSentMsgImage = (data=[]) => {

    // }
    const onSendFriendMsg = (id) => {
        if(msg || allPreFriendMessage.length > 0){
            let data = {...initialMsgState, message:msg, num:allPreFriendMessage.length, img_files:btnImageFiles}
            let pre_msg = [...allPreFriendMessage, data];
            setAllPreFriendMessage(pre_msg);
            setBtnImageFiles([]);
            if(pre_msg.filter((it => it.is_error)).length > 0){
                setMsg('');
                pre_msg.forEach((it) => {
                    setTimeout(() => {
                        const dt = {friend:id, sender:accountState.id, message:it.message}
                        chatServices.createMessage(dt).then(res => {
                            const result = res.data;
                            if(data.img_files.length > 0){
                                data.img_files.forEach((item, i, arr) => {
                                    setTimeout(() => {
                                        const formData = new FormData();
                                        formData.append('msg', result.id)
                                        formData.append('pic', item.file, item.file.name)
                                        chatServices.createImageMessage(formData).then(img_res => {
                                            if(arr.length-1 === i){
                                                setAllPreFriendMessage(allPreFriendMessage.filter((it_) => it_.num !== it.num));
                                            }
                                        })
                                    }, 100);
                                })
                                return;
                            }
                            setAllPreFriendMessage(allPreFriendMessage.filter((it_) => it_.num !== it.num));
                            setAllInitialFriendMessages((prev) => { return groupArray([res.data, ...prev], 'id').sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())})
                        }).catch(() => {

                        })
                    }, 100)
                })
            }
            if(msg){
                setMsg('');
                const filter = allFriends.filter((it) => it.id === id);
                const dt =  {friend:id, sender:accountState.id, message:data.message};
                chatServices.createMessage(dt).then(res => {
                    const result = res.data;
                    if(data.img_files.length > 0){
                        data.img_files.forEach((it, i, arr) => {
                            setTimeout(() => {
                                const formData = new FormData();
                                formData.append('msg', result.id)
                                formData.append('pic', it.file, it.file.name)
                                chatServices.createImageMessage(formData).then(img_res => {
                                    if(arr.length-1 === i){
                                        setAllPreFriendMessage(allPreFriendMessage.filter((item) => item.num !== data.num));
                                        chatServices.updateAccountFriend(filter[0].uid, {is_typing:new Date().getTime() - (1000 * 7) }).then(res => {
                                            setAllFriends((prev) => { return updateArray(prev, filter[0].num, {...prev[filter[0].num], is_typing1:res.data.is_typing1, is_typing2:res.data.is_typing2})});
                                            fetchFriendMsg100(filter[0].uid);
                                        })
                                        setAllInitialFriendMessages((prev) => { return groupArray([res.data, ...prev], 'id').sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())})
                                    }
                                }).catch(e => {
                                    setAllPreFriendMessage(allPreFriendMessage.filter((item) => item.num !== data.num));
                                })
                            }, 100)
                        })
                        return;
                    }
                    chatServices.updateAccountFriend(filter[0].uid, {is_typing:new Date().getTime() - (1000 * 7) }).then(res => {
                        setAllFriends((prev) => { return updateArray(prev, filter[0].num, {...prev[filter[0].num], is_typing1:res.data.is_typing1, is_typing2:res.data.is_typing2})});
                        fetchFriendMsg100(filter[0].uid);
                    });
                    setAllPreFriendMessage(allPreFriendMessage.filter((item) => item.num !== data.num));
                    setAllInitialFriendMessages((prev) => { return groupArray([res.data, ...prev], 'id').sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())})
                }).catch(() => {
                    setAllPreFriendMessage(updateArray(allPreFriendMessage, data.num, {...data, is_error:true}));
                })
            }
        }
    }
    // const onClickChatAdd = (type="") => {
    //     setChatType(type);
    //     setIsClickChatBtn(true);
    // }
    // const onClickAddFriend = (id) => {
    //     const filter = allSearchFriend.filter((it) => it.uid === id)
    //     if(filter.length > 0){
    //         const item = filter[0];
    //         chatServices.createFriendRequest({by:accountState.id, to:item.id}).then(res => {
    //             fetchAllFriendReqs();
    //         }).catch(e => {
                
    //         })
    //     }
    // }
    const OnClickProcessFriendReq = (type="", id) => {
        const filter = allFriendRequest.filter((it) => it.uid === id);
        if(type && filter.length > 0){
            const item = filter[0];
            const data = {accepted: new Date(), request:item.id};
            setAllFriendRequest((prev) => { return updateArray(prev, item.num, {...item, is_loading:true})})
            chatServices.processFriendRequest(id, type === "accept" ? 'accept' : 'reject', data).then(res => {
                fetchAllFriendReqs();
                fetchAllFriend(false);
            }).catch(e => {

            })
            return;
        }
    }
    const onClickChatNavigate = (name="", value="") => {
        if(value === "virtual_assistant" && name === "msger"){
            setNavigate(setParameter(name, value));
            return;
        }
        if(name === "msger"){
            setNavigate(setParameter(name, value));
            setAllFriendMessagePaged(initialPaginationState);
            setAllInitialFriendMessages([]);
            setAllFriendMessages([]);
            setIsFetchMessageFriend(true);
            setIsScrollDownToLastMessage(true);
            setTotUnseenMsg(0);
            return;
        }
    }
    const scrollToBottom = () => {
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    const scrollToBottomMobile = () => {
        messageEndRefMobile.current?.scrollIntoView({ behavior: "smooth" });
    }
    const handleScrollLoadMoreFriendMessages = useCallback((e) => {
        const filter = allFriends.filter((it) => it.uid === searchParam.get('msger'))
        const scrollY = e.target.scrollTop + 550;
        const scrollHeight = e.target.scrollHeight;
        const scrollHeightBy2 = parseInt(scrollHeight / 2);
        if(scrollY >= scrollHeight){
            setTotUnseenMsg(0);
            setIsScrollDownBtn(false);
        }
        if(scrollY < scrollHeightBy2 && !isScrollDownBtn){
            setIsScrollDownBtn(true);
        }
        if(allFriendMessagePaged.next && !isScrollDownToLastMessage){
            const item = filter[0];
            const ref = e.target.scrollTop < 600 ? e.target.scrollTop : 1000;
            if(parseInt(ref) < 600 && !loadingFriendMsg){
                fetchAllMessagesFriend(item.uid, false, currPageFriendState.max_id, 1, currPageFriendState.page+1);
            }
        }
    }, [loadingFriendMsg, allFriends, allFriendMessagePaged.next, searchParam, currPageFriendState, isScrollDownToLastMessage, isScrollDownBtn])


    useEffect(() => {
        if(reload &&  user.id){
            fetchAccount(user.id, user);
            fetchAllFriendReqs();
            fetchAllFriend(true);
            setReload(false); 
        }
        if(accountState.id && isFetchMessageVa){
            fetchAllMessagesVa(accountState.uid);
            setIsFetchMessageVa(false);
        }
    }, [reload,  user, accountState, isFetchMessageVa, fetchAllFriend]);

    useEffect(() => {
        if(isClickChatBtn){
            setNavigate(setParameter('tpe', chatType));
            setChatType('');
            setIsClickChatBtn(false);
        }
    }, [isClickChatBtn, chatType])

    useEffect(() => {
        if(searchParam.get('msger')  && searchParam.get('msger') !== "virtual_assistant"){
            setIsFetchMessageFriend(true);
            setMsg('');
            return;
        }
        if(searchParam.get('msger') === "virtual_assistant"){
            setMsg('');
            return;
        }
    }, [searchParam])

    useEffect(() => {
        if(isScrollDownToLastMessage && allInitialFriendMessages.length > 0){
            scrollToBottom();
            scrollToBottomMobile();
            setTimeout(() => {
                setIsScrollDownToLastMessage(false);
                setTotUnseenMsg(0);
                setIsScrollDownBtn(false);
            }, 1000);
            
        }
    }, [allInitialFriendMessages, isScrollDownToLastMessage])

    useEffect(() => {
        if(isConnectOnlineAssistant && !isConnectedOnlineAssistant && searchParam.get('msger') === "virtual_assistant"){
            if(accountState.is_need_va < new Date().getTime()){
                updateNeedVa(accountState.uid);
                return;
            }
            const interval = setInterval(() => {
                updateNeedVa(accountState.uid);
            }, 1000*30);
            return () => {
                clearInterval(interval);
            }
        }
        if(isConnectedOnlineAssistant && searchParam.get('msger') === "virtual_assistant"){
            const interval = setInterval(() => {
                fetchAccountIntval(user.id);
                fetchMsg100(accountState.uid);
                fetchAllFriend(false);
            }, 1000*8)
            return () => {
                clearInterval(interval);
            }
        }
    }, [isConnectOnlineAssistant, user, isConnectedOnlineAssistant, accountState, searchParam, fetchAllFriend])

    useEffect(() => {
        const filter = allFriends.filter((it) => it.uid === searchParam.get('msger'));
        if(filter.length === 1 && isFetchMessageFriend && searchParam.get('msger')){
            const item = filter[0];
            fetchAllMessagesFriend(item.uid, true);
            setIsFetchMessageFriend(false);
            setAllInitialFriendMessages([]);
            setAllFriendMessages([]);
            setIsScrollDownToLastMessage(true);
            setTotUnseenMsg(0);
            setIsScrollDownBtn(false);
            return;
        }
    }, [allFriends, isFetchMessageFriend, searchParam])
    useEffect(() => {
        const filter = allFriends.filter((it) => it.uid === searchParam.get('msger'));
        
        if(filter.length === 1 && searchParam.get('msger')){
            const item = filter[0];
            const interval = setInterval(() => {
                fetchFriendMsg100(item.uid);
                fetchAllFriend(false);
            }, 1000*5)
            return () => {
                clearInterval(interval);
            }
        }
    }, [allFriends, searchParam, accountState, isFetchMessageFriend, allInitialFriendMessages, fetchAllFriend]);

    useEffect(() => {
        if(isTyping){
            setTimeout(() => {
                chatServices.updateAccountVa(accountState.va_uid, {is_typing1:new Date().getTime() + (1000 * 7) }).then(res => {
                    setAccountState((prev) => { return {...prev, is_typing1:new Date().getTime() + (1000 * 7)}});
                })
                setIsTyping(false);
            }, 1000)
        }
    }, [isTyping, accountState])

    useEffect(() => {
        const filter = allFriends.filter((it) => it.uid === searchParam.get('msger')); 
        if(isTypingToFriend && filter.length === 1){
            setTimeout(() => {
                chatServices.updateAccountFriend(filter[0].uid, {is_typing:new Date().getTime() + (1000 * 7) }).then(res => {
                    setAllFriends((prev) => { return updateArray(prev, filter[0].num, {...prev[filter[0].num], is_typing1:res.data.is_typing1, is_typing2:res.data.is_typing2})});
                })
                setIsTypingToFriend(false);
            }, 1000)
        }
    }, [isTypingToFriend, allFriends, searchParam])

    useEffect(() => {
        if(isSearchNewFriend){
            if(searchNewFriend.search){
                const timeout = setTimeout(() => {
                    setSearchNewFriend(false);
                    fetchAllSearchFriends(searchNewFriend.search);
                }, 1000);
                return () => {
                    clearTimeout(timeout);
                }
            }
            setAllSearchFriend([]);
            setLoadingSearchFriend(false);
        }
    }, [isSearchNewFriend, searchNewFriend]);

    useEffect(() => {
        if(accountState.id){
            const interval = setInterval(() => {
                fetchAllFriendReqs();
                fetchAllFriend();
            }, 1000 * 25);
    
            return () => {
                clearInterval(interval);
            }
        }
    }, [accountState, fetchAllFriend])

    useEffect(() => {
        if(navigate){
            setNavigate('');
            nav(navigate);
        }
    }, [navigate, nav]);

    // useEffect(() => {
    //     if(allFriendMessagePaged.next){
    //         window.addEventListener('scroll', handleScrollLoadMoreFriendMessages);
            
    //         return () => {
    //             window.removeEventListener('scroll', handleScrollLoadMoreFriendMessages);
    //         }
    //     }
    // }, [allFriendMessagePaged, handleScrollLoadMoreFriendMessages]);



    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })
    const btn_pallete = !isConnectedOnlineAssistant ? (!isConnectOnlineAssistant ? [{name:'Connect to an online assistant', onClick:() => {setIsConnectOnlineAssistant(true)}}] : []) : [];
    const all_msg = groupArray([...allInitialMessages, ...allMessages], 'id').sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    const all_friend_msg = groupArray([...allFriendMessages, ...allInitialFriendMessages], 'id').sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    //const filter_search_friend = filterArrayByArray2(allSearchFriend, allFriendRequest, 'id', 'by', 'to');
    const filter_pending_friend = allFriendRequest.length > 0 ? allFriendRequest.filter((it) => !it.is_accept) : [];
    const filter_friend_search_param = searchParam.get('msger') ? allFriends.filter((it) => it.uid === searchParam.get('msger')) : [];
    const processChatBT = (by, to, check) => {
        if(check === by){
            return 't';
        }
        if(check === to){
            return 'b';
        }
        return '';
    }
    const msgFunc = (arr=[]) => {
        if(arr.length > 0){
            const date_diff = new Date(arr[arr.length-1].created).getTime() - new Date(arr[0].created).getTime()
            const date_days = Math.floor(date_diff / (1000 * 60 * 60 * 24))+2;
            const loop_date = date_days>0 ?  loopDateTime(new Date(arr[0].created).getTime(), 'day', date_days, false) : [new Date(arr[0].created).getTime()];
            const spread = spreadArrayByDatetimeDay(arr, loop_date, 'created');
            return spread;
        }
        return [];
    }
    const insert_msger = () => {
        if(searchParam.get('tpe')  === "view_friend" && searchParam.get('msger')){
            return(
                <Flex classname="flex-mobile app-theme">
                    <Header outer_classname="min-h-30px app-theme shadow" is_fixed>
                        <div className="disp-flex wrap jc-sb">
                            <div className="disp-flex wrap jc-stretch">
                               
                                <div className="disp-block">
                                <span className="fas fa-arrow-left font-slightly-small app-text-color"  style={{marginTop:'8px', marginRight:'10px'}} onClick={() => setNavigate(-1)}></span>
                                </div>
                               
                                <div className="disp-block" style={{marginTop:'4px', paddingLeft:'8px'}}>
                                    <span className="font-slightly-small fw-600 app-text-color text-capitalize" style={{ letterSpacing:'.4px'}}>View Profile</span>
                                </div>
                                
                            </div>
                          
                            
                        </div>
                        
                    </Header>
                    {searchParam.get('msger') ? (
                        <>
                        {searchParam.get('msger') === "virtual_assistant" && (
                            <ProfileUIWrapper>
                                <ProfileUIImage src={voskool_logo} title={isConnectedOnlineAssistant ? `${accountState.assistant_full_name}` : 'VOMA (Virtual Assistant)'} />
                                {/* <ProfileUIWrapper2>
                                    <ProfileUI classname="red" icon="fas fa-user-minus" title="Remove Friend" />
                                </ProfileUIWrapper2> */}
                            </ProfileUIWrapper>
                        )}
                        {filter_friend_search_param.length === 1 && (
                            <>
                            {filter_friend_search_param.map((item, i) => {
                                const username = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_username`];
                                const fullname = `${item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_last_name`]} ${item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_first_name`]}`;
                                const about = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_about`];
                                const profile_pic = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_profile_pic`];
                                // const is_online = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_is_online`];
                                // const is_typing = item.by_friend === accountState.id ? item.is_typing2 : item.is_typing1;
                                return(
                                    <Fragment key={i}>
                                        <ProfileUIWrapper>
                                        <ProfileUIImage src={`${BACKEND_URL}${profile_pic}`} is_chat onClickChat={() => setNavigate(-1)} title={fullname} title2={`~${username}`} desc={about ? about : 'Hey there, am using VoChat'} />
                                        <ProfileUIWrapper2>
                                            <ProfileUI classname="red" icon="fas fa-user-minus" title="Remove Friend" />
                                        </ProfileUIWrapper2>
                                    </ProfileUIWrapper>
                                    </Fragment>
                                )
                            })}
                            </>
                        )}
                        </>
                    ) : (
                        <Wrapper>
                            <h5 style={{color:'#666'}}>Profile...</h5>
                        </Wrapper>
                    )}
                    
                </Flex>
            )
        }
        if(searchParam.get('msger')){
            return(
                <Flex classname="flex-mobile" >
                    {searchParam.get('msger') ? (
                            <>
                            {searchParam.get('msger') === "virtual_assistant" && (
                                <>
                                <Header outer_classname="min-h-30px app-theme shadow" is_fixed>
                                    <div className="disp-flex wrap jc-sb">
                                        <div className="disp-flex wrap jc-stretch">
                                            {/* <span className="font-med fw-700 text-upper" style={{color:'#888'}}>EMIFE </span> */}
                                            <div className="disp-block">
                                                <span className="fas fa-arrow-left font-slightly-small app-text-color"  style={{marginTop:'8px', marginRight:'10px'}} onClick={() => setNavigate(-1)}></span>
                                            </div>
                                            <div className="disp-block img-cont">
                                                <img className="img" src={voskool_logo} alt="Assistant" />
                                            </div>
                                            <div className="disp-block" style={{marginTop:'8px', paddingLeft:'8px'}}>
                                                <span className="font-slightly-small fw-600 app-text-color text-capitalize" style={{color:'var(--appBgColor)', letterSpacing:'.4px'}}>{isConnectedOnlineAssistant ? accountState.assistant_full_name : 'VOMA (Virtual Assistant)'}</span>
                                            </div>
                                            <div className="disp-block stat-cont" style={{marginTop:'12px', paddingLeft:'8px'}}>
                                                <span className={`circle online`}></span>
                                            </div>
                                        </div>
                                        {/* <div className="disp-block">
                                            <div className="disp-flex wrap jc-stretch">
                                                <div className="disp-block" style={{marginTop:'8px'}}>
                                                    <i className="fas fa-angle-right font-med" style={{color:'#666'}}></i>
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                    </div>
                                    
                                </Header>
                                <MessageUIWrapper outer_classname="app-body-color">
                                    
                                    <MessageUI img_src={voskool_logo} is_send={false} bg_color="app-bg-color" text="Hey dear, my name is VOMA, what would you like me to do today?" btn_pallete={btn_pallete} />
                                    {isConnectedOnlineAssistant ? (
                                        <>
                                        {loadingMsg && <Spinner />}
                                        <MessageUI img_src={voskool_logo} is_send={false} bg_color="app-bg-color" text="Connected Successfully ..." />
                                        <MessageUI img_src={voskool_logo} is_send={false} bg_color="app-bg-color" text={`${accountState.assistant_full_name.toUpperCase()} will take it from here :)`} />
                                        {preLoadingMsg && <Spinner />}
                                        {loadingMsg && <Spinner />}
                                        
                                        {msgFunc(all_msg).map((it, i) => {
                                           
                                            return(
                                                <Fragment key={i}>
                                                    <div className="header disp-block align-center" style={{marginTop:'10px', marginBottom:'10px'}}>
                                                        <span className="font-super-small text-capitalize fw-600" style={{color:'#666'}}>{convertDate(it.time, 'ddMMyyyy')}</span>
                                                    </div>
                                                    {it.arr.map((item, j) => {
                                                        const img = item.sender ? `${BACKEND_URL}${accountState.profile_pic}` : voskool_logo
                                                        return(
                                                            <Fragment key={j}>
                                                                <MessageUI img_src={img} is_send={item.sender === accountState.id ? true : false} bg_color="app-bg-color" text={item.message} is_seen={item.is_seen}  time={timify(convertDate(item.created, 'hh:mm:ss'))}/>
                                                            </Fragment>
                                                        )
                                                    })}
                                                    
                                                </Fragment>
                                            )
                                        })}
                                        {allPreMessage.map((item, i) => {
                                            return(
                                                <Fragment key={i}>
                                                    <MessageUI img_src={`${BACKEND_URL}${accountState.profile_pic}`} is_send={true} bg_color="app-bg-color" text={parse(item.message)} is_loading={item.is_error ? false : true} is_error={item.is_error}  />
                                                </Fragment>
                                            )
                                        })}
                                        {accountState.is_typing2 > new Date().getTime() && (
                                            <MessageUI img_src={`${voskool_logo}`} is_send={false} bg_color="app-bg-color" is_typing={true}   />
                                        )}
                                        </>
                                    ) : (
                                        <>
                                        {isConnectOnlineAssistant && (
                                            <>
                                            <MessageUI img_src={voskool_logo} is_send={false} bg_color="app-bg-color" text="Please wait, while i connect you to an online assistant" is_loading_text />
                                            </>
                                        )}
                                        </>
                                    )}
                                    
                                    
                                    {/* <MessageUI img_src={voskool_logo} is_send={true} bg_color="app-bg-color" text="asdasd asdaysdiasda aisdvasu dadfad ioado ago gaod goa dguoagdasdoasdov asdyo vasodauy" />
                                    <MessageUI img_src={`${BACKEND_URL}${accountState.profile_pic}`} is_send={false} bg_color="app-bg-color" text="asdasd asdaysdiasd" />
                                    <MessageUI is_send={true} bg_color="app-bg-color" text="asdasd asdaysdiasd" />
                                    <MessageUI is_send={false} bg_color="app-bg-color" text="asdasd asdaysdiasd" /> */}
                                
                                </MessageUIWrapper>
                                <BtnUIWrapper outer_classname="min-h-15vh app-bg-color shadow b-r-b-l-20px b-r-b-r-20px y-10px">
                                    <BtnUI image_btn={false} value={msg} onChange={e => onChange(e)} onSubmit={() => onSendMsg()} />
                                </BtnUIWrapper>
                                </>
                            )}
                            {filter_friend_search_param.length === 1 && (
                                <>
                                {filter_friend_search_param.map((item, i) => {
                                    const username = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_username`];
                                    // const about = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_about`];
                                    const profile_pic = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_profile_pic`];
                                    const is_online = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_is_online`];
                                    const is_typing = item.by_friend === accountState.id ? item.is_typing2 : item.is_typing1;
                                    return(
                                        <Fragment key={i}>
                                            <Header outer_classname="min-h-30px app-theme shadow" is_fixed>
                                                <div className="disp-flex wrap jc-sb">
                                                    <div className="disp-flex wrap jc-stretch">
                                                        {/* <span className="font-med fw-700 text-upper" style={{color:'#888'}}>EMIFE </span> */}
                                                        <div className="disp-block" style={{marginTop:'8px', marginRight:'10px'}}>
                                                            <span className="fas fa-arrow-left font-small app-text-color" onClick={() => setNavigate(-1)}></span>
                                                        </div>
                                                        <div className="disp-block img-cont">
                                                            <img className="img" src={`${BACKEND_URL}${profile_pic}`} alt="Assistant" />
                                                        </div>
                                                        <div className="disp-block" style={{marginTop:'8px', paddingLeft:'8px'}}>
                                                            <span className="font-slightly-small fw-600 app-text-color text-capitalize" onClick={() => setNavigate(setParameter('tpe', 'view_friend'))} style={{color:'var(--appBgColor)', letterSpacing:'.4px'}}>{username}</span>
                                                        </div>
                                                        <div className="disp-block stat-cont" style={{marginTop:'12px', paddingLeft:'8px'}}>
                                                            <span className={`circle ${is_online > new Date().getTime() ? 'online' : 'offline'}`}></span>
                                                        </div>
                                                    </div>         
                                                </div>
                                                
                                            </Header>
                                            {isScrollDownBtn && (
                                                <PageDownBtn number={totUnseenMsg} onClick={() => setIsScrollDownToLastMessage(true)} />
                                            )}
                                            {btnImageFiles.length === 1 && (
                                                <>
                                                <ImgViewerWrapper>
                                                {btnImageFiles.map((img, j) => {
                                                    return (
                                                        <Fragment key={j}>
                                                            <ImgViewer src={img.img} alt={img.value} onClickClose={() => setBtnImageFiles([])} />
                                                        </Fragment>
                                                    )
                                                })}
                                                </ImgViewerWrapper>
                                                </>
                                            )}

                                                
                                            
                                            <MessageUIWrapper outer_classname="app-body-color" ref={loadMoreButtonRef} onScroll={e => handleScrollLoadMoreFriendMessages(e)}>
                                                
                                                    {loadingFriendMsg && <Spinner />}
                                                    {allFriendMessagePaged.next && (
                                                        <div className='disp-block align-center' >
                                                            <button type="button" onClick={() => fetchAllMessagesFriend(item.uid, false, currPageFriendState.max_id, 1, currPageFriendState.page+1)} className='btn app-bg-color hover-opacity app-bg-text-color br-circle'  style={{padding:'5px'}}><i className='fas fa-arrow-up'></i></button>
                                                        </div>
                                                    )}
                                                    {preloadingFriendMsg && <Spinner />}
                                                    
                                                    
                                                    {all_friend_msg.length > 0 ? (
                                                        <>
                                                        {msgFunc(all_friend_msg).map((it, j) => {
                                                            
                                                            return(
                                                                <Fragment key={j}>
                                                                    <div className="header disp-block align-center" style={{marginTop:'10px', marginBottom:'10px'}}>
                                                                        <span className="font-super-small text-capitalize fw-600" style={{color:'#666'}}>{convertDate(it.time, 'ddMMyyyy')}</span>
                                                                    </div>
                                                                    {it.arr.map((iitem, k) => {
                                                                        const img = iitem.sender === accountState.id ? `${BACKEND_URL}${accountState.profile_pic}` : `${BACKEND_URL}${profile_pic}`;
                                                                        let data = [];
                                                                        for(let l = 0; l < iitem.pics.length; l++){
                                                                            data.push({src:`${BACKEND_URL}${iitem.pics[l].pic}`, alt:'imgv', onClick:() => {setOpenImgViewer(true); setImgViewer({src:`${BACKEND_URL}${iitem.pics[l].pic}`, alt:'imgv'})}})
                                                                        }
                                                                        return(
                                                                            <Fragment key={k}>
                                                                                <MessageUI img_src={img} is_send={iitem.sender === accountState.id ? true : false} bg_color="app-bg-color" text={iitem.message} is_seen={iitem.is_seen} img_files={data} time={timify(convertDate(iitem.created, 'hh:mm:ss'))}/>
                                                                            </Fragment>
                                                                        )
                                                                    })}
                                                                </Fragment>
                                                            )
                                                        })}
                                                        </>
                                                    ) : (
                                                        <Wrapper>
                                                            <h5 style={{color:'#666'}}>Hello:) Send a message, to your new friend</h5>
                                                        </Wrapper>
                                                    )}
                                                    {loadingMsg && <Spinner />}
                                                    {allPreFriendMessage.map((it, j) => {
                                                        let data = [];
                                                        for(let l = 0; l < it.img_files.length; l++){
                                                            data.push({src:`${BACKEND_URL}${it.img_files[l].img}`, alt:'imgv'})
                                                        }
                                                        return(
                                                            <Fragment key={j}>
                                                                <MessageUI img_src={`${BACKEND_URL}${accountState.profile_pic}`} is_send={true} bg_color="app-bg-color" text={parse(it.message)} is_loading={it.is_error ? false : true} is_error={it.is_error} img_files={it.img_files}  />
                                                            </Fragment>
                                                        )
                                                    })}
                                                    {is_typing > new Date().getTime() && (
                                                        <MessageUI img_src={`${voskool_logo}`} is_send={false} bg_color="app-bg-color" is_typing={true}   />
                                                    )}
                                                    <div ref={messageEndRefMobile}/>
                                            </MessageUIWrapper>
                                            <BtnUIWrapper outer_classname="min-h-15vh app-bg-color shadow b-r-b-l-20px b-r-b-r-20px y-10px">
                                                <BtnUI  value={msg} onChange={e => onChange(e, 'friend')} onSubmit={() => onSendFriendMsg(item.id)} onChangeImageFile={e => onChangeBtnImageFile(e)} />
                                            </BtnUIWrapper>
                                        </Fragment>
                                    )
                                })}
                                </>
                            )}
                            </>
                        ) : (
                            <>
                            <Wrapper>
                                <h5 style={{color:'#666'}}>Hello, Tap on a chat, to start message</h5>
                            </Wrapper>
                            </>
                        )}
                    
                    
                </Flex>
            )
        }
        return(
            <></>
        )
    }

    const chatFunc = (type="", dev="") => {
        // if(type === "add_friend" && !dev){
        //     return(
        //         <Flex classname="flex-25 app-body-color">
        //             <Header outer_classname="min-h-16vh app-bg-color">
        //                 <div className="disp-flex jc-sb align-center padd-4px" style={{marginBottom:'3px'}}>
        //                     <div className="disp-block">
        //                         <i className="font-slightly-small fw-600 app-text-color fas fa-arrow-left" onClick={() => onClickChatAdd('')} style={{color:'#ccc'}}></i>
        //                     </div>
        //                     <div className="disp-block">
        //                         <span className="font-slightly-small fw-600 " style={{color:'#ccc'}}><i className="fas fa-user-plus"></i> Add new friend</span>
        //                     </div>
        //                     <div className="">

        //                     </div>
        //                 </div>
        //                 <div className="disp-block align-center">
        //                     <input type="text" name="search" value={searchNewFriend.search} onChange={e => onChangeSearchNewFriend(e)} className="font-very-small form-control" style={{background:'transparent', border:'none', borderBottom:'1px solid #666', color:'#ddd'}} placeholder="Search for new friend...." />
        //                 </div>
        //             </Header>
                    
        //             <ChatUi classname="app-theme">
        //                 {loadingSearchFriend && <Spinner />}
        //                 {filter_search_friend.length > 0 ? (
        //                     <>
        //                     {filter_search_friend.map((item, i) => {
        //                         return(
        //                             <Fragment key={i}>
        //                                 <ChatUserUI  title={item.username} desc={item.about ? item.about : 'Hey there, i am using VoChat'} is_init_status={false} img_src={`${BACKEND_URL}${item.profile_pic}`} alt="user" btn_icon="fas fa-user-plus app-bg-color-text" is_btn_text onClickBtn={() => onClickAddFriend(item.uid)} />
        //                             </Fragment>
        //                         )
        //                     })}
        //                     </>
        //                 ) : (
        //                     <>
        //                     {filter_pending_friend.length > 0 ? (
        //                         <>
        //                         {filter_pending_friend.map((item, i) => {
        //                             const username = item[`${processChatBT(item.by, item.to, accountState.id)}_username`];
        //                             const about = item[`${processChatBT(item.by, item.to, accountState.id)}_about`];
        //                             const profile_pic = item[`${processChatBT(item.by, item.to, accountState.id)}_profile_pic`];
        //                             return(
        //                                 <Fragment key={i}>
        //                                     <ChatUserUI  title={username} desc={about ? (about.length > 29 ? `${about.slice(0, 29)}...` : about) : 'Hey there, i am using VoChat'} is_init_status={false} img_src={`${BACKEND_URL}${profile_pic}`} alt="user" btn_icon={"far fa-circle app-bg-color-text"} is_disable_btn_click is_btn_text />
        //                                 </Fragment>
        //                             )
        //                         })}
        //                         </>
        //                     ) : (
        //                         <Wrapper>
        //                             <h5 style={{color:'#666'}}>Search for a friend ...</h5>
        //                         </Wrapper>
        //                     )}
                            
        //                     </>
        //                 )}
                        
                        
        //             </ChatUi>
                    
        //         </Flex>
        //     )
        // }
        // if(type === "add_friend" && dev === "mobile"){
        //     return(
        //         <Flex classname="flex-mobile app-body-color">
        //             <Header outer_classname="min-h-16vh app-bg-color">
        //                 <div className="disp-flex jc-sb align-center padd-4px" style={{marginBottom:'3px'}}>
        //                     <div className="disp-block">
        //                         <i className="font-slightly-small fw-600 app-text-color fas fa-arrow-left" onClick={() => onClickChatAdd('')} style={{color:'#ccc'}}></i>
        //                     </div>
        //                     <div className="disp-block">
        //                         <span className="font-slightly-small fw-600 " style={{color:'#ccc'}}><i className="fas fa-user-plus"></i> Add new friend</span>
        //                     </div>
        //                     <div className="">

        //                     </div>
        //                 </div>
        //                 <div className="disp-block align-center">
        //                     <input type="text" name="search" value={searchNewFriend.search} onChange={e => onChangeSearchNewFriend(e)} className="font-very-small form-control" style={{background:'transparent', border:'none', borderBottom:'1px solid #666', color:'#ddd'}} placeholder="Search for new friend...." />
        //                 </div>
        //             </Header>
                    
        //             <ChatUi classname="app-theme">
        //                 {loadingSearchFriend && <Spinner />}
        //                 {filter_search_friend.length > 0 ? (
        //                     <>
        //                     {filter_search_friend.map((item, i) => {
        //                         return(
        //                             <Fragment key={i}>
        //                                 <ChatUserUI  title={item.username} desc={item.about ? item.about : 'Hey there, i am using VoChat'} is_init_status={false} img_src={`${BACKEND_URL}${item.profile_pic}`} alt="user" btn_icon="fas fa-user-plus app-bg-color-text" is_btn_text onClickBtn={() => onClickAddFriend(item.uid)} />
        //                             </Fragment>
        //                         )
        //                     })}
        //                     </>
        //                 ) : (
        //                     <>
        //                     {filter_pending_friend.length > 0 ? (
        //                         <>
        //                         {filter_pending_friend.map((item, i) => {
        //                             const username = item[`${processChatBT(item.by, item.to, accountState.id)}_username`];
        //                             const about = item[`${processChatBT(item.by, item.to, accountState.id)}_about`];
        //                             const profile_pic = item[`${processChatBT(item.by, item.to, accountState.id)}_profile_pic`];
        //                             return(
        //                                 <Fragment key={i}>
        //                                     <ChatUserUI  title={username} desc={about ? (about.length > 29 ? `${about.slice(0, 29)}...` : about) : 'Hey there, i am using VoChat'} is_init_status={false} img_src={`${BACKEND_URL}${profile_pic}`} alt="user" btn_icon={"far fa-circle app-bg-color-text"} is_disable_btn_click is_btn_text />
        //                                 </Fragment>
        //                             )
        //                         })}
        //                         </>
        //                     ) : (
        //                         <Wrapper>
        //                             <h5 style={{color:'#666'}}>Search for a friend ...</h5>
        //                         </Wrapper>
        //                     )}
                            
        //                     </>
        //                 )}
                        
                        
        //             </ChatUi>
                    
        //         </Flex>
        //     )
        // }
        if(dev === "mobile"){
            return(
                <Flex classname="flex-mobile app-body-color">
                    <Header outer_classname="min-h-120px app-bg-color">
                    <div className="disp-flex wrap jc-sb">
                        <div className="disp-block">
                            <span className="font-med fw-700 text-upper fas fa-times" style={{color:'#888', cursor:'pointer'}} onClick={() => setNavigate(processNavigation())}></span>
                        </div>
                        <div className="disp-block">
                            {accountState.profile_pic ? <img className="user-icon br-circle" src={`${BACKEND_URL}${accountState.profile_pic}`} alt="user" /> : <i className="fas fa-user font-super-big"></i>}
                        </div>
                    </div>
                    <div className="disp-block align-center">
                        <div className="disp-block">
                            <span className="font-small fw-600" style={{color:'#ddd'}}>Chats</span>
                        </div>
                        
                    </div>
                    <div className="disp-block align-center">
                        <input type="text" name="" className="font-very-small form-control" style={{background:'transparent', border:'none', borderBottom:'1px solid #666', color:'#ddd'}} placeholder="Search...." />
                    </div>
                </Header>
                
                <ChatUi>
                    {loadingFriends && <Spinner />}
                    {filter_pending_friend.length > 0 && (
                        <>
                        <div style={{marginBottom:'10px', padding:'8px'}}>
                            <div className="disp-block padd-4px" style={{marginBottom:'5px'}}>
                                <span className="fw-600 font-super-small" style={{color:'#666'}}>All Pending Friend Request</span>
                            </div>
                            {filter_pending_friend.map((item, i) => {
                                const username = item[`${processChatBT(item.by, item.to, accountState.id)}_username`];
                                const about = item[`${processChatBT(item.by, item.to, accountState.id)}_about`];
                                const profile_pic = item[`${processChatBT(item.by, item.to, accountState.id)}_profile_pic`];
                                const is_btn = !item.is_loading ? (item.to === accountState.id ? true : false) : false;
                                return(
                                    <Fragment key={i}>
                                        <ChatUserUI  title={username.length > 20 ? `${username.slice(0, 20)}...` : username} desc={about ? (about.length > 29 ? `${about.slice(0, 29)}...` : about) : 'Hey there, i am using VoChat'} is_init_status={false} img_src={`${BACKEND_URL}${profile_pic}`} alt="user" btn_icon={!is_btn ? "far fa-circle app-bg-color-text" : "fas fa-check green"} btn_icon2={is_btn ? 'fas fa-times red' : ''} is_disable_btn_click={!is_btn} is_btn_text onClickBtn={() => OnClickProcessFriendReq('accept', item.uid)} onClickBtn2={() => OnClickProcessFriendReq('reject', item.uid)} />
                                    </Fragment>
                                )
                            })}
                        </div>
                        </>
                    )}
                    <ChatUserUI number_message={accountState.va_tot_unseen_msg1} onClick={() => onClickChatNavigate('msger', 'virtual_assistant')} title="VOMA (Virtual Assistant)" desc="Need help?, let's chat!" is_init_status={false} img_src={voskool_logo} alt="Assistant" is_active={searchParam.get('msger') === "virtual_assistant" ? true : false} />
                    {allFriends.map((item, i) => {
                        const username = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_username`];
                        const about = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_about`];
                        const profile_pic = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_profile_pic`];
                        const is_online = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_is_online`];
                        const tot_unseen_msg = item.by_friend === accountState.id ? item.tot_unseen_msg2 : item.tot_unseen_msg1;
                        const is_typing = item.by_friend === accountState.id ? item.is_typing2 : item.is_typing1;
                        return(
                            <Fragment key={i}>
                                <ChatUserUI is_typing={is_typing > new Date().getTime() ? true : false} number_message={tot_unseen_msg} title={username.length > 20 ? `${username.slice(0, 20)}...` : username} onClick={() => onClickChatNavigate('msger', item.uid)} desc={about ? (about.length > 29 ? `${about.slice(0, 29)}...` : about) : 'Hey there, i am using VoChat'} is_init_status={true} img_src={`${BACKEND_URL}${profile_pic}`} alt="user" is_online={parseInt(is_online) > new Date().getTime() ? true : false} is_active={searchParam.get('msger') === item.uid ? true : false} is_btn_text />
                            </Fragment>
                        )
                    })}
                    {/* <BtnAbsUIWrapper>
                        <BtnAbsUI is_clickable>
                            <BtnAbsUIBtn title="Add new friend" onClick={() => onClickChatAdd('add_friend')} icon="fas fa-user-plus" />
                            <BtnAbsUIBtn title="Create new group" icon="fas fa-users" />
                        </BtnAbsUI>
                    </BtnAbsUIWrapper> */}
                    
                </ChatUi>
                    
                </Flex>
            )
        }
        return(
            <Flex classname="flex-25 app-body-color">
                <Header outer_classname="min-h-120px app-bg-color">
                    <div className="disp-flex wrap jc-sb">
                        <div className="disp-block">
                        <span className="font-med fw-700 text-upper fas fa-times" style={{color:'#888', cursor:'pointer'}} onClick={() => window.location.href = processNavigation()}> </span>
                        </div>
                        <div className="disp-block">
                            {accountState.profile_pic ? <img className="user-icon br-circle" src={`${BACKEND_URL}${accountState.profile_pic}`} alt="user" /> : <i className="fas fa-user font-super-big"></i>}
                        </div>
                    </div>
                    <div className="disp-block align-center">
                        <div className="disp-block">
                            <span className="font-small fw-600" style={{color:'#ddd'}}>Chats</span>
                        </div>
                        
                    </div>
                    <div className="disp-block align-center">
                        <input type="text" name="" className="font-very-small form-control" style={{background:'transparent', border:'none', borderBottom:'1px solid #666', color:'#ddd'}} placeholder="Search...." />
                    </div>
                </Header>
                
                <ChatUi>
                    {loadingFriends && <Spinner />}
                    {filter_pending_friend.length > 0 && (
                        <>
                        <div style={{marginBottom:'10px', padding:'8px'}}>
                            <div className="disp-block padd-4px" style={{marginBottom:'5px'}}>
                                <span className="fw-600 font-super-small" style={{color:'#666'}}>All Pending Friend Request</span>
                            </div>
                            {filter_pending_friend.map((item, i) => {
                                const username = item[`${processChatBT(item.by, item.to, accountState.id)}_username`];
                                const about = item[`${processChatBT(item.by, item.to, accountState.id)}_about`];
                                const profile_pic = item[`${processChatBT(item.by, item.to, accountState.id)}_profile_pic`];
                                const is_btn = !item.is_loading ? (item.to === accountState.id ? true : false) : false;
                                return(
                                    <Fragment key={i}>
                                        <ChatUserUI  title={username.length > 20 ? `${username.slice(0, 20)}...` : username} desc={about ? (about.length > 29 ? `${about.slice(0, 29)}...` : about) : 'Hey there, i am using VoChat'} is_init_status={false} img_src={`${BACKEND_URL}${profile_pic}`} alt="user" btn_icon={!is_btn ? "far fa-circle app-bg-color-text" : "fas fa-check green"} btn_icon2={is_btn ? 'fas fa-times red' : ''} is_disable_btn_click={!is_btn} is_btn_text onClickBtn={() => OnClickProcessFriendReq('accept', item.uid)} onClickBtn2={() => OnClickProcessFriendReq('reject', item.uid)} />
                                    </Fragment>
                                )
                            })}
                        </div>
                        </>
                    )}
                    <ChatUserUI number_message={accountState.va_tot_unseen_msg1} onClick={() => onClickChatNavigate('msger', 'virtual_assistant')} title="VOMA (Virtual Assistant)" desc="Need help?, let's chat!" is_init_status={false} img_src={voskool_logo} alt="Assistant" is_active={searchParam.get('msger') === "virtual_assistant" ? true : false} />
                    {allFriends.map((item, i) => {
                        const username = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_username`];
                        const about = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_about`];
                        const profile_pic = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_profile_pic`];
                        const is_online = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_is_online`];
                        const tot_unseen_msg = item.by_friend === accountState.id ? item.tot_unseen_msg2 : item.tot_unseen_msg1;
                        const is_typing = item.by_friend === accountState.id ? item.is_typing2 : item.is_typing1;
                        return(
                            <Fragment key={i}>
                                <ChatUserUI is_typing={is_typing > new Date().getTime() ? true : false} number_message={tot_unseen_msg} title={username.length > 20 ? `${username.slice(0, 20)}...` : username} onClick={() => onClickChatNavigate('msger', item.uid)} desc={about ? (about.length > 29 ? `${about.slice(0, 29)}...` : about) : 'Hey there, i am using VoChat'} is_init_status={true} img_src={`${BACKEND_URL}${profile_pic}`} alt="user" is_online={parseInt(is_online) > new Date().getTime() ? true : false} is_active={searchParam.get('msger') === item.uid ? true : false} is_btn_text />
                            </Fragment>
                        )
                    })}
                    {/* <BtnAbsUIWrapper>
                        <BtnAbsUI is_clickable>
                            <BtnAbsUIBtn title="Add new friend" onClick={() => onClickChatAdd('add_friend')} icon="fas fa-user-plus" />
                            <BtnAbsUIBtn title="Create new group" icon="fas fa-users" />
                        </BtnAbsUI>
                    </BtnAbsUIWrapper> */}
                    
                </ChatUi>
                
            </Flex>
        )
    }
    
    return(
        <>
        <BackDrop>
            <Background>
                {preloading && <PreloaderLine />}
                <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {warning.id && (<Notification type="warning" timer={1000 * 60 * 60 * 2} text={warning.text} title={warning.title} click_text={warning.click_text} handleClick={warning.handleClick} />)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
                </NotificationWrapper>  
                {openImgViewer && (
                    <ImgViewer2 zIndex="z-over" src={imgViewer.src} alt={imgViewer.alt} title={imgViewer.title} handleClose={() => setOpenImgViewer(false)}>
                        
                            <Img src={imgViewer.src} alt={imgViewer.alt} selected />
                        
                    </ImgViewer2>
                )}
                {isInitChat ? (
                    <FlexWrapper>
                    {chatFunc(searchParam.get('tpe') ? searchParam.get('tpe') : '')}
                    <Flex classname="flex-50" >
                        
                        {searchParam.get('msger') ? (
                            <>
                            {searchParam.get('msger') === "virtual_assistant" && (
                                <>
                                <Header outer_classname="min-h-30px app-theme shadow" is_fixed>
                                    <div className="disp-flex wrap jc-sb">
                                        <div className="disp-flex wrap jc-stretch">
                                            {/* <span className="font-med fw-700 text-upper" style={{color:'#888'}}>EMIFE </span> */}
                                            <div className="disp-block img-cont">
                                                <img className="img" src={voskool_logo} alt="Assistant" />
                                            </div>
                                            <div className="disp-block" style={{marginTop:'8px', paddingLeft:'8px'}}>
                                                <span className="font-slightly-small fw-600 app-text-color text-capitalize" style={{color:'var(--appBgColor)', letterSpacing:'.4px'}}>{isConnectedOnlineAssistant ? accountState.assistant_full_name : 'VOMA (Virtual Assistant)'}</span>
                                            </div>
                                            <div className="disp-block stat-cont" style={{marginTop:'12px', paddingLeft:'8px'}}>
                                                <span className={`circle online`}></span>
                                            </div>
                                        </div>
                                        {/* <div className="disp-block">
                                            <div className="disp-flex wrap jc-stretch">
                                                <div className="disp-block" style={{marginTop:'8px'}}>
                                                    <i className="fas fa-angle-right font-med" style={{color:'#666'}}></i>
                                                </div>
                                            </div>
                                        </div> */}
                                        
                                    </div>
                                    
                                </Header>
                                <MessageUIWrapper outer_classname="app-body-color">
                                    
                                    <MessageUI img_src={voskool_logo} is_send={false} bg_color="app-bg-color" text="Hey dear, my name is VOMA, what would you like me to do today?" btn_pallete={btn_pallete} />
                                    {isConnectedOnlineAssistant ? (
                                        <>
                                        {loadingMsg && <Spinner />}
                                        <MessageUI img_src={voskool_logo} is_send={false} bg_color="app-bg-color" text="Connected Successfully ..." />
                                        <MessageUI img_src={voskool_logo} is_send={false} bg_color="app-bg-color" text={`${accountState.assistant_full_name.toUpperCase()} will take it from here :)`} />
                                        {preLoadingMsg && <Spinner />}
                                        {loadingMsg && <Spinner />}
                                        
                                        {msgFunc(all_msg).map((it, i) => {
                                           
                                            return(
                                                <Fragment key={i}>
                                                    <div className="header disp-block align-center" style={{marginTop:'10px', marginBottom:'10px'}}>
                                                        <span className="font-super-small text-capitalize fw-600" style={{color:'#666'}}>{convertDate(it.time, 'ddMMyyyy')}</span>
                                                    </div>
                                                    {it.arr.map((item, j) => {
                                                        const img = item.sender ? `${BACKEND_URL}${accountState.profile_pic}` : voskool_logo
                                                        return(
                                                            <Fragment key={j}>
                                                                <MessageUI img_src={img} is_send={item.sender === accountState.id ? true : false} bg_color="app-bg-color" text={item.message} is_seen={item.is_seen}  time={timify(convertDate(item.created, 'hh:mm:ss'))}/>
                                                            </Fragment>
                                                        )
                                                    })}
                                                    
                                                </Fragment>
                                            )
                                        })}
                                        {allPreMessage.map((item, i) => {
                                            return(
                                                <Fragment key={i}>
                                                    <MessageUI img_src={`${BACKEND_URL}${accountState.profile_pic}`} is_send={true} bg_color="app-bg-color" text={parse(item.message)} is_loading={item.is_error ? false : true} is_error={item.is_error}  />
                                                </Fragment>
                                            )
                                        })}
                                        {accountState.is_typing2 > new Date().getTime() && (
                                            <MessageUI img_src={`${voskool_logo}`} is_send={false} bg_color="app-bg-color" is_typing={true}   />
                                        )}
                                        </>
                                    ) : (
                                        <>
                                        {isConnectOnlineAssistant && (
                                            <>
                                            <MessageUI img_src={voskool_logo} is_send={false} bg_color="app-bg-color" text="Please wait, while i connect you to an online assistant" is_loading_text />
                                            </>
                                        )}
                                        </>
                                    )}
                                    
                                    
                                    {/* <MessageUI img_src={voskool_logo} is_send={true} bg_color="app-bg-color" text="asdasd asdaysdiasda aisdvasu dadfad ioado ago gaod goa dguoagdasdoasdov asdyo vasodauy" />
                                    <MessageUI img_src={`${BACKEND_URL}${accountState.profile_pic}`} is_send={false} bg_color="app-bg-color" text="asdasd asdaysdiasd" />
                                    <MessageUI is_send={true} bg_color="app-bg-color" text="asdasd asdaysdiasd" />
                                    <MessageUI is_send={false} bg_color="app-bg-color" text="asdasd asdaysdiasd" /> */}
                                
                                </MessageUIWrapper>
                                <BtnUIWrapper outer_classname="min-h-15vh app-bg-color shadow b-r-b-l-20px b-r-b-r-20px y-10px">
                                    <BtnUI value={msg} image_btn={false} onChange={e => onChange(e)} onSubmit={() => onSendMsg()} />
                                </BtnUIWrapper>
                                </>
                            )}
                            {filter_friend_search_param.length === 1 && (
                                <>
                                {filter_friend_search_param.map((item, i) => {
                                    const username = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_username`];
                                    //const about = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_about`];
                                    const profile_pic = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_profile_pic`];
                                    const is_online = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_is_online`];
                                    const is_typing = item.by_friend === accountState.id ? item.is_typing2 : item.is_typing1;
                                    return(
                                        <Fragment key={i}>
                                            <Header outer_classname="min-h-30px app-theme shadow" is_fixed>
                                                <div className="disp-flex wrap jc-sb">
                                                    <div className="disp-flex wrap jc-stretch">
                                                        {/* <span className="font-med fw-700 text-upper" style={{color:'#888'}}>EMIFE </span> */}
                                                        <div className="disp-block img-cont">
                                                            <img className="img" src={`${BACKEND_URL}${profile_pic}`} alt="Assistant" />
                                                        </div>
                                                        <div className="disp-block" style={{marginTop:'8px', paddingLeft:'8px'}}>
                                                            <span className="font-slightly-small fw-600 app-text-color text-capitalize" style={{color:'var(--appBgColor)', letterSpacing:'.4px'}}>{username}</span>
                                                        </div>
                                                        <div className="disp-block stat-cont" style={{marginTop:'12px', paddingLeft:'8px'}}>
                                                            <span className={`circle ${is_online > new Date().getTime() ? 'online' : 'offline'}`}></span>
                                                        </div>
                                                    </div>         
                                                </div>
                                                
                                            </Header>
                                            {isScrollDownBtn && (
                                                <PageDownBtn number={totUnseenMsg} onClick={() => setIsScrollDownToLastMessage(true)} />
                                            )}
                                            {btnImageFiles.length === 1 && (
                                                <>
                                                <ImgViewerWrapper>
                                                {btnImageFiles.map((img, j) => {
                                                    return (
                                                        <Fragment key={j}>
                                                            <ImgViewer src={img.img} alt={img.value} onClickClose={() => setBtnImageFiles([])} />
                                                        </Fragment>
                                                    )
                                                })}
                                                </ImgViewerWrapper>
                                                </>
                                            )}

                                                
                                            
                                            <MessageUIWrapper outer_classname="app-body-color" ref={loadMoreButtonRef} onScroll={e => handleScrollLoadMoreFriendMessages(e)}>
                                                
                                                    {loadingFriendMsg && <Spinner />}
                                                    {allFriendMessagePaged.next && (
                                                        <div className='disp-block align-center' >
                                                            <button type="button" onClick={() => fetchAllMessagesFriend(item.uid, false, currPageFriendState.max_id, 1, currPageFriendState.page+1)} className='btn app-bg-color hover-opacity app-bg-text-color br-circle'  style={{padding:'5px'}}><i className='fas fa-arrow-up'></i></button>
                                                        </div>
                                                    )}
                                                    {preloadingFriendMsg && <Spinner />}
                                                    
                                                    
                                                    {all_friend_msg.length > 0 ? (
                                                        <>
                                                        {msgFunc(all_friend_msg).map((it, j) => {
                                                            
                                                            return(
                                                                <Fragment key={j}>
                                                                    <div className="header disp-block align-center" style={{marginTop:'10px', marginBottom:'10px'}}>
                                                                        <span className="font-super-small text-capitalize fw-600" style={{color:'#666'}}>{convertDate(it.time, 'ddMMyyyy')}</span>
                                                                    </div>
                                                                    {it.arr.map((iitem, k) => {
                                                                        const img = iitem.sender === accountState.id ? `${BACKEND_URL}${accountState.profile_pic}` : `${BACKEND_URL}${profile_pic}`;
                                                                        let data = [];
                                                                        for(let l = 0; l < iitem.pics.length; l++){
                                                                            data.push({src:`${BACKEND_URL}${iitem.pics[l].pic}`, alt:'imgv', onClick:() => {setOpenImgViewer(true); setImgViewer({src:`${BACKEND_URL}${iitem.pics[l].pic}`, alt:'imgv'})}})
                                                                        }
                                                                        return(
                                                                            <Fragment key={k}>
                                                                                <MessageUI img_src={img} is_send={iitem.sender === accountState.id ? true : false} bg_color="app-bg-color" text={iitem.message} is_seen={iitem.is_seen} img_files={data} time={timify(convertDate(iitem.created, 'hh:mm:ss'))}/>
                                                                            </Fragment>
                                                                        )
                                                                    })}
                                                                </Fragment>
                                                            )
                                                        })}
                                                        </>
                                                    ) : (
                                                        <Wrapper>
                                                            <h5 style={{color:'#666'}}>Hello:) Send a message, to your new friend</h5>
                                                        </Wrapper>
                                                    )}
                                                    {loadingMsg && <Spinner />}
                                                    {allPreFriendMessage.map((it, j) => {
                                                        let data = [];
                                                        for(let l = 0; l < it.img_files.length; l++){
                                                            data.push({src:`${BACKEND_URL}${it.img_files[l].img}`, alt:'imgv'})
                                                        }
                                                        return(
                                                            <Fragment key={j}>
                                                                <MessageUI img_src={`${BACKEND_URL}${accountState.profile_pic}`} is_send={true} bg_color="app-bg-color" text={parse(it.message)} is_loading={it.is_error ? false : true} is_error={it.is_error} img_files={it.img_files}  />
                                                            </Fragment>
                                                        )
                                                    })}
                                                    {is_typing > new Date().getTime() && (
                                                        <MessageUI img_src={`${voskool_logo}`} is_send={false} bg_color="app-bg-color" is_typing={true}   />
                                                    )}
                                                    <div ref={messageEndRef}/>
                                            </MessageUIWrapper>
                                            <BtnUIWrapper outer_classname="min-h-15vh app-bg-color shadow b-r-b-l-20px b-r-b-r-20px y-10px">
                                                <BtnUI value={msg} onChange={e => onChange(e, 'friend')} onSubmit={() => onSendFriendMsg(item.id)} onChangeImageFile={e => onChangeBtnImageFile(e)} />
                                            </BtnUIWrapper>
                                        </Fragment>
                                    )
                                })}
                                </>
                            )}
                            </>
                        ) : (
                            <>
                            <Wrapper>
                                <h5 style={{color:'#666'}}>Hello, Tap on a chat, to start message</h5>
                            </Wrapper>
                            </>
                        )}
                        
                        
                    </Flex>
                    <Flex classname="flex-25-m-none app-theme">
                        {searchParam.get('msger') ? (
                            <>
                            {searchParam.get('msger') === "virtual_assistant" && (
                                <ProfileUIWrapper>
                                    <ProfileUIImage src={voskool_logo} title={isConnectedOnlineAssistant ? `${accountState.assistant_full_name}` : 'VOMA (Virtual Assistant)'} />
                                    {/* <ProfileUIWrapper2>
                                        <ProfileUI classname="red" icon="fas fa-user-minus" title="Remove Friend" />
                                    </ProfileUIWrapper2> */}
                                </ProfileUIWrapper>
                            )}
                            {filter_friend_search_param.length === 1 && (
                                <>
                                {filter_friend_search_param.map((item, i) => {
                                    const username = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_username`];
                                    const fullname = `${item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_last_name`]} ${item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_first_name`]}`;
                                    const about = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_about`];
                                    const profile_pic = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_profile_pic`];
                                    // const is_online = item[`${processChatBT(item.by_friend, item.to_friend, accountState.id)}_is_online`];
                                    // const is_typing = item.by_friend === accountState.id ? item.is_typing2 : item.is_typing1;
                                    return(
                                        <Fragment key={i}>
                                            <ProfileUIWrapper>
                                            <ProfileUIImage src={`${BACKEND_URL}${profile_pic}`} is_chat title={fullname} title2={`~${username}`} desc={about ? about : 'Hey there, am using VoChat'} />
                                            <ProfileUIWrapper2>
                                                <ProfileUI classname="red" icon="fas fa-user-minus" title="Remove Friend" />
                                            </ProfileUIWrapper2>
                                        </ProfileUIWrapper>
                                        </Fragment>
                                    )
                                })}
                                </>
                            )}
                            </>
                        ) : (
                            <Wrapper>
                                <h5 style={{color:'#666'}}>Profile...</h5>
                            </Wrapper>
                        )}
                        
                    </Flex>



                    {!searchParam.get('msger') && (
                        <>
                        {chatFunc(searchParam.get('tpe') ? searchParam.get('tpe') : '', 'mobile')}
                        </>
                    )}
                    {insert_msger()}
                    
                </FlexWrapper>
                ) : (
                    <>
                    {isSetupAccount ? (
                        <>
                        <Wrapper>
                            <Box outer_classname="app-theme">
                                <div className="disp-block">
                                    <h4>Create your chat account....</h4> 
                                </div>
                                <div className="disp-block padd-4px">
                                    <form onSubmit={e => onRegisterAcct(e)}>
                                        <div className="disp-block align-left">
                                            <label className="font-super-small w100 app-text-color fw-500">Enter Username</label>
                                            <input type="text" className="form-control app-text-color font-slightly-small" value={nameVal} onChange={e => setNameVal(e.target.value)} placeholder="Username" />
                                        </div>
                                        <div className="disp-block padd-4px mr-t-4px">
                                            <button type="submit" className="font-very-small app-bg-color app-bg-text-color btn hover-opacity br" disabled={preloading}>Continue <i className="fas fa-arrow-right"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </Box>
                        </Wrapper>
                        </>
                    ): (
                        <>
                        
                        </>
                    )}
                    </>
                )}
                
            </Background>
        </BackDrop>
        </>
    )
}

export default Chat;