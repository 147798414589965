import styled from "styled-components";

export const Wrapper = styled.div`
    padding: 7px 15px;
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;

    label{
        font-size: 0.7rem;
        font-weight: 600;
        color: #333;
    }
    .error{
        font-size: 0.7rem;
        display: block;
        color: red;
        font-weight: 600;
        margin-left: 6px;
    }
    .f-100{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .f-50-alt{
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 5px;
    }
    .f-33{
        flex: 0 0 33.33%;
        max-width: 33.33%;

        @media(max-width: 1280px){
            flex: 0 0 50%;
            max-width: 50%;
        }
        @media(max-width: 400px){
            flex: 0 0 100%;
            max-width: 100%;

        }
    }
    
    .mrn{
        margin: 40px 0;
    }
    .w100{
        width: 100%;
        margin-left: 10px;
    }
    .f-c{
        width: 85%;
        
        @media(max-width: 1280px){
            width: 90%;
        }
        @media(max-width: 400px){
            width: 100%;
        }
    }
`;