import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 40px;

    h5{
        color: #888;
    }
`;
export const Button = styled.button`
    padding: 15px;
    border: none;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .8px;
    background: var(--voskoolBagColor);
    color: #eee;
    border-radius: 10px;
    box-shadow: 4px 3px rgba(0,0,0,0.2);
    transition: .5s ease;

    @media (max-width: 780px){
        font-size: .8rem;
    }
    i{
        padding-left: 8px;
    }
    &:hover{
        opacity: .9;
        box-shadow: 12px 15px rgba(0,0,0,0.2);
    }
`;