import React, {useEffect, useState, useMemo} from "react";
import { HomeWrapper, Container, HomeContent } from 'containers/home/style';
import Countries from "components/countrySort";
import RatedSchools from "components/ratedSchools";
import Home70 from "components/homeSort";

import { appServices5 } from "api/schoolApp/services";
// import { NavHashLink } from "react-router-hash-link";



const Home = ({countries=[], is_loading_countries=false}) => {
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);
    const [state, setState] = useState({id:0});

    const [schools, setSchools] = useState(initialPaginationState);

    const [loadingSchools, setLoadingSchools] = useState(true);

    const onLoadCt = (val) => {
        setState(val);
    }

    const fetchAllSchools = () => {
        setLoadingSchools(true);
        appServices5.getAllSchools(1, 5).then(res => {
            setSchools(res.data);
            setLoadingSchools(false);
        }).catch(e => {
            setLoadingSchools(false);
            setSchools([]);
        })
    }
    console.log(schools)
    useEffect(() => {
        fetchAllSchools();
    }, [])
    return(
        <HomeWrapper id="home">
            <Container>
                <HomeContent className="bg_white">
                    <div className="flex-70">
                        <Home70 />
                    </div>
                    <div className="flex-30">
                        <div className="rounded-cont">
                            <div className="heading">
                                <div className="icon">
                                    <i className="fas fa-award"></i>
                                </div>
                                <Countries countries={countries} position="abs-right" is_loading={is_loading_countries} setCt={onLoadCt} title="Top Schools" />
                                {state.id ? <RatedSchools is_loading={loadingSchools} schools={schools.results} /> : "No School Found"}
                                {/* <div className="disp-block align-center" style={{marginTop:'5px'}}>
                                    <NavHashLink to="#schools" className={'fw-600 padd-4px font-very-small'}>View More <i className="fas fa-arrow-down"></i></NavHashLink>
                                </div> */}
                            </div>
                            
                        </div>
                    </div>
                </HomeContent>
            </Container>
        </HomeWrapper>
    )
}

export default Home;