import React, {useState, useEffect, useMemo, Fragment} from "react";
import { useNavigate } from "react-router-dom";

import VoWrapper from "components/app/vo/SmallerComponents/wrapper";
import { Wrapper, Content } from "components/app/vo/signUpPage/style.js";
import { NotificationWrapper } from "components/app/notification/style";

import TABLE from "components/app/table_html/table";
import TR from "components/app/table_html/tr";
import TD from "components/app/table_html/td";
import TH from "components/app/table_html/th";
import Note from "components/app/NOTE";

import Notification from "components/app/notification";
import Loader from "components/app/loader";
import Redirect from 'components/redirectPage';

import { appServices } from "api/schoolApp/services";
import { load_user } from "actions/auth";
import authHandler from "actions/auth_handler";

import { convertNumber} from 'components/app/actions/money'

const initialUserState = {
    email: "",
    first_name: "",
    id: 0,
    last_name: "",
    phone_number: "",
    user_name: "",
    user_type: null,
}

const CheckApplication = () => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);

    const [user, setUser] = useState(initialUserState);

    const nav = useNavigate();

    const [allAppData, setAllAppData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [navigate, setNavigate] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [redSuccess, setRedSuccess] = useState(false);

    const [redData, setRedData] = useState({id:''});

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);

    const fetchUser =  async () => {
        setIsAuthenticated(true);
        setLoading(true);
        await authHandler.load_user(load_user()).then(result => {
            setIsAuthenticated(result.isAuthenticated); 
            setUser({...result.data})
            setLoading(false)
        }).catch(error => {
            setIsAuthenticated(false);
            setLoading(false)
        })
    }

    const fetchAllStaffApption = (id, vid) => {
        setError({title:'', data:[]});
        setLoading(true);
        appServices.getAllStaffSchoolStaffApplication(id, vid).then(res => {
            setLoading(false);
            setAllAppData([...res.data]);
            console.log(res);
        }).catch(e => {
            setError({title:'Data Error', data:['Something Went Wrong']})
            setAllAppData([]);
        })
    }

    const handleJob = (id, q, school_title="") => {
        setLoading(true);
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setRedSuccess(false);
        setRedData({id:''});
        appServices.handleStaffSchoolAccess(id, q).then(res => {
            if(res.data.staff.is_staff_accepted === true){
                setLoading(false);
                setRedSuccess(true);
                setRedData({id:res.data.school_uid});
                setSuccess({title:'Congratulations! :)', text:`You are officially hired as a staff of ${school_title.toUpperCase()} school.`})
                fetchAllStaffApption(user.id, user.id);
            }
            else{
                setLoading(false);
                setSuccess({title:'School Declined', text:`Declined Successfully`})
                fetchAllStaffApption(user.id, user.id);
            }
            
        }).catch(e => {
            setLoading(false);
            console.log(e.response);
            setError({title:'Error', data:[e.response.data.detail]});
        })
    }

    const getStat = (is_accepted=false, app=true, id=0, school_title="") => {
        if(is_accepted === false && app === true){
            return (<TD><span style={{color:'yellow'}}>Pending</span></TD>);
        }
        else if(is_accepted === true && app === true){
            return (<TD><span style={{color:'green'}}>Accepted</span><br /> <button type="button" className="font-slightly-small hover-a br-5 shadow bg-black" onClick={() => handleJob(id, "accepted", school_title)} style={{backgroundColor:'#00057a'}}>Begin Job</button> 
                    <button type="button" className="font-slightly-small hover-a br-5 shadow bg-black" onClick={() => handleJob(id, "rejected", school_title)} style={{backgroundColor:'red'}}>Decline Job</button></TD>);
        }
        else{
            return (<TD><span style={{color:'red'}}>Rejected</span></TD>);
        }
    }

    useEffect(() => {
        if(isAuthenticated === false){
            return setNavigate('/vo/signup');
        }
        fetchUser()
    }, [isAuthenticated])
    useEffect(() => {
        if(user.id){
            fetchAllStaffApption(user.id, user.id);
        }
    }, [user])

    useEffect(() => {
        if(navigate){
            nav(navigate)
        }
    }, [nav, navigate])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const insertAppTb = allAppData.map((item, i) => {
        i++;
        return(
            <Fragment key={i}>
                <TR>
                    <TD>{i}</TD>
                    <TD>{item.school_name} ({item.school_title.toUpperCase()}) - {item.school_branch_name}</TD>
                    <TD classname="text-capitalize">{item.applicant_type}</TD>
                    <TD>{item.salary > 1 && `${item.curr_symbol}${convertNumber(item.salary)} / mon`}</TD>
                    {getStat(item.is_accepted, item.is_applied, item.id, item.school_title)}
                   
                </TR>
            </Fragment>
        )
    })
    const insFunc = () => {
        if(!redSuccess){
            return(
                <VoWrapper page="Check Applications" steps={false} data={[]} page_under="Check Schools Applied Status" action="All Applications">
                    <Wrapper>
                    {loading && <Loader />}
                        <Content>
                            <Note>Once "Begin Job" is clicked you won't be able to profile other schools, untill your contract with the school employed is terminated</Note>
                            <TABLE>
                                <thead>
                                    <TR>
                                        <TH>S/N</TH>
                                        <TH>School Applied</TH>
                                        <TH>Role</TH>
                                        <TH>Salary</TH>
                                        <TH>Status</TH>
                                    </TR>
                                </thead>
                                <tbody>
                                    {insertAppTb}
    
                                </tbody>
                            </TABLE>
                        </Content>
                    </Wrapper>
                </VoWrapper>
            )
        }
        else{
            return(
                <Fragment><Redirect title="Congratulations :)"  success link1="/" link1_title="Back To Homepage" link2="/" link2_title="Go To Staff Portal" ><b style={{fontSize:'1.5rem'}}>ID: {`${redData.id}`}</b> <br /> <br />You can start your job within the next school working day, meet with the HR / Principal / Direct Superior of the school and show him/her your ID number, He/She will show you the guidelines on what to do next.<br /> God Bless On Your New Adventure <br /> From The Voskool Team  </Redirect></Fragment>
            )
        }
    }
    return (
        <Fragment>
            <NotificationWrapper>
                    {error.title && (errorHandler)}
                    {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper>
            {isAuthenticated ? insFunc() : (<Fragment><Redirect error404 link1="/" link1_title="Back To Homepage" /></Fragment>)}
        </Fragment>
    )
    
}

export default CheckApplication;