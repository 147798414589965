import { convertToNumber } from 'components/app/actions/money';

export const validateExamMultiQuestionFigForm = (values) => {
    const errors = {};
    const ext = [".png", ".jpg", "jpeg"];
    if(!values.quest_pic){
        errors.quest_pic = "Figure Required";
    }
    else{
        const file_ext = values.quest_pic.name.slice(-4);
        if(!ext.includes(file_ext)){
            errors.quest_pic = "Invalid File Format";
            values.quest_pic = null;
            values.quest_pic_value = undefined;
        }
    }
    if(!values.name){
        errors.name="Figure Name Required";
    }
    if(!values.mcq){
        errors.mcq="Exam Type Required";
    }
    return errors;
}

export const validateExamQuestionForm = (values) => {
    const errors = {};
    const ext = [".png", ".jpg", "jpeg"];
    if(values.quest_pic){
        const file_ext = values.quest_pic.name.slice(-4);
        if(!ext.includes(file_ext)){
            errors.quest_pic = "Invalid File Format";
            values.quest_pic = null;
            values.quest_pic_value = undefined;
        }
    }

    if(!values.exam_question){
        errors.exam_question="Question Required";
    }
    if(!values.school_branch){
        errors.school_branch="School Branch Required";
    }
    if(!values.mcq){
        errors.mcq="Exam Type Required";
    }
    if(values.mcq === "obj" && !values.obj_a){
        errors.obj_a="Obj A Required";
    }
    if(values.mcq === "obj" && !values.obj_b){
        errors.obj_b="Obj B Required";
    }
    // if(values.mcq === "obj" && !values.obj_c){
    //     errors.obj_c="Obj C Required";
    // }
    // if(values.mcq === "obj" && !values.obj_d){
    //     errors.obj_d="Obj D Required";
    // }
    // if(values.mcq === "obj" && !values.obj_e){
    //     errors.obj_e="Obj E Required";
    // }
    if(values.mcq === "obj" && !values.exam_question_answer){
        errors.exam_question_answer="Question Answer Required";
    }
    if(!parseInt(values.mark)){
        errors.mark="Mark Required";
    }
    return errors;
}

export const validateSignUpForm = (values) => {
    const errors = {};
    const email_filter = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const user_name_filter = /^[a-zA-Z0-9]+$/;
    const name_filter = /^[a-zA-Z]+$/;
    const names_filter = /^[a-zA-Z]+$/;
    const phone_number_filter = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
    const ext = [".png", ".jpg", "jpeg"]
    if(values.profile_pic){
        const file_ext = values.profile_pic.name.slice(-4);
        if(!ext.includes(file_ext)){
            errors.profile_pic = "Invalid File Format";
            values.profile_pic = null;
            values.profile_pic_value = undefined;
            values.img = null;
        }
    }
    else{
        values.profile_pic = null;
        values.profile_pic_value = undefined;
        values.img = null;
    }
    if(!values.user_name){
        errors.user_name = "UserName Is Required";
    }
        else if(!user_name_filter.test(values.user_name)){
            errors.user_name = "Incorrect Username";
        }
        else if(values.user_name.length < 5){
            errors.user_name = "Username is too short";
        }
    if(!values.last_name){
        errors.last_name = "Surname Is Required";
    }
        else if(!name_filter.test(values.last_name)){
            errors.last_name = "Surname Must Be A Name";
        }
    if(!values.first_name){
        errors.first_name = "Firstname Is Required";
    }
        else if(!names_filter.test(values.first_name)){
            errors.first_name = "Firstname Must Be A Name";
        }
    if(!values.email){
        errors.email = "Email Is Required";
    }
        else if(!email_filter.test(values.email)){
            errors.email = "Invalid Email";
        }
    
    if(!phone_number_filter.test(values.phone_number) && values.phone_number){
            errors.phone_number = "Invalid Phone Number";
    }
    else if(values.phone_number && values.phone_number.length < 5){
        errors.phone_number = "Invalid Phone Number";
    }
    if(!values.user_type){
        errors.user_type = "User Type Is Required";
    }
    if(!values.password){
        errors.password = "Password Is Required";
    }
        else if(values.password.length < 7){
            errors.password = "Password Length Must Be More Than 6";
        }
    if(!values.re_password){
        errors.re_password = "Password Is Required";
    }
        else if(values.re_password !== values.password){
            errors.re_password = "Password And Confirm Password Do Not Match";
        }
    return errors;
}


export const validateSignUpForm2 = (values) => {
    const errors = {};
    const email_filter = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    // const user_name_filter = /^[a-zA-Z0-9]+$/;
    const name_filter = /^[a-zA-Z]+$/;
    const names_filter = /^[a-zA-Z]+$/;
    const phone_number_filter = /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,7}$/im;
    const pwd_filter = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    const ext = [".png", ".jpg", "jpeg"];

    const phone_comb =  values.phone_code + values.phone;
    console.log(phone_comb)
    if(values.profile_pic){
        const file_ext = values.profile_pic.name.slice(-4);
        if(!ext.includes(file_ext)){
            errors.profile_pic = "Invalid File Format";
            values.profile_pic = null;
            values.profile_pic_value = undefined;
            values.img = null;
        }
    }
    else{
        values.profile_pic = null;
        values.profile_pic_value = undefined;
        values.img = null;
    }
    if(!values.gender){
        errors.gender = "Gender Is Required";
    }
    if(!values.last_name){
        errors.last_name = "Surname Is Required";
    }
        else if(!name_filter.test(values.last_name)){
            errors.last_name = "Surname Must Be A Name";
        }
    if(!values.first_name){
        errors.first_name = "Firstname Is Required";
    }
        else if(!names_filter.test(values.first_name)){
            errors.first_name = "Firstname Must Be A Name";
        }
    if(!values.email){
        errors.email = "Email Is Required";
    }
        else if(!email_filter.test(values.email)){
            errors.email = "Invalid Email";
        }
    
    if(!values.phone){
        errors.phone = "Phone Number Is Required";
    }
        else if(!phone_number_filter.test(phone_comb)){
                errors.phone = "Invalid Phone Number";
        }
        else if(values.phone && values.phone.length < 5){
            errors.phone = "Invalid Phone Number";
        }
    if(!values.password){
        errors.password = "Password Is Required";
    }
        else if(!pwd_filter.test(values.password)){
            errors.password = "Password Must Have At Least One Lowercase, Uppercase and Numeric Character";
        }
    if(!values.re_password){
        errors.re_password = "Password Is Required";
    }
        else if(values.re_password !== values.password){
            errors.re_password = "Password And Confirm Password Do Not Match";
        }
    return errors;
}

export const validateStudentDataForm = (values) => {
    const errors = {};
    const email_filter = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const phone_number_filter = /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,7}$/im;  
    const ext = [".png", ".jpg", "jpeg"];

    if(!values.profile_pic_value){
        errors.profile_pic = "Passport Is Required";
    }
    else{
        const file_ext = values.profile_pic.name.slice(-4);
        if(!ext.includes(file_ext)){
            errors.profile_pic = "Invalid File Format";
            values.profile_pic = null;
            values.profile_pic_value = undefined;
            values.img = null;
        }
        else if(values.profile_pic_value && values.profile_pic.size > 1500000){
            errors.profile_pic = "File Size Must Not Be Greater Than 1.5mb";
            values.profile_pic = null;
            values.profile_pic_value = undefined;
        }
    }
    if(!values.gender){
        errors.gender = "Gender Is Required";
    }
    if(!values.dob){
        errors.dob = "Date Of Birth Is Required";
    }
    if(!values.rel){
        errors.rel = "Religion Is Required";
    }
    if(!values.lang_spoken){
        errors.lang_spoken = "Language Is Required";
    }
    if(!values.addr){
        errors.addr = "Address Is Required";
    }
    if(!values.nationality){
        errors.nationality = "Nationality Is Required";
    }
    if(!values.state_origin){
        errors.state_origin = "State Of Origin Is Required";
    }
    if(!values.prev_class){
        errors.prev_class = "Class Is Required";
    }
    if(!values.curr_class){
        errors.curr_class = "Class Is Required";
    }
    if(values.prev_class !== "none" && !values.prev_school){
        errors.prev_school = "Previous School Name And Address Is Required";
    }
        else if(values.prev_school && values.prev_school.length < 10){
            errors.prev_school = "Previous School Name And Address Is Required";
        }
    
    if(!values.birth_cert_value){
        //errors.birth_cert = "Birth Certificate Is Required";
    }
    else{
        const file_ext = values.birth_cert.name.slice(-4);
        if(!ext.includes(file_ext)){
            errors.birth_cert = "Invalid File Format";
            values.birth_cert = null;
            values.birth_cert_value = undefined;
        }
        else if(values.birth_cert_value && values.birth_cert.size > 1500000){
            errors.birth_cert = "File Size Must Not Be Greater Than 1.5mb";
            values.birth_cert = null;
            values.birth_cert_value = undefined;
        }
        
    }
    if(values.prev_class !== "none" && !values.last_rep_card_value){
        //errors.last_rep_card = "Last Report Card Is Required";
    }
        else if(values.last_rep_card_value && !ext.includes(values.last_rep_card.name.slice(-4))){
                errors.last_rep_card = "Invalid File Format";
                values.last_rep_card = null;
                values.last_rep_card_value = undefined;
        }
        else if(values.last_rep_card_value && values.last_rep_card.size > 1500000){
            errors.last_rep_card = "File Size Must Not Be Greater Than 1.5mb";
            values.last_rep_card = null;
            values.last_rep_card_value = undefined;
        }
        

    if(!values.school_branch){
        errors.school_branch = "School Branch Is Required";
    }
    if(!values.custody){
        errors.custody = "Required*";
    }
    if(!values.terms){
        errors.terms = "Terms And Agreement Must Be Checked";
    }
    if(!values.fath_name){
        errors.fath_name = "Father's FullName Is Required*";
    }
    if(!values.fath_nationality){
        errors.fath_nationality = "Father's Nationality Is Required*";
    }
    if(!values.fath_email && !values.is_fath_dead){
        errors.fath_email = "Father's Email Is Required*";
    }
        else if(!values.is_fath_dead && !email_filter.test(values.fath_email)){
            errors.fath_email = "Invalid Email Address*";
        }
    if(!values.fath_phone && !values.is_fath_dead){
        errors.fath_phone = "Father's Phone Number Is Required*";
    }
        else if(!values.is_fath_dead && !phone_number_filter.test(values.fath_phone)){
            errors.fath_phone = "Invalid Phone Number*";
        }
    if(!values.fath_occupation && !values.is_fath_dead){
        errors.fath_occupation = "Father's Occupation Is Required*";
    }
    if(!values.fath_addr && !values.is_fath_dead){
        errors.fath_addr = "Father's Address Is Required*";
    }
    if(!values.sign_value && !values.is_fath_dead){
        errors.sign = "Father's Signature Is Required";
        
    }
    else{
        const file_ext = values.sign.name.slice(-4);
        if(!values.is_fath_dead && !ext.includes(file_ext)){
            errors.sign = "Invalid File Format";
            values.sign = null;
            values.sign_value = undefined;
        }
        else if(values.sign_value && values.sign.size > 500000){
            errors.sign = "File Size Must Not Be Greater Than 500kb";
            values.sign = null;
            values.sign_value = undefined;
        }
    }
    
    if(!values.moth_name){
        errors.moth_name = "Mother's FullName Is Required*";
    }
    if(!values.moth_nationality){
        errors.moth_nationality = "Mother's Nationality Is Required*";
    }
    if(!values.moth_email && !values.is_moth_dead){
        errors.moth_email = "Mother's Email Is Required*";
    }
        else if(!values.is_moth_dead && !email_filter.test(values.moth_email)){
            errors.moth_email = "Invalid Email Address*";
        }
    if(!values.moth_phone && !values.is_moth_dead){
        errors.moth_phone = "Mother's Phone Number Is Required*";
    }
        else if(!values.is_moth_dead && !phone_number_filter.test(values.moth_phone)){
            errors.moth_phone = "Invalid Phone Number*";
        }
    if(!values.moth_occupation && !values.is_moth_dead){
        errors.moth_occupation = "Mother's Occupation Is Required*";
    }
    if(!values.moth_addr && !values.is_moth_dead){
        errors.moth_addr = "Mother's Address Is Required*";
    }
    if(!values.second_sign_value && !values.is_moth_dead){
        errors.second_sign = "Mother's Signature Is Required";
        
    }
    else{
        const file_ext = values.second_sign.name.slice(-4);
        if(!values.is_moth_dead && !ext.includes(file_ext)){
            errors.second_sign = "Invalid File Format";
            values.second_sign = null;
            values.second_sign_value = undefined;
        }
    }

    if(!values.emergency_name){
        errors.emergency_name = "FullName Is Required*";
    }
    if(!values.emergency_country_residence){
        errors.emergency_country_residence = "Country Of Residence Is Required*";
    }
    if(!values.emergency_relate){
        errors.emergency_relate = "Required*";
    }
    if(!values.emergency_email){
        errors.emergency_email = "Email Is Required*";
    }
        else if(!email_filter.test(values.emergency_email)){
            errors.emergency_email = "Invalid Email Address*";
        }
    if(!values.emergency_phone){
        errors.emergency_phone = "Phone Number Is Required*";
    }
        else if(!phone_number_filter.test(values.emergency_phone)){
            errors.emergency_phone = "Invalid Phone Number*";
        }
    if(!values.emergency_occupation){
        errors.emergency_occupation = " Occupation Is Required*";
    }
    if(!values.emergency_addr){
        errors.emergency_addr = "Address Is Required*";
    }
        else if(values.emergency_addr.length < 10){
            errors.emergency_addr = "Address Must Be Detailed";
        }
    if(!values.loc_heard){
        errors.loc_heard = "Location Is Required";
    }
    if(!values.enroll_type){
        errors.enroll_type = "Required*";
    }
    return errors;
}

export const validateParentChildDataForm = (values, parent) => {
    const errors = {};
    // const email_filter = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    // const phone_number_filter = /^[\+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,7}$/im;  
    const ext = [".png", ".jpg", "jpeg"];
    if(!values.is_parent){
        errors.is_parent = "Required*";
    }
    if(!values.is_spouse_parent){
        errors.is_spouse_parent = "Required*";
    }
    if(!values.profile_pic_value){
        errors.profile_pic = "Passport Is Required";
    }
    else{
        const file_ext = values.profile_pic.name.slice(-4);
        if(!ext.includes(file_ext)){
            errors.profile_pic = "Invalid File Format";
            values.profile_pic = null;
            values.profile_pic_value = undefined;
            values.img = null;
        }
        else if(values.profile_pic_value && values.profile_pic.size > 1000000){
            errors.profile_pic = "File Size Must Not Be Greater Than 1mb";
            values.profile_pic = null;
            values.profile_pic_value = undefined;
        }
    }
    if(!values.sur_name){
        errors.sur_name = "Surname Is Required";
    }
    if(!values.other_name){
        errors.other_name = "Othername Is Required";
    }
    if(!values.gender){
        errors.gender = "Gender Is Required";
    }
    if(!values.dob){
        errors.dob = "Date Of Birth Is Required";
    }
    if(!values.rel){
        errors.rel = "Religion Is Required";
    }
    if(!values.lang_spoken){
        errors.lang_spoken = "Language Is Required";
    }
    if(!values.addr){
        errors.addr = "Address Is Required";
    }
    if(!values.nationality){
        errors.nationality = "Nationality Is Required";
    }
    // if(!values.state_origin){
    //     errors.state_origin = "State Of Origin Is Required";
    // }
    if(!values.prev_class){
        errors.prev_class = "Class Is Required";
    }
    if(!values.curr_class){
        errors.curr_class = "Class Is Required";
    }
    if(values.prev_class !== "none" && !values.prev_school){
        errors.prev_school = "Previous School Name And Address Is Required";
    }
        else if(values.prev_school && values.prev_school.length < 10){
            errors.prev_school = "Previous School Name And Address Is Required";
        }
    
    if(!values.birth_cert_value){
        //errors.birth_cert = "Birth Certificate Is Required";
    }
    else{
        const file_ext = values.birth_cert.name.slice(-4);
        if(!ext.includes(file_ext)){
            errors.birth_cert = "Invalid File Format";
            values.birth_cert = null;
            values.birth_cert_value = undefined;
        }
        else if(values.birth_cert_value && values.birth_cert.size > 1500000){
            errors.birth_cert = "File Size Must Not Be Greater Than 1.5mb";
            values.birth_cert = null;
            values.birth_cert_value = undefined;
        }
    }
    if(values.prev_class !== "none" && !values.last_rep_card_value){
        //errors.last_rep_card = "Last Report Card Is Required";
    }
        else if(values.last_rep_card_value && !ext.includes(values.last_rep_card.name.slice(-4))){
                errors.last_rep_card = "Invalid File Format";
                values.last_rep_card = null;
                values.last_rep_card_value = undefined;
        }
        else if(values.last_rep_card_value && values.last_rep_card.size > 1500000){
            errors.last_rep_card = "File Size Must Not Be Greater Than 1.5mb";
            values.last_rep_card = null;
            values.last_rep_card_value = undefined;
        }
        

    if(!values.school_branch){
        errors.school_branch = "School Branch Is Required";
    }
    if(!values.custody){
        errors.custody = "Required*";
    }
    if(!values.terms){
        errors.terms = "Terms And Agreement Must Be Checked";
    }
    if(values.is_parent === "no" && !values.relate_child){
        errors.relate_child = "Required*";
    }
    if(!parent.sign && !values.sign_value){
        errors.sign = "Signature Is Required";
        
    }
    else if(!parent.sign && values.sign_value){
        const file_ext = values.sign.name.slice(-4);
        if(!ext.includes(file_ext)){
            errors.sign = "Invalid File Format";
            values.sign = null;
            values.sign_value = undefined;
        }

    }
    
    if(!values.sign_check && parent.sign){
        errors.sign_check = "Signature Is Required";
    }
    if(!values.enroll_type){
        errors.enroll_type = "Required*";
    }
    
    if((values.is_parent === "no" && parent.gender === "M" && !values.fath_name) || 
        (!values.is_spouse_parent && parent.spouse_type === "husband" && !values.fath_name) || 
        (values.is_spouse_parent === "no" && parent.spouse_type === "husband" && !values.fath_name))
        {
            errors.fath_name = "Father's Fullname Is Required";
        }
    if((values.is_parent === "no" && parent.gender === "M" && !values.fath_nationality) || 
    (!values.is_spouse_parent && parent.spouse_type === "husband" && !values.fath_nationality) || 
    (values.is_spouse_parent === "no" && parent.spouse_type === "husband" && !values.fath_nationality))
    {
        errors.fath_nationality = "Father's Nationality Is Required";
    }
    if((values.is_parent === "no" && parent.gender === "M" && !values.fath_email && !values.is_fath_dead) || 
    (!values.is_spouse_parent && parent.spouse_type === "husband" && !values.fath_email && !values.is_fath_dead) || 
    (values.is_spouse_parent === "no" && parent.spouse_type === "husband" && !values.fath_email && !values.is_fath_dead))
    {
        errors.fath_email = "Father's Email Is Required";
    }
    if((values.is_parent === "no" && parent.gender === "M" && !values.fath_phone && !values.is_fath_dead) || 
    (!values.is_spouse_parent && parent.spouse_type === "husband" && !values.fath_phone && !values.is_fath_dead) || 
    (values.is_spouse_parent === "no" && parent.spouse_type === "husband" && !values.fath_phone && !values.is_fath_dead))
    {
        errors.fath_phone = "Father's Phone Number Is Required";
    }
    if((values.is_parent === "no" && parent.gender === "M" && !values.fath_occupation && !values.is_fath_dead) || 
    (!values.is_spouse_parent && parent.spouse_type === "husband" && !values.fath_occupation && !values.is_fath_dead) || 
    (values.is_spouse_parent === "no" && parent.spouse_type === "husband" && !values.fath_occupation && !values.is_fath_dead))
    {
        errors.fath_occupation = "Father's Occupation Is Required";
    }
    if((values.is_parent === "no" && parent.gender === "M" && !values.fath_addr && !values.is_fath_dead) || 
    (!values.is_spouse_parent && parent.spouse_type === "husband" && !values.fath_addr && !values.is_fath_dead) || 
    (values.is_spouse_parent === "no" && parent.spouse_type === "husband" && !values.fath_addr && !values.is_fath_dead))
    {
        errors.fath_addr = "Father's Address Is Required";
    }
    
    

    if((values.is_parent === "no" && parent.gender === "F" && !values.moth_name) || 
        (!values.is_spouse_parent && parent.spouse_type === "wife" && !values.moth_name) || 
        (values.is_spouse_parent === "no" && parent.spouse_type === "wife" && !values.moth_name))
        {
            errors.moth_name = "Mother's Fullname Is Required";
        }
    if((values.is_parent === "no" && parent.gender === "F" && !values.moth_nationality) || 
    (!values.is_spouse_parent && parent.spouse_type === "wife" && !values.moth_nationality) || 
    (values.is_spouse_parent === "no" && parent.spouse_type === "wife" && !values.moth_nationality))
    {
        errors.moth_nationality = "Mother's Nationality Is Required";
    }
    if((values.is_parent === "no" && parent.gender === "F" && !values.moth_email && !values.is_moth_dead) || 
    (!values.is_spouse_parent && parent.spouse_type === "wife" && !values.moth_email && !values.is_moth_dead) || 
    (values.is_spouse_parent === "no" && parent.spouse_type === "wife" && !values.moth_email && !values.is_moth_dead))
    {
        errors.moth_email = "Mother's Email Is Required";
    }
    if((values.is_parent === "no" && parent.gender === "F" && !values.moth_phone && !values.is_moth_dead) || 
    (!values.is_spouse_parent && parent.spouse_type === "wife" && !values.moth_phone && !values.is_moth_dead) || 
    (values.is_spouse_parent === "no" && parent.spouse_type === "wife" && !values.moth_phone && !values.is_moth_dead))
    {
        errors.moth_phone = "Mother's Phone Number Is Required";
    }
    if((values.is_parent === "no" && parent.gender === "F" && !values.moth_occupation && !values.is_moth_dead) || 
    (!values.is_spouse_parent && parent.spouse_type === "wife" && !values.moth_occupation && !values.is_moth_dead) || 
    (values.is_spouse_parent === "no" && parent.spouse_type === "wife" && !values.moth_occupation && !values.is_moth_dead))
    {
        errors.moth_occupation = "Mother's Occupation Is Required";
    }
    if((values.is_parent === "no" && parent.gender === "F" && !values.moth_addr && !values.is_moth_dead) || 
    (!values.is_spouse_parent && parent.spouse_type === "wife" && !values.moth_addr && !values.is_moth_dead) || 
    (values.is_spouse_parent === "no" && parent.spouse_type === "wife" && !values.moth_addr && !values.is_moth_dead))
    {
        errors.moth_addr = "Mother's Address Is Required";
    }
    return errors;
}

export const validateParentDataForm = (values) => {
    const email_filter = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const phone_number_filter = /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,7}$/im;
    const errors = {};
    if(!values.title){
        errors.title = "Title Is Required";
    }
    if(!values.gender){
        errors.gender = "Gender Is Required";
    }
    if(!values.marital_status){
        errors.marital_status = "Marital Status Is Required";
    }
    if(!values.dob){
        errors.dob = "Date Of Birth Is Required";
    }
    if(!values.rel){
        errors.rel = "Religion Is Required";
    }
    if(!values.nationality){
        errors.nationality = "Country Of Origin Is Required";
    }
    // if(!values.state_origin){
    //     errors.state_origin = "State Of Origin Is Required";
    // }
    if(!values.occupation){
        errors.occupation = "Occupation Is Required";
    }
    if(!values.addr){
        errors.addr = "Address Is Required";
    }
        else if(values.addr.length < 10){
            errors.addr = "Address Must Be Detailed";
        }
    if(values.marital_status === "married" && !values.spouse_title){
        errors.spouse_title = "Spouse's Title Is Required";
    }
    if(values.marital_status === "married" && !values.spouse_name){
        errors.spouse_name = "Spouse's Fullname Is Required";
    }
    if(values.marital_status === "married" && !values.spouse_type){
        errors.spouse_type = "Spouse Type Is Required";
    }
    if(values.marital_status === "married" && !values.spouse_email){
        errors.spouse_email = "Spouse Email Is Required";
    }
        else if(values.marital_status === "married" && !email_filter.test(values.spouse_email)){
            errors.spouse_email = "Invalid Email Address";
        }
    if(values.marital_status === "married" && !values.spouse_phone){
        errors.spouse_phone = "Spouse Phone Number Is Required";
    }
        else if(values.marital_status === "married" && !phone_number_filter.test(values.spouse_phone)){
            errors.spouse_phone = "Invalid Phone Number";
        }
    if(values.marital_status === "married" && !values.spouse_occupation){
        errors.spouse_occupation = "Spouse Occupation Is Required";
    }
    if(!values.emergency_name){
        errors.emergency_name = "Fullname Is Required";
    }
    if(!values.emergency_relate){
        errors.emergency_relate = "Relationship Is Required";
    }
    if(!values.emergency_email){
        errors.emergency_email = "Email Is Required";
    }
        else if(values.marital_status === "married" && !email_filter.test(values.emergency_email)){
            errors.emergency_email = "Invalid Email Address";
        }
    if(!values.emergency_phone){
        errors.emergency_phone = "Phone Number Is Required";
    }
    
        else if(!phone_number_filter.test(values.emergency_phone)){
            errors.emergency_phone = "Invalid Phone Number";
        }
    if(!values.emergency_work_phone){
        errors.emergency_work_phone = "Work Phone Number Is Required";
    }
        else if(!phone_number_filter.test(values.emergency_work_phone)){
            errors.emergency_work_phone = "Invalid Phone Number";
        }
    if(!values.emergency_country_residence){
        errors.emergency_country_residence = "Country Of Residence Is Required";
    }
    if(!values.emergency_occupation){
        errors.emergency_occupation = "Occupation Is Required";
    }
    if(!values.emergency_addr){
        errors.emergency_addr = "Address Is Required";
    }
        else if(values.emergency_addr.length < 10){
            errors.emergency_addr = "Address Must Be Detailed";
        }
    if(!values.loc_heard){
        errors.loc_heard = "Location Is Required";
    }
    return errors;
}

export const validateSchoolStaff = (values, job_types) => {
    const errors = {};
    const email_filter = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const phone_number_filter = /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,7}$/im;  
    const ext = [".png", ".jpg", "jpeg"];

    if(!values.profile_pic_value){
        errors.profile_pic = "Passport Is Required";
    }
        else if(!ext.includes(values.profile_pic.name.slice(-4))){
            errors.profile_pic = "Invalid File Format";
            values.profile_pic = File;
            values.profile_pic_value = undefined;
            values.img = null;
        }
        else if(values.profile_pic.size > 5000000){
            errors.profile_pic = "File Size Must Not Be Greater Than 5mb";
            values.profile_pic = File;
            values.profile_pic_value = undefined;
            values.img = null;
        }
    if(!values.gender){
        errors.gender = "Gender Is Required";
    }
    if(!values.dob){
        errors.dob = "Date Of Birth Is Required";
    }
    if(!values.rel){
        errors.rel = "Religion Is Required";
    }
    if(!values.lang_spoken){
        errors.lang_spoken = "Language Is Required";
    }
    if(!values.addr){
        errors.addr = "Address Is Required";
    }
        else if(values.addr.length < 10){
            errors.addr = "Address Must Be Detailed";
        }
    if(!values.nationality){
        errors.nationality = "Nationality Is Required";
    }
    // if(!values.state_origin){
    //     errors.state_origin = "State Of Origin Is Required";
    // }
    if(!values.marital_status){
        errors.marital_status = "Marital Status Is Required";
    }
    if(!values.national_id_value){
        errors.national_id = "National ID Is Required";
    }
        else if(!ext.includes(values.national_id.name.slice(-4))){
            errors.national_id = "Invalid File Format";
            values.national_id = File;
            values.national_id_value = undefined;
        }
        else if(values.national_id.size > 5000000){
            errors.national_id = "File Size Must Not Be Greater Than 5mb";
            values.national_id = File;
            values.national_id_value = undefined;
        }
    if(!values.job){
        errors.job = "Required*";
    }
    if(!values.qualification){
        errors.qualification = "Required*";
    }
    if(values.qualification === "other" && !values.qualification_other){
        errors.qualification_other = "Required*";
    }
    if(values.qualification && values.qualification !== "none" && !values.school_cert_value){
        errors.school_cert = "Certificate Required*";
    }
        else if(values.qualification && values.qualification !== "none" && !ext.includes(values.school_cert.name.slice(-4))){   
            errors.school_cert = "Invalid File Format";
            values.school_cert = File;
            values.school_cert_value = undefined;
        }
        else if(values.qualification && values.qualification !== "none" && values.school_cert.size > 5000000){   
            errors.school_cert = "File Size Must Not Be Greater Than 5mb";
            values.school_cert = File;
            values.school_cert_value = undefined;
        }
    if(values.qualification && values.qualification !== "none" && !values.cert_name){
        errors.cert_name = "Required*";
    }
    if(values.qualification && values.qualification !== "none" && values.qualification.search('degree') > 0 && !values.cert_type){
        errors.cert_type = "Required*";
    }
    if(job_types.staff_t === "academic" && !values.sub_teach){
        errors.sub_teach = "Required*";
    }
    if(!values.job){
        errors.job = "Required*";
    }
    if(!values.exp){
        errors.exp = "Experience Is Required";
    }
    if(!values.loc_heard){
        errors.loc_heard = "Required*";
    }
    if(!values.kin){
        errors.kin = "Kin Is Required";
    }
    if(!values.kin_name){
        errors.kin_name = "Fullname Is Required";
    }
    if(!values.kin_phone){
        errors.kin_phone = "Phone Number Is Required";
    }
        else if(!phone_number_filter.test(values.kin_phone)){
            errors.kin_phone = "Invalid Phone Number";
        }
    if(!values.kin_addr){
        errors.kin_addr = "Address Is Required";
    }
    return errors;
}

export const validateSchoolStaffApption = (values) => {
    const errors = {};
    if(!values.apply){
        errors.apply = "Required*";
    }
    if(!values.school_branch){
        errors.school_branch = "Required*";
    }

    return errors;

}

export const validateAuthorForm = (values) => {
    let errors = {};
    if(!values.name){
        errors.name = "Author's Name is Required";
    }
    return errors;
}
export const validateSchoolBookDataForm = (values, type="") => {
    var errors = {}
    const ext = [".png", ".jpg", "jpeg"];
    const price = parseFloat(`${convertToNumber(values.price)}.${values.price_point ? values.price_point : '0'}`); 
    if(!values.name){
        errors.name = "Book Name Is Required"
    }
    if(values.pic_value && !values.is_disable_pic){
        const file_ext = values.pic.name.slice(-4);
        if(!ext.includes(file_ext)){
            errors.pic = "Invalid File Format";
            values.pic = null;
            values.pic_value = undefined;
            values.pic_img = null;
        }
    }
    if(!values.school_branch.length){
        errors.school_branch = "School Branch is Required";
    }
    else{
        errors.sb_qty_stock = []
        for(let i = 0; i < values.school_branch.length; i++){
            if(!values.school_branch[i].qty_stock){
                errors.sb_qty_stock.push("Quantity In Stock Is Required");
            } else if(isNaN(parseInt(values.school_branch[i].qty_stock))){
                errors.sb_qty_stock.push("Value Must Be A Number");
            }
        }
    }
    if(!values.classes.length){
        errors.classes = "Class is Required";
    }
    if(!values.subject){
        errors.subject = "Subject is Required";
    }
    if(!values.price){
        errors.price = "Price is Required";
    }
        else if(isNaN(price)){
            errors.price = "Price Must Be A Number";
        }
    if(!values.author && !values.is_disable_author && !type){
        errors.author = "Author Name is Required";
    }
    return errors;
}