import React, { Fragment, useState, useEffect } from "react";
// import countries from "sort/voskool/dataSort/countries";
// import States from "sort/voskool/states";
import { Wrapper, Content, InnerContent, InputContent, SelectContent } from "./style";
import { CountryWrapper, CountryContent, CountryImage, CountryInp } from "components/countrySort/style";
import Grid from "components/grid";
import PopulateSchools, { Div } from "components/populateSchools";
import LoadMoreSchoolsBtn from "components/loadSchoolsBtn";
import Spinner from "components/spinner";

import { convertNumber } from "components/app/actions/money";

import { BACKEND_URL } from "actions/url";

let initCountry =  {id:0, name:'', flag:'', code:''}
let initialPaginationState = {count:0, previous:undefined, next:undefined, results:[]};
const SchoolsComponent = ({schools=initialPaginationState, countries=[], link_title="Enroll", is_change_country=false, is_loading_schools=false, setCP}) => {
    const [state, setState] = useState({q:'', exp_rate:''});
    const [pageCount, setPageCount] = useState({p:1, size:25, q:'', exp_rate:'', is_initial:false});
    const [countryData, setCountryData] = useState(initCountry);

    const [isSearch, setIsSearch] = useState(false);
    const [dispCountryData, setDispCountryData] = useState(false);

    const handleFilter = (e) => {
        setIsSearch(true);
        setState((prev) => {return {...prev, [e.target.name]:e.target.value}});
    }
    const insert_countries = countries.filter((item) => item.id !== countryData.id).map((item, index) => {
        return(
            <div key={item.id} value={item.id} title={item.name} onClick={() => onClickCountry(item.id)}>
                <CountryImage src={`${BACKEND_URL}${item.flag}`}/>
                <button type="button">{item.name}</button>
            </div> 
        )
    })
    const dispData = () => {
        if(dispCountryData){
            setDispCountryData(false);
        }
        else{
            setDispCountryData(true);
        }
    }

    const onClickCountry = (e) => {
        const val = e;
        countries.filter((item) => {
            if(item.id.toString().includes(val)){
                setCountryData(item);
                setDispCountryData(false);
                // setCt({...item});
                return item;
            }
            else{
                return null;
            }
            
            
        })
    }
    const onLoad = () => {
        if(countries.length === 1){
            setCountryData({...countries[0]});
            // setCt({...countries[0]});
        }
    }
    const OnClickLoadMore = (next_page=0) => {
        setPageCount({...pageCount, p:next_page, is_initial:false});
        setCP({...pageCount, p:next_page, is_initial:false});
    }

    const countrySelect = () =>{
        return(
            <CountryWrapper className="anim"> 
                <div className="selected-country">
                    <img alt="" onClick={dispData}  title={countryData ? countryData.name : ""}  src={countryData ? `${BACKEND_URL}${countryData.flag}` : ""} /> <i onClick={dispData} className="fas fa-arrow-down"></i>
                    <div  className={ dispCountryData ? `${" country-cont"}` : `${'disp-none country-cont'}`  }>
                        <CountryContent>
                            {is_change_country && <CountryInp type="text" onChange={e => handleFilter(e)} placeholder="Search Country's Name" name="q" value={state.q} />}
                            {countryData.id > 0 && (
                                <div value={countryData.id} title={countryData.name} onClick={() => onClickCountry(countryData.id)} style={{paddingBottom:'10px', marginBottom:'5px', borderBottom:'1px dotted #666'}}>
                                    <CountryImage src={`${BACKEND_URL}${countryData.flag}`}/>
                                    <button type="button">{countryData.name}</button>
                                </div> 
                            )}
                            {insert_countries}    
                        </CountryContent>
                    </div>
                </div>
            </CountryWrapper>
        );
    }

    useEffect(() => {
        if(isSearch){
            const timeout = setTimeout(() => {
                setIsSearch(false);
                setPageCount((prev) => {return {...prev, ...state, p:1, is_initial:true}});
                setCP({...pageCount, ...state, p:1, is_initial:true});
            }, 600)
            return () => {
                clearTimeout(timeout);
            }
        }
    }, [isSearch, state, pageCount, setCP])

    console.log(isSearch)
    
    return(
        <Wrapper onLoad={() => onLoad()}>
          <Content>
              <SelectContent>
              {countrySelect()}
                <span></span>
                <select className="anim" name="exp_rate" value={state.exp_rate} onChange={e => handleFilter(e)}>
                    <option  disabled>Sort By</option>
                    <option value="" selected>Top Rating</option>
                    <option value={1}>Most Expensive</option>
                    <option value={0}>Least Expensive</option>
                    {/* <option value="1">Cheapest</option> */}
                </select>
                {/* <input list="list" className="anim" name="list_states" placeholder="Filter By" />
                <datalist className="anim" id="list">
                    <option value=""></option>
                    <States country_code={countryData ? countryData.code : ""} />
                </datalist> */}
                
              </SelectContent>
              <InputContent>
                <div className="input-cont anim">
                    <input className="anim" type="text" placeholder="Search..." name="q" value={state.q} onChange={e => handleFilter(e)} />
                    <div className="input-icon">
                        <i className="fas fa-search"></i>
                    </div>
                </div>    
              </InputContent>

              <InnerContent>
                <Grid header="Enroll To A School Now" grid_width="small-w">
                    {is_loading_schools && (<div className="disp-block align-center"><Spinner /></div>) }
                    {schools.count > 0 ? (
                        <>
                        {schools.results.length > 0 ? (
                            <>
                            {schools.results.map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <PopulateSchools title={item.title} link_title={`${link_title} Now`} link={`/${item.title}#enroll`} name={item.name} icon_alt={item.title} bg_img_src={`${BACKEND_URL}${item.pic}`} icon_src={`${BACKEND_URL}${item.icon}`}>
                                            <Div title="Class Section:" is_capitalize desc={item.class_section.join(', ')} />
                                            <Div title="Fee:" desc={`${item.lowest_fee ? `${item.curr_symbol}${convertNumber(item.lowest_fee)}` : ``} ${item.highest_fee ? `- ${item.curr_symbol}${convertNumber(item.highest_fee)}` : ``}`} />
                                            <Div title="Address:" is_capitalize desc={item.address} />
                                        </PopulateSchools>
                                    </Fragment>
                                )
                            })}
                            </>
                        ): (
                            <>
                            {!is_loading_schools && <div className="disp-block align-center"><span className="font-slightly-small">No School Found</span></div>}
                            </>
                        )}
                        </>
                    ) : (
                        <>
                        {!is_loading_schools && <div className="disp-block align-center"><span className="red font-small">No School Found</span></div>}
                        </>
                    )}
                    
                </Grid>
                {is_loading_schools && (<div className="disp-block align-center"><Spinner /></div>) }
                {schools.next && <LoadMoreSchoolsBtn text="Load More" result={schools.results.length} onClick={() => OnClickLoadMore(pageCount.p+1)} />}
              </InnerContent>
          </Content>
        </Wrapper>
    );
}
// const opts = document.getElementById('dlist').childNodes;
// const dinput = document.getElementById('dinput');
// let eventSource = null;
// let value = '';

// dinput.addEventListener('keydown', (e) => {
//   eventSource = e.key ? 'input' : 'list';
// });
// dinput.addEventListener('input', (e) => {
//   value = e.target.value;
//   if (eventSource === 'list') {
//     alert('CLICKED! ' + value);
//   }
// });
export default SchoolsComponent;