import { styled } from "styled-components"
export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .padd-10px{
        padding: 10px;
    }
    .padd-6px{
        padding: 6px;
    }
    .lt05px{
        letter-spacing: .5px;
    }
    .br{
        border-radius: 5px;
        box-shadow: 0 0 3px 2px rgba(0,0,0,0.03);
    }
    .flex-33-mr{
        flex: 0 0 32%;
        max-width: 32%;

        @media(max-width: 1280px){
            flex: 0 0 48%;
            max-width: 48%;
        }
        @media(max-width: 540px){
            flex: 0 0 47%;
            max-width: 47%;
        }
        @media(max-width: 430px){
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .flex-50{
        flex: 0 0 50%;
        max-width: 50%;

        @media(max-width: 820px){
            flex: 0 0 100%;
            max-width: 100%;
        }

        .img-head-cont{
            padding: 1px;
            width: 100%;

            .img{
                width: 100%;
                max-height: 60vh;
                border-radius: 10px;
                object-fit: contain;
            }
        }
    }
`;