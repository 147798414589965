import React from "react";
import { Content } from "./style";

const FlexWrapper = ({children}) => {
    return(
        <Content>
            {children}
        </Content>
    )
}

export default FlexWrapper;