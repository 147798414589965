import React from "react";
import { Wrapper } from "./style";
import ComponentHeader from "components/compoHeader";
import SchoolsComponent from "components/schools";

let initialPaginationState = {count:0, previous:undefined, next:undefined, results:[]};
const Schools = ({is_loading_countries=false, is_loading_schools=false, countries=[], schools=initialPaginationState, link_title="", setCP}) => {
    const onCP = (it) => {
        setCP(it);
    }
    return(
        <>
        <section id="offer">
            <Wrapper id="schools">
                <ComponentHeader title={"Schools"} numIcons={15} />
                <SchoolsComponent is_loading_schools={is_loading_schools} is_loading_countries={is_loading_countries} countries={countries} schools={schools} link_title={link_title} setCP={onCP} />
            </Wrapper>  
        </section>  
        </>    
    );
}

export default Schools;