import styled from "styled-components";

export const Content = styled.div`
    width: 90%;
    text-align: right;
    position: relative;

    .user{
        position: relative;
        transition: .4s ease;
        
        .user-data{
            display: none;
            position: absolute;
            background: #fff;
            border-radius: 15px;
            box-shadow: 0 0 3px 5px rgba(0,0,0,0.1);
            padding: 10px;
            text-align: left;
            transform: translateX(-50%);
            transition: .4s ease;
            width: 200px;
            @media(max-width: 780px){
                transform: translateX(-35%);
            }
            @media(max-width: 540px){
                transform: translateX(-52%);
            }
            @media(max-width: 450px){
                transform: translateX(-66%);
            }
            @media(max-width: 375px){
                transform: translateX(-70%);
            }
            @media(max-width: 320px){
                transform: translateX(-74%);
            }
            @media(max-width: 280px){
                transform: translateX(-78%);
            }
            .main-header{
                
                display: flex;          
                .header{
                    justify-content: space-between;
                    padding: 0 5px;
                    width: 100%;
                    margin-bottom: 4px;
                    .heading{
                        font-size: .7rem;
                        font-weight: 600;
                        color: #555;
                        text-transform: lowercase;
                    }
                    .text{
                        font-size: .65rem;
                        font-weight: 500;
                        text-transform: lowercase;   
                    }
                }
            }
            .data-content{
                margin: 10px 0;
                
                .header{
                    margin-top: 10px;
                    margin-bottom: 8px;
                    span{
                        font-size: .8rem;
                        font-weight: 700;
                        color: #555;
                    }
                }
                .flex{
                    display: flex;
                    justify-content: space-between;
                }
                a{
                    text-decoration: none;
                    .data-flex{
                        display: flex;
                        flex-wrap: wrap;
                        padding: 6px;
                        margin-bottom: 6px;
                        transition: .4s ease;
                        border-bottom: 1px solid #ddd;
                        border-radius: 5px;
                        cursor: pointer;
                        :hover{
                            border-bottom: 1px solid var(--voskoolBagColor);
                        }
                        .data-img{
                            flex: 0 0 17%;
                            max-width: 17%; 
                            
                            img{
                                width: 20px;
                                height: 20px;
                            }
                        }
                        .datas-img{
                            flex: 0 0 20%;
                            max-width: 20%; 
                            
                            img{
                                width: 24px;
                                height: 24px;
                            }
                            i{
                                font-size: 1.2rem;
                                color: var(--voskoolBagColor)
                            }
                        }
                        .datas{
                            flex: 0 0 80%;
                            max-width: 80%; 
                            
                            span{
                                font-size:.7rem;
                                font-weight: 700;
                                display: block;
                                color: #666;
                                text-transform: capitalize;
                                padding-top: 5px;
                                padding-bottom: 1px;
                                padding-left: 3px;
                            }
                        }
                        .data{
                            flex: 0 0 78%;
                            max-width: 78%; 
                            
                            .heading{
                                font-size:.7rem;
                                font-weight: 700;
                                display: block;
                                color: #111;
                                text-transform: uppercase;
                                padding-bottom: 1px;
                            }
                            .text{
                                font-size:.65rem;
                                font-weight: 600;
                                display: block;
                                color: #888;
                                text-transform: capitalize;
                            }
                        }
                        .data-icon{
                            flex: 0 0 5%;
                            max-width: 5%;

                            i{
                                font-size:.6rem; 
                                padding: 3px;
                                background: var(--voskoolBagColor);
                                border-radius: 50%;
                                color: #eee;
                            }
                        }
                    }
                }
            }
        }
    }
    .user:hover .user-data{
        display: block;
    }
`;