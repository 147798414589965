import React, { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import BackDrop from "components/backDrop";
import { Content } from "./style";
import Preloader from "components/preloader";
import InlineNotification from "components/notification";
import { login } from "actions/auth";
import authHandler from "actions/auth_handler";
import { useNavigate } from "react-router-dom";

const initialLoginState = {
    "type":"",
    "code":null,
    "data":null,
    "detail": "",
}
const LoginPage = ({handleClose, is_close=true, user={}}) => {
    const [formData, setFormData] = useState({email: '', password: ''});
    const [loading, setLoading] = useState(false);
    const [loginState, setLoginState] = useState(initialLoginState);
    const [navigate, setNavigate] = useState('');

    const [searchParam] = useSearchParams();
    const nav = useNavigate();
    const {email, password} = formData;
    const onChange = e => setFormData({...formData, [e.target.name]:e.target.value})
    const onSubmit = (e) => {
        setLoading(true);
        e.preventDefault();
        
        authHandler.login(login(email, password)).then(result => { 
            setLoginState({...loginState, ...result})
            setLoading(false);
        }).catch(result => {
            setLoginState({...loginState, ...result})
            setLoading(false);
        });
        
        
    }
    const loginNotification = () => {
        if(loading) return (
            <Preloader />
            )
        
        if(loginState.type === "error"){
            return(
                <InlineNotification type="error" data={loginState.detail} />
           )
        }
        else if(loginState.type === "success"){
            return(
                <InlineNotification type="success" data="Data OK!" />
           )
        }
       
         
    }
    useEffect(() => {
        if(loginState.type === "success" && searchParam.get('red_url')){
            window.location.reload();
            return;
        }
        if(loginState.type === "success"){
            window.location.reload();
            return;
        }
        if(user.id){
            setNavigate(searchParam.get('red_url') ? searchParam.get('red_url') : '/');
            return;
        }
    }, [loginState.type, user.id,  searchParam])

    useEffect(() => {
        if(navigate){
            window.location.href = navigate;
            setNavigate('');
            return;
        }
    }, [navigate, nav])
    
    const dropIn = {
        hidden: {
            y:'-100vh',
            opacity: 0,
            duration: 0.5,
        },
        visible: {
            y:'12vh',
            x:'30%',
            opacity: 1,
            transition: {
                duration: 0.5,
                type: "spring",
                damping: 19,
                stiffness: 500,
            },
        },

        exit: {
            y:'100vh',
            opacity: 0,
        },
    }
    return(
        <BackDrop>
            <Content variants={dropIn} initial="hidden" animate="visible" exit="exit" //drag={true} dragConstraints={{left: 80, right: 50, top:20, bottom: 50}}
             >
                <div className="container">
                    {is_close && (
                        <div className="close-modal-cont  bg-black"  onClick={handleClose}>
                            <i className="fas fa-times anim-rotate"></i>
                        </div>
                    )}
                    <div className="header align-center">
                        <div className="heading">
                            <span>Wanna Login?</span>
                        </div>    
                        
                    </div>
                    <div className="inner-content">
                        <form onSubmit={e => onSubmit(e)}>
                            <div className="disp-flex wrap">
                                <div className="flex-100 form-group">
                                    <input type="text" required value={email} onChange={e => onChange(e)} name="email" placeholder="Email Address" disabled={user.id ? true : false} className="form-control rem1 bg-light-grey form-br-15" id="uname" />
                                    <div className="form-icon-group right">
                                        <i className="fas fa-user-circle transparent"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="disp-flex wrap">
                                <div className="flex-100 form-group">
                                    <input type="password" required value={password} onChange={e => onChange(e)} name="password" disabled={user.id ? true : false} placeholder="Password" className="form-control rem1 bg-light-grey form-br-15" id="pwd" />
                                    <div className="form-icon-group right">
                                        <i className="fas fa-lock transparent"></i>
                                    </div>
                                </div>
                            </div>
                            {loginNotification()}
                            
                            <div className="group">
                                <Link to={`/vo/signup`} target={'_blank'}>Don't Have An Account? Sign Up!</Link>
                            </div>
                            <div className="group">
                                <Link to={`https://register.voskool.com/reset_password`} target="_blank">Forgot Your Password?</Link>
                            </div>    
                            <div className="form-group-btn align-center">
                                
                                <button type="submit" disabled={loading && true} className="btn hover-a br-5 shadow rem1 text-upper bg-black">LOGIN <i className="fas fa-paper-plane"></i></button>
                            </div>
                        </form>    
                    </div>
                </div>
            </Content>
        </BackDrop>    
    )
}

export default LoginPage;