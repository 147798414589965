import {useState} from 'react';
// import getAll from 'api/voskool/services/home';
// import homeLogo from 'images/home_logo.jpg';
import home2 from 'images/home2.png';
// const homeData = [
//     {"id":1, "title":"Are You A School Owner?", 
//     "text1":'Voskool is a platform designated with the aim and purpose of making your school the best it can possibly be by solving logical problems your school is currently facing, saving you financial expenses and saving you time in accomplishing tasks',
//     "text2":"Applications Includes The Attendance system, Online and Offline Exams, Library System, Finance Managing System, Transportation System.",
//     "text3":"I can continue this list but it will take up space, if you want to know more, click on the link below!",
//     "image":homeLogo,
//     "image_anim":"anim_img",
//     "link_name":"See Details Now",
//     "link_path":"/"
//     },
//     {"id":2, "title":"Are You A Parent?", 
//     "text1":'Voskool is a platform designated with the aim and purpose of making your school the best it can possibly be by solving logical problems your school is currently facing, saving you financial expenses and saving you time in accomplishing tasks',
//     "text2":"Applications Includes The Attendance system, Online and Offline Exams, Library System, Finance Managing System, Transportation System.",
//     "text3":"",
//     "image":home2,
//     "image_anim":"anim_new_img",
//     "link_name":"Check It Now",
//     "link_path":"/"
//     },
// ] ;

const initialState = [{
    "id":-1, 
    "title":"Transforming Education Virtually!", 
    "text1":'Voskool is a platform that interconnects school, teachers, parent and your children with amazing application features to create connectivity and to mitigate hassle and stress',
    "text2":"Some of our applications include: <b>Result Checking System</b>, <b>Online Payment System</b>, <b>Finance Management System</b> and so much more.",
    "text3":"",
    "image":home2,
    "image_anim":"anim_new_img",
    "link_name":"See More",
    "link_path":"#offer"
}];
export const useHomeData = () => {
    
    const [homeData] = useState(initialState);

    // const fetchData = () => {
    //     getAll().then(response => {
    //         setHomeData(response.data);
    //         console.log(response.data);
    //     }).catch(e => {
    //         console.log(e);
    //     });
    // }

    // useEffect(() => {
    //     fetchData();
    // }, [homeData.length])
    return {homeData}
}
