import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    box-sizing: border-box;
    z-index: 10;
    animation: wranimate .6s ease;
    -webkit-animation: wranimate 1s ease;
    -moz-animation: wranimate 1s ease;

    @keyframes wranimate {
        from{
           opacity: 0;
        }
        to{
            opacity: 1;
        }
    }
    .disp_none{
        @media (max-width: 780px) {
            display: none;
        }
    }
    .disp_show{
        @media (max-width: 780px) {
            display: flex;
            -webkit-display: flex;
            -moz-display: flexbox;
            flex-wrap: wrap;
            animation: animHorNav 1s ease;
            -webkit-animation: animHorNav 1s ease;
            -moz-animation: animHorNav 1s ease;
            @keyframes animHorNav{
                from{
                    opacity: 0;
                }
                to{
                    opacity: 1;
                }
            }
        }
    }

    
`;
export const HorizontalNav = styled.div`
    display: none;
    @media (max-width: 780px) {
        width: 40%;
        height: 400px;
        overflow-y: auto;
        background: #26223a;
        padding: 10px 10px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 6px 5px 7px 0 rgba(0,0,0,0.3);
        .header{
            flex: 100%;
            max-width: 100%;
            margin-bottom: 0;
            h5{
                color: #aaa;
                padding-bottom: 6px;
                border-bottom: 1px  solid #aaa;
            }
        }
        .anim_icon{
            animation: animIcon .6s ease;
            -webkit-animation: animIcon .6s ease;
            -moz-animation: animIcon .6s ease;
            transform: rotate(90deg);
            @keyframes animIcon{
                from{
                    transform: rotate(0deg);
                }
                to{
                    transform: rotate(90deg);
                }
            }
        }
        .navLinks{
            flex: 85%;
            max-width: 85%;
            padding: 2px;

            a, span{
                color: #ccc;
                text-decoration: none;
                font-size: .8rem;
                font-weight: 500;
                letter-spacing: .5px;
                padding: 5px;
                border: 1px;
                border-radius: 10px;
                cursor: default;
                transition: .3s ease-in-out;
                -webkit-transition: .5s ease;
                -moz-transition: .5s ease;
                @media (max-width: 540px) {
                    font-size: .8rem;
                }
                @media (max-width: 375px) {
                    font-size: .7rem;
                }
                @media (max-width: 280px) {
                    font-size: .6rem;
                }
            }
            a:hover, span:hover{
                padding-left: 8px;
                border: 1px solid #aaa;
            }
            .disp_none{
                @media (max-width: 780px) {
                    display: none;
                }
            }
            .disp_show{
                @media (max-width: 780px) {
                    display: block;
                    animation: animHorNav 1s ease;
                    -webkit-animation: animHorNav 1s ease;
                    -moz-animation: animHorNav 1s ease;
                    @keyframes animHorNav{
                        from{
                            opacity: 0;
                        }
                        to{
                            opacity: 1;
                        }
                    }
                }
            }
            div{
                padding: 2px;

                div{
                    padding: 3px;
                    width: 100%;
                    a{
                        border: none;
                        font-size: .7rem;
                        @media (max-width: 540px) {
                            font-size: .6rem;
                        }
                        @media (max-width: 375px) {
                            font-size: .5rem;
                        }
                        @media (max-width: 280px) {
                            font-size: .4rem;
                        }
                    }
                    a:hover{
                        border: none;
                        color: #aaa;
                    }
                }
            }
        }
        .navIcons{
            flex: 15%;
            max-width: 15%;
            padding: 2px 0px 3px 2px;

            i{
                color: #ccc;
                text-decoration: none;
                font-size: .8rem;
                padding: 5px;
                border: 1px;
                border-radius: 10px; 
                @media (max-width: 375px) {
                    font-size: .7rem;
                }
                @media (max-width: 280px) {
                    font-size: .6rem;
                }
            }
        }
    }
    @media (max-width: 540px) {
        width: 50%;
    }
`;
export const VerticalNav = styled.div`
    display: flex;
    background: #26223a;
    -webkit-display: flex;
    -moz-display: flexbox;
    flex-wrap: wrap;
    z-index: 10;
`;
export const LogoContent = styled.div`
    display: none;
    @media (max-width: 780px) {
        display: inline-block;
        flex: 0 0 10%;
        max-width: 10%;
    }
    @media (max-width: 780px) {
        .anim_icon{
            animation: animIcon .6s ease;
            -webkit-animation: animIcon .6s ease;
            -moz-animation: animIcon .6s ease;
            transform: rotate(90deg);
            @keyframes animIcon{
                from{
                    transform: rotate(0deg);
                }
                to{
                    transform: rotate(90deg);
                }
            }
        }
        i{
            font-size: var(--fontBig);
            margin: 9px 0 auto;
            padding-left: 4px;
            color: #bbb;
            transition:.4s ease;
        }
        i:hover{
            color: #aaaaaa;
        }
    }
    @media (max-width: 280px) {
        i{
            margin: 10px 0 auto;
            font-size: var(--fontMed);
            
        }
    }
`;

export const ImgContent = styled.div`
    flex: 0 0 15%;
    max-width: 15%;
    padding: 3px;
    @media (max-width: 1280px) {
        flex: 0 0 12%;
        max-width: 12%;
    }
    @media (max-width: 780px){
        flex: 0 0 20%;
        max-width: 20%;
    }
    img{
        width: 70%;
        height: 40px;

        @media (max-width: 1280px) {
            width: 70%;
            height: 30px;
        }
        @media (max-width: 780px) {
            width: 70%;
            height: 30px;
        }
    }
`;
export const SearchContent = styled.div`
    flex: 0 0 40%;
    max-width: 40%;
    padding: 5px;
    padding-left: 15px;
    @media (max-width: 1280px) {
        padding-left: 10px;
    }
    @media (max-width: 780px){
        padding-left: 5px;
        flex: 0 0 50%;
        max-width: 50%;
    }
    
    div{
        margin-top: 4px;

        input{
            background: transparent;
            padding: 6px 6px 6px 10px;
            font-size: .8rem;
            width: 85%;
            border: 1px solid #bbb;
            border-radius: 10px;
            color: #fff;
            letter-spacing:.6px;
            @media (max-width: 1280px) {
            padding: 3px 3px 3px 5px;
            font-size: .6rem;
            width: 82%;
            }
            @media (max-width: 780px){
                padding: 3px 3px 3px 5px;
                font-size: .8rem;
                width: 85%;
            }
            @media (max-width: 540px){
                padding: 3px 3px 3px 5px;
                font-size: .6rem;
                width: 85%;
            }
        }
        input:focus{
            outline: none;
        }
        i{
            font-size: var(--fontMed);
            margin-left: 5px;
            color: #ccc;
            transform: translateY(3px);

            @media (max-width: 1280px) {
                font-size: var(--fontSmall);
            }
            @media (max-width: 780px){
                font-size: .8rem;
            }
        }
    }
`;
export const AuthContent = styled.div`
    flex: 0 0 9%;
    max-width: 9%;
    padding: 5px;

    @media (max-width: 1280px) {
        flex: 0 0 12%;
        max-width: 12%;
    }
    @media (max-width: 780px) {
        flex: 0 0 20%;
        max-width: 20%;
        padding: 2px;
    }
    .log{
        width: 90%;
        margin-left: 10px;
        text-align: right;
        @media (max-width: 1280px) {
            margin-left: 5px;
        }

        a, span{
            transition: .3s ease-in-out;
            -webkit-transition: .5s ease;
            -moz-transition: .5s ease;
            font-size: .7rem;
            padding: 4px;
            text-decoration: none;
            color: #ccc;
            font-weight: 500;
            letter-spacing: .6px;
            border-radius: 7px;
            cursor: pointer;

            @media (max-width: 1280px) {
                font-size: .5rem;
                padding: 2px;
            }
            @media (max-width: 780px) {
                font-size: .7rem;
            }
            @media (max-width: 540px){
                font-size: .5rem;
            }
            i{
                @media (max-width: 280px) {
                    display: none;
                 }
            }
            
        }
        a:hover, span:hover{
            color: #aaa;
        }
    }
`
export const TextContent = styled.div`
    flex: 0 0 8%;
    max-width: 8%;
    padding: 8px;
    text-decoration: none;
    display: inline-block;
    position: relative;
    @media (max-width: 1280px) {
        padding: 5px;
    }
    @media (max-width: 780px){
        display: none;
    }

    a, span{
        font-size: var(--fontSmall);
        color: #ddd;
        padding: 5px;
        font-weight: 300;
        letter-spacing: 1px;
        text-decoration: none;
        transition: .3s ease-in-out;
        -webkit-transition: .5s ease;
        -moz-transition: .5s ease;
        cursor: default;
        text-align: center;
        border: 1px solid #26223a;
        border-radius: 10px;
        display: block;
        @media (max-width: 1280px) {
            font-size: .9rem;
        }
        .fontIcon{
            font-size: .8rem;
            @media (max-width: 1280px) {
            font-size: .4rem;
            }
        }

        div{
            position: absolute;
            visibility: hidden;
            margin-top: 3px;
            background: #000;
            width: 180px;
            border-radius: 10px;

            @media (max-width: 1280px) {
            width: 150px;
            }

            
            
            div {
                padding: 15px;
                @media (max-width: 1280px) {
                    padding: 10px;
                }
                
                a{
                    font-size: .8rem;
                    margin-bottom: 10px;
                    border: none;
                    font-weight: 400;
                    position: relative;

                    @media (max-width: 1280px) {
                        font-size: .6rem;
                    }
                    .dropDownFontIcon{
                        font-size: var(--fontMed);
                        text-align: right;
                        @media (max-width: 1280px) {
                        font-size: var(--fontSmall);
                        }
                    }
                }
            }
        }
        div::after{
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 8px;
            border-style: solid;
            border-color: transparent transparent black transparent;
        }
        
    }
    a.active, span.active{
        transform: scale(1.1);
        border: 1px solid #ccc;
    }
    a:hover{
        color: #ccc;
        transform: scale(1.1);
        border: 1px solid #ccc;
    }
    span:hover, span:focus, span::active{
        color: #ccc;
        border: 1px solid #ccc;
        div{
            animation: animDd .3s ease;
            @keyframes animDd{
                from{
                    transform: scale(0);
                }
                to{
                    transform: scale(1);
                }
            }
            visibility: visible;
        }
    }
`;
export const BigTextContent = styled.div`
    flex: 0 0 12%;
    max-width: 12%;
    padding: 8px;
    display: inline-block;
    position: relative;
    
    @media (max-width: 1280px) {
        padding: 5px;
    }
    @media (max-width: 780px){
        display: none;
    }
    a, span{
        font-size: var(--fontSmall);
        color: #ddd;
        padding: 5px;
        font-weight: 300;
        letter-spacing: 1px;
        text-decoration: none;
        cursor: default;
        transition: .3s ease-in-out;
        -webkit-transition: .5s ease;
        -moz-transition: .5s ease;
        text-align: center;
        border: 1px solid #26223a;
         border-radius: 10px;
        display: block;
        @media (max-width: 1280px) {
            font-size: .9rem;
        }
        .fontIcon{
            font-size: .8rem;
            @media (max-width: 1280px) {
            font-size: .4rem;
            }
        }

        div{
            position: absolute;
            visibility: hidden;
            margin-top: 3px;
            background: #000;
            width: 180px;
            border-radius: 10px;

            
            
            div {
                padding: 15px;
                
                a{
                    font-size: .8rem;
                    margin-bottom: 10px;
                    border: none;
                    font-weight: 400;
                    position: relative;

                    .dropDownFontIcon{
                        font-size: var(--fontMed);
                        text-align: right;
                    }
                }
            }
        }
        div::after{
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 8px;
            border-style: solid;
            border-color: transparent transparent black transparent;
        }
    }
    a.active, span.active{
        transform: scale(1.1);
        border: 1px solid #ccc;
    }
    a:hover{
        color: #ccc;
        transform: scale(1.1);
        border: 1px solid #ccc;
    }
    span:hover{
        color: #ccc;
        border: 1px solid #ccc;
        div{
            animation: animDd .3s ease;
            @keyframes animDd{
                from{
                    transform: scale(0);
                }
                to{
                    transform: scale(1);
                }
            }
            visibility: visible;
        }
    }
`;