import React from "react";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

import ComponentHeader from "components/compoHeader";
import Chunk from 'components/chunk';

import moneyFast from "images/money_hand_holding.jpg";
import resultImg from "images/result.jpg";
import personWithLaptop from "images/person_laptop.jpg";
import teacherTeaching from "images/teacher_teaching.jpg";
import assistantImg from "images/assistant.jpg";

const StudentOffers = () => {
    return(
        <>
        <section id="offer">
            <ComponentHeader title={"What we offer..."} numIcons={5} />
            <Chunk img={personWithLaptop} alt="access" title="Enroll To Your Preferred School With Your Finger Tips..." img_top_resp={true} is_put_img={true} >
                <p className="font-small">You can now register and enroll to your preferred school all in one sitting with your fingertips and avoid the hassle of physically going back and forth with the chosen school</p>
                <b className="font-small">Haven't enrolled yet? What are you waiting for?. Click on the link below</b>
                <div className="align-right">
                    <p className="font-small"><NavHashLink to="?t=student#schools">Enroll To A School</NavHashLink></p>
                </div>
            </Chunk>
            <Chunk img={moneyFast} alt="Money" title="Fast And Secure Online Payment...." desc={"Combining speed, convenience and top-notch security, we ensure that you enjoy a seamless online payment to your preferred school without headache. We offer different varieties of payment options using our tested payment gateway for a hitch-free payment. Our payments include : Pay with card, with bank, with transfer and more"} img_top_resp={true} is_put_img={true} is_put_img_right={true} />
            <Chunk img={teacherTeaching} alt="live" title="Live Timetable Teaching Session...." desc={"With the voskool app live timetable teaching session system, you can  view synopsis of what your teacher is currently teaching at the stipulated time on the timetable  and you can give reviews about the topic taught all on realtime "} img_top_resp={true} is_put_img={true} />
            <Chunk img={resultImg} alt="result_checker" title="Online Result Checker..." desc={"You can check your school result online with only your pin as the requirement on the result checker portal"} img_top_resp={true} is_put_img={true} is_put_img_right={true} />
            <Chunk title="Interactive Games" desc="This feature will be coming soon :)" />
            <Chunk img={assistantImg} title="24 Hrs Live Customer Support And More" is_put_img is_put_img_right img_top_resp>
                <p className="font-small">Get 24 hours uninterrupted customer support through our chat app or email address at <Link to="mailto:support@voskool.com">support@voskool.com</Link></p>
                <p className="font-small">Are you intrigued? click <Link to="/">here</Link> to see more of what we offer</p>
            </Chunk>
        </section>
        </>    
    );
}

export default StudentOffers;