import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import VoWrapper from "components/app/vo/SmallerComponents/wrapper";
import { Wrapper, Content } from "components/app/vo/signUpPage/style.js";
import PreloaderLine from "components/preloaderLine";
import Redirect from "components/redirectPage";

import { appServices } from "api/schoolApp/services";
import { load_user } from "actions/auth";
import authHandler from "actions/auth_handler";

const initialUserState = {
    email: "",
    first_name: "",
    id: 0,
    last_name: "",
    phone_number: "",
    user_name: "",
    user_type: null,
}
const PrerollmentPage = () => {
    const [service, setService] = useState('')
    const {sn} = useParams();

    const [school, setSchool] = useState({id:0, title:''})
    const [user, setUser] = useState(initialUserState);

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const fetchUser =  async () => {
        setIsAuthenticated(true);
        setLoading(true);
        await authHandler.load_user(load_user()).then(result => {
            setIsAuthenticated(result.isAuthenticated); 
            setUser({...result.data})
            setLoading(false)
        }).catch(error => {
            setIsAuthenticated(false);
            setLoading(false)
        })
    }

    const fetchSchool = (title) => {
        setPreloading(true);
        appServices.getSchool(title).then(res => {
            setSchool({id:res.data.id, title:res.data.title});
            setPreloading(false);
        }).catch(e => {
            setSchool({id:0, title:''});
            setPreloading(false);
        })
        
    }
    const insertThis = () => {
        if(school.id === 0 && service === "enroll"){
            return(
                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group mrn f-100">
                    <label style={{fontSize:'.8rem', padding:'10px'}}>Select the school you will like to enroll!</label>
                    <select className="f-c w100" name="service" onChange={(e) => setService(e.target.value)} value={service}>
                        <option value=""></option>
                        <option value="enroll">Enroll Your Kid To A School</option>
                        <option value="lesson">Apply For An Online Lesson For Your Kid</option>
                    </select>
                </motion.div>
            )
        }
        else{
            return(
                <></>
            )
        }
    }


    
    useEffect(() => {
        if(isAuthenticated === false){
            return navigate('/vo/signup');
        }
        fetchUser()
        fetchSchool(sn);
    }, [sn, user.id, isAuthenticated, navigate])

    useEffect(() => {
        if(service === "enroll" && school.id){
            return navigate(`/vo/enroll/${school.title}`);
        }
        if(service === "job_application" && school.id){
            return navigate(`/vo/job/application/${school.title}`);
        }
    }, [sn, service, school, navigate])

    return(
    <>
        {preloading && <PreloaderLine />}
        {!preloading && (
            <>
            {school.id ? (
                <VoWrapper page="Service" page_under="Choose What You Will Like To Do!" action="Select..."
        data={[{name:'User Select...', done:true}, {name:'Sign Up', done:true}, {name:'What Service Would You Like From Us Today?', done:false},]}>
                <Wrapper>
                    <form>
                        <Content>
                            {!service && (
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}} className="form-group mrn f-100">
                                    <label style={{fontSize:'.8rem'}}>Do you want to....</label>
                                    <select className="f-c w100" name="service" onChange={(e) => setService(e.target.value)} value={service}>
                                        <option value=""></option>
                                        <option value={user.user_type === "staff" ? `job_application` : `enroll`}>{user.user_type === "staff" ? 'Apply For A Job To A School' :  user.user_type === "parent" ? 'Enroll Your Kid To A School' : 'Enroll To A School'}</option>
                                        {/* <option value={user.user_type === "staff" ? `lesson_application` : `lesson`}>{user.user_type === "staff" ? 'Apply For On Online Lesson Teacher' : user.user_type === "parent" ? 'Apply For An Online Lesson For Your Kid' : 'Apply For An Online Lesson'}</option> */}
                                    </select>
                                </motion.div>
                            )}
                            
                            {insertThis()}
                        </Content>
                    </form>
                </Wrapper>
            </VoWrapper>
            ) : (
                <Redirect error title="400"   link1={`/?t=${user.user_type}#schools`} link1_title="Search Your School">Data loaded with errors..., search for your school from the link below</Redirect>
            )}
                
            </>
        )}
        
    </>
    )
}

export default PrerollmentPage;