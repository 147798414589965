import React, {useState, useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import { SchoolAppStyle } from 'GlobalStyle';

import LoginPage from "components/loginPage";
import ResultChecker from "components/app/resultChecker";
import SchoolWebsite from "containers/schoolWebsite";

const SchoolWebApp = ({state}) => {
    const [user, setUser] = useState({});

    useEffect(() => {
        setUser(state);
    }, [state])
    return(
        <>
            <Routes>
                <Route path="/result_checker" element={<ResultChecker user={user} />} />
                <Route path="/:sname"  element={<SchoolWebsite user={user} />} />
            </Routes>
            <SchoolAppStyle />
        </>
    )
}

export default SchoolWebApp;