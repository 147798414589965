import React from "react";

import { OuterWrapper, Wrapper, Content } from './style';

export const CertficationSliderWrapper = ({children}) => {
    return(
        <OuterWrapper>
            <Wrapper>
                {children}
            </Wrapper>
        </OuterWrapper>
    )
}

const CertficationSlider = ({src="", alt=""}) => {
    return(
       <Content>
           <img src={src} alt={alt} />
       </Content>
    )
}

export default CertficationSlider;