import React, {useState, useEffect,useMemo, Suspense} from 'react';
import { AnimatePresence } from 'framer-motion';
import { Routes, Route, useSearchParams, useNavigate, Link } from 'react-router-dom';
import { GlobalStyle } from 'GlobalStyle';
import { HeadComponent } from 'components/head';
import Loader from 'components/app/loader';
import Layout from 'components/app/vo/layout';
import Notification from "components/app/notification";
import CountDownTimer from 'components/app/countDownTimer';
import Background from 'components/Background';
import  NavBar  from 'components/navbar';
import Home from 'containers/home';
import Schools from 'containers/schools';
import SchoolOffers from 'containers/schoolOffers';
import StudentOffers from 'containers/studentOffers';
import ParentOffers from 'containers/parentOffers';
import Chunk from 'components/chunk';
import CenteredPage from 'components/app/centeredPage';
import Footer from "components/app/footer";
import PageDownBtn from 'components/app/chat/SmallerComponents/pageDownBtn';

import Header from 'components/school/header';

// import Pos from 'components/pos';

import voskool_logo from "images/voskool_logo.png";
import namelessSchool from "images/nameless_school.jpg";
import kidsLookingJpg from "images/kids_looking.jpg";
import olderCoupleJpg from "images/older_parent.jpg";
import teacherWBookJpg from "images/teacher_wbook.jpg";
import cacPng from "images/cac.png";

import LoginPage from "components/loginPage";
import SignUpPage from 'components/app/vo/signUpPage';
// import ActivateUserPage from 'components/activateUserPage';
import TermsOfService from 'components/voskoolTermOfService';
import PrivacyPolicy from 'components/voskoolTermOfService/privacyPolicy';
import { setParameter } from 'components/app/school/actions/url';
// import PasswordResetPage from 'components/passwordResetPage';
// import PasswordResetConfirmationPage from 'components/passwordResetPage/confirmationPage';

import { NotificationWrapper } from "components/app/notification/style";
// import Redirect from 'components/redirectPage';
// import ResultChecker from 'components/app/resultChecker';
// import {VoRoutes} from 'appContainer/voRoutes';
import { COUNTRIES_VO } from "api/voskool/services/countries";
import { appServices5 } from 'api/schoolApp/services';
import { phoneDivider } from 'components/app/actions/phone';
// import { NavHashLink } from 'react-router-hash-link';
import Chat from 'components/app/chat/chat';
import FlexWrapper from 'components/chunk/flexWrapper';
import CertficationSlider, { CertficationSliderWrapper } from 'components/app/chat/SmallerComponents/certficationSlider';
// const GlobalStyle = lazy(() => import('exportGlobalStyle'));
// const Layout = lazy(() => import('components/app/vo/layout'));
// const NavBar = lazy(() => import('components/navbar'));
// const Home = lazy(() => import('containers/home'));
// const Schools = lazy(() => import('containers/schools'));
// const SchoolOffers = lazy(() => import('containers/schoolOffers'));
// const StudentOffers = lazy(() => import('containers/studentOffers'));
// const ParentOffers = lazy(() => import('containers/parentOffers'));
// const Chunk = lazy(() => import('components/chunk'));


const IndexComponent = ({user={}}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);
    const [navigate, setNavigate] = useState('');
    
    const [currPageState, setCurrPageState] = useState({size:25, p:1, q:'', exp_rate:'', is_initial:false});
    const [error, setError] = useState(initialErrorState);

    const [isLoadingCountries, setIsLoadingCountries] = useState(true);
    const [isChangePageState, setIsChangePageState] = useState(false);
    const [isLoadingSchools, setIsLoadingSchools] = useState(true);
    // const [isLoadMore, setIsLoadMore] = useState(false);
    const [reload, setReload] = useState(true);
    const [isShowChatBtn, setIsShowChatBtn] = useState(false);

    const [schools, setSchools] = useState(initialPaginationState);
    const [countries, setCountries] = useState([]);

    const [searchParam] = useSearchParams();
    const nav = useNavigate();

    const onChange = (val) => {
        setNavigate(`?t=${val}`);
    }
    const fetchCountries = () => {
        setIsLoadingCountries(true);
        COUNTRIES_VO().then(res => {
            setCountries(res.data);
            setIsLoadingCountries(false);
        }).catch(() => {
            setIsLoadingCountries(false);
        })
    }
    
    const fetchAllSchools = (p=1, size=30, q="", exp_rate="", is_add=false) => {
        setIsLoadingSchools(true);
        setError({data:[], title:''})
        appServices5.getAllSchools(p, size, q, exp_rate).then(res => {
            setSchools((prev) => is_add ? {...res.data, results:[...prev.results, ...res.data.results]} : res.data);
            setIsLoadingSchools(false);
        }).catch(e => {
            setIsLoadingSchools(false);
            setSchools([]);
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'School Data Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
        })
    }
    const onCP = (item) => {
        setIsChangePageState(true);
        setCurrPageState({...currPageState, ...item});
    }
    const onClickChatBtn = () => {
        setError(initialErrorState);
        setTimeout(() => {
            if(user.id){
                setNavigate(setParameter('bt', 'chat'));
                return;
            }
            setError({title:'Error', data:['Login to access this feature']});
            return;
        })

    }
    // console.log(currPageState)
    // console.log(schools)

    const sectionStyle = {
        marginTop:'20px',
        marginBottom: '20px'
    }

    const mainSectionStyle = {
        margin:'30px'
    }

    useEffect(() => {
        fetchCountries();   
    }, []);
    useEffect(() => {
        if(isChangePageState){
            setIsChangePageState(false);
            fetchAllSchools(currPageState.p, currPageState.size, currPageState.q, currPageState.exp_rate, !currPageState.is_initial);
        }
    }, [isChangePageState, currPageState])
    useEffect(() => {
        if(reload){
            setReload(false);
            fetchAllSchools(currPageState.p, currPageState.size, currPageState.q, currPageState.exp_rate);
            return;
        }
    }, [reload, currPageState])
    useEffect(() => {
        if(navigate){
            nav(navigate);
            setNavigate('');
        }
    }, [navigate, nav]);

    useEffect(() => {
        if(!isShowChatBtn){
            setTimeout(() => {
                setIsShowChatBtn(true);
            }, 10000)
        }
    }, [isShowChatBtn])
    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} click_text={error.click_text} handleClick={error.handleClick} />)
    })

    return(
        <>
        <Suspense fallback={<Loader />}>
        <NotificationWrapper>
            {error.title && (errorHandler)}
        </NotificationWrapper>
        <NavBar />
            {/* {searchParam.get('t')  === "school_owner" && (
            <Pos is_fixed y_stop={2250} timer={10} styleWrapper={{background:'var(--voskoolBagColor)'}}>
                <div className='disp-block padd-4px'>
                    <div className='disp-block align-center'>
                        <span className='font-big app-bg-text-color fw-700'>Hey, The Voskool App just launched! </span>
                    </div>
                    <div className='disp-flex wrap jc-sb'>
                        <div className='disp-block' style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            <span style={{letterSpacing:'.4px'}} className='font-small app-bg-text-color fw-500'>To celebrate our launch, you can register your school with us for <b className='font-med text-upper'>free now!</b>
                            <br /><br /><span className='font-slightly-small app-bg-text-color fw-500'>Explore this amazing offer NOW, so you don't miss out</span>
                            </span>
                        </div>
                        <div className='disp-block'>
                            <div className='disp-block align-center'>
                                <span className='font-slightly-small fw-600' style={{color:'#555'}}>EXPIRES</span>
                            </div>
                            <CountDownTimer end={'2023-12-22 12:00:00'} />
                        </div>
                    </div>
                    <div className='disp-block align-center' style={{marginTop:'4px'}}>
                        <NavHashLink to={`?t=${searchParam.get('t')}#plans`} className={'font-slightly-small app-bg-text-color hover-opacity fw-600'}>See Our Packages <i className='fas fa-arrow-down'></i></NavHashLink>
                    </div>
                    
                </div>
            </Pos>
            )} */}
            <Home countries={countries.map((it) => {return {...it.country}})} is_loading_countries={isLoadingCountries} />
            {isShowChatBtn && (
                <PageDownBtn pos='fixed' icon="fas fa-comment-dots" is_big is_animate onClick={() => onClickChatBtn()} />
            )}    
            {searchParam.get('bt') === "chat" && (
                <>
                
                    {user.id && (
                        <>
                        <AnimatePresence>
                            <Chat user={user} user_id={user.id} user_type={user.user_type} />
                        </AnimatePresence>
                        </>
                    )}
                    
                
                </>
            )}
            <section style={mainSectionStyle}>
                <div className='disp-block align-center'>
                    <span className='font-super-big app-text-color padd-4px fw-700'>One technology companion for all your school needs</span>
                </div>
                <div style={sectionStyle} className='disp-block align-center'>
                    <span className='fw-500 app-text-color font-small padd-4px' style={{letterSpacing:'.4px'}}>
                        Our technology can greatly transform the way your school operates in a much easier and faster way, also cutting down cost in the process. <br /> <br />
                        Prospective parents can enroll their child to your school online with ease and comfort, teachers to can apply to teach for your school, all through the generated website made with voskool. <br /><br />
                        Fee payments can also be made with ease with our highly secure payment gateway <br />
                        Voskool technology solution gives your students a gamified learning experience with different variety of games <br />
                    </span>
                </div>
            </section>
            <section style={{...mainSectionStyle, marginTop:'40px'}}>
                <Chunk title="Why choose us right?">
                    <span className='disp-block align-center app-text-color fw-500 font-med'>Keep going to see what you are missing out!</span>
                </Chunk>
                <FlexWrapper>
                    <div className='flex-50 ' style={{paddingLeft:'15px', marginBottom:'30px'}}>
                        <div className='img-head-cont'>
                            <img className='img h-60vh' src={namelessSchool} alt="school" />
                        </div>
                        <div className='disp-block' style={{...sectionStyle}}>
                            <span className='font-big app-text-color fw-600'>For Your School</span>
                        </div>
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Optimize and simplify school administration</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Transform school learning operations</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online Website Generator</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online and easy student enrollment for parent or student</span>
                            </div>
                        </div>
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online job application for teachers</span>
                            </div>
                        </div>
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online Live Timetable Teaching Session and Tracking</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online Exam Timetable</span>
                            </div>
                        </div>
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Physical/Offline Exam Questions Generator</span>
                            </div>
                        </div>
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Exam Optical Mark Recognition (OMR) Sheet Generator For Student <span className='font-very-small'>(Newly Released: on trial) </span></span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online and Secure Fee Payment For Students</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >All school fee payments at once</span>
                            </div>
                        </div>    
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Report card management system</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Accessible in all kinds of device (android, apple, PC, smart TV ....)</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Chat System <span className='font-very-small'>(More features incoming) </span></span>
                            </div>
                        </div>   
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Announcement System <span className='font-very-small'>(Coming Soon) </span></span>
                            </div>
                        </div>    
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online Exam System <span className='font-very-small'>(Coming Soon) </span></span>
                            </div>
                        </div> 
                        
                    </div>       
                    <div className='flex-50 ' style={{paddingLeft:'15px', marginBottom:'30px'}}>
                        <div className='img-head-cont'>
                            <img className='img' src={olderCoupleJpg} alt="school" />
                        </div>
                        <div className='disp-block' style={{...sectionStyle}}>
                            <span className='font-big app-text-color fw-600'>For Parents</span>
                        </div>
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Optimize and eases the school processes</span>
                            </div>
                        </div>    
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online enrollment to the school of your choosing  for your kid(s) at the comfort of your home</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online and Secure School Fee Payment</span>
                            </div>
                        </div>    
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Easy communication process with chat system</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Check your kids result with ease</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online Interactive Games (Multiplayer or with ai) <span className='font-very-small'>(More features incoming) </span></span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Access to all student features</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Accessible in all kinds of device (android, apple, PC, smart TV ....)</span>
                            </div>
                        </div>    
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Chat System <span className='font-very-small'>(More features incoming) </span></span>
                            </div>
                        </div>  
                    </div>        
                </FlexWrapper>
                <FlexWrapper>
                    <div className='flex-50 ' style={{paddingLeft:'15px', marginBottom:'30px'}}>
                        <div className='img-head-cont'>
                            <img className='img' src={kidsLookingJpg} alt="school" />
                        </div>
                        <div className='disp-block' style={{...sectionStyle}}>
                            <span className='font-big app-text-color fw-600'>For Students</span>
                        </div>
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Optimize and eases the school processes</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online Interactive Games (Multiplayer or with ai) <span className='font-very-small'>(More features incoming) </span></span>
                            </div>
                        </div>    
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online enrollment to the school of your choosing at the comfort of your home</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online and Secure School Fee Payment</span>
                            </div>
                        </div>   
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' > Online Live Timetable Teaching Session</span>
                            </div>
                        </div>  
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Easy communication process with chat system</span>
                            </div>
                        </div>    
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >submit your assignment to your teacher with the chat system</span>
                            </div>
                        </div>
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Check your kids result with ease</span>
                            </div>
                        </div> 
                        
                    </div>       
                    <div className='flex-50 ' style={{paddingLeft:'15px', marginBottom:'30px'}}>
                        <div className='img-head-cont'>
                            <img className='img' src={teacherWBookJpg} alt="school" />
                        </div>
                        <div className='disp-block' style={{...sectionStyle}}>
                            <span className='font-big app-text-color fw-600'>For Teachers</span>
                        </div>
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Apply for job to any school of your choice</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Optimize and eases the school processes</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online Student Attendance System</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online Exam Related Systems</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' > Online Live Timetable Teaching Session</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Online Salary Payment Confimation (no tax involved)</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Salary Payment Tracking System</span>
                            </div>
                        </div> 
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >School curriculum management system</span>
                            </div>
                        </div>  
                        <div className='disp-flex  wrap jc-stretch padd-4px' >
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Accessible in all kinds of device (android, apple, PC, smart TV ....)</span>
                            </div>
                        </div>    
                        <div className='disp-flex  wrap jc-stretch padd-4px'>
                            <div className='disp-block padd-4px'>
                                <span className='far fa-check-circle font-med' style={{color:'#00057a'}}></span>
                            </div>
                            <div className='disp-block padd-4px'>
                                <span className='font-small app-text-color fw-500' >Chat System <span className='font-very-small'>(More features incoming) </span></span>
                            </div>
                        </div>  
                    </div>        
                </FlexWrapper>
            </section>
            <section style={{...mainSectionStyle, marginTop:'40px'}}>
                <Chunk title='What Makes VOSKOOL, "The Best"' />
                <div className='disp-block ' style={{marginLeft:'10px'}}>
                    <FlexWrapper>
                        <div className='flex-33-mr br padd-10px br' style={{marginBottom:'30px', marginRight:'10px', background:'var(--appTheme)'}}>
                            <div className='disp-block align-center' style={{marginBottom:'20px'}}>
                                <span className='app-bg-color padd-4px app-bg-text-color font-big fas fa-1' style={{borderRadius:'50px', paddingLeft:'8px', paddingRight:'8px'}}>1</span>
                            </div>
                            <div className='disp-block align-center' style={{marginBottom:'10px'}}>
                                <span className='app-text-color font-med fw-600'>All in one technology solution </span>
                            </div>
                            <div className='disp-block align-center'>
                                <span className='app-text-color font-slightly-small fw-500 padd-6px lt05px'>Engaging with different technology providers can be stressful and getting one can be time consuming and expensive, talk less unreliable for future use</span>
                            </div>
                        </div>
                        <div className='flex-33-mr br padd-10px br' style={{marginBottom:'30px', marginRight:'10px', background:'var(--appTheme)'}}>
                            <div className='disp-block align-center' style={{marginBottom:'20px'}}>
                                <span className='app-bg-color padd-4px app-bg-text-color font-big fas fa-industry' style={{borderRadius:'50px', paddingLeft:'8px', paddingRight:'8px'}}></span>
                            </div>
                            <div className='disp-block align-center' style={{marginBottom:'10px'}}>
                                <span className='app-text-color font-med fw-600'>Affordable, High-Quality technologies </span>
                            </div>
                            <div className='disp-block align-center'>
                                <span className='app-text-color font-slightly-small fw-500 padd-6px lt05px'>Affordable High quality educational technologies at your fingertips, </span>
                            </div>
                        </div>
                        <div className='flex-33-mr br padd-10px br' style={{marginBottom:'30px', background:'var(--appTheme)'}}>
                            <div className='disp-block align-center' style={{marginBottom:'20px'}}>
                                <span className='app-bg-color padd-4px app-bg-text-color font-big fas fa-handshake' style={{borderRadius:'50px', paddingLeft:'8px', paddingRight:'8px'}}></span>
                            </div>
                            <div className='disp-block align-center' style={{marginBottom:'10px'}}>
                                <span className='app-text-color font-med fw-600'>Reliable long-time Partnership </span>
                            </div>
                            <div className='disp-block align-center' >
                                <span className='app-text-color font-slightly-small fw-500 padd-6px lt-05px'> Voskool has been operating for half a decade, and we always try to make sure our customers stay in the long run with us. To make sure we help them and we too, grow in the process </span>
                            </div>
                        </div>
                        <div className='flex-33-mr br padd-10px br' style={{marginBottom:'30px', marginRight:'10px', background:'var(--appTheme)'}}>
                            <div className='disp-block align-center' style={{marginBottom:'20px'}}>
                                <span className='app-bg-color padd-4px app-bg-text-color font-big fas fa-code' style={{borderRadius:'50px', paddingLeft:'8px', paddingRight:'8px'}}></span>
                            </div>
                            <div className='disp-block align-center' style={{marginBottom:'10px'}}>
                                <span className='app-text-color font-med fw-600'>Constant New Innovative Technologies </span>
                            </div>
                            <div className='disp-block align-center' >
                                <span className='app-text-color font-slightly-small fw-500 padd-6px lt05px'>There are never-ending problems for our customers and we are constantly looking for ways to solve the next problem. <br /><br /> We dedicate our time, sweat and lives to make sure things are faster, efficient and little-to-no stress for you </span>
                            </div>
                        </div>
                        <div className='flex-33-mr br padd-10px br' style={{marginBottom:'30px', marginRight:'10px', background:'var(--appTheme)'}}>
                            <div className='disp-block align-center' style={{marginBottom:'20px'}}>
                                <span className='app-bg-color padd-4px app-bg-text-color font-big fas fa-gamepad' style={{borderRadius:'50px', paddingLeft:'8px', paddingRight:'8px'}}></span>
                            </div>
                            <div className='disp-block align-center' style={{marginBottom:'10px'}}>
                                <span className='app-text-color font-med fw-600'>Gamified Learning Experience </span>
                            </div>
                            <div className='disp-block align-center' >
                                <span className='app-text-color font-slightly-small fw-500 padd-6px lt-05px'> Students can learn in a manner that suits their wants (game) without parents having to constantly worry about the nature of the games being a bad influence </span>
                            </div>
                        </div>
                        <div className='flex-33-mr br padd-10px br' style={{marginBottom:'30px', background:'var(--appTheme)'}}>
                            <div className='disp-block align-center' style={{marginBottom:'20px'}}>
                                <span className='app-bg-color padd-4px app-bg-text-color font-big fas fa-heart' style={{borderRadius:'50px', paddingLeft:'8px', paddingRight:'8px'}}></span>
                            </div>
                            <div className='disp-block align-center' style={{marginBottom:'10px'}}>
                                <span className='app-text-color font-med fw-600'>Ease of use </span>
                            </div>
                            <div className='disp-block align-center' >
                                <span className='app-text-color font-slightly-small fw-500 padd-6px lt-05px'> Intuitive interfaces that require no previous know-how. Get started easily. </span>
                            </div>
                        </div>
                    </FlexWrapper>
                </div>
            </section>
            {!searchParam.get('t') ? (
                <div id="sel_type">
                <Chunk>
                    <div className='disp-block align-center' style={{width:'100%'}}>
                        <span className='font-big fw-700 text-capitalize' style={{color:'#26223a'}}>Are You {!searchParam.get('t') ? (
                            <select onChange={e => onChange(e.target.value)} className='form-control font-big fw-700' style={{width:'150px', outline:'none', background:'transparent'}}>
                            <option value={""}>Select Me...</option>
                            <option value={"school_owner"}>A Prinicipal/School Owner?</option>
                            <option value={"parent"}>A Parent?</option>
                            <option value={"student"}>A Student?</option>
                            <option value={"looking_for_a_job"}>Looking For A Job?</option>
                        </select>
                        ) : `A ${searchParam.get('t').split('_')}?`}, to proceed </span>
                    </div>
                </Chunk>
                </div>
            ) : (
                <>
                {searchParam.get('t')  === "school_owner" && (
                    <>
                    <SchoolOffers />
                    </>
                )}
                {searchParam.get('t')  === "student" && (
                    <>
                    <StudentOffers />
                    <Schools is_loading_countries={isLoadingCountries} countries={countries.map((it) => {return {...it.country}})} schools={schools} is_loading_schools={isLoadingSchools} setCP={onCP} />
                    </>
                )}
                {searchParam.get('t')  === "parent" && (
                    <>
                    <ParentOffers />
                    <Schools is_loading_countries={isLoadingCountries} countries={countries.map((it) => {return {...it.country}})} schools={schools} is_loading_schools={isLoadingSchools} setCP={onCP} />
                    </>
                )}
                {searchParam.get('t')  === "looking_for_a_job" && (
                    <>
                    <Schools is_loading_countries={isLoadingCountries} countries={countries.map((it) => {return {...it.country}})} schools={{...schools, results:schools.results.filter((it) => it.is_start_staff_enroll)}} is_loading_schools={isLoadingSchools} link_title='Apply' setCP={onCP} />
                    </>
                )}
                <CertficationSliderWrapper>
                    {/* <div className='disp-block flex-100'>
                        <h3 className='app-text-color'>Certification:</h3>
                    </div> */}
                    <div className='disp-block flex-100 align-center'>
                        <CertficationSlider src={cacPng} alt="CAC" />
                    </div>
                </CertficationSliderWrapper>
                <Footer style={{background:'var(--voskoolBagColor)', borderRadius:'0px'}}>
                    <div className="flex-100">
                        <div className='disp-block align-center mr-3px'>
                            <span className='fw-600 font-slightly-small' style={{color:'#ddd'}}>Voskool is a revolutionary way of which your school can be interacted with to reduce hassle and give you a seamless experience.</span>
                        </div>
                        <span className="disp-block font-big padd-4px app-opp-text-color fw-600" style={{marginTop:'7px'}}>NEED HELP, CONTACT US NOW!</span>
                        <div className="disp-flex wrap jc-stretch padd-4px">
                            <div className="padd-4px">
                                <i className="app-bg-color-text fas fa-paper-plane"></i>
                            </div>
                            <div  className="padd-4px">
                                <Link to="mailto:support@voskool.com" className="font-small fw-500" style={{color:'#ddd'}}>support@voskool.com</Link>
                            </div>
                        </div>
                        <div className="disp-flex wrap jc-stretch padd-4px">
                            <div className="padd-4px">
                                <i className="app-bg-color-text fas fa-phone"></i>
                            </div>
                            <div  className="padd-4px">
                                <span className="font-small fw-500" style={{color:'#ddd'}}>{phoneDivider('+2348127404244')}</span>
                            </div>
                        </div>
                    </div>
                </Footer> 
                </>
            )} 
            
        </Suspense>
        </>
    )
}

const LandingPageCoundownTimer = () => {
    return(
        <>
            <HeadComponent title="Voskool Launch Countdown Timer" />
            <Background bg_color='#26223a'>
                <CenteredPage bg_style={{background:'#eed'}}>
                    <div className="disp-block align-center">
                        <img style={{width:'150px', height:'50px'}} src={voskool_logo} alt="Voskool" />
                        <span className='disp-block font-bigger fw-600 app-text-color padd-4px align-center'>Launch Countdown Timer</span>
                    </div>
                    <div className="disp-block" style={{marginTop:'30px'}}>
                        <CountDownTimer end={new Date('2023/08/29 23:59')} style={{background:'#000', color:'#eee'}} font_item='font-slightly-small' font_item_number='font-big' />
                    </div>
                    <div className='disp-block' style={{marginTop:'20px', padding:'10px'}}>
                        <span className='font-very-small fw-600 app-text-color'>Do you have a school? <br /> Register your school with us now, to benefit a 100% discount which is only available before launch <br /> <br />  </span>
                        <div className='disp-block align-center'>
                            <span className='font-slightly-small fw-500 app-text-color'><Link className='fw-600' to="https://register.voskool.com/">Register Now! </Link> <span className='font-small fw-600'>OR See</span> <Link to="https://register.voskool.com/"> What We Offer!</Link></span>
                        </div>
                    </div>
                </CenteredPage>
            </Background>
        </>
    )
}

const Voskool = ({state}) => {
    const [user, setUser] = useState(state);

    useEffect(() => {
        setUser(state);
    }, [state]);
    return(
        <>  
            <Routes>
                {/* <Route path="*" element={<LandingPageCoundownTimer />} /> */}
                <Route path="/" element={<IndexComponent user={user.data} />} />
                <Route path="/vo/*" element={<Layout inner_state={user} />} />
                <Route path="/login" element={<LoginPage user={user.data} is_close={false} />} />
                {!user.isAuthenticated && (
                    <>
                        <Route path="/signup" element={<SignUpPage />} />
                        {/* <Route path="/reset_password" element={<PasswordResetConfirmationPage />} />
                        <Route path="/activate/:uid/:token" element={<ActivateUserPage />} />
                        <Route path="/password/reset/:uid/:token" element={<PasswordResetPage />} /> */}
                    </>
                ) }
                <Route path="/terms_of_service" element={<TermsOfService />} />
                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                {/* <Route path="/*" element={<Redirect error404 title='Page Not Found' link1='/' link1_title='Back To Voskool' />} /> */}
            </Routes>
            <GlobalStyle />
        </>
    )
}
export {LandingPageCoundownTimer}
export default Voskool;