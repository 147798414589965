import React, {useState} from "react";
import { CountryWrapper, CountryContent, CountryInp, CountryImage } from "components/countrySort/style";
// import RatedSchools from "components/ratedSchools";
import { BACKEND_URL } from "actions/url";
import Spinner from "components/spinner";

let initCountry =  {id:0, name:'', flag:'', code:''}
const Countries = ({position, countries=[], title="Top Rated Schools", is_change_country=false, is_loading=false, setCt}) => {
    const [state, setState] = useState("");
    const [countryData, setCountryData] = useState(initCountry);
    const [dispCountryData, setDispCountryData] = useState(false);
    const handleFilter = (e) => {
        setState(e.target.value);
    }
    const insert_countries =  countries.filter((it) => it.id !== countryData.id).filter((item) => {
        if(state === ""){
            return item;
        }
        else if(item.name.toLowerCase().includes(state.toLowerCase())){
            return item;
        }
        else{
            return {};
        }
    }).map((item, index) => {
        return(
            <div key={item.id} value={item.id} title={item.name} onClick={() => onClickCountry(item.id)}>
                <CountryImage src={`${BACKEND_URL}${item.flag}`}/>
                <button type="button">{item.name}</button>
            </div> 
        )
    })
    const dispData = () => {
        if(dispCountryData){
            setDispCountryData(false);
        }
        else{
            setDispCountryData(true);
        }
    }

    const onClickCountry = (e) => {
        const val = e;
        countries.filter((item) => {
            if(item.id.toString().includes(val)){
                setCountryData(item);
                setCt(item);
                setDispCountryData(false);
                return item;
            }
            else{
                return null;
            }
            
            
        })
    }
    const onLoad = () => {
        if(countries.length === 1){
            setCountryData({...countries[0]});
            setCt({...countries[0]});
        }
    }
    
    return(
        <>
        <CountryWrapper className={position} onLoad={e => onLoad()}> 
            <div className="selected-country">
                <img alt="" onClick={dispData}  title={countryData ? countryData.name : ""}  src={countryData ? `${BACKEND_URL}${countryData.flag}` : ""} /> <i onClick={dispData} className="fas fa-arrow-down"></i>
                <div  className={ dispCountryData ? `${" country-cont"} ${position}` : `${'disp-none country-cont'} ${position}`  }>
                    {is_loading ? (
                        <div className="disp-block align-center">
                            <Spinner />
                        </div>
                    ) : (
                        <CountryContent>
                            {is_change_country && <CountryInp type="text" onChange={e => handleFilter(e)} placeholder="Search Country's Name" name="" />}
                            {countryData.id && (
                                <div value={countryData.id} title={countryData.name} onClick={() => onClickCountry(countryData.id)} style={{paddingBottom:'10px', marginBottom:'5px', borderBottom:'1px dotted #666'}}>
                                    <CountryImage src={`${BACKEND_URL}${countryData.flag}`}/>
                                    <button type="button">{countryData.name}</button>
                                </div> 
                            )}
                            {insert_countries}    
                        </CountryContent>
                    )}
                    
                </div>
            </div>
        </CountryWrapper>
        <h3>{title}</h3>
        {/* {countryData ? (<RatedSchools country_code={countryData.code} />) : "No School Found"} */}
        </>
        
    )
    
}

export default Countries;