import React from "react";

import { Wrapper, Content } from './style';

const PageDownBtn = ({number=0, pos="absolute", is_big=false, icon="fas fa-angle-down", is_animate=false, onClick}) => {
    return(
        <Wrapper style={{position:pos}}>
            <Content className={`${is_animate ? 'animate' : ''}`}>
                <i onClick={onClick} className={`${icon} ${is_big ? 'font-big' : 'font-med'} hover-opacity app-bg-color-text app-theme`}></i>
                {number > 0 && (
                    <div className="num-cont">
                        <span className="font-super-small num">{number}</span>
                    </div>
                )}
                
            </Content>
        </Wrapper>
    )
}

export default PageDownBtn;