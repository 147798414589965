import React from "react";
import { Link } from "react-router-dom";

// import schools, { classify_rating } from "sort/voskool/schools";
import { BACKEND_URL } from "actions/url";
import { Wrapper, Content } from "components/ratedSchools/style";
import Spinner from "components/spinner";

const RatedSchools = ({schools=[], is_loading=false}) => {

    var count = 0;
    // const insert_schools = schools.filter((item) => {  
    //     if(item.country.toLowerCase().includes(country_code.toLowerCase())){
    //         return item;
    //     }
    //     else{
    //         return null;
    //     }
    // })
    const insert_schools = schools.length > 0 ? schools.map((item) => {
        count = count + 1;
        return (
           
            <Content key={count}>          
                <div className="list-numbering">
                    <h5>{count}</h5>
                </div>
                <div className="list-img">
                    <img src={`${BACKEND_URL}${item.icon}`} title={item.title} alt="" />
                </div>
                <div className="list-text">
                    <h5>{item.title}</h5>
                    <p>{item.name.length > 35 ? `${item.name.slice(0, 35)}...` : item.name}</p>   
                    {item.motto && (
                        <div className="footer">
                            <span><i>Motto {item.motto}"' </i></span>
                        </div>
                    )}
                    
                </div>  
                <div className="list-rate">
                    {/* <h6>Rating {       classify_rating(item.rating)             }<i>{item.rating}/5</i> </h6> */}
                    <div className="link-list">
                        <Link to={`/${item.title}`} target={'_blank'}>
                            <i className="fas fa-eye"></i>
                        </Link>
                    </div>
                    <div className="footer">
                     <span>{item.state_name}</span>
                    </div> 
                </div>
            </Content>  
        );
        
    }) : '';

    return(
        <Wrapper>
            {is_loading ? (
                <div className="disp-block align-center">
                    <Spinner />
                </div>
            ) : schools.length > 0 && insert_schools}
               
        </Wrapper>
    )
}

export default RatedSchools;