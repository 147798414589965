import React, {useState, useEffect} from "react";
import {  useSearchParams } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import parse from 'html-react-parser';
import { BACKEND_URL } from 'actions/url';
import {useHomeData} from "sort/voskool/useHomeData";
const Home70 = () => {
    const {homeData} = useHomeData();
    const [items, setItems] = useState(homeData[0]);
    const [index, setIndex] = useState(0);

    const [searchParam] = useSearchParams();
    
    useEffect(() => {
        const timer = setInterval(() => {
            setIndex((i) => (i + 1) % homeData.length)
        }, 1000 * 25);
        return () => clearInterval(timer);
    }, [homeData.length]);

    useEffect(() => {
        setItems(homeData[index])
    }, [index, homeData]);

    const dispImg = (i) => {     
        setIndex(i);
    }
    const dotIcon = (num, i) => {
        return(<span key={i} className={i===num ? "circle active" : "circle"} onClick={() => dispImg(i)}></span>);
    }
    const roundedIcons = (num) => {
        var rows = [];
        for(let i = 0; i < num; i++){
            rows.push(dotIcon(index, i));
        }
        return rows;
    }

    const SlideshowItems = ({event}) => {
        return(
        <>
            <div className="home-img-cont">
            <div className={`${" overlay-cont"}`}>
                <div className="overlay">
                    <div className="heading">
                        <div className="block"></div>
                        <h1>{event.title}</h1>
                    </div>
                    <div className="text-container">
                        <p>{event.text1}
                        </p>
                        <p style={{letterSpacing:'.5px'}}>{parse(event.text2)}
                        </p>
                        <p style={{letterSpacing:'.5px'}}>{event.text3}
                        </p>
                        <div className="link-cont">
                            {event.link_name ? <NavHashLink to={!searchParam.get('t') ? '#sel_type' : `?t=${searchParam.get('t')}${event.link_path}`}>{event.link_name} <i className="fas fa-arrow-down"></i></NavHashLink> : ""}
                        </div>
                    </div>
                </div>
            </div>
            <img src={event.id === -1 ? `${event.image}` : `${BACKEND_URL}${event.image}`} alt="" className={event.image_anim} />
        </div>
        <div className="rounded-icon-cont">
            <div className="rounded-icon">
                {roundedIcons(homeData.length)}
            </div>
        </div>
       </> 
        )
    }
    

    return (
        <>
          {<SlideshowItems event={items} />}
        </>    
    )
}

export default Home70;