export const setParameter = (name="", value="") => {
    const loc = window.location.href
    let data = '';
    const init_param = loc.split('//')[1].split('?');
    const loc_addr_spl = init_param[0].split('/');
    console.log(loc_addr_spl)
    let all_param = init_param.length > 1 ? init_param[1].split('&') : init_param;
    let key_param = [];
    for (let i = 0; i < all_param.length; i++){
        const spl = all_param[i].split('=');
        key_param.push(spl[0]);
        if(spl[0] === name){
            data = `${data}${i < 1 ? `?${name}=${value}` : `&${name}=${value}`}`;
            continue
        }
        data = `${data}${i < 1 ? `?${spl[0]}=${spl[1]}` : `&${spl[0]}=${spl[1]}`}`;
    }
    if(!key_param.includes(name)){
        data = `${data}${all_param.length < 1 ? `?${name}=${value}` : `&${name}=${value}`}`;
    }
    if(!loc_addr_spl[1] && !init_param[1]){
        data = `/?${name}=${value}`;
        return data
    }
    loc_addr_spl.shift();
    
    console.log(data)
    data = `/${loc_addr_spl.join('/')}${data}`;
   return data;
}

export const processNavigation = (type="reload") => {
    const loc = window.location.href;
    let data = '';
    const init_param = loc.split('//')[1].split('?');
    const loc_addr_spl = init_param[0].split('/');
    if(type === "reload"){
        loc_addr_spl.shift();
        data = `/${loc_addr_spl.join('/')}`;
    }
    return data;

}