import styled from "styled-components";

export const Content = styled.tr`
    transition: .3s ease;
    margin-bottom: 5px;
    &:nth-child(even){
        background-color:rgba(0,0,0,.04);
    }
    &:hover{
        background-color:rgba(0,0,0,.1);
    }
    .align-center{
        text-align: center;
    }
    .font-super-small{
            font-size: .7rem;
            @media(max-width: 780px){
                font-size: .65rem;
            }
            @media(max-width: 540px){
                font-size: .6rem;
            }
            @media(max-width: 320px){
                font-size: .5rem;
            }
            @media(max-width: 280px){
                font-size: .45rem;
            }
        }
        .font-very-small{
            font-size: .8rem;
            @media(max-width: 780px){
                font-size: .75rem;
            }
            @media(max-width: 540px){
                font-size: .65rem;
            }
            @media(max-width: 320px){
                font-size: .55rem;
            }
            @media(max-width: 280px){
                font-size: .5rem;
            }
        }
        .font-slightly-small{
            font-size: .85rem;
            @media(max-width: 780px){
                font-size: .75rem;
            }
            @media(max-width: 540px){
                font-size: .7rem;
            }
            @media(max-width: 320px){
                font-size: .6rem;
            }
            @media(max-width: 280px){
                font-size: .5rem;
            }
        }
        .font-small{
            font-size: 1rem;
            @media(max-width: 780px){
                font-size: .8rem;
            }
            @media(max-width: 540px){
                font-size: .75rem;
            }
            @media(max-width: 320px){
                font-size: .6rem;
            }
            @media(max-width: 280px){
                font-size: .55rem;
            }
        }
`;