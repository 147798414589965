import React, { useState, useEffect, useMemo, Fragment } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { motion } from "framer-motion";

import VoWrapper from "components/app/vo/SmallerComponents/wrapper";
import { Wrapper, Content } from "components/app/vo/signUpPage/style.js";
import { NotificationWrapper } from "components/app/notification/style";
import PreloaderLine from "components/preloaderLine";
import TABLE from "components/app/table_html/table";
import TR from "components/app/table_html/tr";
import TD from "components/app/table_html/td";

import { BACKEND_URL } from "actions/url";

import { appServices } from "api/schoolApp/services";
import { load_user } from "actions/auth";
import authHandler from "actions/auth_handler";

import Loader from "components/app/loader";
import Notification from "components/app/notification";
import Redirect from 'components/redirectPage';
import { validateSchoolStaffApption } from "components/app/actions/validation";
import { appServices5 } from "api/schoolApp/services";
import Spinner from "components/spinner";
import Note from "components/app/NOTE";


const initialUserState = {
    email: "",
    first_name: "",
    id: 0,
    last_name: "",
    phone_number: "",
    user_name: "",
    user_type: null,
}
const JobApplicationPage = () => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialPaginationState = useMemo(() => ({count:0, previous:undefined, next:undefined, results:[]}), []);
    const initialFormUserState = useMemo(() => ({apply:false, school_branch:''}), [])

    const [user, setUser] = useState(initialUserState);
    const [staff, setStaff] = useState({id:0, staff_type:''});
    const [school, setSchool] = useState({id:0, title:'', icon:'',name:''});
    const [staffSchoolType, setStaffSchoolType] = useState({id:0, staff_t:'', name:''});
    const [formUser, setFormUser] = useState(initialFormUserState);
    const [formUserError, setFormUserError] = useState({});
    const [currPageState, setCurrPageState] = useState({size:25, p:1, q:'', exp_rate:'', is_initial:false});

    const [allSchoolBranch, setAllSchoolBranch] = useState([]);
    const [allAppliedSchool, setAllAppliedSchool] = useState([]);
    const [schools, setSchools] = useState(initialPaginationState);

    const [loading, setLoading] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const [reload, setReload] = useState(true);
    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [isLoadingSchools, setIsLoadingSchools] = useState(false);
    const [isChangePageState, setIsChangePageState] = useState(false);
    const [isStaffAuthenticated, setIsStaffAuthenticated] = useState(true);

    const [submit, setSubmit] = useState('');
    const [formName, setFormName] = useState('school_select');
    const [schoolSearch, setSchoolSearch] = useState('');

    const navigate = useNavigate();
    const {sn} = useParams();

    const fetchUser =  async () => {
        setIsStaffAuthenticated(true);
        setIsAuthenticated(true);
        setPreloading(true);
        await authHandler.load_user(load_user()).then(result => {
            setIsAuthenticated(result.isAuthenticated); 
            setUser({...result.data});  
            appServices.getStaffSchool(result.data.id).then(res => {
                setStaff({...res.data});
                setIsStaffAuthenticated(true);
                appServices.getStaffSchoolTypeData(res.data.staff_type).then(res => {
                    setStaffSchoolType({...res.data});
                    setPreloading(false);
                }).catch(e => {
                    setStaffSchoolType({id:0, staff_t:'', name:''});
                    setPreloading(false);
                }) 
            }).catch(e => {
                setStaff({id:0});
                setIsStaffAuthenticated(false);
                setPreloading(false);
            })
            
        }).catch(error => {
            setIsAuthenticated(false);
            setIsStaffAuthenticated(false);
            setPreloading(false)
        })
    }

    const fetchAllSchools = (p=1, size=30, q="", exp_rate="", is_add=false) => {
        setIsLoadingSchools(true);
        setError({data:[], title:''})
        appServices5.getAllSchools(p, size, q, exp_rate).then(res => {
            setSchools((prev) => is_add ? {...res.data, results:[...prev.results, ...res.data.results].filter((it) => it.is_start_staff_enroll)} : res.data);
            setIsLoadingSchools(false);
        }).catch(e => {
            setIsLoadingSchools(false);
            setSchools({count:0, previous:undefined, next:undefined, results:[]});
            setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'School Data Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
        })
    }
    const fetchAllAppliedSchool = () => {
        appServices.getAllStaffSchoolStaffApplication().then(res => {
            setAllAppliedSchool(res.data);
        }).catch(e => {
            setAllAppliedSchool([]);
        })
    }
    const fetchSchool = (title) => {
        if(title === "school"){
            setFormName('school');
            return;
        }
        appServices.getSchool(title).then(res => {
            setFormName('application');
            setSchool({id:res.data.id, title:res.data.title, icon:res.data.icon, name:res.data.name});
            appServices.getAllSchoolBranch(res.data.id).then(res => {
                setAllSchoolBranch([...res.data]);
            }).catch(e => {
                setAllSchoolBranch([]);
            })
        }).catch(e => {
            setSchool({id:0, title:'', icon:'', name:''});
            setFormName('school_select');
        })
        
    }

    const onChangeSearchSchool = (e) => {
        setIsChangePageState(true);
        setSchoolSearch(e.target.value);
    }
    const handleUserChange = (e) => {
        setFormUser({...formUser, [e.target.name]:e.target.value});
    }
    const handleUserSelect = (e, val) => {
        setFormUser({...formUser, [e.target.name]:val});
    }
    const onClickSchools = () => {
        setFormUser(initialFormUserState);
        setFormName('applied_school');
    }
    const onClickStat = (type="", id=0) => {
        if(type === "accepted"){
            setLoading(true);
            appServices.handleStaffSchoolAccess(id, type).then(res => {
                setLoading(false);
                fetchAllAppliedSchool();
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Data Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
                setLoading(false);
                fetchAllAppliedSchool();
            })
            return;
        }
       if(type === "rejected"){
            setLoading(true);
            appServices.handleStaffSchoolAccess(id, type).then(res => {
                setLoading(false);
                fetchAllAppliedSchool();
            }).catch(e => {
                setError({data:[e.response.data.detail ? e.response.data.detail : 'Something Went Wrong'], title:'Data Fetch Error', click_text:'Reload', handleClick: () => setReload(true)});
                setLoading(false);
                fetchAllAppliedSchool();
            })
            return;
        }
    }
    const onFinishApplication = (e) => {
        e.preventDefault();
        setSubmit('insert_app');
        setFormUserError(validateSchoolStaffApption(formUser));
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        if(submit === "inserted_app"){
            setLoading(true);
            const data = {
                school:school.id,
                school_branch:formUser.school_branch,
                is_applied: true,
                staff: staff.id,
                staff_type: staff.staff_type
            }
            appServices.insertStaffSchoolApplication(data).then(res => {
                setLoading(false);
                setSubmit('');
                setFormUser(initialFormUserState);
                setSuccess({title:'Success', text:`Application Form Submitted Successfully`});
                setFormName('complete');
            }).catch(e => {
                setLoading(false);
                setSubmit('');
                if(e.response.data.status === "application_exists"){
                    setError({title:'Application Error', data:[e.response.data.detail, 'Click back to see other schools']})  
                }   
                else{   
                    setError({title:'Application Error', data:[e.response.data.detail]})  
                }
                
            })
        }
    }
    useEffect(() => {
        if(isAuthenticated === false){
            return navigate('/vo/signup');
        }
        if(isStaffAuthenticated === false){
            return navigate('/vo/job/application');
        }
        fetchUser();
        fetchSchool(sn);
    }, [isAuthenticated, navigate, isStaffAuthenticated, sn]);
    useEffect(() => {
        if(reload){
            fetchAllSchools(currPageState.p, currPageState.size, currPageState.q, currPageState.exp_rate);
            fetchAllAppliedSchool();
            setReload(false);
        }
    }, [reload, currPageState]);
    useEffect(() => {
        if(Object.keys(formUserError).length === 0 && submit === "insert_app"){
            setSubmit("inserted_app");
        }
    }, [formUserError, submit,  navigate]);
    useEffect(() => {
        if(isChangePageState){
            setIsLoadingSchools(true);
            const timeout = setTimeout(() => {
                setIsChangePageState(false);
                setCurrPageState((prev) => {return {...prev, p:1, q:schoolSearch, is_initial:true}});
            }, 1000);
            return () => {
                clearTimeout(timeout);
            }
        }
    }, [isChangePageState, currPageState, schoolSearch])

    useEffect(() => {
        if(currPageState.is_initial){
            setIsLoadingSchools(true);
            fetchAllSchools(currPageState.p, currPageState.size, currPageState.q, currPageState.exp_rate, !currPageState.is_initial);
            setCurrPageState((prev) => {return {...prev, is_initial:false}});
            fetchAllAppliedSchool();
        }
    }, [currPageState])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })
    const schoolBranchOptions = allSchoolBranch.map((item) => {
        return(
            <option key={item.id} value={item.id}>{item.name}</option>
        )
    })
    console.log(staff)
    const statFunc = (item={}) => {
        if(item.is_applied && item.is_accepted && item.is_staff_accepted){
            return (<><span className="font-slightly-small green fw-600">APPROVED</span> <br />
                <div className="disp-block align-center" style={{marginTop:'10px'}}>
                    <Link to={`https://app.voskool.com/${item.school_title}/login`} className="btn app-bg-color hover-opacity  padd-4px br app-bg-text-color font-super-small" target={'_blank'} style={{padding:'4px'}}>Start School App</Link>
                </div>
            </>);
        }
        if(item.is_applied && item.is_accepted && item.is_staff_declined ){
            return (<span className="font-slightly-small red fw-600">Declined</span>);
        }
        if(item.is_applied && item.is_accepted){
            return (<><span className="font-slightly-small green fw-600">Offer Accepted</span> <br /> 
                <div className="disp-block align-center" style={{marginBottom:'10px'}}>
                    <button type="button" className="btn bg-green hover-opacity  padd-4px br app-bg-text-color font-super-small" style={{padding:'4px'}} onClick={() => onClickStat('accepted', item.id)}>Accept</button>
                </div>
                <div className="disp-block align-center">
                    <button type="button" className="btn bg-red hover-opacity padd-4px br app-bg-text-color font-super-small" style={{padding:'4px'}} onClick={() => onClickStat('rejected', item.id)}>Decline</button>
                </div>
            </>);
        }
        if(item.is_applied && item.is_interviewed){
            return (<span className="font-slightly-small app-text-color fw-500">Pending (Interviewing Proccess)</span>);
        }
        if(item.is_applied){
            return (<span className="font-slightly-small app-text-color fw-500">Pending</span>);
        }
        if(!item.is_applied){
            return (<span className="font-slightly-small red fw-600">Rejected</span>);
        }
    }

    const formInsert = () => {
        if(formName === "school"){
            return(
                <VoWrapper back="/" back_title="Back To Voskool" page="Choose A School (JOBBER)" page_under="Select School ..." action={`Search for a school...`}
                data={[{name:'What Service Would You Like From Us Today?', done:true}, {name:'Personal Form', done:true}, {name:'CV', done:true}, {name:'Select School....', done:true}, {name:'School Job Application', done:false}, {name:'Role', done:false},]}>
                    <Wrapper>
                        <Content> 
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-100">
                                <input type="text" className={`f-c font-slightly-small`} name="search" placeholder="Search ..." value={schoolSearch} onChange={e => onChangeSearchSchool(e)} />
                            </motion.div>
                            
                        </Content>
                        {isLoadingSchools && <><div className="disp-block align-center"><Spinner /></div></>}
                            {schools.results.length > 0 && (
                                <Wrapper className="w100">
                                    <TABLE>
                                        <tbody>
                                            {schools.results.map((item, i) => {
                                                return(
                                                    <Fragment key={i}>
                                                        <TR>
                                                            <TD style={{width:'85%'}}>
                                                                <span className="font-very-small app-text-color fw-600 text-capitalize">{item.name} ({item.title.toUpperCase()})</span>
                                                            </TD>
                                                            <TD>
                                                                <span className="btn hover-opacity bg-blue app-bg-text-color padd-4px br font-super-small" onClick={() => navigate(`/vo/job/application/${item.title}`)}  style={{padding:'3px'}}>Apply Now</span>
                                                            </TD>
                                                        </TR> 
                                                    
                                                    </Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </TABLE>
                                </Wrapper>
                            )}
                     
                        
                    </Wrapper>
                </VoWrapper>
            )
        }
        if(formName === 'application'){
            return(
                <VoWrapper back="/vo/job/application" back_title="" page="JOBBER" page_under="Application Form Page" action={`${school.title.toUpperCase()} APPLICATION`} img={`${BACKEND_URL}${school.icon}`}
                data={[{name:'What Service Would You Like From Us Today?', done:true}, {name:'Personal Form', done:true}, {name:'CV', done:true}, {name:'Select School....', done:true}, {name:'School Job Application', done:false}, {name:'Role', done:false},]}>
                    <Wrapper>
                        {loading && <Loader />}
                        <form onSubmit={e => onFinishApplication(e)}>
                            <Content>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-100">
                                    <label style={{fontSize: '1.2rem'}}>Would You Like To Apply As A <b>{staffSchoolType.name.toUpperCase()}</b> To <b>{school.name.toLocaleUpperCase()}</b>?<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                    <input type="checkbox" name="apply" value={formUser.apply} checked={formUser.apply} onChange={e => handleUserSelect(e, !formUser.apply)}   />
                                    {formUserError.apply && (<span className="error">{formUserError.apply}</span>)}
                                </motion.div>
                                {formUser.apply && (
                                    <>
                                        <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.4}} className="form-group f-100">
                                            <label>Select A Branch Of Our School You Will Like To Apply To<span style={{color:'red', fontSize:'1rem'}}>*</span></label>
                                            <select className={formUserError.school_branch ? `f-c b-red`: `f-c`} name="school_branch" value={formUser.school_branch} onChange={e => handleUserChange(e)}>
                                                <option value="">Select...</option>
                                               {schoolBranchOptions}
                                            </select>
                                            {formUserError.school_branch && (<span className="error">{formUserError.school_branch}</span>)}
                                        </motion.div>
                                    </>
                                )}
                                {allAppliedSchool.length > 0 && (
                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className={`form-group align-right f-100`} style={{marginTop:'15px'}}>
                                        <span className="rem1_" style={{color:"blue", cursor:'default'}} onClick={() => setFormName('applied_school')}> Click here to check all schools applied to! </span>
                                    </motion.div>
                                )}
                                
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className={`form-group align-right f-100`} style={{marginTop:'15px'}}>
                                    <span className="rem1_"> Not the school you are looking for? <Link to="/vo/job/application/school" className="rem1_">Click here to view other schools</Link></span>
                                </motion.div>

                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className={`form-group align-center f-50-alt`} style={{marginTop:'15px'}}>
                                    <button type="button" onClick={() => onClickSchools()} className="btn hover-b br-5 shadow rem1_"><i className="fas fa-arrow-left"></i> Back</button>
                                </motion.div>
                                <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className={`form-group align-center f-50-alt`} style={{marginTop:'15px'}}>
                                    <button type="submit" className="btn hover-a br-5 shadow rem1_ bg-black">Finish <i className="fas fa-paper-plane"></i></button>
                                </motion.div>
                            </Content>
                        </form>
                    </Wrapper>
                    <Wrapper>
                    {allAppliedSchool.length > 0 && (
                        <>
                        <div className="disp-block align-center">
                            <h5 className="app-text-color">All Schools Applied</h5>
                        </div>
                        <TABLE>
                            <tbody>
                                <>
                                    {allAppliedSchool.map((item, i) => {
                                        return(
                                            <Fragment key={i}>
                                                <TR>
                                                    <TD style={{width:'85%'}}><span className="font-slightly-small app-text-color fw-600 text-capitalize">{item.school_name} ({item.school_title.toUpperCase()})</span></TD>
                                                    <TD>{statFunc(item)}</TD>
                                                </TR>
                                            </Fragment>
                                        )
                                    })}
                                </>
                            </tbody>
                        </TABLE>
                        </>
                    )}
                    </Wrapper>
                </VoWrapper>
            )
        }
        if(formName === "applied_school"){
            return(
                <>
                <VoWrapper back="/vo/job/application" back_title="" page="All Applied Schools" page_under="Applied Schools" action={`All Applied Schools`}
                data={[{name:'What Service Would You Like From Us Today?', done:true}, {name:'Personal Form', done:true}, {name:'CV', done:true}, {name:'Select School....', done:true}, {name:'School Job Application', done:false}, {name:'Role', done:false},]}>
                    <Wrapper>
                        <TABLE>
                        <tbody>
                            {allAppliedSchool.length > 0 ? (
                                <>
                                    {allAppliedSchool.map((item, i) => {
                                        return(
                                            <Fragment key={i}>
                                                <TR>
                                                    <TD style={{width:'85%'}}><span className="font-slightly-small app-text-color fw-600 text-capitalize">{item.school_name} ({item.school_title.toUpperCase()})</span></TD>
                                                    <TD>{statFunc(item)}</TD>
                                                </TR>
                                            </Fragment>
                                        )
                                    })}
                                </>
                            ) : (
                                <>
                                <TR>
                                    <TD style={{width:'100%'}}><span className="font-slightly-small red fw-600 text-capitalize">No Record Found</span></TD>
                                </TR>
                                </>
                            )}
                            
                        </tbody>
                        </TABLE>
                        <Content>
                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className={`form-group align-center f-100`} style={{marginTop:'15px'}}>
                                <button type="click" className="btn hover-a br-5 shadow rem1_ bg-black text-capitalize" onClick={() => setFormName('application')}>{school.title} Application Form <i className="fas fa-arrow-right"></i></button>
                            </motion.div>
                        </Content>
                        <Note>Keep refreshing this page, it might change!</Note>
                    </Wrapper>
                </VoWrapper>
                </>
            )
        }
        if(formName === "complete"){
            return(
                <Redirect success title="Application Submitted Successfully" link1="/" link1_title="Back To Homepage" link2={`/`} link2_title="Check Application Status">
                    Your application to {school.name} ({school.title.toUpperCase()}) is currently been processed, Please wait for 24 hours an email will be sent for further actions or click on the link below to see application status
                </Redirect>
            )
        }
        if(formName === "approved"){
            return(
                <Redirect success title="Application Submitted Successfully" link1="/" link1_title="Back To Homepage" link2={`https://app.voskool.com/${school.title}/login`} link2_title="Start School App">
                    Your application to {school.name} ({school.title.toUpperCase()}) has been approved, explore the school app now
                </Redirect>
            )
        }
    }

    return(
        <>
            <NotificationWrapper>
                {error.title && (errorHandler)}
                {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
            </NotificationWrapper> 
            {preloading && <PreloaderLine />}
            {!preloading && (
                <>
                    {user.user_type === "staff" ? formInsert() : (<Redirect error404 link1="/" link1_title="Back To Homepage" />)}
                </>
            )}   
        </>
    )
}

export default JobApplicationPage;